import React from "react";
import { Divider, Typography } from "@mui/material";
import LeatherHatch from "../Images/Leatherback-Sea-Turtle-Hatchlings.jpg";
export default function EvaluatingtheEffectsofPaternity() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <div className="pb-5">
          <h1 className="text-2xl font-bold">
            Evaluating the Effects of Paternity on Hatchling Body Size in Leatherback Sea Turtle
            Hatchlings <br />
            (Dermochelys coriacea) Using DNA Microsatellites
          </h1>
        </div>
      </div>
      <div className="lg:flex mb-2">
        <div className="lg:w-1/2">
          <Typography paragraph>
            Shreya Banerjee, <br />
            M.S. Student, Marine Biology, <br />
            Scripps Institution of Oceanography <br />
            University of California, San Diego <br />
          </Typography>
        </div>
        <div className="lg:w-1/2">
          <Typography paragraph>
            Marine Mammal and Turtle Division <br />
            Southwest Fisheries Science Center <br />
            National Marine Fisheries Service, NOAA <br />
            8901 La Jolla Shores Drive <br />
            La Jolla, CA, 92037 <br />
          </Typography>
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="lg:flex my-5">
        <div className="lg:w-2/3">
          <Typography paragraph>
            Male leatherback turtles (Dermochelys coriacea) are rarely seen, and it is difficult to
            assess aspects of their reproductive success, such as the quantity and body size of
            their offspring. Molecular techniques can reveal paternal contributions to hatchlings
            without sampling the males themselves, provided that the female’s identity is known.
            From June through August (2015, 2016), we measured body size and collected skin samples
            from leatherback hatchlings at Sandy Point National Wildlife Refuge, St. Croix, USVI.
            Seven microsatellite DNA markers will be used to evaluate paternity within 20
            leatherback nests laid by individual females. Maternal and hatchling genotypes will be
            used to reconstruct paternal genotypes and assign hatchlings to a father. For nests with
            multiple paternity, we will compare the carapace lengths and weights of hatchlings from
            the same mother but from different fathers to determine whether there is a significant
            difference in size for hatchlings based on paternal identity. We will determine whether
            nests with multiple paternity have greater size variation than single paternity nests.
          </Typography>

          <Typography paragraph>
            We will also quantify the overall size variation among leatherback hatchlings at SPNWR.
            Understanding factors that affect hatchling body size, which may be a measure of fitness,
            and identifying males through paternity analysis will help inform management strategies
            for leatherbacks. We greatly appreciate the financial support of The National Save The Sea
            Turtle Foundation in furthering this important research.
          </Typography>
        </div>
        <div className="lg:w-1/3 lg:ml-5">
          <img className="rounded-2xl mx-auto" src={LeatherHatch} alt="" title="" />
        </div>
      </div>

      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="my-5">
        <Typography paragraph>
          <strong className="underline">Objectives:</strong>
        </Typography>
      </div>
      <div>
        <Typography paragraph>
          Determine if there is a correlation between hatchling size variation and differential
          paternity within leatherback clutches.
        </Typography>
      </div>
      <div>
        <Typography paragraph>
          Determine whether there is a correlation between hatchling size variation and the number
          of contributing fathers among leatherback clutches.
        </Typography>
      </div>
      <div>
        <Typography paragraph>
          Quantify overall leatherback hatchling size variation at Sandy Point National Wildlife
          Refuge, St. Croix, USVI.
        </Typography>
      </div>
      <div>
        <Typography paragraph>
          <strong className="underline">Expected Outcomes:</strong>
        </Typography>
      </div>
      <div>
        <Typography paragraph>
          A preliminary study using eight nests with 12 hatchlings each revealed multiple paternity
          in four out of eight nests. For these four nests, we found no significant difference
          between the sizes of hatchlings based on paternal identity within a clutch. A larger
          sample size is needed to strengthen and further evaluate this conclusion. This
          investigative study found a much higher rate of multiple paternity (50%) than the
          previously reported rate of 23.6% (Stewart and Dutton, 2014). The eight nests analyzed
          were selected because they had the highest size variation of nests measured. It is
          possible that this biased selection, in addition to small sample size, explains the high
          rate of multiple paternity. This indicates that nests with multiple paternity may have
          more within nest body size variation, and this warrants further investigation with more
          hatchlings from additional nests using random selection of both nests and hatchlings.
        </Typography>
      </div>
    </div>
  );
}
