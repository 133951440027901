import React from 'react'
import { Typography, Divider } from '@mui/material'
import SeaTurtleMonitoring from '../Images/Sea-Turtle-Monitoring-and-Satellite-Tracking-Project.jpg'
import volunteerteamStCroix from '../Images/volunteer-team-St-Croix.jpg'
import volunteerteamSandyPoint from '../Images/volunteer-team-at-Sandy-Point.jpg'
import LeatherbackTrackUpdate from '../Images/Leatherback-Track-Update.jpg'

export default function SeaTurtleMonitoringandSatelliteTracking() {
    return (
        <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>

            <div className='text-2xl font-bold pb-5'><h1>Sea Turtle Monitoring and Satellite Tracking Project at Sandy Point National Wildlife Refuge,<br />St. Croix, Virgin Islands, 2021</h1></div>
            <Divider className='dark:bg-[#032546] bg-white/70' />

            <div className='mt-5'><img src={SeaTurtleMonitoring} alt='' title='' className='rounded-2xl mx-auto' /></div>
            <div className='lg:flex lg:gap-5 mt-5'>
                <div className='lg:w-1/2'><Typography>Thanks to the support of the National Save the Sea Turtle Foundation, we had a great 2021 at Sandy Point National Wildlife Refuge! We had a small crew to start the leatherback nesting season in March, but completed 10 weeks of night patrols. We had quite a bump up from last year’s numbers and encountered 27 individual leatherbacks. The oldest (from 1999) and biggest turtle (174 cm) was Rosie - we saw her several times, including one cloudy afternoon (see photo). We built upon our satellite tracking program for leatherbacks and deployed another eight tags! Kelly Stewart and Claudia Lombard (refuge biologist) deployed four of these tags during one particularly intense night! Mike Evans (refuge manager) and Dante Trivett (resource specialist) assisted with leatherback patrols and satellite tagging. Our colleagues at the Canadian Sea Turtle</Typography></div>
                <div className='lg:w-1/2'>
                    <div><Typography paragraph>Network had satellite tagged our turtle Carol during summer 2020, but her tag had failed. Despite this, they were expecting her back to nest at Sandy Point. And we found her! We replaced her broken satellite tag with a new one before she left the nesting grounds. Three turtles are still transmitting as of 1 December and are off the east coast of the United States (see map).</Typography></div>
                    <div><Typography paragraph>We supported a small but mighty team and accomplished a great deal this year. We finally were able to resume the outreach program and along with Fish and Wildlife at the refuge, we presented two great in-person programs – Turtle Watch and Sea Turtle Scientist. Our Outreach and Education Specialist, Haley Jackson (a recent Duke masters graduate), did a fantastic job organizing, scheduling, and</Typography></div>
                </div>
            </div>

            <div>
                <div>
                    <div className='lg:float-right lg:ml-5'>
                        <img src={volunteerteamStCroix} alt='' title='' className='rounded-2xl mx-auto mb-5' />
                    </div>
                    <div><Typography paragraph>leading these afternoon beach sessions, with help from our strong and enthusiastic volunteer team. The children of St. Croix were able to see leatherback hatchlings emerging from their nests during Turtle Watch in July. During Sea Turtle Scientist later in October, St. Croix children and youth watched our team do a nest excavation for green and hawksbill turtles. Both programs were really well received and the FWS plans to run them again next year.</Typography></div>
                    <div><Typography paragraph>We spent more time with nesting green and hawksbill turtles this year, doing more than three weeks of night work during September and October. We saw tagged green turtles on 73 occasions, representing 51 individuals, a big increase over last year. Hawksbills were a bit more cryptic this year, and although we saw several, not all of them nested, so we tagged 10 turtles. We collected genetic samples from all, and these will contribute to our population studies on both species.</Typography></div>
                    <div><Typography paragraph>The Youth Conservation Corps (YCC) team of Yaira Ortiz and Jahsendi Simmonds, led by Dante Trivett, helped so much with day patrols, leatherback genetic sampling, and leatherback nest excavations. We counted on them every day of the summer! YCC is a great summer job experience provided by Fish and Wildlife.</Typography></div>

                </div>

            </div>

            <div>
                <div className='lg:float-right lg:ml-5'>
                    <div><img src={LeatherbackTrackUpdate} alt='' title='' className='rounded-2xl mx-auto' /></div>
                    <div className='pt-2 pb-5'><em>Our project is updated regularly on our website at<br />
                        STXTurtles.com. We also have a Facebook page<br />
                        (@stxleatherbacks) and post on Instagram<br />(#stxturtles).
                        Our sincere thanks to National<br />Save the Sea Turtle
                        Foundation for the<br />support of our program this year!
                        The St. Croix<br />Sea Turtle Project is sponsored by The
                        Ocean<br />Foundation, a 501(c)(3) organization, working
                        in<br />cooperation with the US Fish and Wildlife Service<br />and
                        NOAA National Marine Fisheries Service Marine<br />Turtle
                        Genetics Program in La Jolla, California.</em></div>
                </div>
                <div className='lg:float-left lg:mr-5'><img src={volunteerteamSandyPoint} alt='' title='' className='rounded-2xl mx-auto mb-5' /></div>
                <div><Typography paragraph>We supported three graduate students this year, who are collecting data in the Virgin Islands for their thesis work. Makayla Kelso (University of the Virgin Islands) and Paige Kauffman (UC San Diego) worked on digitizing and analyzing historical records of hawksbill and green turtles and this important data is now integrated into the Sandy Point database! Katie Ayres (University of the Virgin Islands) worked on St. Thomas to build a citizen science program to help her record hawksbill activity on that island. Overall, we are learning so much more about our green and hawksbill populations and covering more beaches regularly!</Typography></div>
                <div><Typography paragraph>Temperature loggers were deployed in over 50 hawksbill nests at Sandy Point (as a complement to Katie Ayres study in St. Thomas). Dante Trivett is helping to develop our sand temperature profile project and deployed many of the loggers in the hawksbill nests. It’s important to understand what’s happening at the local level with sand temperatures and nest temperatures, both for determining possible hatchling sex ratios but also for monitoring climate change and sea level rise.</Typography></div>
                <div><Typography paragraph>The volunteer team at Sandy Point is growing and extremely enthusiastic about all components of the sea turtle conservation work. Volunteers helped with all turtle patrols, nest excavations, outreach programs and maintenance projects all year long. We celebrated their accomplishments with them in November with a lunchtime get together (see photo). Again this year, we used a mobile app for collecting all data for all three species nesting at Sandy Point. We did training for the app and for identifying turtle crawls for volunteers, who patrol seven days a week.</Typography></div>
                <div><Typography>The National Save the Sea Turtle Foundation was again instrumental in our success in 2021! We are so grateful for their help in getting this critical conservation work done!</Typography></div>

            </div>
        </div>
    )
}
