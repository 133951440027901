import React from "react";
import { Typography, Divider } from "@mui/material";
import GM from "../Images/G-M.png";
import GMB from "../Images/Green-B.jpg";

export default function Green() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex text-center">
        <div className="w-1/2">
          <Typography className="">
            <h1 className="text-2xl font-bold">Green Sea Turtles Fast Facts</h1>
          </Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Type: Reptile</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Diet: Herbivore</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Average life span in the wild: Over 80 years (est.)</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Size: Up to 5 ft (1.5 m)</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Weight: Up to 700 lbs (317.5 kg)</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Protection status: Endangered</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <div className="flex justify-center">
            <div>
              <Typography className="">Size relative to a 6-ft (2-m) man:</Typography>
            </div>
            <div>
              <img className="rounded-2xl" src={GM} alt="" title="" />
            </div>
          </div>
        </div>
        <div className="w-1/2 flex justify-center">
          <img className="rounded-2xl" src={GMB} alt="" title="" />
        </div>
      </div>
      <div>
        <Typography paragraph>Did you know? </Typography>
        <Typography paragraph>
          Like other sea turtles, the green turtle cannot pull its head into its shell.
        </Typography>
        <Typography paragraph>
          The green turtle is a large, weighty sea turtle with a wide, smooth carapace, or shell. It
          inhabits tropical and subtropical coastal waters around the world and has been observed
          clambering onto land to sunbathe.
        </Typography>
        <Typography paragraph>
          It is named not for the color of its shell, which is normally brown or olive depending on
          its habitat, but for the greenish color of its skin. There are two types of green
          turtles—scientists are currently debating whether they are subspecies or separate
          species—including the Atlantic green turtle, normally found off the shores of Europe and
          North America, and the Eastern Pacific green turtle, which has been found in coastal
          waters from Alaska to Chile.
        </Typography>
        <Typography paragraph>
          Weighing up to 700 pounds (317.5 kilograms) green turtles are among the largest sea
          turtles in the world. Their proportionally small head, which is nonretractable, extends
          from a heart-shaped carapace that measures up to 5 feet (1.5 meters). Males are slightly
          larger than females and have a longer tail. Both have flippers that resemble paddles,
          which make them powerful and graceful swimmers.
        </Typography>
        <Typography paragraph>
          Unlike most sea turtles, adult green turtles are herbivorous, feeding on sea grasses and
          algae. Juvenile green turtles, however, will also eat invertebrates like crabs, jellyfish,
          and sponges.
        </Typography>
        <Typography paragraph>
          While most sea turtles warm themselves by swimming close to the surface of shallow waters,
          the Eastern Pacific green turtle will take to land to bask in the sun. Occasionally seen
          sunbathing alongside seals and albatrosses, it is one of the few marine turtles known to
          leave the water other than at nesting times.
        </Typography>
        <Typography paragraph>
          Green turtles, like other sea turtles, undertake lengthy migrations from feeding sites to
          nesting grounds, normally on sandy beaches. Mating occurs every two to four years and
          normally takes place in shallow waters close to the shore. To nest, females leave the sea
          and choose an area, often on the same beach used by their mothers, to lay their eggs. They
          dig a pit in the sand with their flippers, fill it with a clutch of 100 to 200 eggs, cover
          the pit and return to the sea, leaving the eggs to hatch after about two months. The most
          dangerous time of a green turtle’s life is when it makes the journey from nest to sea.
          Multiple predators, including crabs and flocks of gulls, voraciously prey on hatchlings
          during this short scamper.
        </Typography>
        <Typography paragraph>
          Green turtles are listed as an endangered species, and a subpopulation in the
          Mediterranean is listed as critically endangered. Despite this, they are still killed for
          their meat and eggs. Their numbers are also reduced by boat propeller accidents,
          fishnet-caused drowning, and the destruction of their nesting grounds by human
          encroachment.
        </Typography>
      </div>
    </div>
  );
}
