import React from "react";
import { Typography, Divider } from "@mui/material";
import FAUanimalB from "../Images/FAU-Animal-Behavior-and-Visual-Perception.jpg";
import SizeLeather1 from "../Images/the-size-of-leatherbacks.jpg";
import AdultTswiming from "../Images/A-swimming-adult.jpg";
import Youngleatherbacks from "../Images/Young-leatherbacks.jpg";
import WhatsInAName from "../Images/Whats-In-A-Name.jpg";
import WhatsInAName2 from "../Images/Whats-In-A-Name-2.jpg";
import Feedingleatherbacks from "../Images/Feeding-leatherbacks.jpg";
import Ourpreferredoutcome from "../Images/Our-preferred-outcome.jpg";
import GreyCylinder from "../Images/The-large-grey-cylinder-houses.jpg";

export default function LearningLeatherbacks() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:float-right mb-5"><img className="rounded-2xl mx-auto border-4 border-white " src={FAUanimalB} alt="" title="" /></div>

      <div className="pb-5">
        <h1 className="text-2xl font-bold">
          Learning About Leatherbacks: Challenges of Captive Rearing
        </h1>
      </div>
      <div>
        <Typography paragraph className="">
          Mike Salmon, Department of Biological Sciences<br /> Florida Atlantic University
        </Typography>
      </div>

      <div>
        <Divider className='dark:bg-[#032546] bg-white/70 clear-both' />
        <div className="lg:flex gap-4 mt-5">
          <Typography paragraph>
            The fossil evidence tells us that marine turtles arose from aquatic turtles living in
            brackish waters, a habitat that provided an easy transition for the evolution of other
            turtle species adapted to marine habitats. The result was an explosion of over 100 new
            marine turtles in 4 distinct families. Unfortunately two families, which produced
            extremely large marine turtles, have gone extinct but the remaining two families have
            hung on and are represented by 6 species of “hard shelled” turtles and a single survivor
            of the other family, the leatherback or “leathery shelled” turtle. The leatherback is
            without question the most unusual and distinctive representative of today’s aquatic
            turtles, and perhaps the most unique species of turtle that ever lived! It is those
            differences that make these animals so interesting to study, simply because they
            demonstrate just how remarkably “flexible” and varied the design of a turtle can be.
          </Typography>
          <Typography paragraph>
            Instead, their body is covered by smooth skin over a layer of thick connective tissue
            and fat, in which are embedded numerous small bony ossicles that provide strength but
            also, flexibility. Body flexibility is important for leatherbacks as they must withstand
            the extreme pressures associated with diving to depths exceeding 1500 feet in search of
            food. Their prey are jellyfish, salps and pelagic tunicates (collectively, “gelatinous
            zooplankton”). Other marine turtles also feed on jellyfish but the leatherback,
            uniquely, is a feeding “specialist” that exclusively feeds on these organisms. Most of
            the structural, physiological and behavioral features of leatherbacks are best explained
            as adaptations to this unique foraging strategy.
          </Typography>
        </div>
        <div className="lg:flex gap-4">
          <div className="lg:w-1/2">
            <h2 className="text-2xl mb-2 underline">Distinguishing Features of Leatherbacks</h2>
            <Typography paragraph>
              Leatherbacks are easily distinguished from other marine turtles by the absence of a
              rigid shell of bony plates (scutes) on their top (carapace) and bottom (plastron)
              surfaces.
            </Typography>
            <figure>
              <img className="rounded-2xl" src={SizeLeather1} alt="" title="" />
              <figcaption className="mt-2">
                <em>
                  Figure 1. You can best appreciate the size of leatherbacks when they are
                  photographed next to a person for scale. This female came ashore to nest at Sandy
                  Point, St. Croix, U.S. Virgin Islands. Prof. Jeanette Wyneken, behind the turtle,
                  witnessed the event.
                </em>
              </figcaption>
            </figure>
          </div>
          <div className="lg:w-1/2">
            <Typography paragraph>
              Leatherbacks as adults are huge! (Figure 1). They can reach a length that exceeds 6
              feet and a mass of over 2000 lbs, though most adults are smaller. Their large size and
              rotund shape results in a large body volume, one that allows the turtles to sequester
              the metabolic heat associated with muscular activity while swimming. That form of
              warm-bloodedness, called “gigantothermy”, is unique among turtles and, in turn, allows
              them to search for jellyfish in the colder, more productive northern waters where
              jellies thrive. Those habitats can’t be tolerated by other marine turtles so
              leatherbacks have no turtle food competitors.
            </Typography>
            <Typography paragraph>
              But a jellyfish diet doesn’t make life easy. For one thing, jellies are a
              nutritionally poor food source so leatherbacks have to consume them almost constantly,
              day and night, to obtain enough calories for growth and eventually, for reproduction.
              Furthermore, jellyfish often exist in low concentrations over widely scattered areas,
              requiring a continuous search to find them. As a consequence, leatherbacks swim almost
              continuously and that puts a premium on having a highly streamlined body to reduce
              drag, front flippers that power them efficiently through the water, and a chest that
              bulges with muscular power (Figure 2). Leatherbacks routinely swim slowly to conserve
              energy but when they have to, they can fly through the water at impressive speeds.
              Finally, we return to another characteristic of leatherbacks: their flexible bodies.
              Leatherbacks can store large quantities of fat under their skin without much change in
              their body shape. That’s a very handy characteristic to possess when you can’t be sure
              of where, or when, you’ll get your next meal. It also enables leatherbacks to overeat
              at locations where jellyfish are concentrated in “blooms” and store those excess
              calories as fat reserves.
            </Typography>
          </div>
        </div>
        <div className="lg:flex gap-4 my-5">
          <div className="lg:w-1/2">
            <figure>
              <img className="rounded-2xl" src={AdultTswiming} alt="" title="" />
              <figcaption className="mt-2">
                <em>
                  Figure 2. A swimming adult. Note its hydro-dynamically efficient body shape,
                  immensely long flippers and muscular shoulders. Ridges running down the length of
                  the carapace promote the orderly (“laminar”) flow of water across the turtle’s
                  surface. That reduces the energetic costs of swimming. (Photo: Seapics.com)
                </em>
              </figcaption>
            </figure>
          </div>
          <div className="lg:w-1/2">
            <h2 className="text-2xl mb-2 mt-5 underline">Raising Leatherbacks at the FAU Marine Lab</h2>
            <Typography paragraph>
              Turtles generally, and sea turtles in particular, are relatively easy to captive-rear
              but that statement applies only to the hard-shelled species. Leatherbacks are a
              different story! The problems are many and varied. Leatherbacks are open-water species
              that do not recognize barriers and so when kept in tanks, they crash into walls and
              the bottom, abrade their skin and die either from infection or stress. To make matters
              worse, they have no ability to swim in a reverse direction. That inability just makes
              things worse and so to rear them, each turtle has to be tethered by use of a short
              leash to preserve the illusion that they are swimming in open water (Figure 3).
            </Typography>
            <Typography paragraph>
              Green turtle and loggerhead hatchlings are not picky about what they eat. They thrive
              on a diet of shrimp, fish, and veggies supplemented with reptile vitamins and
              minerals. They are perfectly content to enjoy a large meal once daily, which makes
              both food preparation and scheduled feeding easy to arrange and finite in time.
              Leatherbacks, unfortunately, are a different story! If fed a nutritionally rich diet
              once daily, they develop digestive problems and die. Instead, both food and feeding
              must conform to what leatherbacks experience in the wild, that is, they must be fed a
              low calorie (“jellyfish like”) food multiple times each day, a schedule that resembles
              the opportunistic and varied tempo of encounters with jellyfish that a leatherback is
              likely to experience in nature.
            </Typography>
          </div>
        </div>
        <div className="lg:flex gap-4 my-5">
          <div className="lg:w-1/2">
            <h2 className="text-2xl mb-2 underline">Understand Leatherback Behavior</h2>
            <Typography paragraph>
              Until recently, much of what we knew about leatherback behavior was based upon
              scattered observations and more recently, records from instruments attached to the
              turtles. Thanks to these studies, we now know that leatherbacks often migrate over
              1000 miles between their northern feeding grounds and their southern nesting beaches,
              when and where they dive, and how long they can hold their breath (over 80 min!).
              Animals captured on their feeding or nesting grounds have been used to determine what
              they are eating, their internal body temperature, and their genetic relationships.
              While all of these findings are important, they provide only limited information of
              interest to behavioral biologists who want to know how these turtles perceive their
              environment, how they detect and recognize their prey, and how that understanding
              might be used to manage and protect their populations as leatherbacks are critically
              endangered. But how does one do behavioral experiments on an animal that is so massive
              and that requires a steady diet of jellyfish? The trick is instead to work with
              smaller, and more convenient life-history stages (young juveniles) but that, in turn,
              means they must be captive-raised. It turns out that rearing leatherbacks in the lab
              isn’t easy but studies, supported by the National Save The Sea Turtle Foundation, have
              made advances possible!
            </Typography>
          </div>
          <div className="lg:w-1/2">
            <figure>
              <img className="rounded-2xl" src={Youngleatherbacks} alt="" title="" />
              <figcaption className="mt-2">
                <em>
                  Figure 3. Young leatherbacks always assume they are in open water and do not
                  recognize barriers, like the sides or bottom of a tank. They will continue to bump
                  into these obstructions and in the process, injure themselves. The solution is to
                  tether each turtle on a short monofilament leash so that they never contact
                  anything solid. Here, 3 turtles are maintained in a single rearing tank, with
                  tethers too short to touch either any part of the tank or one another. (J. Wyneken
                  photo)
                </em>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <img className="rounded-2xl m-5" src={WhatsInAName} alt="" title="" />
      </div>
      <div className="flex justify-center">
        <img className="rounded-2xl m-5" src={WhatsInAName2} alt="" title="" />
      </div>
      <div className="lg:flex gap-4 my-5">
        <div className="lg:w-1/2">
          <figure>
            <img className="rounded-2xl" src={Feedingleatherbacks} alt="" title="" />
            <figcaption className="mt-2">
              <em>
                Figure 4. Feeding leatherbacks is a time-consuming process. Each turtle receives a
                known quantity of gelatin as several bite-sized cubes containing vitamins, minerals,
                and a low concentration of protein and carbohydrate, comparable to the nutrition
                they would consume if eating a jellyfish. They eat slowly! It takes time to feed
                them! (J. Wyneken photo)
              </em>
            </figcaption>
          </figure>
        </div>
        <div className="lg:w-1/2">
          <Typography paragraph>
            In our lab, that means leatherbacks are fed small quantities 3 times daily (“breakfast,
            lunch and dinner”) on a diet that is mostly gelatin, with a bit of fish added for
            protein, plus vitamins and minerals. It takes a lot of help from a crew of dedicated
            students to feed each turtle individually, and to work all those shifts (Figure 4)!
          </Typography>
          <Typography paragraph>
            But as if the above didn’t make things sufficiently difficult, there is another
            challenge, one that was unanticipated. Leatherbacks hatch with a barely functional
            immune system that can’t tolerate the higher levels of bacteria and viruses present in
            our coastal waters, especially during the summer when that water is warm. Normally,
            hatchlings enter the sea from our beaches and swim swiftly and directly out to sea where
            the water is both cleaner and cooler. Unfortunately, our laboratory obtains its seawater
            from a source near shore. We found it essential that the seawater used in leatherback
            tanks passes through a UV filter (Figure 5) to kill microbes, is purified by a protein
            skimmer to remove organic compounds, and finally is cooled before it is pumped into a
            tank housing leatherback hatchlings or juveniles. Even with those precautions, water in
            those tanks must be partially changed each day to maintain those conditions.
          </Typography>
        </div>
      </div>
      <div>
        <Typography paragraph>
          These are lessons that took years of trial-and-error explorations to learn. It’s not
          surprising that we are the only facility in the world that now raises leatherbacks. It’s a
          stressful, time consuming and expensive proposition. We’re still perfecting our techniques
          to improve our success. I’m happy to report that as a result, we are producing some very
          attractive, fat and healthy juveniles for further study (Figure 6). They can be observed
          from the Visitors Gallery at the FAU marine lab. Come visit and see for yourself our
          current brood of growing leatherbacks.
        </Typography>
      </div>
      <div className="lg:flex gap-4 my-5">
        <div className="lg:w-1/2">
          <figure>
            <img className="rounded-2xl" src={Ourpreferredoutcome} alt="" title="" />
            <figcaption className="mt-2">
              <em>
                Figure 6. Our preferred outcome: a fat and healthy juvenile about 10 inches in
                length, ready to be used in a brief experiment before it is taken offshore and
                released. (M. Salmon photo)
              </em>
            </figcaption>
          </figure>
        </div>
        <div className="lg:w-1/2">
          <figure>
            <img className="rounded-2xl" src={GreyCylinder} alt="" title="" />
            <figcaption className="mt-2">
              <em>
                Figure 5. The large grey cylinder houses 4 powerful ultraviolet light tubes that
                kill all viruses and bacteria present in the lab’s seawater. The water that exits is
                then processed through another filtering system (a “protein skimmer”, not pictured)
                to remove organic pollutants, and cooled in a chiller. Finally, the water is
                funneled into tanks where we house our leatherbacks. (M. Salmon photo)
              </em>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
}
