import React from "react";
import { Divider, Typography } from "@mui/material";
import InwaterGroup3 from "../Images/Inwater-Research-group-3.jpg";
import Kempii1 from "../Images/ILepidochelys-kempii.jpg";
import Jasper1 from "../Images/Jasper.jpg";
import JasperW from "../Images/Jasper-the-wayward.jpg";
import JasperW2 from "../Images/Jasper-the-wayward-2.jpg";

export default function WhatAreYouDoing() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex lg:gap-5 mb-2">
        <div className="lg:w-3/4">
          <div className="pb-5">
            <h1 className="text-2xl font-bold">What Are You Doing Here? </h1>
          </div>
          <Typography paragraph>Ryan M. Chabot, Inwater Research Group </Typography>
        </div>
        <div className="lg:w-1/4">
          <img className="lg:float-right rounded-2xl mx-auto" src={InwaterGroup3} alt="" title="" />
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="lg:flex justify-between pt-5">
        <div className="lg:mr-5 lg:w-1/2">
          <div><img className="rounded-2xl mx-auto" src={Kempii1} alt="" title="" /></div>
          <div className="pt-2">
            <em>
              Though closely related to the Kemp’s Ridley (Lepidochelys kempii), the Olive Ridley sea
              turtle (Lepidochelys olivacea) is is extremely rare in Florida. They are a relatively
              small species of sea turtle, and can be found in most tropical waters around the world,
              but often prefer offshore waters where encounters are less frequent.
            </em>
          </div>
        </div>
        <div className="lg:w-1/2">
          <Typography paragraph>
            Saturday, May 11, 2019 started out like any ordinary day for the team of biologists at
            Inwater Research Group (IRG), but quickly changed into one of the most exciting events
            of the year. While conducting routine monitoring of our St. Lucie study site, we
            captured an adult male olive ridley (Lepidochelys olivacea) sea turtle. Although olive
            ridleys are commonly found in parts of the Atlantic, Pacific, and Indian Oceans, finding
            one in Florida is an extremely rare event! Unlike most other hard-shelled sea turtle
            species, olive ridleys spend much of their life in offshore or continental shelf waters,
            making in-water encounters of them infrequent. Even more so, nesting in the Atlantic is
            restricted to the northeastern coasts of South America and the central coast of West
            Africa. Since record keeping began in Florida, there have only been seven other
            sightings of olive ridleys in the state, six of which were sick or debilitated animals
            that needed rehabilitation or subsequently died, while the seventh was discovered dead.
            Unlike these other turtles, this olive ridley appeared to be in near-perfect physical
            condition. The only other healthy confirmed olive ridley observed by researchers in U.S.
            waters was by the South Carolina Department of Natural Resources during one of their
            annual research cruises.
          </Typography>
        </div>
      </div>
      <div className="lg:flex pt-5">
        <div className="lg:w-3/4">
          <Typography paragraph>
            The sightings of olive ridleys in Florida raise some very interesting questions. Is it
            normal for the species to be in our waters? Or is this turtle a stray who accidentally
            wandered all the way from South America or Africa? How do we protect a species for which
            we have no real information about their behavior in U.S. waters? To try and at least
            begin answering these questions, we reached out to the U.S. Fish and Wildlife Service
            (FWS) and our collaborators at Gumbo Limbo Nature Center (GLNC) for help.
          </Typography>
          <Typography paragraph>
            With experienced guidance from FWS about species protocols, and generous support from
            GLNC, we had a once-in-a-lifetime opportunity to track this turtle’s movements. Like
            every other sea turtle we encounter, the olive ridley was first given a full assessment
            including being weighed and measured. Metal flippers tags and a microchip were also
            applied, which can be used to identify the animal if it’s seen again. But since this
            encounter was so special, the turtle was also affectionately named “Jasper.”
          </Typography>
        </div>
        <div className="pl-5 lg:w-1/4 ">
          <div className="lg:float-right">
            <div><img className="rounded-2xl mx-auto" src={Jasper1} alt="" title="" /></div>
            <div className="pt-2">
              <em>
                Researchers with the InwaterResearch Group examined andtagged “Jasper” for future
                identification.
              </em>
            </div>
          </div>


        </div>
      </div>
      <div className="lg:flex gap-5 pt-5">
        <div className="lg:mr-5 lg:w-1/3">
          <div><img className="rounded-2xl mx-auto" src={JasperW} alt="" title="" /></div>
          <div className="pt-2">
            <em>
              Jasper the wayward olive ridley will carry two kinds of remote sensing devices. The
              larger transmitter on the apex of the shell can communicate with satellites to record
              long-distance movements. The smaller device near the rear of the shell can communicate
              with acoustic listening stations along the coastline
            </em>
          </div>
        </div>
        <div className="lg:w-1/3">
          <Typography paragraph>
            The IRG team then outfitted Jasper with a small acoustic tag. A series of acoustic
            receivers along the east coast of the US constantly “listens” for the presence of
            animals with acoustic tags. So, if Jasper gets close enough to any of these receivers,
            it will record when and where he was “heard”. However, these receivers are mostly in
            very nearshore waters, so if Jasper wanders further from shore, they wouldn’t be able to
            hear his acoustic tag. Fortunately, the sea turtle team from GLNC rushed to deliver a
            satellite transmitter they had on hand to apply to Jasper. Much like the tracking
            technology in your car and phone, whenever the antenna on Jasper’s satellite transmitter
            breaks the water’s surface (like when he comes up to breathe), it transmits his position
            to satellites overhead. The materials used to attach these transmitters will degrade
            over time, so Jasper will simply shed the tags when he makes new shell material as he
            grows. These two technologies combined will hopefully give us some new insight on just
            what olive ridleys found in the Northwest Atlantic do during their time here. This
            information, in turn, can help us as researchers and conservation managers better
            understand what everyone can do to ensure the long-term survival of this high seas
            traveler. Cheers to safe travels for Jasper, a one-in-a-million find!
          </Typography>
          <Typography paragraph>
            Jasper’s satellite transmitter tracking map can be viewed at:
            <a href="http://www.inwater.org/jasper">www.inwater.org/jasper</a>
          </Typography>
        </div>
        <div className="lg:w-1/3">
          <img className="rounded-2xl mx-auto" src={JasperW2} alt="" title="" />
        </div>
      </div>
    </div>
  );
}
