import {Typography} from "@mui/material";
import React from "react";
import KempsRidleyImg from "../Images/Kemps-Ridley-B.jpg";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import {createTheme, ThemeProvider} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#932B16", // custom secondary color
    },
  },
});

export default function KempsRidley() {
  return (
    <div className="dark:text-[#032546] dark:bg-white/70 p-5 w-full h-full bg-[#032546]/90 rounded-3xl text-[#ffffff]">
      <div>
        <Typography paragraph>
          <h2 className="text-2xl font-bold">Kemp's Ridley Sea Turtles</h2>
        </Typography>
      </div>
      <div className="lg:float-left lg:w-1/3 mb-5 lg:mr-5">
        <img className="rounded-3xl shadow-lg" src={KempsRidleyImg} alt="" title="" />
      </div>
      <div>
        <Typography>
          The rarest and smallest of all the sea turtles, the endangered Kemp's Ridley feeds in the
          coastal waters of Florida on blue crabs and other crabs and shrimp. All Kemp's
          Ridley&nbsp;s nest on a single stretch of beach on the Gulf Coast of Mexico.
        </Typography>
      </div>
      <div className="my-5 text-right  mt-24">
        <ThemeProvider theme={theme}>
          <Link href="/SeaTurtles/KempsRidley" title="Kemps Ridley Sea Turtles">
            <Button color="secondary" variant="contained">
              More about Kemp's Ridley Sea Turtles
            </Button>
          </Link>
        </ThemeProvider>
      </div>
    </div>
  );
}
