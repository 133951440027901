import React from "react";
import { Typography, Divider } from "@mui/material";
import InwaterResearchGrouplogo2 from '../Images/Inwater-Research-Group-logo-2.jpg'
import TravelingTurtlestrunkprogram from '../Images/Traveling-Turtles-trunk-program.jpg'
import logoFinal from '../Images/National-Save-The-Sea-Turtle-Foundation-logo-Final.png'
import numberoftrunks from '../Images/number-of-trunks.jpg'
import BridgingtheGap from '../Images/Bridging-the-Gap.jpg'
import turtlesintheirownlocalwaterways from '../Images/turtles-in-their-own-local-waterways.jpg'

export default function EducationDuringCOVID() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className='pb-5'><h1 className='text-2xl font-bold'>Navigating Conservation Education During the COVID-19 Pandemic
      </h1></div></div>
      <div><Typography paragraph>Rebecca Mott, Inwater Research Group, Inc.</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className='lg:float-right lg:ml-5 pt-5'><img className="rounded-2xl mx-auto w-40" src={InwaterResearchGrouplogo2} alt="" title="" /></div>
      <div className="pt-5"><Typography paragraph>With COVID taking full effect during the spring semester of 2020, Inwater Research Group’s (IRG) programs came to a halt almost immediately. Without the hands-on components to our award-winning trunk program, we feared the semester would leave students without access to marine conservation tools. As some of you know, our traveling trunk programs are wheeled trunks that house 4 weeks’ worth of hands-on learning materials for teachers and students. Teachers may borrow these trunks for free and provide lessons that are built to put students into first-hand roles of real marine biologists.</Typography></div>
      <div className='lg:float-right lg:ml-5 mb-5'><img className="rounded-2xl mx-auto" src={TravelingTurtlestrunkprogram} alt="" title="" /></div>
      <div><Typography paragraph>Traveling Turtles, our flagship trunk program, is an excellent introduction to the sea turtles of Florida. Students learn about what makes a sea turtle a sea turtle as well as nesting habits, how to sample a population like real scientists, and how to collect data from their very own sea turtle model. Darker Skies, Darker Beaches, our second trunk program, teaches students about the impacts coastal lighting can have on sea turtle nesting. Students learn all about sea turtle biology, forms of energy, what makes a circuit, and how to create sea turtle-friendly lighting. Activities include: Hands-on stations, an interactive Power Point presentation with games, creating a circuit and testing conductors/insulators, using the Engineering Design Process to develop and implement a turtle-friendly light fixture, and assessing their schools’ lighting.</Typography></div>
      <div><Typography paragraph>With such hands-on trunk programs, we had to get creative and develop ways to convert our lessons to those that followed CDC guidelines. All lessons were digitized and the entire curriculum became adaptable for both social distance and virtual learning. This aided teachers who struggled to find teaching tools they could use with their students in the classrooms as well as those tuning in from home.</Typography></div>
      <div><Typography paragraph>In addition to our local trunk programs, IRG operates a statewide initiative (Traveling Trunks of Florida) that allows partners such as zoos, school districts, and nature centers to purchase our trunk programs and run them as their own. While the number of students reached was lower due to the pandemic, the initiative still provided a valuable resource to schools across the state of Florida. In fact, even during the pandemic, we were still receiving orders for more trunks. As of now, our trunks are in (or have orders in to be made) 21 school districts, with 20 partner organizations, totaling 54 trunks in the state of Florida. Organizations such as NOAA, The Nature Conservancy, The Literacy Coalition of Palm Beach County, and various school districts are just a few of our partners.</Typography></div>
      <div className='lg:float-left lg:mr-5 mb-5'><img className="mx-auto" src={logoFinal} alt="" title="" /></div>
      <div><Typography paragraph>Other COVID Resources</Typography></div>
      <div><Typography paragraph>With so many students, parents, and teachers at a loss for resources during the pandemic, IRG saw an opportunity to become a hub of online resources that would be housed on our website. We reached out to national and international organizations to participate and share their virtual/digital programs on our platform. All resources must be free to be eligible. Our website now hosts over 40 partner organization resources with links to content, so teachers and parents have a place to go to access science/conservation-based materials.</Typography></div>
      <div className='lg:float-left lg:mr-5 mb-5'><img className="rounded-2xl mx-auto" src={numberoftrunks} alt="" title="" /></div>
      <div><Typography paragraph>In addition to converting our trunks and creating a hub of resources online, IRG also joined the FlipGrid team to provide a digital tool for students to interact with real scientists from our organization. FlipGrid is an app that connects young learners with both educators and other students all over the world! We created a platform with videos of scientists discussing popular sea turtle topics that also align with statewide curriculum. Students can access the platform at home or in school and join the dialogue, sharing videos of them answering questions and conversing on the topic. While the pandemic has been a trying time for all of us, it has also given us the opportunity to flex creativity and find new outlets and avenues to reach students. We are hopeful the 2021-22 school year will bring normalcy back to our programming but if not…we are ready to adapt and do what it takes to continue to build the next generation of responsible environmental stewards.</Typography></div>
      <div className='lg:float-right lg:ml-5 pt-5 mb-5'><img className="rounded-2xl mx-auto" src={turtlesintheirownlocalwaterways} alt="" title="" /></div>
      <div><Typography paragraph>So What’s Next?</Typography></div>
      <div><Typography paragraph>With the world beginning to open up once again, we’re gearing up for a very busy year! Combining forces with our research team, we’ll be hosting professional development (PD) experiences for teachers across the State. These PD days include a classroom workshop where teachers learn to use our trunk programs so they can share them with their students. Coupled with a day out in the field working with our researchers to catch and “work up” turtles in their own local waterways (see photo facing page), these PDs are sure to be a hit!</Typography></div>
      <div><Typography paragraph>Turtle Makeovers!</Typography></div>
      <div><Typography paragraph>In addition to all the trunks being created for partners across the State, our model turtles utilized in the trunks are getting a major makeover. For years, the only models available were anatomically incorrect and unrealistic. However, we have made big strides in updating our programs to remedy this. Working with stateside manufacturers, we are fabricating our own model turtles that are based on one of IRG’s research turtles caught back in 2020. This juvenile green turtle will now be a part of education programs across the State, making for the most authentic experience we can offer.</Typography></div>
      <div className='lg:float-left lg:mr-5 mb-5'><img className="rounded-2xl mx-auto" src={BridgingtheGap} alt="" title="" /></div>
      <div><Typography paragraph>Bridging the Gap</Typography></div>
      <div><Typography paragraph>In the field of sea turtle conservation, it can be hard to share data with the public. Many of us are biologists who don’t know much about speaking to groups of various ages, backgrounds, and/or learning levels. We apply for permits, work with turtles, collect data, and write reports, but then what? How do we, as scientists, find ways to share our conservation message with others outside of our industry?</Typography></div>
      <div><Typography paragraph>IRG is working on a publication that will focus on how scientists perceive education, what they consider effective modes of education, and what they, themselves, are doing to foster education. This publication will be pave the way for scientists and educators to collaborate on projects that combine both science and education. It will empower researchers in our field to take on the role of educator to better promote their work as well as conservation as a whole. IRG greatly appreciates the strong support of the National Save The Sea Turtle Foundation to make these programs possible!</Typography></div>
      <div className="lg:text-right"><Typography >To Learn More, Visit <a href="https://www.inwater.org">www.inwater.org</a></Typography></div>
    </div>
  );
}
