import React from "react";
import { Typography, Divider } from "@mui/material";
import FIUCCORR from "../Images/FIUs-Center-for-Coastal-Oceans-Research-Reach.jpg";
import CodyLarry from "../Images/Cody-Mott-Larry-Wood--Analisa-Duran.jpg";
import Trunks1 from "../Images/The-trunks.jpg";

export default function TrunksTravelSouth() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex">
        <div>
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              Turtle Trunks Travel South: Inwater Resarch Group <br /> and FIU’s Center for
              Coastal Oceans Research Reach Students in Monroe County
            </h1>
          </div>
          <Typography paragraph>
            Analisa Duran<br />
            Education & Outreach Program Coordinator<br />
            Center for Coastal Oceans Research
          </Typography>
        </div>
        <div className="mr-5 p-2">
          <img
            className="lg:float-right rounded-2xl border-4 border-white"
            src={FIUCCORR}
            alt=""
            title="Coastal Oceans Research"
          />
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="my-5">
        <div className="float-right ml-5 mb-5">
          <div><img
            className="rounded-2xl"
            src={CodyLarry}
            alt=""
            title="Cody Mott and Larry Wood deliver “Traveling Trunks” to Analisa
            Duran in Key Largo. They will be shared among Monroe County
            school teachers."
          /></div>
          <div className="pt-2"><em>
            Cody Mott and Larry Wood deliver “Traveling Trunks” to <br />Analisa Duran in Key
            Largo. They will be shared among<br /> Monroe County school teachers.
          </em></div>
        </div>
        <div>
          <Typography paragraph>
            The successful Travelling Turtles and Darker Skies, Darker Beaches trunk programs are
            now available for teachers to use in Monroe County. Inwater Research Group’s Education
            Manager, Rebecca Mott, developed this program to teach about sea turtles and to bring
            awareness to the threats and issues sea turtles face into the classroom. This is done
            through trunk programs where each trunk consists of lessons, materials and supplemental
            resources for teachers to facilitate on their own. Trunks can be moved between
            classrooms and even between schools. This way, resources can be shared and teachers can
            utilize materials and lessons in a time efficient and easy to manage way.
          </Typography>
          <Typography paragraph>
            The trunk program Travelling Turtles focuses on sea turtle biology and rehabilitation
            while the Darker Skies, Darker Beaches program focuses on sea turtle safe lighting. Both
            programs offer well rounded lesson plans and activities that hit a variety of standards
            in correlation to the Florida Standards of Education.
          </Typography>
        </div>
      </div>

      <div className="float-left mr-5">
        <div>
          <img
            className="rounded-2xl"
            src={Trunks1}
            alt=""
            title="The trunks provide teachers with all the tools
          to provide their students with meaningful,
          hands-on learning experiences."
          />
        </div>
        <div className="pt-2">
          <em>
            The trunks provide teachers with all the<br /> tools to provide their students with<br />
            meaningful, hands-on learning experiences.
          </em>
        </div>
      </div>
      <Typography paragraph>
        Sea turtles can be found throughout the Florida Keys, offshore and on various nesting
        beaches. Teaching students about sea turtles and their importance, biodiversity and ecology
        at a young age is crucial to their understanding of the environment that will lead them to
        become environmental stewards. There are 16 schools within the Monroe County School
        district, most of which have a Title I status. These trunks will be used to serve these
        schools and give a new opportunity to teachers and students to connect real science in their
        classes.
      </Typography>

      <Typography paragraph>
        Florida International University’s Center for Coastal Oceans Research (FIU’s CCOR) will be
        partnering with Inwater Research on this Initiative thanks to funding provided by the
        National Save The Sea Turtle Foundation. Although FIU is located in Miami-Dade County, they
        have a very large presence in the Florida Keys. FIU scientists conduct work in relation to
        the seagrass, fish ecology, coral reef biology, water quality and sea level rise in the
        Keys. FIU has invested in the education of Monroe County community members and students in
        marine science through an Education and Outreach program located in Key Largo. The
        coordination of trunks between schools, relationships with schools and oversight of the
        program will be done by the FIU CCOR Education & Outreach Coordinator.
      </Typography>
      <Typography >
        FIU, Inwater Research Group and National Save The Sea Turtle Foundation hope to bring a
        new-found awareness of the environment to inspire the youth of today to be inspired to
        protect and conserve the resources and wildlife around them.
      </Typography>
    </div>
  );
}
