import { Divider } from '@mui/material';
import FundUnder from './Images/Fund-Undergraduate-Scholarships.jpg';
import ScholarshipProgram1 from './Images/Scholarship-Program.jpg';
import Shelby from './Images/Shelby-Creager-2.jpg';
import Victoria from './Images/Victoria-Tori-Erb-2.jpg';
import { Link, Button } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5", // custom primary color
        },
        secondary: {
            main: "#932B16", // custom secondary color
        },
    },
});

export default function ScholarshipProgram() {
    return (
        <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
            <div className='pb-5'><div><h1 className='text-2xl'>Scholarship Program</h1></div></div>
            <Divider className='dark:bg-[#032546] bg-white/70' />

            <div className='pt-5'>
                The National Save The Sea Turtle Foundation has greatly expanded its support of scholarship programs over the last several years. Chosen by each school’s faculty, the ideal candidates are senior-year undergraduate students or graduate students that have an interest in any aspect of marine turtle research, and plan to continue their careers in the marine sciences. Your contribution to the Foundation helps provide critical support that young scientists so desperately need to complete their graduate studies.
            </div>


            <div className='lg:w-1/4 mx-auto'>
                <div className='mx-auto text-center'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/forms/cashdonation.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>

                                    <div className='text-center'>Donate</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>
            </div>

            <div className='pt-5 underline'>Florida Atlantic University</div>
            <div className='pt-5'>
                For many years, the Department of Biological Sciences at Florida Atlantic University has been the recipient of numerous scholarships from the National Save The Sea Turtle Foundation. These funds are used to support graduate research at the Master of Science and Doctoral levels. While in the past the Foundation has provided funding for projects involving marine turtles, in recent years the decision was made to support broader topics of research dealing with the conservation, management, and/or basic biology of marine fishes as well as turtles.
            </div>

            <div className='pt-5 underline'>University of Florida</div>
            <div className='pt-5'>
                In 2020, the Foundation established the Fibropapillomatosis (FP) Training and Research Initiative at the University of Florida’s Whitney Laboratory in St. Augustine to specifically address the ongoing concerns surrounding this debilitating, globally-distributed sea turtle disease. Since then the Program has supported over six graduate students and has expanded to include topics such as sea turtle genomics and health assessments in wild populations.
            </div>

            <div className='pt-5 underline'>University of Central Florida</div>
            <div className='pt-5'>
                Since 2021, the National Save The Sea Turtle Foundation has been supporting the Marine Turtle Research Group at the University of Central Florida in a number of ways. Starting with partial funding for a research vessel, our partnership has since grown to support over six exceptional undergraduates who have demonstrated a sincere interest in marine turtle research. In 2023, we also established the Dr. Llewellen Ehrhart Sea Turtle Research Internship Endowed Fund to support student interns seeking to work with the Marine Turtle Research Group at the Archie Carr National Wildlife Refuge.
            </div>

            <div className='pt-5 underline'>Florida International University</div>
            <div className='pt-5'>
                The undergraduate research scholarships provided to FIU by the Foundation have enabled several undergraduate students and interns to pay for tuition, supplies, and research expenses associated with projects ranging from the ecology of sea turtles here and abroad to sea turtle health and public education. We also recently established the NSTSTF Scholarship Endowment, which helps ensure that funding will be available well into the future.
            </div>
            <div className='pt-5 underline'>Florida Gulf Coast University</div>
            <div className='pt-5'>
                The National Save The Sea Turtle Foundation Scholarship at FGCU was established in 2020 to provide undergraduate students the opportunity to participate in studies focused on nest success on Florida beaches and the movements of rare leatherback turtles nesting on the coast of West Africa. Since then, six students have received NSTSTF Scholarship awards.
            </div>
            <div className='pt-5 underline'>University of the Virgin Islands</div>
            <div className='pt-5'>
                The Foundation’s commitment to the research and conservation of hawksbill turtles led to a partnership with the University of the Virgin Islands, located in St. Thomas, USVI. The National Save The Sea Turtle Foundation Scholarship at UVI has supported several undergraduate and graduate students that have focused their research on this critically endangered species in and around the Virgin Islands.
            </div>


            <div className='mt-5'><img className='rounded-2xl mx-auto' src={FundUnder} alt='' title='' /></div>


            <div className='mt-5'><img className='rounded-2xl mx-auto' src={ScholarshipProgram1} alt='' title='' /></div>

            <div className='lg:flex mx-auto pt-5 gap-5'>
                <div className='lg:w-1/2 my-5'><img className='rounded-2xl' src={Shelby} alt='' title='' /></div>
                <div className='lg:w-1/2 my-5'><img className='rounded-2xl mx-auto' src={Victoria} alt='' title='' /></div>
            </div>

        </div>
    )
}