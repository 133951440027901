import React from "react";
import { Typography, Divider } from "@mui/material";
import Sargassum from '../Images/Sargassum.jpg'
import HarborBranch from '../Images/Harbor-Branch.jpg'

export default function SargassumNowWorld() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className='pb-5'><h1 className='text-2xl font-bold'>Sargassum Now World’s Largest Harmful Algal Bloom Due to Nitrogen

      </h1></div></div>
      <div><Typography paragraph>Gisele Galoustian<br />
        Florida Atlantic University</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="lg:flex lg:gap-4 mt-5">
        <div className="lg:w-1/2">
          <div><Typography paragraph>For centuries, pelagic Sargassum, floating brown seaweed, have grown in low nutrient waters of the North Atlantic Ocean, supported by natural nutrient sources like excretions from fishes and invertebrates, upwelling, and nitrogen fixation. Using a unique historical baseline from the 1980s and comparing it to samples collected since 2010, researchers from Florida Atlantic University’s Harbor Branch Oceanographic Institute and collaborators have discovered dramatic changes in the chemistry and composition of Sargassum, transforming this vibrant living organism into a toxic “dead zone.”</Typography></div>
          <div>
            <div><img className="rounded-2xl" src={Sargassum} alt="" title="" /></div>
            <div className="pt-2 pb-5"><em>A photo taken this month shows Sargassum piled up on a beach in Palm Beach County, Florida. (Photo credit: Brian Lapointe, Ph.D.)</em></div>
          </div>
          <div><Typography paragraph>Their findings, published in Nature Communications, suggest that increased nitrogen availability from natural and anthropogenic sources, including sewage, is supporting blooms of Sargassum and turning a critical nursery habitat into harmful algal blooms with catastrophic impacts on coastal ecosystems, economies, and human health. Globally, harmful algal blooms are related to increased nutrient pollution. The study, led by FAU Harbor Branch, in collaboration with the University of South Florida, Woods Hole Oceanographic Institution, the University of Southern Mississippi, and Florida State University, was designed to better understand the effects of nitrogen and phosphorus supply on Sargassum. Researchers used a baseline tissue data set of carbon (C), nitrogen (N) and phosphorus (P) and molar C:N:P ratios from the 1980s and compared them with more recent samples collected since 2010.
          </Typography></div>
        </div>
        <div className="lg:w-1/2">
          <div className="mb-5"><div><img className="rounded-2xl mx-auto" src={HarborBranch} alt="" title="" /></div></div>
          <div><Typography paragraph>Results show that the percentage of tissue N increased significantly (35 percent) concurrent with a decrease in the percentage of phosphorus (42 percent) in Sargassum tissue from the 1980s to the 2010s. Elemental composition varied significantly over the long-term study, as did the C:N:P ratios. Notably, the biggest change was the nitrogen:phosphorus ratio (N:P), which increased significantly (111 percent). Carbon:phosphorus ratios (C:P) also increased similarly (78 percent). “Data from our study supports not only a primary role for phosphorus limitation of productivity, but also suggests that the role of phosphorus as a limiting nutrient is being strengthened by the relatively large increases in environmental nitrogen supply from terrestrial runoff, atmospheric inputs, and possibly other natural sources such as nitrogen fixation,” said Brian Lapointe, Ph.D., senior author, a leading expert on Sargassum and a research professor at FAU Harbor Branch.</Typography></div>
          <div><Typography paragraph>A total of 488 tissue samples of Sargassum were collected during various research projects and cruises in the North Atlantic basin between 1983-1989 and more recently between 2010-2019, and included seasonal sampling offshore Looe Key reef in the lower Florida Keys (1983 and 1984) and a broader geographic sampling (1986 and 1987) offshore the Florida Keys, Gulf Stream (Miami, Charleston and Cape Fear), and Belize, Central America. Oceanic stations included the northern, central and southern Sargasso Sea. The highest percentage of tissue N occurred in coastal waters influenced by nitrogen-rich terrestrial runoff, while lower C:N and C:P ratios occurred in winter and spring during peak river discharges. The overall range for N:P ratios was 4.7 to 99.2 with the highest mean value in western Florida Bay (89.4) followed by locations in the Gulf of Mexico and Caribbean. The lowest N:P ratios were observed in the eastern Caribbean at St. Thomas (20.9) and Barbados (13.0).</Typography></div>
          <div><Typography paragraph>Because of anthropogenic emissions of oxides of nitrogen (NOx), the NOx deposition rate is about five-fold greater than that of pre-industrial times largely due to energy production and biomass burning. Production of synthetic fertilizer nitrogen has increased nine-fold, while that of phosphate has increased three-fold since the 1980s contributing to a global increase in N:P ratios. Notably, 85 percent of all synthetic nitrogen fertilizers have been created since 1985, which was shortly after the baseline Sargassum sampling began at Looe Key in 1983. “Over its broad distribution, the newly-formed Great Atlantic Sargassum Belt can be supported by nitrogen and phosphorus inputs from a variety of sources including discharges from the Congo, Amazon and Mississippi rivers, upwelling off the coast of Africa, vertical mixing, equatorial upwelling, atmospheric deposition from Saharan dust, and biomass burning of vegetation in central and South Africa,” said Lapointe.
          </Typography></div>


        </div>

      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />

      <div className="lg:flex lg:gap-4 mt-5">
        <div className="lg:w-1/2">
          <div><Typography paragraph>Long-term satellite data, numerical particle-tracking models, and field measurements indicate that the Great Atlantic Sargassum Belt has recurred annually since 2011 and extended up to 8,850 kilometers from the west coast of Africa to the Gulf of Mexico, peaking in July 2018. “Considering the negative effects that the Great Atlantic Sargassum Belt is having on the coastal communities of Africa, the Caribbean, Gulf of Mexico and South Florida, more research is urgently needed to better inform societal decision-making regarding mitigation and adaptation of the various terrestrial, oceanic, and atmospheric drivers of the Sargassum blooms,” said Lapointe.</Typography></div>
          <div><Typography paragraph>Sargassum removal from Texas beaches during earlier, less severe inundations was estimated at $2.9 million per year and Florida’s Miami-Dade County alone estimated recent removal expenses of $45 million per year. The Caribbean-wide clean-up in 2018 cost $120 million, which does not include decreased revenues from lost tourism. Sargassum strandings also impact marine life and cause respiratory issues from the decaying process and other human health concerns, such as increased fecal bacteria. “Human activities have greatly altered global carbon, nitrogen, and phosphorus cycles, and nitrogen inputs are considered now ‘high risk’ and above a safe planetary boundary,” said Lapointe. “Based on scientific research, population growth and land-use changes have increased nitrogen pollution and degradation of estuaries and coastal waters since at least the 1950s. Despite decreases in nitrogen loading in some coastal watersheds, N:P ratios remain elevated in many rivers compared to historic values. The trend toward higher N:P ratios in the major rivers in the Atlantic basin parallel the increased N:P ratios we now see in Sargasum.”</Typography></div>
        </div>
        <div className="lg:w-1/2">
          <div><Typography paragraph>Study co-authors are Rachel Brewton, a research coordinator, and Laura Herren, a research biologist, both at FAU Harbor Branch; Chuanmin Hu, Ph.D., a professor of optical oceanography, University of South Florida; Mengqui Wang, Ph.D., a post-doctoral researcher in the College of Marine Science, University of South Florida; Dennis McGillicuddy, Jr., Ph.D., senior scientist and department chair of applied ocean physics and engineering, Woods Hole Oceanographic Institution; Scott Lindell, a research specialist, Woods Hole Oceanographic Institution; Frank J. Hernandez, Ph.D., an assistant professor, Division of Coastal Sciences, University of Southern Mississippi; and Peter Morton, Ph.D., Department of Earth, Ocean and Atmospheric Science, Florida State University.</Typography></div>
          <div><Typography>This research was funded by the U.S. NASA Ocean Biology and Biogeochemistry Program (NNX14AL98G, NNX16AR74G) and Ecological Forecast Program (NNX17AE57G), NOAA RESTORE Science Program (NA17NOS4510099), National Science Foundation (NSF-OCE 85–701 15492 and OCE 88–12055) and a Red Wright Fellowship from the Bermuda Biological Station.</Typography></div>
        </div>

      </div>
    </div>
  );
}
