import React from "react";
import { Typography, Divider } from "@mui/material";
import Coastal1 from "../Images/Coastal-connections.jpg";
import ReleaseV from "../Images/Rescue-Release-Volunteers.jpg";
import CCinc from "../Images/CCinc.jpg";
import CCinc2 from "../Images/CCinc-2.jpg";

export default function IndianRiver() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="pb-5">
        <h1 className="text-2xl font-bold">
          Vero Beach’s Coastal Connections, Inc. Makes an Impact in Indian River County
        </h1>
      </div>
      <Typography paragraph>
        Kendra Cope, M.S.<br />
        Founding Director, Coastal Connections, Inc
      </Typography>
      <div className="my-5">
        <Divider className='dark:bg-[#032546] bg-white/70 clear-both' />
      </div>
      <div className="lg:float-right lg:ml-5 mb-5"><img className="rounded-2xl border-4 border-white mx-auto" src={Coastal1} alt="" title="" /></div>
      <Typography paragraph>
        Coastal Connections, Inc (CCinc) is located in Vero Beach, Florida and was incorporated in
        2017 to provide opportunities for people to connect with coastal wildlife through
        educational and engaging programs. Studies have shown that people who interact with
        charismatic protected species, for example nighttime sea turtle walks in Florida, are more
        likely to feel connected to the focal species and will react by changing their behaviors to
        more educated and conservation-minded habits. In addition, ecotourism programs focused
        around protected species impel and increase eco-minded travel, attract more responsible
        visitors, and infuse more funds into the local economy surrounding the focal species than
        traditional tourism, thus, benefiting the entire community. CCinc uses this strategy and
        approach to benefit wildlife, people, and the local economy in all of our programs. This is
        what makes CCinc truly unique among Florida sea turtle conservation organizations.
      </Typography>
      <Typography paragraph>
        CCinc has three main initiatives to meet their mission of protecting coastal habitats. These
        initiatives include: Vero Goes Zero, a sustainability initiative, Sea Turtle
        T.R.A.C.K.E.R.S., a sea turtle conservation, education and research initiative, and Sea
        Turtle Friendly Certified, a synergistic and incentivizing initiative which challenges
        businesses to be environmentally friendly. Each of these initiatives includes a multitude of
        programs to help reach initiative goals including:
      </Typography>
      <Typography paragraph>
        Turtle Digs - These are FREE daytime public educational nest excavations. Hundreds of people
        regularly attend each event to watch CCinc conduct a full hatched nest evaluation while
        receiving an educational presentation, watching for the possibility of saving a straggler
        hatchling or two. This program has introduced 2,000+ people each summer to sea turtle
        conservation. All data collected during nest marking, daily nest monitoring, and nest
        excavation is provided to the County and State to be incorporated into annual reports and
        management strategies.
      </Typography>
      <div className="lg:float-left lg:mr-5 mb-5"><img className="rounded-2xl mx-auto" src={ReleaseV} alt="" title="" /></div>
      <Typography paragraph>
        Turtle Stranding Rescue and Release - Volunteers also coordinate closely with the County,
        FWC, and rehabilitation facilities to respond to stranded sea turtles, transport turtles to
        rehabilitation centers and help facilitate large educational turtle release events on the
        beaches of Indian River County. Volunteers help with approximately 75% of the recorded
        stranding events in Indian River County each year.
      </Typography>
      <Typography paragraph>
        Turtle Friendly Business - This is an incentivizing program that holds beachside businesses
        to novel standards to ensure the success of sea turtle reproduction on our beaches.
        Businesses voluntarily pledge to meet turtle friendly operating standards. For example,
        having on-site recycling, complying with local lighting ordinances, removing barriers to
        nesting from the beaches each day, and offering educational materials to guests.
      </Typography>
      <Typography paragraph>
        The impacts of COVID-19 have extended far greater and longer than many initially imagined.
        The need for social distancing and new safety protocols has called for the cancelation of
        community outreach events, fundraising celebrations, and has challenged CCinc to adapt to a
        new normal. CCinc was awarded a $15,000 gift from the National Save The Sea Turtle
        Foundation to supplement financial losses due to COVID-19, allowing CCinc to grow, adapt and
        ultimately continue its impactful summer Turtle Dig program and redevelop the certification
        and inspection processes for Turtle Friendly Businesses, which will be initiated in Fall
        2020.
      </Typography>
      <div className="lg:float-right lg:ml-5 mb-5"><img className="rounded-2xl mx-auto" src={CCinc} alt="" title="" /></div>
      <Typography paragraph>
        During the months of July and August CCinc hosted twelve (12) COVID-19 adapted Turtle Dig
        events. In-person reservations were limited to allow for 6ft distancing during each event. A
        new “donut” like set up with 6ft distancing reminders was created to greet and space family
        groups to ensure the ultimate experience while remaining safe. Approximately 250 people were
        able to enjoy the on site events, not including beachgoers who were interested and listened
        from afar. However, the addition of Virtual Turtle Digs was new this year to provide the
        same connection and experience with those not able to attend in person. An estimated 10,000
        viewers watched a Turtle Dig online safely from afar this summer, expanding CCinc’s reach
        and impact far greater than any previous year.
      </Typography>
      <Typography paragraph>
        The National Save The Sea Turtle Foundation’s generous gift has also allowed CCinc to
        develop educational resources to provide to Turtle Friendly Businesses, including a
        Turtle-Safe Light Test. These free take-home tests were piloted by adults and children alike
        at the CCinc Turtle Dig education table this summer. This resource has become the perfect
        souvenir for those who attended a Turtle Dig, allowing them to take their new enthusiasm for
        sea turtle conservation with them to think about what they can do at home to help save our
        sea turtles.
      </Typography>
      <div className="lg:float-left lg:mr-5 mb-5"><img className="rounded-2xl" src={CCinc2} alt="" title="" /></div>
      <Typography paragraph>
        If you would like to support Coastal Connections, Inc or become more involved in their
        programs and events, please visit their website and event calendar to get connected.
        www.coastal-connections.org/events. Please follow them on Facebook @coastalconnectionsinc or
        Instagram @coastalconnections_inc for regular updates on their impact in Indian River County
        and the Florida Treasure Coast. Our award-winning collaborative Sea Turtle Trunk Program
        created by Inwater Research Group (IRG) is reaching new heights! Until now, this Statewide
        program, which reaches tens of thousands of students each year, has relied on the usage of
        plastic model turtles that weren’t quite anatomically correct. The program teaches students
        about sea turtle biology and conservation through the use of these models so it was an
        important feature we just didn’t have…until now.
      </Typography>
      <Typography paragraph>
        Recently, the National Save The Sea Turtle Foundation funded an endeavor to create new and
        improved sea turtle models based on a real juvenile green turtle encountered during a
        research trip. The turtle was part of a population study conducted by IRG in Jupiter, FL.
        While in hand, it was kind enough to allow us to take a number of photographs from all
        angles. The images were then used to create a 3D model created by our partners at Digital
        Life 3D (
        <a href="http://digitallife3d.org" class="text-blue-500 underline">
          http://digitallife3d.org
        </a>
        ). This model is now being transformed into an anatomically correct, tangible model that
        will be a part of our Trunk Program in perpetuity.
      </Typography>
      <Typography>
        Journey, as the turtle has been named, will educate hundreds of thousands of students over
        its “lifetime” and will bring a new atmosphere of authenticity to our programs. Children
        from all over Florida will learn what it means to be a sea turtle biologist and how they can
        protect sea turtles in their daily lives. We are so excited to take this Journey into the
        classroom and hope the students love it as much as we do!
      </Typography>
    </div>
  );
}
