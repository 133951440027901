import React from 'react';
import { Typography } from '@mui/material'
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import WynekenSalmon2020 from './SeaTurtleResearch/WynekenSalmon2020.pdf'
import IdentifyingSexofNeonateTurtles from './SeaTurtleResearch/IdentifyingSexofNeonateTurtles.pdf'
import Incubationenvironment from './SeaTurtleResearch/Incubationenvironment.pdf'
import JournalofExperimental from './SeaTurtleResearch/JournalofExperimental.pdf'
import Biphasicallometricgrowth from './SeaTurtleResearch/Biphasicallometricgrowth.pdf'
import Howhatchlingmarineturtles from './SeaTurtleResearch/Howhatchlingmarineturtles.pdf'
import Earlyontogeny from './SeaTurtleResearch/Earlyontogeny.pdf'
import Hydricenvironmentaleffects from './SeaTurtleResearch/Hydricenvironmentaleffects.pdf'
import Experimentalassessment from './SeaTurtleResearch/Experimentalassessment.pdf'
import Breedingsexratio from './SeaTurtleResearch/Breedingsexratio.pdf'
import Theeffectsofextendedcrawling from './SeaTurtleResearch/Theeffectsofextendedcrawling.pdf'



function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }} className='text-[#ffffff] dark:text-[#032546]'>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon className='text-[#ffffff] dark:text-[#032546]' /> : <FirstPageIcon className='text-[#ffffff] dark:text-[#032546]' />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight className='text-[#ffffff] dark:text-[#032546]' /> : <KeyboardArrowLeft className='text-[#ffffff] dark:text-[#032546]' />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft className='text-[#ffffff] dark:text-[#032546]' /> : <KeyboardArrowRight className='text-[#ffffff] dark:text-[#032546]' />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon className='text-[#ffffff] dark:text-[#032546]' /> : <LastPageIcon className='text-[#ffffff] dark:text-[#032546]' />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(name) {
  return { name };
}

const rows = [



  createData(<Link color="inherit" href="/FoundationNews/LeatherbackSeaTurtleVision"><Typography paragraph>Florida Atlantic University Studies Leatherback Sea Turtle Vision <KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/WaterwayCleanup"><Typography paragraph>The National Save The Sea Turtle Foundation Supports the Broward County Waterway Cleanup for 28th Year <KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/FloridaSeaTurtlesGetaBoost"><Typography paragraph>Florida Sea Turtles Get a Boost with Gift of New Boat<KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/SargassumBloomsandHatchlingProduction"><Typography paragraph>Too Much of a Good Thing: Sargassum Blooms and Hatchling Production at a Local Nesting Beach<KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/AReelDilemma"><Typography paragraph >Permit, Sharks and Fishing in the Lower Keys: A Reel Dilemma<KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/memoryofLlewellynEhrhart"><Typography paragraph >A Tribute to Doc In memory of Llewellyn Ehrhart (April 22, 1942- March 3, 2022)<KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/NewAluminumNestMarkingSigns"><Typography paragraph>New Aluminum Nest-Marking Signs Now Being Deployed on Florida Beaches <KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/SeaTurtleMonitoringandSatelliteTracking"><Typography paragraph>Sea Turtle Monitoring and Satellite Tracking Project at Sandy Point National Wildlife Refuge, St. Croix, Virgin Islands, 2021 <KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/SeaTurtleAdventures2021"><Typography paragraph>Sea Turtle Adventures: 2021 Recap <KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/UFwhitneyLab"><Typography paragraph>UF&apos;s Whitney Lab Hosts International Fibropapilloma Summit <KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/MORAESVkey"><Typography paragraph>MORAES Manages Sea Turtle Nesting on Miami&apos;s Virginia Key <KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/InwaterTeachesPasco"><Typography paragraph>Inwater Research Group Teaches the Teachers in Pasco County, Florida <KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/InsideTurtleNest"><Typography paragraph>Inside a Turtle Nest: Embryos Communicate About When to Hatch and When to Emerge <KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/Hawksbill2021"><Typography paragraph>Florida Hawksbill Project 2021 <KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/BrowardCountryNesting"><Typography paragraph>Broward County Reports Strong Nesting Season <KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/StyrofoamBoxes"><Typography paragraph>Can long-lasting Styrofoam boxes incubate sea turtle eggs? <KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/HelpedBioAlum"><Typography paragraph>How an undergraduate scholarship helped a marine biology alum start her research <KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/PHDstudents"><Typography paragraph>Ph.D. student&apos;s research will help protect sea turtles, marine communities <KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/StcroixProject"><Typography paragraph>ST. CROIX SEA TURTLE PROJECT @ SANDY POINT 2021 ANNUAL REPORT <KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/SargassumNowWorld"><Typography paragraph>Sargassum Now World&apos;s Largest Harmful Algal Bloom Due to Nitrogen <KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/EducationDuringCOVID"><Typography paragraph>Navigating Conservation Education During the COVID-19 Pandemic <KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/InsightsKeewaydin2"><Typography paragraph>Insights from Keewaydin Island, II. How Are Reproductively Superior Female Loggerheads Made? <KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/SargassoSea"><Typography paragraph >Legendary Sargasso Sea May Be Sea Turtles&apos; Destination During Mysterious ‘Lost Years&apos;<KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/WarmingOliveRidley"><Typography paragraph>The Effects of Warming Temperatures on Olive Ridley Sea Turtle Hatchlings<KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/EastCoastBiologists">East Coast Biologists, Inc. Investigates How Young Loggerhead Turtles Use Artificial Reefs in Brevard County<KeyboardArrowRight className="float-right " /></Link>),
  createData(<Link color="inherit" href="/FoundationNews/InsightsKeewaydin"><Typography paragraph > Insights from Keewaydin Island, I. Why Haven&apos;t Florida&apos;s Adult Loggerheads Recovered?<KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/TurlteAdventureExpand"><Typography paragraph>Sea Turtle Adventures Continues to Expand its Conservation Programming<KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/CostalCleanup"><Typography paragraph>National Save The Sea Turtle Foundation&apos;s Coastal Cleanup Program Still Afloat<KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/AmazingMantas"><Typography paragraph>Florida&apos;s Amazing Mantas!<KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/MeetStudents"><Typography paragraph>Meet the Students: National Save The Sea Turtle Foundation Scholarship Recipients Chosen at the University of Florida<KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="https://anglers.com/news/overfishing/" target="_blank" rel="noreferrer"><Typography paragraph>Overfishing, Conservation, Sustainability, and Farmed Fish<KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/SandyPoint2020"><Typography paragraph>Sandy Point Sea Turtle Project 2020! Sea Turtle Monitoring and Satellite Tracking Project at Sandy Point National Wildlife Refuge, St. Croix, Virgin Islands, 2020.<KeyboardArrowRight className="float-right " /></Typography></Link>),
  createData(<Link color="inherit" href="/FoundationNews/LeatherbackLight"> <Typography paragraph >Leatherbacks, Light, and the Comparative Study of Seafinding Behavior <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/InwaterJupiter"> <Typography paragraph >Inwater Research Group Studies Green Turtles at the Jupiter Inlet <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/FLHawkbill2020"> <Typography paragraph >Florida Hawksbill Project 2020 <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/UVIpartnership"> <Typography paragraph >National Save The Sea Turtle Foundation Partners with the University of the Virgin Islands to Study Hawksbill Turtles <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/UCFscholarshipAward"> <Typography paragraph >University of Central Florida Awards National Save The Sea Turtle Foundation Undergraduate Scholarship <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/NewTurtleModel"> <Typography paragraph >A Star is Born! New Sea Turtle Model Under Development at the Inwater Research Group, Inc. <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/DrErinSeney"> <Typography paragraph >What&apos;s in a Diet? An Interview with Dr. Erin Seney <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/BlackSaltmarsh"> <Typography paragraph >Black Saltmarsh Mosquitos - Things That Hum In The Night <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/FGCUawards"> <Typography paragraph >Florida Gulf Coast University Awards National Save The Sea Turtle Foundation Scholarships <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/FAUlabRefreshed"> <Typography paragraph >FAU Marine Science Laboratory Refreshed <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/IndianRiver"> <Typography paragraph >Vero Beach&apos;s Coastal Connections, Inc. Makes an Impact in Indian River County Kendra Cope, M.S. <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/WhitneyLab"> <Typography paragraph >The University of Florida&apos;s Whitney Laboratory for Marine Bioscience and Sea Turtle Hospital: Advancing Sea Turtle Disease Research <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/RecipientsChosen"> <Typography paragraph >First Recipients of the National Save The Sea Turtle Foundation Scholarship Fund Chosen at the University of Central Florida <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/MikeSalmon"> <Typography paragraph >FAU&apos;s Dr. Mike Salmon Joins the National Save The Sea Turtle Foundation&apos;s Research Team <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/MarineTurtleHistory"> <Typography paragraph >Life History Characteristics of Marine Turtles: What Are They and Why Are Adults and Juveniles So Different? <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/TemperatureInterview"> <Typography paragraph >Temperature-Dependent Sex Determination Unraveled: An Interview with Dr. Alex Lolavar <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/FinalReport2020"> <Typography paragraph >International Education Initiative 2019-20 Final Report <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/UCFReceives"> <Typography paragraph >UCF Receives a National Save The Sea Turtle Foundation Scholarship Grant <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/MEECadds"> <Typography paragraph >MEEC Adds Staff for Expanding Education Programming <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/InwaterResearch"> <Typography paragraph >Inwater Research Group Leads in Environmental Education Through Innovative Programming <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/TrunksTravelSouth"> <Typography paragraph >Turtle Trunks Travel South: Inwater Resarch Group and FIU&apos;s Center for Coastal Oceans Research Reach Students in Monroe County <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/GlobalSeaTurtle"> <Typography paragraph >Florida International University Leads in Global Sea Turtle Conservation <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/SupportFLgulf"> <Typography paragraph >The National Save The Sea Turtle Foundation Supports Florida Gulf Coast University <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/NovaMember"> <Typography paragraph >National Save The Sea Turtle Foundation Becomes a New Member of Nova Southeastern&apos;s Fellows Society <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/FAUsecondYear"> <Typography paragraph >Scholarship Program at FAU Now in Second Year <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/LearningLeatherbacks"> <Typography paragraph >Learning About Leatherbacks: Challenges of Captive Rearing <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/SandyPoint"> <Typography paragraph >Sandy Point Leatherback Project 2019 <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/SaltyAirSea"> <Typography paragraph >Salty Air and Sea Turtles <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/LeatherbackNesting"> <Typography paragraph >Leatherback Nesting in Southeast Florida <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/MaleGreenTurtles"> <Typography paragraph >Update: Tracking Male Green Turtles in SE Florida <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/WhatAreYouDoing"> <Typography paragraph >What Are You Doing Here? <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/SupportsEducation"> <Typography paragraph >The National Save The Sea Turtle Foundation Supports Education Programs at the Marine Environmental Education Center at the Carpenter House (MEEC) in Hollywood, Fl <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/NavigatingtheFuture"> <Typography paragraph >Navigating the Future: 39th Annual Sea Turtle Symposium Held in Charleston, South Carolina <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/OutandAbout"> <Typography paragraph >Out and About NSTSTF Attends Broward County Waterway Cleanup and Gumbo Limbo Nature Center&apos;s Sea Turtle Day <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/ClassroomEducation"> <Typography paragraph >Taking Classroom Education to a Whole New Level <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/DaringExcitingToothy"> <Typography paragraph >Daring... Exciting...and Toothy? Saving Florida&apos;s Sharks: A Look at Conservation Efforts in the Sunshine State <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/EducationatFIUCenterOceans"> <Typography paragraph >Education and Research at FIU&apos;s Center for Coastal Oceans Research <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/SeaTurlteAdventures"> <Typography paragraph >Sea Turtle Adventures Continues to Develop Innovative Programming for Adults with Special Needs <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/FoundationNews/StatewideNestingSummary2018"> <Typography paragraph >The Numbers Are In! 2018 Statewide Nesting Summary <KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/Differencesinvisualperception"> <Typography paragraph >Differences in visual perception are correlated with variation in sea-finding behaviour between hatchling leatherback, Dermochelys coriacea, and loggerhead, Caretta caretta, marine turtles<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href={WynekenSalmon2020} target='_blank'> <Typography paragraph >Linking Ecology, Morphology, and Behavior to Conservation: Lessons Learned from Studies of Sea Turtles<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href={IdentifyingSexofNeonateTurtles} target='_blank'> <Typography paragraph >Identifying Sex of Neonate Turtles with Temperature-dependent Sex Determination via Small Blood Samples<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href={Incubationenvironment}> <Typography paragraph >Incubation environment and parental identity affect sea turtle development and hatchling phenotype<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/UCFMarineTurtleResearchGroup"> <Typography paragraph >The UCF Marine Turtle Research Group – A Whole-Life Approach to Understanding Sea Turtle Biology and Informing Conservation<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/FloridaHawksbillProject2020"> <Typography paragraph >The Florida Hawksbill Project: 2020 and Beyond<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href={JournalofExperimental} target='_blank'> <Typography paragraph >Journal of Experimental Marine Biology and Ecology<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/AMDRO"> <Typography paragraph >The Pluses and Minuses of Using AMDRO© to Protect Marine Turtle Nests from Fire Ants<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/IdentifyingSexinHatchlingTurtles"> <Typography paragraph >A New Approach to Identifying Sex in Hatchling Turtles, and a Potential Game-Changer for Assessing Marine Turtle Hatchling Sex Ratios<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/MigrationofLoggerheadHatchlings"> <Typography paragraph >A Brief Exposure to Magnetic Distortions During Embryonic Development May Compromise the Migration of Loggerhead Hatchlings<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href={Biphasicallometricgrowth} target='_blank'> <Typography paragraph >Biphasic allometric growth in juvenile green turtles Chelonia mydas<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href={Howhatchlingmarineturtles} target='_blank'> <Typography paragraph >Seafinding revisited: how hatchling marine turtles respond to natural lighting at a nesting beach<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href={Earlyontogeny} target='_blank'> <Typography paragraph >The early ontogeny of carapace armoring in hawksbill sea turtles (Eretmochelys imbricata), with comparisons to its close relatives (Loggerhead, Caretta caretta; Kemp's ridley, Lepidochelys kempii)<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/AnimalBehaviorandVisualPerception"> <Typography paragraph >Animal Behavior and Visual Perception: How Does Sea Turtle Vision Differ from Ours<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/EpibiontsofHawksbill"> <Typography paragraph >Epibionts of Hawksbill Sea Turtles in Southeast Florida<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href={Hydricenvironmentaleffects} target='_blank'> <Typography paragraph >Hydric environmental effects on turtle development and sex ratio<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href={Experimentalassessment} target='_blank'> <Typography paragraph >Experimental assessment of the effects of moisture on loggerhead sea turtle hatchling sex ratios<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href={Breedingsexratio} target='_blank'> <Typography paragraph >Breeding sex ratio and population size of loggerhead turtles from Southwestern Florida<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href={Theeffectsofextendedcrawling} target='_blank'> <Typography paragraph >The effects of extended crawling on the physiology and swim performance of loggerhead and green sea turtle hatchlings<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/StudyingFloridasLivingDinosaurs"> <Typography paragraph >Studying Florida’s Living Dinosaurs: One Turtle at a Time<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/SatelliteTracking"> <Typography paragraph >Satellite Tracking Adult Male Green Turtles at the Archie Carr National Wildlife Refuge<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/RecoveryatGumboLimbo"> <Typography paragraph >The Importance of Males to the Recovery of Marine Turtle Populations<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/StudySeaTurtlesinBiscayneNationalPark"> <Typography paragraph >Florida Hawksbill Project Partners with Inwater Research Group, Inc. to Study Sea Turtles in Biscayne National Park<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/EvaluatingtheEffectsofPaternity"> <Typography paragraph >Evaluating the Effects of Paternity on Hatchling Body Size in Leatherback Sea Turtle Hatchlings (Dermochelys coriacea) Using DNA Microsatellites<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/CanMarineTurtlesNavigate"> <Typography paragraph >Can Marine Turtles Navigate? How Do We Know?<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/OctopusSuperpowers"> <Typography paragraph >Octopus Superpowers<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/SeekingTheGreenTurtles"> <Typography paragraph >Seeking The Green Turtles of the Marquesas Keys<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/HawksbillTurtleAggregation"> <Typography paragraph >Hawksbill Turtle Aggregation Being Documented in the Lower Florida Keys<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/Whysexingaseaturtlehatchlingisimportant"> <Typography paragraph >Why sexing a sea turtle hatchling is important!<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
  createData(<Link color="inherit" href="/Research/HawksbillProjectHighlightedinVideoProduction"> <Typography paragraph >National Save The Sea Turtle Foundation’s Hawksbill Project Highlighted in Video Production<KeyboardArrowRight className="float-right " /> </Typography> </Link>),
].sort((a, b) => (a.name < b.name ? -1 : 1));

export default function CustomPaginationActionsTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  // const [searchQuery, setSearchQuery] = React.useState("");

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [searchQuery, setSearchQuery] = React.useState("");


  return (
    <div>
      <div className='my-5'>
        <TextField fullWidth="true"
          placeholder="Search Foundation News"
          variant="filled"
          size="normal"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon className='text-[#932B16]' />
              </IconButton>
            ),
            style: { color: '#932B16', backgroundColor: "#ffffff" }
          }}

        />
      </div>
      <TableContainer className='dark:bg-white/90 text-white bg-[#032546]/90 '>
        <Table aria-label="custom pagination table">
          <TableBody>
            {rows
              .filter((row) => {
                const text = row.name.props.children?.props?.children?.[0] || '';
                const link = row.name.props.children?.props?.href || '';
                const query = searchQuery.toLowerCase();
                return (
                  text.toLowerCase().includes(query) ||
                  link.toLowerCase().includes(query)
                );
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row" className='text-white '>
                    <div className='text-white dark:text-[#032546]'>{row.name}</div>
                  </TableCell>

                </TableRow>
              ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[20, 40, 60, 80, 100, { label: 'All', value: -1 }]}
                colSpan={3}

                count={rows.length}
                rowsPerPage={rowsPerPage}
                style={{ color: '#ffffff', backgroundColor: '#032546' }}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                    style: { color: '#ffffff' }, // Update dropdown text color
                  },
                  MenuProps: {
                    classes: {
                      paper: 'bg-[#ffffff]', // Update dropdown menu background color
                    },
                  },

                  IconComponent: () => <span className="text-white">&#x25bc;</span>, // Update dropdown icon color

                  classes: {
                    icon: '#ffffff', // Update dropdown icon color
                    iconFilled: '#ffffff',
                    iconOutlined: '#ffffff',
                    IconButton: '#ffffff',
                  },
                }}


                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}