import React from 'react'
import { Typography, Divider, Button, Link } from '@mui/material'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5", // custom primary color
        },
        secondary: {
            main: "#932B16", // custom secondary color
        },
    },
});

export default function BecomeAMember() {
    return (
        <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
            <div><Typography paragraph><h1 className='text-2xl font-bold'>Membership</h1></Typography></div>
            <Divider className='dark:bg-[#032546] bg-white/70' />
            <div className='mt-5'><Typography>Since 1987, the National Save The Sea Turtle Foundation has worked tirelessly to provide educational programming, promote public awareness, and support sea turtle research in the U.S. and abroad. Your contribution is critical to our continued success. Thank you!</Typography></div>
            <div className='mt-5 lg:flex gap-5'>
                <div className='lg:w-1/2 text-center my-3'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/Member25.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>
                                    <div className='text-2xl font-bold text-center py-2 text-[#CBC132]'>$25.00</div>
                                    <div className='text-center'>Individual Kids Membership</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>
                <div className='lg:w-1/2 text-center my-3'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/Member25Friend.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>
                                    <div className='text-2xl font-bold text-center py-2 text-[#CBC132]' >$25.00</div>
                                    <div className='text-center'>Individual Kids Membership for a Friend</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>

            </div>

            <div className='mt-5 lg:flex gap-5'>

                <div className='lg:w-1/2 text-center my-3'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/Member50.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>
                                    <div className='text-2xl font-bold text-center py-2 text-[#CBC132]' >$50.00</div>
                                    <div className='text-center'>Individual Standard Membership</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>
                <div className='lg:w-1/2 text-center my-3'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/Member50Friend.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>
                                    <div className='text-2xl font-bold text-center py-2 text-[#CBC132]' >$50.00</div>
                                    <div className='text-center'>Individual Standard Membership for a friend</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>

            </div>


            <div className='lg:flex gap-5'>
                <div className='lg:w-1/2 text-center my-3'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/Member100.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>
                                    <div className='text-2xl font-bold text-center py-2 text-[#CBC132]' >$100.00</div>
                                    <div className='text-center'>Individual Sponsor Membership</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>
                <div className='lg:w-1/2 text-center my-3'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/Member100Friend.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>
                                    <div className='text-2xl font-bold text-center py-2 text-[#CBC132]' >$100.00</div>
                                    <div className='text-center'>Individual Sponsor Membership for a friend</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>

            </div>

            <div>
                <div className='lg:w-1/2 text-center my-3 mx-auto'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/Member300.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>
                                    <div className='text-2xl font-bold text-center py-2 text-[#CBC132]' >$300.00</div>
                                    <div className='text-center'>Business Membership</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>
            </div>


            <div className='lg:flex gap-5'>
                <div className='lg:w-1/2 text-center my-3'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/Member500.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>
                                    <div className='text-2xl font-bold text-center py-2 text-[#CBC132]' >$500.00</div>
                                    <div className='text-center'>Patron Membership</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>

                <div className='lg:w-1/2 text-center my-3'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/Member500Friend.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>
                                    <div className='text-2xl font-bold text-center py-2 text-[#CBC132]' >$500.00</div>
                                    <div className='text-center'>Patron Membership for a Friend</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>
            </div>


            <div className='lg:flex gap-5'>
                <div className='lg:w-1/2 text-center my-3'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/Member1000.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>
                                    <div className='text-2xl font-bold text-center py-2 text-[#CBC132]' >$1000.00</div>
                                    <div className='text-center'>Lifetime Membership</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>

                <div className='lg:w-1/2 text-center my-3'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/Member1000Friend.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>
                                    <div className='text-2xl font-bold text-center py-2 text-[#CBC132]' >$1000.00</div>
                                    <div className='text-center'>Lifetime Membership for a Friend</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>
            </div>


            <div className='lg:flex gap-5'>
                <div className='lg:w-1/2 text-center my-3'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/Member5000.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>
                                    <div className='text-2xl font-bold text-center py-2 text-[#CBC132]' >$5000.00</div>
                                    <div className='text-center'>Elite Sponsor Membership</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>

                <div className='lg:w-1/2 text-center my-3'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/Member5000Friend.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>
                                    <div className='text-2xl font-bold text-center py-2 text-[#CBC132]' >$5000.00</div>
                                    <div className='text-center'>Elite Sponsor Membership for a friend</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>
            </div>
        </div>
    )
}
