import { Typography } from '@mui/material'
import React from 'react'
import FAUMarineBiology from '../Images/celebrate-the-renovation-of-the-FAU-Marine-Biology.jpg'
import celebratetherenovation from '../Images/celebrate-the-renovation-of-the-FAU-Marine-Biology-2.jpg'
import Environmental1 from '../Images/Environmental-Studies-Center.jpg'
import Envionmental2 from '../Images/Environmental-Studies-Center-2.jpg'
import Sponssored1 from '../Images/Sponsored-Sea-Turtle-Exhibit-05.jpg'
import Sponsored2 from '../Images/Sponsored-Sea-Turtle-Exhibit-06.jpg'



export default function FacilitiesEnhancement() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className='pb-2'><Typography><h1 className='text-2xl'>Gumbo Limbo Nature Center</h1></Typography></div>
      <div><Typography paragraph>For many years, the National Save The Sea Turtle Foundation has been committed to enhancing the education and research facilities at the Gumbo Limbo Nature Center, located in Boca Raton, Florida.</Typography></div>
      <div><Typography paragraph>In the summer of 2014, Gumbo Limbo unveiled their new marine science research annex, operated and utilized by FAU professors and students. Renovations of this dated facility were funded by the National Save the Sea Turtle Foundation, totaling in excess of $80,000. Renovations to the facility included kid-friendly viewing areas so that the research happening down below could be seen by even the smallest of future marine biologists. The research area on the ground floor received a complete renovation and overhaul with new rust-resistant lab tables, washer/dryer, storage cabinets, as well as cutting edge lab equipment such as scales and UV sterilizers.</Typography></div>
      <div><Typography paragraph><em>“Because of rotting lights, we had rust dropping into our tanks," said Jeanette Wyneken, an FAU biology professor who works in the lab. "We don't have to worry about that anymore. Part of doing good science is being able to control your environment.”</em></Typography></div>

      <div className='lg:flex lg:w-full gap-4'>
        <div className='lg:w-1/2'><img className='rounded-2xl' src={FAUMarineBiology} alt='' title='' /></div>
        <div className='lg:w-1/2'><img className='rounded-2xl' src={celebratetherenovation} alt='' title='' /></div>
      </div>

      <div className='py-2'><Typography><h1 className='text-2xl'>Florida Oceanographic Society</h1></Typography></div>

      <div><Typography paragraph>The first phase of the Florida Oceanographic Society's new seasonal sea turtle exhibit is underway. This exhibit, which has been sponsored by the National Save The Sea Turtle Foundation, will provide warm conditions for the Society's resident sea turtles during the cooler months of the year, when they would otherwise be kept out of the public's view. This system will also serve as a 'farm' for growing sea lettuce, a natural and nutritious food for the Society's captive green sea turtles.</Typography></div>

      <div className='lg:flex gap-4'>
        <div className='lg:w-1/2'><img className='rounded-2xl' src={Sponssored1} alt='' title='' /></div>
        <div className='lg:w-1/2'><img className='rounded-2xl' src={Sponsored2} alt='' title='' /></div>
      </div>

      <div className='lg:flex'>
        <div className='lg:w-1/3'>
          <div className='mt-5'><Typography><h1 className='text-2xl'>Environmental Studies Center</h1></Typography></div>
          <div className='pt-5'><Typography paragraph>The National Save The Sea Turtle Foundation is a strong supporter of the Environmental Studies Center located in Jensen Beach, Florida. Recently, the Foundation donated a beautiful 12- foot sea turtle educational display board to bring a more dynamic and hands-on learning experience to the children who visit the facility.</Typography></div>

          <div><Typography paragraph>The display highlights the biology of sea turtles and focuses on conservation issues, such as pollution, and how they affect these endangered animals. The Center is part of the Martin County school district and serves as an educational facility for all the children in the County. Roughly 10,000 students pass through its doors each school year. </Typography></div>

          <div><Typography paragraph>In addition, the Center&apos;s teaching boat, the River Scout, received a major upgrade funded through a grant from the National Save the Sea Turtle Foundation. Most notably, the boat received new paint and an exciting hull wrap depicting an underwater scene complete with a sea turtle, fish, and coral reef. Safety equipment including a new VHF radio and Garmin navigation system were installed. New life jackets were purchased for all the staff and students. Before being put back in the water, the River Scout&apos;s motors were inspected and made ready for the new school year. </Typography></div>

        </div>

        <div className='lg:w-2/3'>
          <div className='ml-5 my-5'><img className='rounded-2xl' src={Environmental1} alt='' title='' /></div>
          <div className='ml-5'><img className='rounded-2xl' src={Envionmental2} alt='' title='' />
          </div>
        </div>
      </div>

    </div>

  )
}
