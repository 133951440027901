import React from "react";
import { Typography, Divider } from "@mui/material";
import UCF1 from "../Images/University-of-central-florida.jpg";
import JenRote from "../Images/Jen-Rote.jpg";
import JenRote2 from "../Images/Jen-Rote-2.jpg";
import MonicaReusche2 from "../Images/Monica-Reusche-2.jpg";
import MonicaReusche from "../Images/Monica-Reusche.jpg";

export default function RecipientsChosen() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <div className="lg:float-right mb-5">
          <img className="rounded-2xl border-4 border-white mx-auto" src={UCF1} alt="" title="" />
        </div>
        <div>
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              First Recipients of the National Save The Sea Turtle Foundation Scholarship Fund
              Chosen at the University of Central Florida
            </h1>
          </div>
        </div>
        <div>
          <Typography paragraph>Larry Wood, Ph.D.</Typography>
        </div>

        <div className="">
          <Divider className='dark:bg-[#032546] bg-white/70 clear-both' />
        </div>
      </div>

      <div className="my-5">
        <div className="lg:float-right lg:ml-5 mb-5"><img className="rounded-2xl mx-auto" src={JenRote} alt="" title="" /></div>
        <Typography paragraph>
          Recently, the National Save The Sea Turtle Foundation established a scholarship fund at
          the University of Central Florida in support of their Marine Turtle Research Group, led in
          part by Dr. Kate Mansfield (visit
          https://sciences.ucf.edu/biology/marineturtleresearchgroup/). The funds will support
          tuition and fees for one full academic year for each of three qualified students, along
          with the various equipment and publishing costs associated with their research projects.
          So far two students have been selected to receive their scholarships, and we wish them the
          best of luck as they embark on their academic careers! Here are their stories:
        </Typography>
        <Typography paragraph>
          Hello I’m Jen Rote! I’m originally from Pennsylvania and transferred to UCF in the Fall
          2018 semester. I had my first research experience when I took a tropical ecology course
          with a study abroad trip to the Bahamas at the Gerace Research Center. This learning
          experience on collecting and analyzing data on intertidal invertebrates gave me clarity
          that I wanted to pursue a career in field research. Once I finished my Associate’s degree
          I started to explore universities to transfer to.
        </Typography>
        <div className="lg:float-left lg:mr-5 mb-5"><img className="rounded-2xl mx-auto" src={JenRote2} alt="" title="" /></div>
        <Typography paragraph>
          I stumbled across UCF and discovered the Marine Turtle Research Group. Based on my
          previous research experience, I knew working with the turtle lab would be something I
          would enjoy and provide me with further knowledge on properly collecting data, various
          field research methodologies, and inferential analyses. The UCF MTRG ended up being a
          major deciding factor for me choosing to transfer to UCF. Despite having no guarantee of
          working in the turtle lab, I made it a top priority to reach while attending UCF. I
          completed the UCF MTRG internship last summer. After the internship I started working with
          Dr. Erin Seney, researching Kemp’s ridley turtle diets, by analyzing samples collected in
          the Gulf region from 2011-2015.
        </Typography>
        <Typography paragraph>
          My research interests include studying population ecology, animal behavior, and
          biodiversity conservation methods. My ultimate career aspiration is to develop
          conservation and management strategies for IUCN (International Union for the Conservation
          of Nature) red list species. Working with imperiled species, like marine turtles, provided
          me with the knowledge and skills to one day become a successful naturalist and
          conservation biologist. I hope to continue working alongside the turtle lab and I have set
          a new goal to present research at the 2021 International Sea Turtle Symposium. Thank you
          for the generous scholarship to students like me who want to help conserve our planet and
          all its inhabitants. I know someday I’ll make a difference.
        </Typography>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 clear-both' />
      <div className="mt-5">
        <div className="lg:float-right lg:ml-5 mb-5"><img className="rounded-2xl mx-auto" src={MonicaReusche2} alt="" title="" /></div>
        <div className="lg:float-left lg:mr-5 mb-5"><img className="rounded-2xl mx-auto" src={MonicaReusche} alt="" title="" /></div>
        <Typography paragraph>
          My name is Monica Reusche and I am a recent graduate of the University of Central Florida.
          My undergraduate degree was in biology with a focus on ecology, evolutionary, and
          conservation biology. I have been a member of the UCF Marine Turtle Research Group for
          three years and have loved every moment of it. I began my turtle journey as an intern and
          did my first research project using stable isotopes to evaluate habitat use of juvenile
          green turtles in the Indian River Lagoon with and without fibropapillomatosis. From there
          I continued to work with sea turtles and began a new project that became my undergraduate
          honors thesis which used mtDNA to study the population structure and diversity of juvenile
          green turtles foraging on the coast of Florida.
        </Typography>

        <Typography>
          Now that I have graduated, I have begun looking into graduate programs that will allow me
          to continue studying sea turtles to further our knowledge of their biology and facilitate
          their conservation. Ultimately my goal is to have a better understanding of how the
          different life stages of sea turtle populations can be protected to ensure their
          long-lasting existence. I think the biggest tool we have for conservation is educating the
          public and creating a sense of awe and responsibility. Because turtles are so long lived
          it can be hard to see the long term impacts that everyday actions may have on their
          survival, but when people are able to see and understand how intricate and connected the
          world is the idea becomes less abstract. As I continue to work and study, I hope that I
          can share my perspective and love for sea turtles with others who may never have the
          chance to experience it for themselves.
        </Typography>
      </div>
    </div>
  );
}
