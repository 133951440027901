import React from "react";
import { Divider, Typography } from "@mui/material";
import Temp1 from "../Images/Sex-ratio-temperature-response-curve.jpg";
import Youngt from "../Images/Dr-Wyneken-uses-a-laparoscope-to-peer-inside-a-young-turtle.jpg";
import View from "../Images/a-view-of-what-Wyneken-sees.jpg";
import Schematic from "../Images/Schematic-representation-of-the-mechanism-behind-sex-determination-in-turtles.jpg";
import LoggerEggs from "../Images/Loggerhead-Turtle-laying-eggs-in-a-Florida-nesting-beach.jpg";
import Observed from "../Images/Expected-vs-observed-hatchling-sex-ratios-of-the-loggerhead-turtle.jpg";
import Natural1 from "../Images/Loggerhead-hatchling-sex-ratios-from-natural-nests-over-many-nesting-seasons.jpg";

export default function Whysexingaseaturtlehatchlingisimportant() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="pb-5">
        <h1 className="text-2xl font-bold">Why sexing a sea turtle hatchling is important!</h1>
      </div>
      <div className="flex mb-2">
        <div className="pb-5">

          <Typography>
            Boris Tezak and Itzel Sifuentes Romero <br />
            Department of Biological Sciences <br />
            Florida Atlantic University <br />
          </Typography>
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="lg:flex my-5">
        <div className="lg:w-1/2">
          <Typography paragraph>
            If you walk the beach in South Florida during a summer night, you might get the
            impression that sea turtles are everywhere. You might very well see hatchlings
            scampering to the ocean and adults nesting! That’s because we have the largest nesting
            population of loggerhead turtles (Caretta caretta) in the world (Figure 1), about 48,000
            nests just last year! Additionally, the numbers of nesting green turtles (Chelonia
            mydas; ~ 28,000 nests) and leatherbacks (Dermochelys coriacea; 650 nests) have also
            increased over the past few years.
          </Typography>
          <Typography paragraph>
            Although these numbers are encouraging, currently the six extant species of sea turtle
            found in U.S. waters are considered threatened or endangered. Together, the stresses
            introduced by humans have increased pollution, disease, habitat degradation and habitat
            destruction as risks for marine turtle survival. Humans have also hunted turtles and
            harvested their eggs. All of these activities have contributed to significant population
            declines, especially compared to historical numbers which were several orders of
            magnitude greater.
          </Typography>
          <div>
            <img className="rounded-2xl mx-auto" src={Temp1} alt="" title="" />
            <em>
              Figure 2. Sex ratio-temperature response curve. This graph shows the theoretical
              expected sex ratio response to incubation temperatures derived from laboratory
              experiments. Note that temperatures of 29°C produce a 50:50 sex ratio. Lower
              temperatures produce mostly males while higher temperatures produce mostly females.
              (Graph by J. Wyneken & B. Tezak)
            </em>
          </div>
        </div>
        <div className="lg:w-1/2 ml-5">
          <div className="mb-5">
            <img className="rounded-2xl mx-auto" src={LoggerEggs} alt="" title="" />
            <em>
              Figure 1. Loggerhead Turtle laying eggs in a Florida nesting beach (B.E. Witherington,
              photo)
            </em>
          </div>
          <Typography paragraph>
            The fragile nature of all present sea turtle populations makes it important to
            understand and assess the different factors that affect sea turtle populations now, and
            those that will remain important in the years to come. By knowing those factors we can
            hope to successfully promote the recovery of marine turtle populations. One particularly
            important factor that could influence the survival of sea turtles is climate change.
            Globally, we are already experiencing some of the effects of climate change with
            extremely hot summers, incredibly cold winters, as well as increased frequency and
            severity of storms. Although we can escape some of these changes through the use of
            technology (Air conditioning during the summer is a MUST in South Florida!), most
            animals are not so lucky and may soon be faced with environmental conditions that are
            not suitable for many key biological processes. For example, climate change may very
            well affect the proportion of male and female offspring produced by marine turtles and
            those proportions, in turn, the ability of each species to successfully reproduce. There
            can be no successful recovery of marine turtle populations without successful
            reproduction!
          </Typography>
          <Typography paragraph>
            In sea turtles, unlike mammals (That’s us!), the sex of an individual is not determined
            at the moment of fertilization. This is because sea turtles lack sex chromosomes (X and
            Y in humans) and therefore, they don't have sex-specific genes (genes present only in a
            male or a female) that direct an embryo to become one or the other sex. Instead, sex in
            turtles is determined by the environment that the embryos experience during incubation
            inside the nest, and in particular by nest temperature (Figure 2).
          </Typography>
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="lg:flex my-5">
        <div className="lg:w-1/2">
          <Typography paragraph>
            In sea turtles, this pattern of temperature-dependent sex determination (TSD) is
            informally characterized by the “hot chicks, cool dudes” rule. That translates formally
            into the now well substantiated observation that when nest temperatures are relatively
            warm, most of the hatchlings will be female and when relatively cool, male. At
            intermediate temperatures, both sexes will be represented. But recent research at
            Florida Atlantic University suggests that while temperature is an important factor, it
            isn’t the only factor (Figure 3).
          </Typography>
          <Typography paragraph>
            Rainfall, and its effect on moisture conditions inside the nest, also modifies sex
            ratios by promoting the production of proportionally more male hatchlings in the nest
            than predicted by temperature, alone. This effect, which is slight but none-the-less
            significant, was only revealed by field studies and careful measurements carried out
            over the past 14 years (Figures 4). Measurements of turtle sex proportions were
            documented from nests exposed to known conditions of temperature and humidity by
            actually looking inside young turtles that came from those nests. The “look inside” was
            done using a laparoscope to see the immature ovaries and testes. This labor-intensive
            technique was developed by Dr. Jeanette Wyneken (Figure 5) and until recently, was the
            only reliable way to determine turtle sex in juvenile marine turtles without sacrificing
            the animal. (Obviously, killing the turtle to find out its sex defeated the purpose of
            promoting the recovery of marine turtle populations!)
          </Typography>
          <Typography paragraph>
            However, this technique has shortcomings. Hatchlings are too small for laparoscopic
            surgery so the turtles must be raised in captivity for at least three months, making the
            entire procedure a very expensive and labor intensive method for determining sex and
            nest sex ratios. Additionally, an expert must perform the surgery and make the
            identification.
          </Typography>
          <Typography paragraph>
            Those requirements make it impractical to obtain data on a large scale, for example, to
            determine how many males and females are being produced from the thousands of nests
            deposited each year on Florida’s beaches. What is needed, instead, is a simple and
            relatively inexpensive procedure for estimating nest sex ratios from hatchlings, without
            harming the turtles.
          </Typography>
        </div>
        <div className="lg:w-1/2 ml-5">
          <div className="mb-5">
            <img className="rounded-2xl mx-auto" src={Observed} alt="" title="" />
            <em>
              Figure 3. Expected vs. observed hatchling sex ratios of the loggerhead turtle. The red
              curve shows the expected sex ratio under laboratory conditions where external
              temperatures in incubators where the eggs develop remain constant. The black dots show
              the sex ratios obtained from natural loggerhead nests in south Florida, where
              temperature (and other conditions) fluctuates. The large blue dot shows the lab
              temperature (29°C) at which the hatchling sex ratio should be 50:50. Natural nests,
              exposed to variable temperatures that averaged 30° C (dashed box), should produce
              mostly females but instead, sex ratios are variable and can even result in a few nests
              that produce mostly males. This lack of agreement between lab and field results
              indicates that temperatura is not the only factor determining sex ratios (Adapted from
              published studies by Jeanette Wyneken and her student, Alexandra Lolavar, 2015).
            </em>
          </div>
        </div>
      </div>
      <div className="lg:flex my-5">
        <div className="lg:w-1/2">

          <div>
            <img className="rounded-2xl " src={Natural1} alt="" title="" />
            <em>
              Figure 4. Loggerhead hatchling sex ratios from natural nests over many nesting seasons
              (adapted from Wyneken & Lolavar). Sex ratios have been female-biased since 2002 but
              during years with heavy rainfall (bars containing storm clouds), the sex ratio is less
              female-biased than during dry years. This effect occurs because during wet years, some
              nests produce more males than expected.
            </em>
          </div>
        </div>
        <div className="lg:w-1/2 ml-5 pt-10">

          <Typography paragraph>
            Because sex determination in turtles is so closely linked to environmental conditions,
            the most common prediction associated with climate change is that marine turtles will be
            at a higher risk of extinction if warmer temperatures cause sex ratios to become even
            more dramatically female-biased than they already are. These concerns highlight the
            importance of identifying current and historical sex ratios at sea turtle nesting
            beaches, and using long-term sex ratio data to predict how sex ratios could change in
            the future. Put most simply, there may be a danger that if current trends continue,
            there won’t be enough males around to serve as mates for the steadily increasing numbers
            of marine turtle females.
          </Typography>
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="lg:flex mt-5">
        <div className="lg:w-1/2 my-3">
          <img className="rounded-2xl mx-auto" src={Youngt} alt="" title="" />
        </div>
        <div className="lg:w-1/2 my-3">
          <img className="rounded-2xl mx-auto" src={View} alt="" title="" />
        </div>


      </div>
      <div className="mb-5">
        <em>
          Figure 5. Left: Dr. Wyneken uses a laparoscope to peer inside a young turtle and inspect
          its developing gonad (ovary if female, testes if male). The examination takes only a few
          minutes and the turtle recovers from the surgery within 3-4 days. Right: a view of what
          Wyneken sees. Arrow shows the location of an ovary.
        </em>
      </div>
      <div className="lg:flex">
        <div>
          <Typography paragraph>
            To reiterate, the sex of hatchling marine turtles cannot at the present time be
            determined by their external appearance; it can only be determined by methods that
            require sacrificing the hatchling. Attempts to estimate nest sex ratios have
            historically been based on nest temperatures but new studies show that temperature alone
            does not provide an accurate assessment. Moisture conditions must also be known but
            until recently, moisture has not been considered. Laparoscopy provides an accurate
            estimate of sex ratios for juvenile turtles but requires raising the turtles for several
            weeks before the surgery can be performed, an expensive and time-consuming procedure.
            What is needed is a reliable, non-lethal and relatively inexpensive technique to
            identify sex at the hatchling stage for large numbers of turtles, without harming any of
            them.
          </Typography>
          <Typography paragraph>
            That’s where our work enters the scene! We are exploring a new technique to identify
            hatchling sex and, thus far, the results are promising. This technique takes advantage
            of some recent advances in molecular biology, coupled with a better understanding of the
            molecular signals that guide how each sex develops. According to current knowledge,
            temperature and moisture trigger the activity of specific genes that promote the
            development of each sex (Figure 6). The activity of those genes results in the
            production of different, sex-specific proteins that, in turn, cause an undifferentiated
            gonad to develop into either an ovary or a testis. This, at least, is what occurs in
            other reptiles such as the American alligator and the snapping turtle.
          </Typography>
          <Typography paragraph>
            Our studies reveal that similar processes also occur in marine turtles (Figure 6). Our
            new method is based upon detecting these proteins from a tiny drop of hatchling blood, a
            sample so small that it does not impair hatchling health or affect hatchling survival.
          </Typography>
        </div>
        <div className="ml-5">
          <Typography paragraph>
            Finding such a sex-specific marker in hatchlings would allow for large scale
            measurements and verification of naturally occurring sea turtle sex ratios. Our project
            is very timely, especially when we consider increasing nesting temperatures and the
            potential impacts of continued climate change on these and other turtle species with
            temperature-dependent sex determination. Our goal is to provide definitive information
            that confirms the accuracy and economic feasibility of this approach. Once that’s done,
            it should be possible to determine sex ratios of hatchlings from many sea turtle nests
            on an annual basis, and closely monitor how changes in temperature and rainfall are
            affecting each generation. That information will be essential for anticipating how
            changes in the environment are affecting marine turtle populations now, and for planning
            how to manage those populations in the future.
          </Typography>

          <div><img className="rounded-2xl mx-auto" src={Schematic} alt="" title="" /></div>
          <div className="pt-2">
            <em>
              Figure 6. Schematic representation of the mechanism behind sex determination in turtles.
              Marine turtles take about 50 days to complete embryonic development. The middle third of
              that (“thermos-sensitive”) period is when environmental features (temperature, moisture)
              trigger development down a “maleness” or “femaleness” path. Tezak and Romero are
              determining whether that path can be discovered on the basis of blood proteins that are
              specific to each sex. (Graph based upon studies by B. Tezak and I. S. Romero)
            </em>
          </div>
        </div>
      </div>
    </div>
  );
}
