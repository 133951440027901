import React from "react";
import {Typography} from "@mui/material";
import LoggerheadImg from "../Images/Loggerhead-B.jpg";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import {createTheme, ThemeProvider} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#932B16", // custom secondary color
    },
  },
});

export default function Loggerhead() {
  return (
    <div className="dark:text-[#032546] dark:bg-white/70 p-5 w-full h-full bg-[#032546]/90 rounded-3xl text-[#ffffff]">
      <div>
        <Typography paragraph>
          <h2 className="text-2xl font-bold">Loggerhead Sea Turtles</h2>
        </Typography>
      </div>

      <div className="lg:float-left lg:w-1/3 mb-5 lg:mr-5">
        <img className="rounded-3xl shadow-lg" src={LoggerheadImg} alt="" title="" />
      </div>
      <div>
        <Typography>
          The loggerhead turtle is the most common sea turtle in Florida. It is listed as a
          threatened species under the federal Endangered Species Act. Named for its large head,
          which can be ten inches wide, it has powerful jaws to crush the heavy-shelled clams, crabs
          and encrusting animals on which it feeds. In the past few years, 49,000 to 68,000
          loggerhead nests have been recorded in Florida annually.
        </Typography>
      </div>
      <div className="my-5 text-right">
        <ThemeProvider theme={theme}>
          <Link href="/SeaTurtles/Loggerhead" title="Loggerhead Sea Turtles">
            <Button color="secondary" variant="contained">
              More about Loggerhead Sea Turtles
            </Button>
          </Link>
        </ThemeProvider>
      </div>
    </div>
  );
}
