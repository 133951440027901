import React from "react";
import { Typography, Divider } from "@mui/material";
import KR1 from "../Images/Kemps-Ridley-B.jpg";
import KRM from "../Images/KR-M.png";

export default function KempsRidley() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex text-center">
        <div className="w-1/2">
          <Typography className="">
            <h1 className="text-2xl font-bold">Kemp's Ridley Sea Turtles Fast Facts</h1>
          </Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Type: Reptile</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Diet: Omnivore</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Average life span in the wild: About 50 years (est.)</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Size: 2 ft (65 cm)</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Weight: 100 lbs (45 kg)</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Protection status: Endangered</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <div className="flex justify-center">
            <div>
              <Typography className="">Size relative to a 6-ft (2-m) man:</Typography>
            </div>
            <div>
              <img className="rounded-2xl" src={KRM} alt="" title="" />
            </div>
          </div>
        </div>
        <div className="w-1/2 flex justify-center">
          <img className="rounded-2xl" src={KR1} alt="" title="" />
        </div>
      </div>
      <div>
        <Typography paragraph>Did you know? </Typography>
        <Typography paragraph>
          A 1947 amateur film showed some 40,000 female Kemp's ridley turtles nesting in Mexico in a
          single day. Today, it is estimated that only about 1,000 breeding females exist worldwide.
        </Typography>
        <Typography paragraph>
          The Kemp’s ridley turtle is the world’s most endangered sea turtle, and with a worldwide
          female nesting population roughly estimated at just 1,000 individuals, its survival truly
          hangs in the balance. Their perilous situation is attributed primarily to the
          over-harvesting of their eggs during the last century. And though their nesting grounds
          are protected and many commercial fishing fleets now use turtle excluder devices in their
          nets, these turtles have not been able to rebound.
        </Typography>
        <Typography paragraph>
          For this reason, their nesting processions, called arribadas, make for especially high
          drama. During an arribada, females take over entire portions of beaches, lugging their big
          bodies through the sand with their flippers until they find a satisfying spot to lay their
          eggs. Even more riveting is the later struggle to the ocean of each tiny, vulnerable
          hatchling. Beset by predators, hatchlings make this journey at night, breaking out of
          their shells using their caruncle, a single temporary tooth grown just for this purpose.
        </Typography>
        <Typography paragraph>
          Found primarily in the Gulf of Mexico, but also as far north as Nova Scotia, Kemp’s
          ridleys are among the smallest sea turtles, reaching only about 2 feet (65 centimeters) in
          shell length and weighing up to 100 pounds (45 kilograms). Their upper shell, or carapace,
          is a greenish-grey color, and their bellies are off-white to yellowish.
        </Typography>
        <Typography paragraph>
          They prefer shallow waters, where they dive to the bottom to feed on crabs, which are
          their favorite food, and other shellfish. They also eat jellyfish, and occasionally munch
          on seaweed and sargassum. They may live to be 50 years old.
        </Typography>
        <Typography paragraph>
          Females aren’t sexually mature until about ten to twelve years of age. They nest every one
          to three years and may lay several clutches of eggs each season. Highly migratory animals,
          they often travel hundreds of miles (kilometers) to reach their nesting beach, usually the
          same beach they hatched from.
        </Typography>
      </div>
    </div>
  );
}
