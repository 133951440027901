import React from "react";
import { Typography, Divider } from "@mui/material";
import Sandy2020 from "../Images/Sandy-Point-Sea-Turtle-Project-2020.png";
import Leona1 from "../Images/Leona-10May-24June.jpg";
import Leona2 from "../Images/Leona-24June-30November.jpg";
import SandyClip from "../Images/Sandy2020Clip.jpg";
import Foundlogo from "../Images/Foundation-Logo.png";
import Nest1 from "../Images/A-hawksbill-turtle-returns-to-the-water-after-nesting.jpg";
import SatTag from "../Images/satellite-tag.jpg";
import TrainingVol from "../Images/Training-volunteers-for-green-and-hawksbill-season.jpg";
import UTVsafe from "../Images/Sandy-Point-is-a-busy-place-during-green-and-hawksbill-season.jpg";
import BusyGreen from "../Images/Sandy-Point-is-a-busy-place-during-green-and-hawksbill-season.jpg";
import HatchNest from "../Images/A-hawksbill-hatchling-found-during-a-nest-excavation.jpg";
import Scope from "../Images/spotting-scope.jpg";
import Camp1 from "../Images/Turtle-Camp.jpg";
import Moonlight from "../Images/A-green-turtle-digs-a-nest-at-Sandy-Point-in-the-moonlight.jpg";

export default function SandyPoint2020() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <h1 className="text-2xl font-bold">Sandy Point 2020</h1>
      </div>
      <div className="my-5">
        <Divider className='dark:bg-[#032546] bg-white/70' />
      </div>
      <div className="mx-auto mb-5">
        <img className="rounded-2xl" src={Sandy2020} alt="" title="" />
      </div>
      <Typography paragraph>
        Despite all the challenges of 2020, the Sandy Point Sea Turtle Project emerged stronger and
        better this year than ever before! We were able to begin our leatherback satellite tagging
        program (delayed from 2019), complete leatherback night patrols, expand training for green
        and hawksbill monitoring and re-initiate our tagging of green and hawksbill turtles in the
        refuge. We accomplished a lot with a very small team this year.
      </Typography>
      <Typography paragraph>
        When Covid-19 struck, we had plans (and flights) for students and volunteers to join us, and
        also to present our work at the International Sea Turtle Symposium in Cartagena, Colombia.
        All of this had to be postponed initially and then canceled. By late March, we were denied
        access to nesting beaches due to a stay at home order from the governor in St. Croix. But
        working with our colleagues at the US Fish and Wildlife Service, we got special permission
        to go ahead with night patrols for leatherbacks. Thanks to the National Save the Sea Turtle
        Foundation, who had provided funds previously to purchase a Pioneer 500 UTV, we were able to
        do these patrols safely and following all precautions. Our skeleton crew of Kelly Stewart
        (TOF), Claudia Lombard and Mike Evans (USFWS), patrolled for 43 consecutive nights,
        identifying five individual leatherback turtles. We deployed satellite tags on the three
        best candidates and tracked them during the internesting season. Maps of their travels can
        be found at STXTurtles.com. We will continue this program in 2021 by deploying new tags in
        St. Croix and also in Puerto Rico with our partners there. This year we coordinated with
        other nesting beach programs and volunteers on the island for leatherback season, and we
        documented 76 leatherback nesting activities island-wide.
      </Typography>
      <div className="lg:flex my-5 lg:gap-5">
        <div className="flex-1">
          <img className="rounded-2xl my-5" src={Leona1} alt="" title="" />
          <img className="rounded-2xl" src={Leona2} alt="" title="" />
        </div>
        <div className="flex-1">
          <img className="my-5" src={SandyClip} alt="" title="" />
          <Typography paragraph>
            Even though our turtle team was not together in one place, we still worked on projects
            remotely, including data analyses, report writing, and the creation of educational
            materials for future programs.
          </Typography>
          <Typography paragraph>
            We had been working toward improving our green and hawksbill sea turtle monitoring
            protocols over the past few years and this year we made great strides! We developed and
            used mobile app questions for collecting all data for these species. We did training for
            the app and for identifying turtle crawls for volunteers, who patrol seven days a week.
          </Typography>
        </div>
      </div>
      <Typography paragraph>
        By the end of November, we had recorded 1,350 green turtle nests and 110 hawksbill nests!
        The mobile data collection made this work much more efficient, and ties in seamlessly to our
        database. In addition, we re-initiated our tagging program for greens and hawksbills,
        spending two sessions of eight days each over the full moon periods in late July and August.
        We tagged 26 greens and nine hawksbill turtles. We even found a hawksbill that had begun
        nesting at Sandy Point in 2006!
      </Typography>
      <div className="lg:float-right"><img className="my-5 rounded-2xl mx-auto" src={Foundlogo} alt="" title="" /></div>
      <Typography paragraph>
        More than ever this season, the National Save the Sea Turtle Foundation was instrumental in
        our success. Without the UTV for patrols and tagging exercises, we would not have been able
        to comply with Covid restrictions, and get this critical conservation work done. We are so
        grateful!
      </Typography>
      <Typography paragraph>
        Our project is updated regularly on our website at SeaTurtleCensus.com. We also have a
        Facebook page (@stxleatherbacks) and post on Instagram (#stxturtles). Our sincere thanks to
        National Save the Sea Turtle Foundation for the support of our program this year! The Sea
        Turtle Census Initiative is a project of The Ocean Foundation, a 501(c)(3) organization,
        working in cooperation with the US Fish and Wildlife Service and NOAA National Marine
        Fisheries Service Marine Turtle Genetics Program in La Jolla, California.
      </Typography>
      <div className="lg:flex lg:gap-5">
        <div className="flex-1">
          <div className="mt-5"><img className="rounded-2xl" src={Nest1} alt="" title="" /></div>
          <div className="pt-2">
            <em>A hawksbill turtle returns to the water after nesting</em>
          </div>
          <div className="mt-5"><img className=" rounded-2xl" src={SatTag} alt="" title="" /></div>
          <div className="pt-2">
            <em>Preparing a satellite tag for deployment by testing signal strength.</em>
          </div>
          <div className="mt-5"><img className=" rounded-2xl" src={TrainingVol} alt="" title="" /></div>
          <div className="pt-2">
            <em>Training volunteers for green and hawksbill season.</em>
          </div>
          <div className="mt-5"><img className=" rounded-2xl" src={UTVsafe} alt="" title="" /></div>
          <div className="pt-2">
            <em>The Pioneer 500 UTV made patrols safe and efficient</em>
          </div>
          <div className="mt-5"><img className=" rounded-2xl" src={BusyGreen} alt="" title="" /></div>
          <div className="pt-2">
            <em>Sandy Point is a busy place during green and hawksbill season</em>
          </div>
        </div>
        <div className="flex-1">
          <div className="mt-5"><img className=" rounded-2xl" src={HatchNest} alt="" title="" /></div>
          <div className="pt-2">
            <em>A hawksbill hatchling found during a nest excavation.</em>
          </div>
          <div className="mt-5"><img className="rounded-2xl" src={Scope} alt="" title="" /></div>
          <div className="pt-2">
            <em>
              During the time we could not access the beach, we recorded leatherback<br />
              activities using a spotting scope!
            </em>
          </div>
          <div className="mt-5"><img className="rounded-2xl" src={Camp1} alt="" title="" /></div>
          <div className="pt-2">
            <em>Turtle Camp was home once again for the project.</em>
          </div>
          <div className="mt-5"><img className="rounded-2xl" src={Moonlight} alt="" title="" /></div>

          <div className="pt-2">
            <em>A green turtle digs a nest at Sandy Point in the moonlight </em>
          </div>

        </div>
      </div>
    </div >
  );
}
