import React from 'react'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Typography, Divider, Link } from '@mui/material';




function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton className='dark:text-[#032546] '
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon className='dark:text-[#032546]' /> : <FirstPageIcon className='dark:text-[#032546]' />}
      </IconButton>
      <IconButton className='dark:text-[#032546]'
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight className='dark:text-[#032546]' /> : <KeyboardArrowLeft className='dark:text-[#032546]' />}
      </IconButton>
      <IconButton className='dark:text-[#032546]'
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton className='dark:text-[#032546]'
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon className='dark:text-[#032546]' /> : <LastPageIcon className='dark:text-[#032546]' />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, url, calories, fat) {
  return { name, url };
}

const rows = [
  createData('South Florida Audubon Society', 'https://www.browardaudubon.org/sea-turtles.html'),
  createData('Marine Environmental Education Center (MEEC)', 'https://hcas.nova.edu/carpenter-house-meec/index.html'),
  createData('Better Days Foundation', 'https://betterdays.foundation'),
  createData('Brevard Zoo', 'https://brevardzoo.org'),
  createData('Coastal Connections, Inc.', 'https://coastal-connections.org'),
  createData('East Coast Biologists', 'https://www.eastcoastbiologists.org'),
  createData('Florida International University', 'https://www.fiu.edu'),
  createData('Florida Gulf Coast University', 'https://www.fgcu.edu'),
  createData('MORAES Foundation', 'https://www.soflomoraes.com'),
  createData('Palm Beach Atlantic University', 'https://www.pba.edu'),
  createData('Sea Turtle Adventures', 'https://www.seaturtleadventures.com/'),
  createData('SEE Turtles, Inc.', 'https://www.seeturtles.org'),
  createData('THE OCEAN FOUNDATION – THE ST.CROIX, USVI SEA TURTLE PROJECT AT SANDY POINT NWR', 'https://seaturtlecensus.com/'),
  createData('University of Florida’s Whitney Lab', 'https://www.whitney.ufl.edu'),
  createData('University of the Virgin Islands', 'https://www.uvi.edu'),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

export default function CustomPaginationActionsTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

  };



  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <Typography paragraph><h1 className="text-2xl font-bold">We Support</h1></Typography>
      </div>

      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className='my-5'><Typography>YOU CAN HELP The National Save the Sea Turtle Foundation
        in our partnership efforts to support these exceptional organizations.</Typography></div>
      <div className='mb-5 text-center'><Typography>YOU CAN HELP TOO…</Typography></div>


      <TableContainer style={{ marginTop: '25px' }} className='bg-white/50 dark:bg-[#032546]/50 dark:text-[#032546]'>
        <Table sx={{ minWidth: 200 }} aria-label="custom pagination table">
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  <div variant='button' className='dark:text-[#ffffff] text-[#032546]'>
                    <a href={row.url} target='_blank' rel='noopener noreferrer'>{row.name}</a>
                  </div>
                </TableCell>


              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter className='dark:text-[#032546]'>
            <TableRow className='dark:text-[#032546]'>
              <TablePagination className='dark:text-[#032546]'

                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}

                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}

                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}