import React from 'react'
import { Divider, Typography } from '@mui/material';
import FIU from '../Images/FIU-Florida-International-University.jpg'
import FIUResearchTeam from '../Images/The-FIU-Research-Team.jpg'
import BenjaminBinderMonicaCastillo from '../Images/Benjamin-Binder-Monica-Castillo.jpg'
import multibeam from '../Images/multibeam.png'
import multibeamM3 from '../Images/multibeam-M3.jpg'
import ResearchVesselHawksbill from '../Images/Research-Vessel-Hawksbill.jpg'

export default function AReelDilemma() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className='pb-5'><h1 className='text-2xl font-bold'>Permit, Sharks and Fishing in the Lower Keys: A Reel Dilemma</h1></div></div>
      <div><Typography paragraph>Monica Gabrielle, Contributing Science Writer</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className='pt-5'>
        <div className='lg:float-right lg:ml-5 mb-5'><img src={FIU} alt='' title='' className='rounded-2xl mx-auto' /></div>
        <div><Typography paragraph>Green and silver hues flash beneath the surface far off in the distance as a fisheries researcher hops on to the gunwale. Polarized blue sunglasses slice through the afternoon glare as he calls back to the captain, “Permit! Two o’clock, 150 meters!”.</Typography></div>
        <div><Typography paragraph>What could easily be mistaken as a glare by the amateur fisher, is a tell-tale sign recognizable to the saltiest of fishers… PERMIT! Permit (Trachinotus falcatus) can be found along the western Atlantic but are more commonly seen in southern Florida and the Gulf of Mexico. Reaching lengths over 40 inches and weighing anywhere from 20-45 lbs., permit are a highly valued sportfish that offer the fight of a lifetime. In the Keys and in Biscayne Bay, an important population of permit spend the majority of their time in the deep cuts near beaches and on the flats but move to deeper waters offshore in the spring to spawn near artificial and natural reefs. In the Florida Keys, 100’s to even 1000’s of permit aggregate between March and June during the full moons. That is where our story begins.</Typography></div>
      </div>
      <div className='pt-5'>
        <div className='lg:float-left mr-5'>
          <div><img src={FIUResearchTeam} alt='' title='' className='rounded-2xl mx-auto' /></div>
          <div className='py-2'><em>The FIU Research Team</em></div>
        </div>
        <div className='lg:float-right ml-5'>
          <div><img src={BenjaminBinderMonicaCastillo} alt='' title='' className='rounded-2xl mx-auto' /></div>
          <div className='py-2'><em>Ph.D. Candidate Benjamin Binder<br />
            and Monica Castillo with a<br />
            freshly-caught permit<br />
            (Trachinotus falcatus)</em></div>
        </div>
        <div><Typography paragraph>While incredible to witness, this reproductive strategy is shared by over 150 species around the world. These “fish spawning aggregations” consist of 10’s-1000’s of individuals traveling several miles for the sole purpose of spawning during conspicuous times of the year. Not only do aggregations tend to recur during the same couple of weeks each year, they consistently travel to the same isolated stretch of reef or wreck to spawn. Variability in occurrence does exist, but aggregations are often subjected to exceptionally intense periods of fishing, some sites more popular than others, which can cause once vibrant fisheries to decline or even collapse. This is the case for permit, which are a prized target for offshore anglers while they are spawning. Permit are not listed as a species of concern under IUCN (International Union for the Conservation of Nature) guidelines, but due to the concerns of a declining population and overharvest at permit spawning sites in the Florida Keys, Florida Fish and Wildlife Commision (FWC) instituted a precautionary no harvest closure throughout the Florida Keys to protect spawning fish in the spring.</Typography></div>

        <div><Typography>This does not mean that the lucrative fishery has disappeared. In fact, the catch-and-release fishery remains nearly as strong as ever. Catch-and-release may not stand out as a problem on its own, and it does draw a significant amount of revenue to the Keys, but over the years permit have started to become even more elusive on the flats. Reports from regional inshore anglers have indicated that permit are becoming scarce on the flats, and they’ve attributed it to a unique source of mortality. Their concerns point towards depredation, or the consumption by sharks and larger fish during a fight when anglers would otherwise be practicing catch-and-release fishing. Which means, prohibiting harvest was not enough to keep these fish safe while they spawn. Not only does this concern local anglers, but also regional fisheries managers and non-profits that worked hard to see the creation of the spawning season closure years earlier. This prompted Florida International University (FIU) researchers, supported by Bonefish Tarpon Trust and the FWC, to take a closer look at permit aggregation fishing in the lower Keys. The Marine Ecology and Acoustics laboratory at FIU applies fascinating technology to answer important ecological questions in novel ways. Most of the technology has been widely used for decades, but their lab is constantly pushing the bar to find new ways to apply this powerful tool to coastal fisheries research.</Typography></div>
      </div>

      <div className='mt-5'>
        <div><img src={multibeam} alt='' title='' className='rounded-2xl mx-auto' /></div>
        <div className='py-2'><em>The multibeam provides a unique view of the underwater environment. In the image on the left, individual permit stand out against the darker background. On the right, four Caribbean reef sharks (Carcharhinus perezi) can be seen pursuing and attacking an angled permit as it rushes towards the surface. High resolution imagery like this is used to validate species when water clarity, depth, or light levels prevent traditional methods from being successful.</em></div>
      </div>
      <div>
        <div><Typography paragraph>The project initially started at one of their most hallowed spawning grounds, Western Dry Rocks, a 1 square mile area of high relief contiguous reef around ~10 miles southwest of Key West. The project started small, with the goal of characterizing fishing pressure and depredation mortality of permit at Western Dry Rocks specifically. Since 2018, the project has grown to sites in the middle Keys and now includes the 7 Mile Bridge Rubble, the Thunderbolt, and an unmarked artificial reef. The goals have expanded as well, and now they are working towards comparing aggregation sizes at artificial and natural reefs, estimating fishing effort between the regions, and determining how shark depredation varies through the Keys. The goals were simple, but the technology used to answer most of these questions was anything but! The researchers gathered fisheries independent and dependent data, applied sonar technology (echosounders and multibeam imaging sonar) to map the seafloor, estimate aggregation size, and even document the interactions between permit and their predators.</Typography></div>
        <div><Typography paragraph>A Day in the Life of a Fisheries Scientist</Typography></div>
      </div>

      <div>
        <div className='lg:float-right ml-5 mb-5'>
          <div><img src={multibeamM3} alt='' title='' className='rounded-2xl mx-auto' /></div>
          <div className='pt-2'><em>View of multibeam (M3) imaging<br />
            sonar deployed off the side of<br />
            the research vessel to capture<br />
            predator-prey interactions</em></div>
        </div>
        <div><Typography>I’ve been fortunate enough to support the team of scientists in their field efforts through the years, which mainly consisted of long days under the beating sun and lugging around bulky sonar equipment and fishing gear. After a 30-60 minute run to the site, the fun begins! All hands on deck – or better yet, eyes are laser focused on the horizon in search of those silvery green ghosts rolling inches below the surface. Once spotted, the research vessel creeps in the direction of the flashes to cast an arsenal of fishing lines that present their favorite dish, the swimming crab! If you think this sounds a lot like fishing, well it is, but for scientific purposes! Once the crab is gobbled up and the hook is set, another researcher deploys the sonar pod to determine the fate of the hooked permit and maybe capture the interaction between the school and any sharks in the area. Simultaneously, a third team member is actively recording catch rates of other anglers on the aggregation site and keeping track of the school for the next cast. Once a fish is landed, its length is recorded, pictures are taken to document their condition (and because these scientists love what they do), and the fish is promptly returned to the water with a smile and wave. This entire process is repeated over and over again until near sunset, weather permitting. At the end of the day, tired, salty, and satisfied, researchers return home to the National Save The Sea Turtle Foundation’s R/V Hawksbill I to break down and clean up sampling gear. Before a well earned night’s rest the team hunts down a slice of key lime pie, and then it’s lights out before they hit the water the following morning! While bad weather days, a shortage of live crabs, and a few delays due to the worldwide pandemic halted the project for a season, the FIU team charges onward to aid in permit conservation efforts throughout the Keys.
          What the Data Have Told Us (continued)
          Over three years, FIU researchers angled nearly 200 permit and reported highly variable rates of depredation. Between March and May of 2021, variation occurred between sites, but also month to month at the same location, ranging from 0-37% of all angled fish between March and May of 2021. Interestingly, aggregation size was comparable across the season and between sites, and there were no apparent changes in conditions, but they reported a half dozen Caribbean reef sharks (Carcharhinus perezi) and the occasional great hammerhead (Sphyrna mokarran) following the boat day after day while conducting fisheries independent surveys. Sharks in high numbers like this aren’t exceptionally common “to just see” in their work, but once the sharks have it figured out, they can’t get a permit to the boat! Due to this variability they have planned to continue their work in 2022 and 2023 and plan to deploy environmental sensors to capture the seasonal change in conditions. They’re also expanding their data collection effort at each site to begin explaining the stark changes in shark abundance and activity levels through the season.</Typography></div>
      </div>

      <div className='mt-5'>
        <div className='lg:float-left lg:mr-5 mx-auto'>
          <div><img src={ResearchVesselHawksbill} alt='' title='' className='rounded-2xl mx-auto' /></div>
          <div className='pt-2 pb-5'><em>The National Save The Sea Turtle Foundation’s<br />
            Research Vessel Hawksbill I served as home<br />
            base for the FIU Research Team while in Key West</em></div>
        </div>
        <div><Typography paragraph>Fishing pressure also varied across the season in each of the three field years, with pressure reaching its peak in March and April. So intense, in fact, that they regularly record landings upwards of 100 permit in a single day. During these periods it is not uncommon to see 10 boats fishing the aggregation simultaneously for days on end, sharing information between one another, and hooking 3-5 permit on a single pass through the aggregation. But May offers a completely different experience. In every season to date, recreational and charter anglers alike pass directly through the aggregation site, with permit breaking the surface all around them, only to drop anchor on the reef a short distance away… One might think this is peculiar, but on speaking with the research team, they’ve provided an interesting insight. Fishers switch focus from permit to other aggregating species nearby, specifically the mutton snapper (Lutjanus analis) which were off limits prior to May 1st. They represent more than a sportfishing opportunity, but a prized gamefish of excellent table fare that is iconic in the Keys. The take home message here is that permit may experience some relief in the later parts of their spawning season, which means depredation is effectively non-existent!</Typography></div>
        <div><Typography paragraph>There is more work to be done over the coming years, but the data have already told an interesting story. In order to safeguard these spawning aggregations for years to come, it’s important to understand how predators and permit interact, and how fishing influences that relationship. You can be sure that these scientists love to fish as well, and that’s what makes them the right people for the job. They engage with local anglers, have fished for most of their lives, and truly believe in the work they are doing. In the end, the hope is the data can be used by fishers and managers to ensure access to the fishery is maintained in a sustainable way.</Typography></div>
        <div><Typography paragraph>Acknowledgements</Typography></div>
        <div><Typography>This work was funded by Bonefish Tarpon Trust and supported by the Florida FWC. The team would like to offer a special thanks to Dr. Jacob Brownscombe of Carleton University and Dr. Ross Boucek of BTT. Also, a special thanks to Dr. Larry Wood and Captain Mike Osborne of the National Save The Sea Turtle Foundation for their continued support and providing accommodations aboard the R/V Hawksbill I. It has been a pleasure to work alongside Benjamin Binder, Kirk Gastrich, Gina Clementi, and the rest of the Dr. Kevin Boswell’s lab from FIU.</Typography></div>
      </div>

    </div>
  )
}
