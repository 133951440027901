import React from "react";
import { Typography, Divider } from "@mui/material";
import SeaTurtleAdventures from '../Images/Sea-Turtle-Adventures-2.png'
import STA from '../Images/STA.png'
import SeaTurtlePatrol from '../Images/Sea-Turtle-Patrol.jpg'
import RideAlong from '../Images/Ride-Along.jpg'
import RideAlong2 from '../Images/Ride-Along-2.jpg'
import RideAlong3 from '../Images/Ride-Along-3.jpg'
import RideAlong4 from '../Images/Ride-Along-4.jpg'


export default function TurlteAdventureExpand() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className='pb-5'><h1 className='text-2xl font-bold'>Sea Turtle Adventures Continues to Expand its Conservation Programming</h1></div></div>
      <div><Typography paragraph>Jacquelyn Kingston, Executive Director</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className='lg:float-right lg:ml-5 mt-5'><img className="rounded-2xl mx-auto" src={SeaTurtleAdventures} alt="" title="" /></div>
      <div className="pt-5"><Typography paragraph>Sea Turtle Adventures (STA) embarks on its 22nd year in 2021 monitoring local beaches in Palm Beach County for nesting sea turtles. The three-mile section of beach monitored by STA encompasses all of the Town of Gulf Stream, Briny Breezes, and a portion of Ocean Ridge. This section averages 900 nests annually and in 2021 reported a total of 740 nests and 623 false crawls. Although its total nest count was slightly less than average, leatherback nesting hit a new high with 19 nests (the previous record was 16 nests in 2009). Over 75% of all nests are inventoried by a team of hardworking permitted volunteers.</Typography></div>
      <div><Typography paragraph>During the hatching season, STA offers a popular and one-of-a-kind “Ride Along” program. Interested individuals hop on STA’s 5-seater ATV, equipped with cup holders and high powered fans and enjoy an ATV beach ride in route to sea turtle nests that are ready for inventory. Guests look on as the sea turtle monitor excavates the nest. Guests help record data and learn about sea turtles. From July through September 2020, STA offered 54 ride-alongs to families, groups of friends, and even a pair of Catholic nuns!</Typography></div>
      <div><Typography paragraph>In July 2020, STA worked successfully with a large condo in Town of Gulf Stream to retrofit its exterior lighting (see below). Palm Beach County and Florida Fish and Wildlife Conservation Commission (FFWCC) were instrumental in helping STA interact with the condo association and identify the optimal lights for the building to not attract nearby sea turtles but also keep condo residents safe.</Typography></div>
      <div><Typography paragraph>Additional conservation efforts that occur year-round include our efforts to respond to calls of injured and dead sea turtles. With over a dozen rescues and salvage events in 2021, STA frequently works with Gumbo Limbo Nature Center and Loggerhead Marinelife Center (LMC) to coordinate transfer of injured sea turtles and weak hatchlings to their facilities. STA also assists with the rescue of injured seabirds and shorebirds and transports them to Busch Wildlife Sanctuary for treatment.</Typography></div>
      <div><Typography paragraph>STA also partners with LMC to help implement its Responsible Pier Initiative. Members of STA’s iCare Program (a nature based program for adults with special needs) are responsible for visiting 18 piers and fishing locations in Palm Beach County on a regular basis to collect used fishing line. The monofilament is then prepared for recycling before LMC ships it off to Berkley Fishing.</Typography></div>
      <div><img className="rounded-2xl mx-auto bg-[#032546] p-5 dark:bg-transparent" src={STA} alt="" title="" /></div>
      <div>
        <div className='lg:float-left lg:mr-5'><img className="rounded-2xl mx-auto" src={SeaTurtlePatrol} alt="" title="" /></div>
        <div className="mt-5"><Typography paragraph>STA recently finished its research and testing of more environmentally friendly materials to use as opposed to plastic for sea turtle nest signs. After evaluating over 25 materials (including edible plastic and waterproof paper) and field testing seven, STA concluded that .040-gauge aluminum stood superior. In April 2020, FFWCC polled all 122 Florida Marine Turtle Permit Holders and of the 50% who responded, 80% were interested in switching to a non-plastic sign. In 2021, STA will be deploying 5,000 aluminum sea turtle nesting signs to various permit holders throughout Florida as part of a pilot project to further test the durability and overall use of these signs compared to the traditional plastic nest signs. The aluminum signs are projected to have an 8-year life span compared to 2-3 years for the currently offered plastic signs. If you are interested in receiving aluminum signs to test out on your beach, contact Jacquelyn Kingston at <a href="mailto:jkingston@seaturtleadventures.com">jkingston@seaturtleadventures.com</a>.</Typography></div>
      </div>

      <div className="lg:flex lg:gap-5 mt-5 lg:justify-center">
        <div className="lg:w-1/4 mt-5"><img className="rounded-2xl mx-auto" src={RideAlong} alt="" title="" /></div>
        <div className="lg:w-1/4 mt-5"><img className="rounded-2xl mx-auto" src={RideAlong2} alt="" title="" /></div>
        <div className="lg:w-1/4 mt-5"><img className="rounded-2xl mx-auto" src={RideAlong3} alt="" title="" /></div>
      </div>
      <div className="mt-5"><img className="rounded-2xl mx-auto" src={RideAlong4} alt="" title="" /></div>
    </div>
  );
}
