import React from "react";
import { Divider, Typography } from "@mui/material";
import FLhawkP from "../Images/florida-hawksbill-project.jpg";
import Epib from "../Images/Epibionts.jpg";
import LightShell from "../Images/hawksbill-turtle-with-light-area-on-the-shell.jpg";
import HarrietWil from "../Images/Harriet-L-Wilkes-Honors-College-Florida-Atlantic-University.jpg";

export default function EpibiontsofHawksbill() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex mb-2">
        <div>
          <div className="pb-5">
            <h1 className="text-2xl font-bold">Epibionts of Hawksbill Sea Turtles in Southeast Florida</h1>
          </div>
          <Typography paragraph>Liberty Boyd, Contributing Author</Typography>
        </div>
        <div className="lg:float-right">
          <img className=" border-4 mx-auto border-white rounded-2xl" src={FLhawkP} alt="" title="" />
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="lg:flex my-5">
        <div className="lg:w-1/2">
          <Typography paragraph>
            The Harriet L. Wilkes Honors College, located in the heart of the Abacoa community in
            Jupiter, Florida, is a growing liberal arts college with rigorous curricula and
            bountiful research opportunities for undergraduates. Every student attending this
            college is required to complete an honors undergraduate thesis on a topic of their
            choosing based on their major in order to graduate. When I began exploring my options
            for a thesis subject related to my marine biology major, I sought out a project
            involving one of my favorite marine organisms; sea turtles. Most of the sea turtle
            research conducted in South Florida involves studying the large population of turtles
            that crawl onto our beaches every year to nest, as well as the subsequent emergence of
            millions of baby sea turtle hatchlings. While this facet of the sea turtle life cycle is
            important to study, I was more interested in studying the typical day to day behaviors
            and ecology of sea turtles, which requires in-water research. In order to develop an
            in-water based sea turtle project, I partnered with Dr. Larry Wood of the Florida
            Hawksbill Project and the National Save The Sea Turtle Foundation, whose research
            focuses on hawksbill sea turtle population assessments and migration off the coast of
            Southeast Florida.
          </Typography>
          <Typography paragraph>
            Many scientists who conduct in-water sea turtle research ask important questions about
            sea turtle behavior, ecology, and habitat use. However, my central question, while
            involving sea turtles, focuses rather on a less explored feature of these animals; the
            behaviors, ecology, and distribution of the organisms that live on their exterior,
            otherwise known as epibionts. The amount and variety of other organisms living on sea
            turtles varies based on the turtle species, their size, and location. In particular, the
            hawksbill sea turtle is known to host a wide array of epibionts on their exterior,
            including over 100 epibiont species from algae, sponges, corals, and crustaceans.
          </Typography>
          <Typography paragraph>
            Due to the wide and diverse habitat range of hawksbills, the epibiont communities that
            live on their body differ depending on the home range of an individual turtle.
            Hawksbills found in the Indian Ocean will have very different epibionts than those found
            in the Caribbean or in the Pacific. Epibiont studies for hawksbills have been conducted
            in numerous places all over the world, but the epibionts of hawksbills off the Southeast
            coast of Florida have never been documented. That is where the work conducted by Dr.
            Wood and the Florida Hawksbill Project came into play.
          </Typography>
        </div>
        <div className="lg:w-1/2">
          <Typography paragraph>
            Dr. Wood, who has been collecting data since 2004, allowed me to use his capture data
            and photographs to analyze the epibiont communities that were present on the captured
            sea turtles. With a sample size of 213 turtles, there were plenty of turtle pictures to
            look through and see what types of animals were colonizing. I limited my focus to the
            turtle carapace due to limitations from the photos themselves and to standardize the
            study. I also used data from the capture data sheets to gain information about the size
            of the turtle’s shell, which relates to the relative area that the epibionts could
            settle.
          </Typography>
          <Typography paragraph>
            After looking at all the pictures, I was excited to find a variety of organisms living
            on the hawksbill carapaces including filamentous red and green algae, calcareous red
            algae, red sponge, Chelonibia spp. (sea turtle barnacles), and Millepora spp. (fire
            coral). I also noticed patterns in the distribution of these organisms on their shells.
            The red sponge was most commonly found tucked under the overlaps of the scutes near the
            tail end of the carapace. Fire coral, which was found on 20% of the turtles analyzed,
            also preferred to grow on the tail end of the shell, specifically on the pygal scutes.
            However, the sea turtle barnacles had different preferences and were found to settle
            more often near the top or middle of the shell.
          </Typography>
          <Typography paragraph>
            The straight carapace length at maximum (SCLmax) was the measurement I used as my
            standard for determining turtle size, which can also be used as a proxy for the turtle’s
            relative age.
          </Typography>
          <img className="mx-auto rounded-2xl" src={Epib} alt="" title="" />
          <em>
            “Epibionts” are organisms that live on the exterior of other organisms. Sea turtles
            accumulate various types of epibionts, including barnacles, algae, sponge, and coral. In
            most cases, they are harmless and have formed a symbiotic relationship with their hosts.
          </em>
        </div>
      </div>
      <div className="lg:flex gap-5">
        <div className="lg:w-1/4">
          <div><img className="mx-auto rounded-2xl" src={LightShell} alt="" title="" /></div>
          <div className="pt-2">
            <em >
              Along with some barnacles, this hawksbill turtle is hosting a colony of fire coral
              Millepora spp. (light area on the shell).
            </em>
          </div>
        </div>
        <div className="lg:w-1/3">
          <Typography paragraph>
            When comparing the SCLmax to the presence of organisms found on the carapace, I found
            that smallest, youngest turtles had little to no epibiont coverage. The first epibionts
            to settle were the filamentous red and green algae and the sea turtle barnacles. As the
            turtle grows larger and older, encrusting red algae begins to settle, followed by the
            fire coral. This suggests that these turtles are not exposed to areas that host these
            types of organisms until they move into deeper reef areas as they transition from
            juveniles to sub-adults. As the turtles grow, the increasing surface area of the
            turtle’s shell may also play a role in epibiont settlement because of the greater
            available space for them to land and settle.
          </Typography>
          <Typography paragraph>
            One of the most interesting parts of the project was looking at the epibiont growth over
            time based off photographs
          </Typography>
        </div>
        <div className="lg:w-1/2">
          <div className="lg:float-right  lg:ml-5 lg:mb-5 mb-5"><img className="mx-auto rounded-2xl" src={HarrietWil} alt="" title="" /></div>
          <Typography paragraph>

            from turtles that were re-captured. I took a special interest in comparing the number of
            sea turtle barnacles between captures and found that the number of sea turtle barnacles
            on hawksbill carapaces increases over time. I also saw that the number of sea turtle
            barnacles compared to the size of the carapace tend to plateau as the turtle reaches
            maturity. I speculate that this is due to slower growth of the turtle shell, meaning
            there is less surface area for the barnacles to settle. I also think that this may be
            due to a reached carrying capacity for healthy barnacle coverage on the carapace.
          </Typography>
          <Typography paragraph>
            Moving forward, I plan to continue researching this topic by doing more spatial analysis
            of epibiont growth on hawksbill carapaces to gain a better understanding of how these
            epibionts grow and settle. I hope that this work can be used in the future for sea
            turtle health assessments, hawksbill identification, and to further identify and study
            hawksbill epibionts in this area.
          </Typography>
        </div>
      </div>
    </div>
  );
}
