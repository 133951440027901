import React from "react";
import { Divider, Typography } from "@mui/material";
import FLhb2020 from "../Images/The-Florida-Hawksbill-Project-2020.png";
import Generous1 from "../Images/generous-volunteers.jpg";
import Inhabit2020 from "../Images/Hawksbill-turtles-primarily-inhabit.jpg";

export default function FloridaHawksbillProject2020() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex mb-2">
        <div className="lg:w-3/4">
          <div className="pb-5">
            <h1 className="text-2xl font-bold">The Florida Hawksbill Project: 2020 and Beyond</h1>
          </div>
          <Typography paragraph>
            Larry Wood, Ph.D., Founder and Director,<br />
            Florida Hawksbill Project
          </Typography>
        </div>
        <div className="lg:w-1/4 lg:float-right">
          <img className="mx-auto rounded-2xl" src={FLhb2020} alt="" title="" />
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="lg:flex my-5">
        <div className="">
          <Typography paragraph>
            The Florida Hawksbill Project finished out the decade with another successful year! Now
            <div className="lg:float-left">
              <div className="lg:mr-5 mb-5">
                <img className="rounded-2xl mx-auto my-5" src={Generous1} alt="" title="" />
                <em>
                  Many thanks to the generous volunteers who donate their time to
                  help search for hawksbill turtles! All activities authorized under State and Federal Permits.
                </em>
              </div>
            </div>

            moving into its 16th year, the Project continues to gather vital information concerning
            the abundance and distribution of this highly endangered species in Florida waters.
            Since hawksbills don’t nest in Florida, it is the extensive coral reefs that line the
            coast from the Keys to Palm Beach County that attract them to our waters. While here,
            these young individuals are going through one of the most vulnerable stages of their
            lives, and the Project remains focused on bringing good science and public awareness to
            help them along their way.
          </Typography>
          <Typography paragraph>
            One of the most important things we’d like to know is how many hawksbills might inhabit
            Florida’s waters, so we make an effort to spread the search effort to as many locations
            along the reef tract as possible. In 2019, we visited some new areas that included the
            Dry Tortugas, North Miami, and the upper Florida Keys near Key Largo and Islamorada.
            These surveys help us understand how the seascape and other local environmental factors
            may influence their distribution during the time they are visiting Florida.
          </Typography>
          <div className="lg:float-right">
            <div> <img className="rounded-2xl mx-auto" src={Inhabit2020} alt="" title="" /></div>
            <div className="pt-2 pb-5">
              <em>
                Hawksbill turtles primarily inhabit Florida’s waters as juveniles, and are
                expected to depart for their places of origin throughout the Caribbean once
                they reach maturity. They are dependent upon healthy coral reefs to survive.
              </em>
            </div>
          </div>
          <Typography paragraph>
            In 2019, myself and/or the team jumped in the water 107 times for a grand total of 4,120
            minutes (68.7 hours) of seeking turtles (not adjusted for number of surveyors; if so,
            that number would grow substantially). We captured a total of 18 turtles, which was
            comprised of 14 new ones and 4 ‘recaptures’, which are ones that we had previously
            marked with identification tags. All four were found in their original capture
            locations, reinforcing our understanding of their tendency to stay in certain locations
            for extended periods of time. Our smallest of the year, now fondly known as ‘Baby
            Spice’, was 26.2 cm (shell length), and the biggest was a hefty 79.2 cm. Overall, the
            average size of the turtles we captured was 48.3 cm, exactly what one would expect from
            an aggregation of young hawksbills hanging out in their developmental habitat. These
            captures bring the grand total to 245 individuals we’ve been able to collect data from,
            which is by far the largest dataset for this species in this part of their range.
          </Typography>
          <Typography paragraph>
            2020 is going to be an exciting year for the Project. We expect to achieve the sample
            sizes required to complete some of our ongoing studies concerning this population’s
            physiological health, movements, and behavior. We are looking forward to presenting some
            results of our barnacle studies at the upcoming International Sea Turtle Symposium to be
            held in Cartagena, Colombia, and creating new partnerships to expand our impact beyond
            Florida.
          </Typography>
          <Typography paragraph>
            I would like to thank the strong support of the National Save The Sea Turtle Foundation
            to allow this long-term project to become even longer-term. Research conducted in the
            water with sea turtles is logistically challenging, very costly, and often progresses at
            a slow pace, but the Foundation has been able to accomodate our unusual needs patiently
            and enthusiastically. I’d also like to thank all the wonderful volunteers who have
            joined us on our survey trips, they truly make the project possible, and the Captains
            and Crews of all the dive boats who are so kind to welcome us aboard!
          </Typography>
        </div>
        <div className=""></div>
      </div>
    </div>
  );
}
