import React from "react";
import { Typography, Divider } from "@mui/material";
import MEEC1 from "../Images/MEEC-Adds-Staff-for-Expanding-Education-Programming.jpg";
import MEEC2 from "../Images/MEEC.jpg";
import NOVA2 from "../Images/Nova-Southeastern-University-Halmos-College-of-natural-sciences-and-oceanography.png";
import MEEC11 from "../Images/MEEC-1.jpg";
import MEEC22 from "../Images/MEEC-2.jpg";
import BrowardCFL from "../Images/Broward-County-Florida.jpg";
import MEEC33 from "../Images/MEEC-3.jpg";

export default function MEECadds() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <div className="pb-5">
          <h1 className="text-2xl font-bold">MEEC Staff Expansion</h1>
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="lg:flex justify-center items-center mt-5 ">
        <div>
          <img
            className="rounded-2xl"
            src={MEEC1}
            alt=""
            title="MEEC Adds Staff for Expanding Education Programming"
          />
        </div>
      </div>

      <div className="my-5">
        <div className="lg:float-right lg:ml-5 mb-5"><img className="rounded-2xl mx-auto" src={MEEC2} alt="" title="" /></div>
        <Typography paragraph>
          Thanks to the support of the National Save The Sea Turtle Foundation, the NSU Marine
          Environmental Education Center (MEEC) at the Carpenter House has introduced many new
          educational programs and welcomed new staff members in 2019. With the support of the
          Foundation, the MEEC has added more educators to expand on school and public programs.
          Since opening in 2017, over 40,000 guests have visited the MEEC with nearly 3,000 of those
          participating in school programing. School children of all ages can participate in
          programs designed to provide more information about endangered species, ecology and
          conservation, and invasive species.
        </Typography>
        <div className="lg:float-left lg:mr-5"><img className="rounded-2xl mx-auto" src={NOVA2} alt="" title="" /></div>
        <Typography paragraph>
          In the fall of 2019, the MEEC educators started a bi-monthly Science Saturday program with
          various subjects for each program including ghost fishing, invasive lionfish, plankton,
          freshwater systems, sustainability and sea turtle nesting. Future Science Saturday program
          topics can be found on the MEEC website as well as Facebook and Instagram pages.
        </Typography>
        <Typography paragraph>
          While visiting the MEEC, guests get a chance to visit their resident green sea turtle,
          Captain, and learn more about the threats to our local wildlife. There is a public sea
          turtle feeding each day at 3:30pm where you can hear Captain’s history and learn about the
          different sea turtle species. In addition to Captain, guests can visit with the resident
          box turtle, Stella, a red-eared slider named Cashew, and Clem, the resident diamondback
          terrapin. As the MEEC continues to grow, additional exhibits and educational opportunities
          will be added.
        </Typography>
      </div>

      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />

      <div className="pt-5">
        <div className="lg:float-left lg:mr-5 mb-5"><img className="rounded-2xl mx-auto" src={MEEC11} alt="" title="" /></div>
        <div className="lg:float-right lg:ml-5 mb-5"><img className="rounded-2xl mx-auto" src={BrowardCFL} alt="" title="" /></div>

        In the summer of 2019, the MEEC welcomed a new education coordinator, Carley Todd. Carley
        grew up on Long Island surrounded by the water, spending her childhood days exploring
        tidal pools and playing in the woods. She received her Bachelor’s degree in Biology from
        Salisbury University on the Eastern Shore of Maryland. After graduating, she worked a
        variety of jobs until she ended up on Big Pine Key as a Marine Science Instructor at
        Newfound Harbor Marine Institute. She quickly realized experiential learning was a great
        way for students to learn while having fun. Carley then went to the west coast of Florida
        as a Marine Science Educator at Sanibel Sea School where she led hands-on classes and
        programs about the marine environment. After a stint on land as an Environmental Educator,
        she went back home to the ocean and ended up at the MEEC as the Education Coordinator.
        She’s excited to help inspire the next generation to fall in love with the ocean and all
        of its inhabitants. Carley enjoys hiking, cooking, reading, playing volleyball and doing
        yoga in her free time.
      </div>


      <div className="clear-both mt-5">
        <div className="lg:float-right lg:ml-5 mb-5"><img className="rounded-2xl " src={MEEC22} alt="" title="" /></div>
        In the fall of 2019, Kelly Martin joined the MEEC as the program coordinator. Kelly grew up
        in Michigan with a love for all animals. After an internship working with sea turtles, she
        was hooked. Kelly has worked with sea turtles in a variety of settings over the past 17
        years. After graduating from Michigan State University and completing an internship with
        leatherbacks in southeast Florida, she took a job in animal care at the Clearwater Marine
        Aquarium and then at Mote Marine Lab. She cared for captive loggerhead, green, hawksbill,
        and Kemp’s ridley sea turtles. While working at Mote, she completed her Master’s in Marine
        Science at the University of South Florida while studying the auditory and cognitive
        abilities of captive sea turtles. She then moved back to the east coast where she conducted
        nesting surveys, leatherback tagging and tracking, and lighting compliance surveys in Palm
        Beach County for ten years. She is currently the president of Florida Leatherbacks Inc., a
        nonprofit that conducts leatherback sea turtle research in Martin County. When not spending
        her time with turtles she loves to travel, camp, hike, and volunteer with the dogs at her
        local animal shelter.
      </div>




      <div className="lg:flex gap-5 clear-both">

        <div className="lg:w-1/3">
          <div className="my-5 lg:float-left"><img className="rounded-2xl mx-auto" src={MEEC33} alt="" title="" /></div>
        </div>
        <div className="lg:w-2/3">
          <div >
            <div className="">

              The MEEC’s biggest event of the year is coming up on April 25, 2020. The Free Our Seas
              and Beyond Art Festival will be held at the MEEC on Hollywood Beach. The MEEC partners
              with Free Our Seas and Beyond to host a beach cleanup and a full-day festival including
              vendors, artists, live music, food, and more. Please make sure to check out the website
              and social media pages for updates. The MEEC is located in Hollywood, FL and is open
              Tuesday through Saturday from 10am-5pm.

            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
