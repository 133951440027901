import React from "react";
import { Typography, Divider } from "@mui/material";
import ChristineSarkis from '../Images/Christine-Sarkis.jpg'
import UCF from '../Images/University-of-central-florida.jpg'

export default function UCFscholarshipAward() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className="pb-5"><h1 className='text-2xl font-bold'>University of Central Florida Awards National Save The Sea Turtle Foundation Undergraduate Scholarship</h1></div></div>
      <div><Typography paragraph>Christine Sarkis, University of Central Florida</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="mt-5">
        <div className='lg:float-right lg:ml-5 mb-5'><img className="border-4 border-white mx-auto" src={UCF} alt="" title="" /></div>
        <div className='lg:float-left lg:mr-5 mb-5'><img className="rounded-2xl mx-auto" src={ChristineSarkis} alt="" title="" /></div>
        <div><Typography paragraph>I am a senior majoring in Biology at the University of Central Florida. I am a Learning Assistant for Calculus 1, and this is my fourth semester aiding incoming freshmen in their STEM journey through Calculus 1. This year I am also a peer coach for the Knights Major Exploration and Transition Center, assisting students in their major exploration. In addition, I am working with Dr. Anna Forsman on a research project in collaboration with Dr. Erin Seney from the Marine Turtle Research Group on using genomic methods to identify the diet of green sea turtles. I started working on this project my freshman year and have learned a lot about sea turtle conservation as well as how genomics can be an effective tool in conservation efforts. It has taught me how to be a better scientist and the true impact that science can have on the world around us.</Typography></div>
        <div><Typography >Being chosen to receive the National Save The Sea Turtle Foundation Scholarship was deeply humbling and I am honored to be chosen. This scholarship will transform the time I have left at the University of Central Florida. It relieves the financial burden associated with pursuing a degree and enables me to focus on my research project. This scholarship allows me to dedicate more time to my research project and furthering sea turtle conservation. It gives me the freedom and confidence to focus on experiences outside the classroom. It empowers me to continue my journey in science and conservation, and not be limited by financial concerns.</Typography></div>
      </div>
    </div>
  );
}
