import React from "react";
import { Typography, Divider } from "@mui/material";
import Boydrecordsdata from '../Images/Boyd-records-data-on-juvenile-green-sea-turtles.jpg'
import Boydholdsjuvenile from '../Images/Boyd-holds-a-juvenile-green-sea-turtle.jpg'
import Boydrecordsdata2 from '../Images/Boyd-records-data.jpg'

export default function PHDstudents() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className='pb-5'><h1 className='text-2xl font-bold'>Ph.D. student’s research will help protect sea turtles, marine communities
      </h1></div></div>
      <div><Typography paragraph>Written by Contributing Writer<br />
        December 21, 2021</Typography></div>
      <div><Typography paragraph>By: Carolina Maria</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />

      <div className="pt-5">
        <div><Typography paragraph>It takes a lot of seagrass to support a healthy sea turtle population.</Typography></div>
        <div><Typography paragraph>What a healthy sea turtle population looks like, how many seagrasses they need to stay healthy, where are they eating and how much they eat are key questions FIU researchers are trying to answer as they look for insights into whether sea turtle conservation efforts are working.</Typography></div>
      </div>

      <div className="lg:flex gap-4">
        <div>
          <div><img className="rounded-2xl mx-auto" src={Boydrecordsdata} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>Boyd records data on juvenile green sea turtles. <br />Photo courtesy Liberty Boyd.</em></div>
        </div>
        <div>
          <div><img className="rounded-2xl mx-auto" src={Boydholdsjuvenile} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>Boyd holds a juvenile green sea turtle.<br />Photo courtesy Liberty Boyd.</em></div>
        </div>
        <div>
          <div><img className="rounded-2xl mx-auto" src={Boydrecordsdata2} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>Boyd records data.<br />Photo courtesy Liberty Boyd.</em></div>
        </div>
      </div>


      <div>
        <div><Typography paragraph>Liberty Boyd, a Ph.D. student in the FIU Institute of Environment’s Heithaus Lab is looking for answers at the Cape Eleuthera Institute in the Bahamas. Boyd is mapping the seagrass beds and measuring how quickly they regrow using drone footage and in-water surveys. She is also tagging sea turtles as part of a long-term mark and recapture project to understand how their populations have changed over time. Aerial drone surveys will tell her where sea turtles live and how many there are.</Typography></div>
        <div><Typography paragraph>The mostly juvenile green sea turtles at Cape Eleuthera eat seagrasses and grow significantly as they reach sexual maturity. Similar to cattle, sea turtles trim back seagrass growth, which helps more seagrasses grow back in place of what was eaten.</Typography></div>
        <div><Typography paragraph>Boyd’s research, made possible by support from the National Save the Sea Turtle Foundation, will provide the clearest evidence yet of what’s happening beneath the waves. The foundation’s support is also helping FIU create a hub for sea turtle research.</Typography></div>
        <div><Typography paragraph>“Studying the ecological impacts sea turtles have on marine habitats is what makes my work so interesting because with this knowledge we can better understand how to protect sea turtles and our marine communities,” Boyd said.</Typography></div>
        <div><Typography >​​In part because of the research conducted by the Institute of Environment, FIU was ranked the No. 3 public university in the U.S. and No. 11 in the world for SDG 14, Life Below Water, which measures universities’ research on life below water and their education on and support for aquatic ecosystems.</Typography></div>
      </div>

    </div>
  );
}
