import React from "react";
import { Divider, Typography } from "@mui/material";
import Exposure1 from "../Images/A-Brief-Exposure-Fig1.jpg";
import Exposure2 from "../Images/A-Brief-Exposure-Fig2.jpg";

export default function MigrationofLoggerheadHatchlings() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex mb-2">
        <div>
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              A Brief Exposure to Magnetic Distortions During Embryonic Development <br /> May
              Compromise the Migration of Loggerhead Hatchlings
            </h1>
          </div>
          <Typography paragraph>MICHAEL SALMON</Typography>
          <Typography paragraph>
            Department of Biological Sciences, Florida Atlantic University, <br />
            Boca Raton, Florida, 33431, USA [salmon@fau.edu]. <br />
          </Typography>
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="my-5">
        <Typography paragraph>
          ABSTRACT. – In a previous study it was shown that loggerhead eggs exposed during embryonic
          development to a distorted magnetic field produced hatchlings incapable of using magnetic
          information for orientation or navigation. My study was done to determine if hatchlings
          could recover from those effects when magnetic distortions were confined to shorter time
          periods (the first, second or last third of embryonic development). Under those conditions
          I found that orientation still failed and conclude that even a relatively short exposure
          to field distortions might potentially incapacitate hatchlings that use magnetic cues
          during offshore migration.
        </Typography>
        <Typography paragraph>
          Loggerhead hatchlings (Caretta caretta) typically emerge from their nests on western North
          Atlantic oceanic beaches at night, and then crawl to the surf zone and swim offshore. They
          then migrate to nursery areas often located hundreds of kilometers distant from the nest
          site. Observations done over several years have confirmed that many turtles find those
          areas even though juveniles at this stage in their development are both small and weak
          swimmers. They succeed by swimming into, then utilizing, oceanic surface currents (gyres)
          that help transport them to distant nursery areas (Lohmann and Lohmann 2003). They also
          detect the inclination angle and intensity of the earth’s magnetic field, magnetic
          attributes that vary spatially and provide unique paired values across the migratory
          routes and habitats frequented by the turtles during their early ontogeny. That
          sensitivity makes possible a bi-coordinate navigation system used to determine position
          within the North Atlantic Gyre (Lohmann and Lohmann 2003; Putman et al. 2011), as well as
          progress toward nursery sites adjacent to the Azores, Canary and Madeira Islands.
        </Typography>
        <Typography paragraph>
          This study was inspired by an experiment done by Fuxjager et al. (2014) designed to
          determine how loggerhead hatchlings develop the ability to use magnetic information. They
          placed magnets in close proximity to the eggs so that the embryos developed in a distorted
          magnetic field. They then determined how hatchlings that emerged from those nests
          responded to magnetic and other orientation cues required for successful migration. They
          found that hatchlings were able to orient from the nest to the surf zone (using visual
          cues) and from the surf zone to deeper water (using the orbital motion contained in
          oceanic surface waves) but were unable to orient when presented with magnetic cues. Thus,
          a hatchling’s ability to respond appropriately to magnetic cues required that embryonic
          development was completed in a natural magnetic environment.
        </Typography>
        <Typography paragraph>
          A number of studies, however, have shown that many vertebrate species can develop normally
          even when exposed to deficient environments through developmental homeostasis (Alcock
          2009), a process that acts as a buffer by partially or completely suppressing non-adaptive
          phenotypes. My study was done to determine if turtle embryos exposed to magnets for
          shorter and/or different periods during embryonic development might emerge from nests
          still capable of using magnetic cues for orientation.
        </Typography>
        <img className="lg:float-right rounded-2xl m-5" src={Exposure1} alt="" title="" />
        <Typography paragraph>
          Methods. —To test this idea, I designed experiments in which two bar magnets, identical in
          size, shape and strength to those used by Fuxjager and associates (10-cm long x 1.4-cm
          wide), were placed on opposite sides of loggerhead nests during the first third (days
          1–16; “early magnet”), second third (days 17–32; “middle magnet”) or last third (day 33
          until emergence; “late magnet”) of the typical summer incubation period (47–50 days,
          depending upon temperature). A fourth group consisted of nests exposed to magnets for the
          entire incubation period (“total magnet” group) while a fifth group consisted of control
          nests exposed to a pair of (non-magnetic) aluminum bars (Fig. 1). A sixth (natural
          control) group consisted of undisturbed nests.
        </Typography>
        <Typography paragraph>
          My study was carried out at Boca Raton, Florida, U.S.A.(26.22°N, 80.07°W) over three
          summers (July–Sept, 2016–2018). That lengthy time period was a consequence of two
          successively hot and dry seasons that reduced hatchling survival and compromised their
          vigor, especially for turtles that emerged from nests during the mid- to late summer. For
          all but the natural control nests, I constructed pairs of “J-shaped” PVC tubes (23-cm long
          x 5-cm outside diameter) and buried them ~30 cm to the east and west of the egg chamber,
          at a depth of ~ 1.0 m to the bottom (horizontal portion) of the tube (Fig. 1). Tubes were
          left in place from the morning after egg deposition until the end of incubation. I lowered
          bar magnets or aluminum bars into each tube by a string until they were positioned
          horizontally in a N-S direction at the bottom of the tube; the string was again used to
          retrieve the bars at an appropriate time. Otherwise, each tube was sealed with a cap (Fig.
          1). Three nests were assigned to each of the 6 groups. They were established at the beach
          in a rotating sequence at intervals of 1–4 days. In the afternoon before an anticipated
          emergence, I removed a sample of hatchlings from each nest and placed them on a shallow
          layer of damp sand inside a small styrofoam™ cooler. The cooler was stored in a dark
          non-air conditioned laboratory until the evening when the responses of 10 hatchlings from
          each nest were recorded. All of the turtles were then released.
        </Typography>
        <Typography paragraph>
          I determined the ability of each turtle to orient when only magnetic cues were available
          in an outdoor water-filled, circular polyethylene pool, 1.45 m in diameter x 53 cm tall,
          filled to a depth of 40 cm with fresh water. The pool was elevated on concrete blocks at a
          location where the horizontal and vertical magnetic components at the beach were closely
          approximated. The pool was covered during testing to eliminate stray lighting.
        </Typography>
        <div className="lg:float-left lg:mr-5"><img className="mx-auto rounded-2xl" src={Exposure2} alt="" title="" /></div>
        <Typography paragraph>
          This system is pictured elsewhere (Merrill and Salmon 2011) and will only briefly be
          described here. Each turtle was placed in a cloth harness (Fig. 2) attached by a
          monofilament line to a lever arm. The arm rotated horizontally just above the water
          surface to track the turtle’s directional swimming movements. A digital encoder linked to
          the lever arm was used to record the turtle’s orientation and send that information to a
          computer. A dim light, located on one side of the tank, attracted the turtle so that it
          swam east during a 10 min “pretest” period. That response is used to activate the turtle’s
          magnetic orientation system. The light was then extinguished so that the turtle swam in
          total darkness during a 3-min “acclimation” period. The acclimation period was followed by
          a “test” period when the turtle’s heading was recorded every 10 sec for 5 min. Those 30
          headings stored on computer were averaged to yield a mean heading for that subject.
        </Typography>
        <Typography paragraph>
          Loggerhead hatchlings exposed to this testing regime (developed by Lohmann and Lohmann
          2004) will orient in directions appropriate to the magnetic information they detect during
          the test period. In this experiment, that information was the inclination angle and
          intensity of the magnetic field at Boca Raton, Florida. The predicted response was an
          eastward heading, provided that the turtles had developed the capacity to respond to
          magnetic cues. To find out if they had, I compared the number of mean headings that were
          generally eastward (between 0° and 179°) to the number that were generally westward (180°
          through 359°) for the turtles under each treatment condition. A binomial test was used to
          determine if those headings were distributed randomly or if the turtles exhibited an
          eastward bias.
        </Typography>
        <Typography paragraph>
          Results and Discussion. — While the turtles in the two control groups showed a significant
          eastward preference, all of the turtles exposed during development to a magnetically
          distorted field, regardless of when or for how long during incubation that exposure
          occurred, showed a distribution of mean headings that was indistinguishable from random
          (Table 1; Fig. 1). It appears that even a short exposure during embryogenesis (relative to
          the entirety of the incubation period) can affect the ability of hatchlings to utilize
          magnetic information. Whether that deficiency compromises the function of the magnetic
          compass, the ability to discern spatial position, or both capabilities is unknown
          (Fuxjager et al. 2014). It also remains to be determined whether turtles can eventually
          recover from these effects, and if so whether that recovery occurs more rapidly when the
          exposure period is shortened.
        </Typography>
        <Typography paragraph>
          My results also suggest that nests placed near to structures containing magnetic materials
          (e.g., steel reinforced concrete groins or steel-containing seawalls) or nests exposed for
          shorter time periods to galvanized metal screens and/or cages, might produce hatchlings
          that appear normal as they can locate the sea from the nest and initially swim offshore,
          but in reality are incapacitated because they are unable to use magnetic maps to
          determining their present oceanic location or to orient toward distant nursery areas.
          Irwin et al. (2004) presented similar warnings.
        </Typography>
        <Typography paragraph>
          Finally, there is little understanding of what developmental processes are affected.
          Rearing juvenile steelhead trout in a distorted magnetic field disrupts their ability to
          use magnetic maps (Putman et al. 2014). Exposure to magnetic disturbances even earlier in
          development affects the orientation of the embryo’s body inside the egg. This response,
          which indicates that embryos can detect and respond to the field, has been shown in fish
          (Formici and Winniki 1998) and in loggerhead embryos (Carthy et al. 2018). But how such a
          change in embryonic body orientation is related to the ability of juvenile fishes or
          turtles to use magnetic information remains an interesting, but currently unexplored,
          question. Hopefully, at some future date, additional experiments will provide an answer.
        </Typography>
        <Typography paragraph>
          Acknowledgements. — This study was supported by personal funds and contributions from the
          National Save the Sea Turtle Foundation. Thanks to Kirt Rusenko and David Anderson of the
          Gumbo Limbo Environmental Complex for providing access to the nests on Boca Raton beach,
          and for allowing me to collect a sample of hatchlings from those nests. Gillian Davis
          provided inside space for a laboratory and a magnetically uniform outside patio for the
          test tank. Critical reading by Mary Jane Saunders, Jeanette Wyneken and Nathan Putman
          improved manuscript clarity and organization. The study design was peer-reviewed and
          subsequently permitted by the Florida Fish and Wildlife Conservation Commission (Turtle
          Permit-173).
        </Typography>
      </div>
      <div>

        <Typography className="pb-5">
          <h1 className="text-2xl font-bold">LITERATURE CITED</h1>
        </Typography>
        <Typography paragraph>
          ALCOCK, J.A. 2009. Animal Behavior, an Evolutionary Approach. Ninth Edition. Sunderland,
          MA: Sinauer Associates, Inc, 606 pp. <br />
          CARTHY, R.R., BROTHERS, J.R., BÉZY, V., LOHMANN, K.J., AND LAMONT, M.M. 2018. Ontogeny of
          orientation responses in developing sea turtle embryos. Paper presented at the
          International Sea Turtle Symposium, Kobe, Japan. <br />
          FORMIKI, K. AND WINNICKI, A. 1998. Reactions of fish embryos and larvae to constant
          magnetic fields. Italian Journal of Zoology 65:479–482. <br />
          FUXJAGER, M.J., DAVIDORF, K.R., MANGIAMELE, L.A., AND LOHMANN, K.J. 2014. The geomagnetic
          environment in which sea turtle eggs incubate affects subsequent magnetic navigation
          behaviour of hatchlings. Proceedings of the Royal Society B 281:1–8. <br />
          IRWIN, W.P., HORNER, A.J., AND LOHMANN, K.J. 2004. Magnetic field distortions produced by
          protective cages around sea turtle nests: Unintended consequences for orientation and
          navigation? Biology of Conservation 118:117–120. <br />
          LOHMANN, K.J. AND LOHMANN, C.M.F. 2003. Orientation mechanisms of hatchling loggerheads.
          In: Bolten, A.B. and Witherington, B.E. (Eds.) The Loggerhead Sea Turtle. Smithsonian
          Books, Washington, D.C. pp. 44–62. <br />
          LOHMANN, K.J. AND LOHMANN, C.M.F. 1994. Detection of magnetic inclination angle by sea
          turtles: a possible mechanism for determining latitude. Journal of Experimental Biology
          194:23–32. <br />
          MERRILL, M.M. AND SALMON, M. 2010. Magnetic orientation by hatchling loggerhead sea
          turtles (Caretta caretta) from the Gulf of Mexico. Marine Biology 158:101–112. <br />
          PUTMAN, N.F., ENDRES, C.S., LOHMANN, C.M.F., AND LOHMANN, K.J. 2011. Longitude perception
          and bicoordinate magnetic maps in sea turtles. Current Biology 21:463–466. <br />
          PUTMAN, N.F., MEINKE, A.M., AND NOAKES, D.L. 2014. Rearing in a distorted magnetic field
          disrupts the "map sense" of juvenile steelhead trout. Biology Letters 10(6): 20140169.
          <br />
          http://rsbl.royalsocietypublishing.org/content/10/6/20140169.short. <br />
        </Typography>
        <Typography paragraph>
          Submitted: 29 November 2018 <br />
          Revised and Accepted: 20 February 2019 <br />
          Handling Editor: Jeffrey A. Seminoff <br />
        </Typography>
      </div>
      <div className="my-5">
        <Typography className="pb-5">
          <h1 className="text-2xl font-bold">Figure Legends</h1>
        </Typography>
        <Typography paragraph>
          Figure 1. Above, left: a PVC tube is used to secure either a bar magnet (M) or a (control)
          aluminum bar (A) on each side of a sea turtle nest (above, right) placed on the beach at
          Boca Raton, Florida, U.S.A. Tubes are buried ~ 1 m below the sand surface and ~ 30 cm from
          the egg chamber. They remain in place throughout incubation. Bars are lowered into (and
          recovered from) the tube using a string. See the text for a description of the temporal
          schedule of bar placement. The tube is otherwise sealed with a cap (C). Circle diagrams
          (below) show the distribution of mean angles eastward vs. westward for the 30 hatchlings
          from three natural (undisturbed) and three aluminum control nests. In both groups most of
          the turtles swim toward the eastern half of the tank. See Table 1 for details and
          statistics.
        </Typography>
        <Typography paragraph>
          Figure 2. A loggerhead hatchling, attached by a cloth harness to a lever arm, swims in a
          circular pool where its orientation abilities are measured. (M. Salmon photo)
        </Typography>
        <Typography paragraph>
          Table 1. Distribution of mean orientation angles shown by loggerhead hatchlings swimming
          in complete darkness after orienting toward an east light for 10 minutes. Data are based
          upon the responses of 30 turtles (n = 10 turtles/nest from 3 different nests) that hatched
          from eggs exposed to one of the six treatments. Vectors chosen by each turtle are grouped
          by whether they fell within the eastern (0° - 179°) or the western (180° - 359°) half of
          the pool (See Figure 1). A binomial test (1 tailed) is used to determine if the
          distributions differ from random (at p &lt; 0.05).
        </Typography>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="flex text-center my-2">
        <div className="w-1/3">Treatments</div>
        <div className="w-1/3">Number of turtles swimming:</div>
        <div className="w-1/3">Binomial Probability</div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="flex text-center my-2">
        <div className="w-1/4">(3 nests in each)</div>
        <div className="w-1/4">Eastward</div>
        <div className="w-1/4">Westward</div>
        <div className="w-1/4">(1 tailed)</div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="flex text-center my-2">
        <div className="w-1/4">Natural Control</div>
        <div className="w-1/4">23</div>
        <div className="w-1/4">7</div>
        <div className="w-1/4">0.003</div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="flex text-center my-2">
        <div className="w-1/4">Aluminum Control</div>
        <div className="w-1/4">21</div>
        <div className="w-1/4">9</div>
        <div className="w-1/4">0.02</div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="flex text-center my-2">
        <div className="w-1/4">Total Magnet</div>
        <div className="w-1/4">17</div>
        <div className="w-1/4">13</div>
        <div className="w-1/4">n.s.</div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="flex text-center my-2">
        <div className="w-1/4">Early Magnet</div>
        <div className="w-1/4">14</div>
        <div className="w-1/4">16</div>
        <div className="w-1/4">n.s.</div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="flex text-center my-2">
        <div className="w-1/4">Middle Magnet</div>
        <div className="w-1/4">14</div>
        <div className="w-1/4">1</div>
        <div className="w-1/4">n.s.</div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="flex text-center my-2">
        <div className="w-1/4">Late Magnet</div>
        <div className="w-1/4">17</div>
        <div className="w-1/4">13</div>
        <div className="w-1/4">n.s.</div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
    </div>
  );
}
