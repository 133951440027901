import React from "react";
import { Typography, Divider } from "@mui/material";
import FAU1 from "../Images/FAU-Animal-Behavior-and-Visual-Perception.jpg";
import FAU2 from "../Images/life-history-cycle-of-marine-turtles.jpg";

export default function MarineTurtleHistory() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <div className="lg:float-right lg:ml-5 mb-5">
          <img className="rounded-2xl border-4 border-white mx-auto" src={FAU1} alt="" title="" />
        </div>
        <div>
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              Life History Characteristics of Marine Turtles: What Are They and Why Are Adults and
              Juveniles So Different?
            </h1>
          </div>
        </div>
        <div>
          <Typography paragraph>
            Mike Salmon, Ph.D., Research Professor<br />
            Florida Atlantic University .
          </Typography>
        </div>

        <div className="">
          <Divider className='dark:bg-[#032546] bg-white/70 clear-both' />
        </div>
      </div>
      <div className="lg:flex lg:gap-5 mt-5">
        <div>
          <Typography paragraph>
            There is no question that the way living creatures go about the business of reproduction
            is complicated, so much so that there seems to be no end to how the process is
            described. Biologists, in an attempt to make some order out of this apparent chaos, have
            tried over the years to come up with overarching “principles”. These are largely based
            upon what qualities are characteristic of the two main actors, specifically, males and
            females.
          </Typography>
          <Typography paragraph>
            Darwin, as so often is the case, was the first to do so with competence. His
            distinctions were based upon behavior. He characterized males as the sex most likely to
            spend time competing with other males for access to females, using aggression or
            conspicuous displays both to inhibit other males and to attract as many females as
            possible for mating. A consequence of those habits, incidentally, was the increased
            likelihood of risk-taking*. Unfortunately, aggression and other forms of competition
            often led to injuries and attracted predators, so males invariably suffered an earlier
            death than females. But for males, success was measured by the number of accomplished
            matings, not by how long they lived! Females, in contrast, were often drab in color,
            favored being inconspicuous, chose their mates carefully, stayed safe as long as
            possible, and used their time to convert food to as many surviving offspring as
            possible. Thus, as Darwin suggested, each sex had the same goal (maximizing offspring
            production) but went about the process behaviorally in entirely different ways.
          </Typography>
          <Typography paragraph>
            An alternative perspective was developed by ecologists who while interested in
            behavioral differences between the sexes, were far more concerned with relationships
            between reproduction, on the one hand, and on the other hand by adaptations shown by
            populations to favor survival in their environment. They came up with a scheme based
            upon what they called “life-history characteristics”. According to this idea,
            “environments” can be characterized as either relatively stable (that is, changing
            slowly over long time periods) or unstable (subject to frequently unpredictable, sudden
            and drastic change caused by fire, storms, drought, flooding, etc.).
          </Typography>
        </div>
        <div>
          <Typography paragraph>
            Organisms living in those contrasting environments exhibited very different reproductive
            strategies. In stable environments, organisms tend to be large, slow growing, live a
            long time, exist in populations that show little fluctuation in numbers, and produce
            only a few offspring over a long reproductive life span. Those youngsters receive lavish
            parental care and usually survive. In contrast, organisms living in unstable
            environments showed the antithesis of these characteristics. They tend to be small in
            size, grow quickly to sexual maturity, and produce many offspring in just one or a few
            reproductive episodes. Most of their offspring fail to survive. That’s because when
            conditions suddenly change, both adults and juveniles have to migrate elsewhere in
            search of another habitat. More often than not, they starve to death or are taken by
            predators before they can find a new home. But because so many offspring are produced, a
            few lucky ones usually find a new home so the cycle is repeated once again. Obviously,
            populations of these animals, again compared to species in stable habitats, fluctuate
            wildly over time. Those numbers are best described by the phrase “boom or bust”!
          </Typography>
          <Typography paragraph>
            Ecologists soon recognized that most species didn’t fall neatly into one or the other
            category; instead, they exist along a continuum reflecting the extent to which their
            environment varied in its stability. Perhaps most interesting of all, some creatures,
            especially those found in marine habitats, show combinations of these life history
            characteristics, even within a species. Marine turtles are an excellent example (Figure
            1).
          </Typography>
        </div>
      </div>
      <div className="lg:flex lg:gap-5">
        <div className="lg:w-1/2">
          <Typography paragraph>
            powerful animals capable of effectively searching for, and finding, habitats favorable
            for feeding and resting. When they do, they stay there, often for years at a time. They
            depart only when the time comes to breed. For females, that’s after several years of
            feeding to accumulate enough energy for egg production, and to migrate hundreds of miles
            to nest at natal beach sites. For males, it’s usually an annual migration to intercept,
            court and mate with as many females as possible. But breeding takes only a few weeks and
            then both sexes use their superb navigational skills to return to their traditional
            foraging areas.
          </Typography>
          <Typography paragraph>
            But the environments occupied by the eggs, hatchlings and juvenile marine turtles are,
            from their perspective, best characterized as unstable. Female marine turtles can’t
            defend nests and so after depositing their eggs in the sand, they hide the nest location
            as best they can and leave. Abandoned nests are subject to calamities that can occur
            suddenly, and at any time. The eggs (if found) are consumed by predators, washed out by
            storms, or invaded by ants and plant roots. If the beach sands get too cold, the eggs
            won’t develop; if too hot, the embryos will die; too wet, and the eggs will suffocate.
            The hatchlings that emerge from nests lucky enough to survive an unprotected 45- 60 day
            incubation period, must immediately complete a dangerous migration offshore to nursery
            areas hundreds of miles distant. Reef fish predators await them in the shallows and sea
            birds as well as pelagic fishes (tunas, dolphin, snappers and sharks) hunt for them in
            the open ocean. Small turtles have few defenses other than to hide but they must find
            food to grow. Searching for food exposes them to danger and so most of them are taken by
            predators or die from starvation, if or when their food supply vanishes. As they grow,
            the turtles gradually become less vulnerable to predators but even then, danger is never
            far away. Growing juveniles occupy different “developmental habitats” as they increase
            in size, or as the seasons change. They have to locate those habitats, a search that
            requires migration in open water (Figure 1). Those journeys always increase exposure to
            predators.
          </Typography>
          <Typography paragraph>
            To compensate for these losses, female marine turtles produce hundreds of eggs during
            each breeding season, apportioned within several nests at different local beaches where
            there’s a good chance that at least a few nests will survive long enough to produce
            hatchlings. (Females obviously avoid putting all their eggs in one basket!) But
            producing that many eggs requires an ability to store lots of energy, and that can only
            happen if the turtles become large enough to accumulate huge reserves of fat. That’s why
            marine turtles are so much larger than most other turtles, and why they produce many
            more eggs than most turtle species living on land. Marine turtle nests can contain
            anywhere between 50 and 200 eggs, with the number depending upon both egg size and
            species. The bottom line: we’re left with a large, long-lived animal occupying a stable
            habitat that breeds just like small, short-lived creature occupying an unstable habitat.
            Why?
          </Typography>
        </div>
        <div className="lg:w-1/2">
          <Typography paragraph>
            Adult marine turtles are extremely large, slow growing, take years to reach maturity and
            once they do live a long time. We know from historical observations made as little as
            500 years ago that before humans began over exploiting them, populations of marine
            turtles were stable at numbers many times greater than exist today. Clearly, these are
            attributes of species that occupy stable environments and adult marine turtles do
            exactly that! They are big,
          </Typography>
          <figure className="lg:float-right m-5">
            <img
              className="rounded-2xl"
              src={FAU2}
              alt=""
              title="Generalized description of the life history cycle of marine turtles. The left side of the diagram illustrates the juvenile stages of development; the right side illustrates what transpires during the adult stages"
            />
            <figcaption className="text-center">
              <em>
                Figure 1. Generalized description of the life history cycle of marine turtles. The
                left side of the diagram illustrates the juvenile stages of development; the right
                side illustrates what transpires during the adult stages.
              </em>
            </figcaption>
          </figure>
        </div>
      </div>
      <Typography paragraph>
        To repeat, it’s because marine turtles during their lifetime occupy two environmental
        extremes, just like the ones described earlier by ecologists - stable habitats as adults and
        unstable habitats as eggs, hatchlings and juveniles - and so they uniquely combine the life
        history characteristics we described above for different species into those for one species.
        Doing so is just another feature of their biology that makes these animals so unique, so
        interesting to study, and so remarkably different in both their ecology and behavior as they
        transition over the years from hatchlings and juveniles, leading dangerous and stressful
        lives, to adults who if they survive long enough to achieve that status, may finally get
        some relief. It’s also why those of us who study marine turtles have come to realize that
        what we learn about one life history stage doesn’t necessarily apply to other life history
        stages. In essence, we are faced with understanding the behavioral and ecological
        characteristics of two kinds of animals, both of which belong to the same species!
      </Typography>
    </div>
  );
}
