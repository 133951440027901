import {Typography} from "@mui/material";
import React from "react";
import GreenImg from "../Images/Green-B.jpg";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import {createTheme, ThemeProvider} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#932B16", // custom secondary color
    },
  },
});

export default function Green() {
  return (
    <div className="dark:text-[#032546] dark:bg-white/70 p-5 w-full h-full bg-[#032546]/90 rounded-3xl text-[#ffffff]">
      <div>
        <Typography paragraph>
          <h2 className="text-2xl font-bold">Green Sea Turtles</h2>
        </Typography>
      </div>

      <div className="lg:float-left lg:w-1/3 mb-5 lg:mr-5">
        <img className="rounded-3xl shadow-lg" src={GreenImg} alt="" title="" />
      </div>
      <div>
        <Typography>
          The green turtle, named for the greenish color of its body fat, is listed as endangered in
          Florida. Most green turtles nest in the Caribbean but 500 to 2000 nests are recorded in
          Florida each year. Green turtles have been hunted for centuries for their meat and
          gelatinous "calipee" that is made into soup. Hunting and egg gathering have reduced their
          number greatly. Green turtles are the only sea turtles that eat plants. They graze on the
          vast beds of sea grasses found throughout the tropics. Some populations travel over a
          thousand miles over open ocean to nest on islands in the mid-Atlantic.
        </Typography>
      </div>
      <div className="my-5 text-right">
        <ThemeProvider theme={theme}>
          <Link href="/SeaTurtles/Green" title="Green Sea Turtles">
            <Button color="secondary" variant="contained">
              More about Green Sea Turtles
            </Button>
          </Link>
        </ThemeProvider>
      </div>
    </div>
  );
}
