import React from 'react'
import { Divider, Typography } from '@mui/material';
import SeaTurtleAdventures from '../Images/Sea-Turtle-Adventures-2.png'
import Cautiontape from '../Images/Caution-tape.jpg'
import AluminumNestMarkingSigns from '../Images/New-Aluminum-Nest-Marking-Signs.jpg'
import FriendsofGumboLimbo from '../Images/Friends-of-Gumbo-Limbo-2.jpg'

export default function NewAluminumNestMarkingSigns() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className='pb-5'><h1 className='text-2xl'>New Aluminum Nest-Marking Signs Now Being Deployed on Florida Beaches</h1></div></div>
      <div><Typography paragraph>Larry Wood, Ph.D., NSTSTF Research Coordinator</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className='mt-5'>
        <div className='lg:float-right mb-5'><img src={SeaTurtleAdventures} alt='' title='' className='mx-auto' /></div>
        <div><Typography paragraph>As most of you are aware, Florida’s beaches are host to tens of thousands of sea turtle nests each year. Usually beginning sometime in mid- to late February with the arrival of the first leatherback nests and finishing up sometime in December with the departure of the last hatchlings, the season brings with it not only lots of turtles, but also a virtual army of environmental professionals, students, and citizen volunteers that diligently document the comings and goings of the females and their offspring. The nesting survey efforts are coordinated by Florida’s Fish and Wildlife Conservation Commission (FWC) that provides training and material support to dozens of coastal organizations around the State.</Typography></div>
        <div >
          <div className='lg:float-left lg:mr-5'><img src={AluminumNestMarkingSigns} alt='' title='' className='mx-auto' /></div>
          <div className='lg:float-left mt-5 mr-5 mb-5 clear-left'><img src={FriendsofGumboLimbo} alt='' title='' className='mx-auto' /></div>
        </div>
        <div className='lg:float-right lg:ml-5'>
          <div><img src={Cautiontape} alt='' title='' className='rounded-xl mx-auto' /></div>
          <div className='py-2'><em>Caution tape surrounds a leatherback nest on<br />
            Singer Island, Florida</em></div>
        </div>
        <div><Typography paragraph>Early morning nesting surveys are a fundamental part of the effort. Once trained, surveyors can effectively tell what species of turtles had come ashore the night before by examining the marks left behind in the sand. Lucky for us, loggerhead, green, and leatherback turtles (Florida’s primary nesters) leave distinctive tracks as they traverse the beach, making species identification pretty easy. The next thing we’d like to know is whether the turtles actually deposited eggs or not while they were on the beach. Turtle biology provides the nesting females with some behavioral flexibility during that vulnerable time searching for a suitable nest site, resulting sometimes in what are commonly called ‘false crawls’ or ‘non-nesting emergences’ in which the turtle returns to the water without nesting. Not to worry, though, false crawls aren’t uncommon, and plenty of observations indicate that unless there is some anatomical or physiological anomaly in the female, she’ll try again until she finds a good spot.</Typography></div>

        <div><Typography>It’s one thing to count the nests, but another all together to keep track of their well-being. Everything from tropical storms to natural predators to beach parties threaten them, so protecting and subsequently evaluating their success often requires some way of visibly marking them for both the turtle surveyors and the public to easily see. To help, FWC has for many years provided State-approved nest marking signs free of charge to those who request them. In an effort to reduce the consumption of single-use plastics, particularly in the beach environment, Sea Turtle Adventures, Inc. recently undertook an effort to evaluate signs made of alternative, more sustainable materials. After a year of field testing, their team determined that light-gauge aluminum signs were best-suited for the job, so they teamed up with Florida Power and Light, Friends of Gumbo Limbo Nature Center, and the National Save The Sea Turtle Foundation to create the first batch of signs to be distributed to over 20 organizations around the State. These organizations will have the opportunity to further evaluate their practicality and durability through this summer’s nesting season, and hopefully inspire others to also switch over to this lightweight and durable alternative.</Typography></div>

      </div>
    </div>
  )
}
