import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FacilitiesEnhancement from './Education/FacilitiesEnhancement';
import EducationalOutreach from './Education/EducationalOutreachPrograms';
import ScholarshipProgram from './Education/ScholarshipProgram';
import FloridaEnvironmental from './Education/FloridaEnvironmentalOutreach';



export default function Education() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div >
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className="text-[#032546] bg-[#032546]/90 dark:bg-white/70 dark:text-[#032546] shadow-2xl">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Facilities Enhancement
          </Typography>

        </AccordionSummary>
        <AccordionDetails>
          <FacilitiesEnhancement />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className='text-[#032546] bg-[#032546]/90 dark:bg-white/70 dark:text-[#032546] shadow-2xl'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Educational Outreach Programs</Typography>

        </AccordionSummary>
        <AccordionDetails>
          <EducationalOutreach />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className='text-[#032546] bg-[#032546]/90 dark:bg-white/70 dark:text-[#032546] shadow-2xl'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Scholarship Program
          </Typography>

        </AccordionSummary>
        <AccordionDetails>
          <ScholarshipProgram />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className='text-[#032546] bg-[#032546]/90 dark:bg-white/70 dark:text-[#032546] shadow-2xl'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Florida Environmental Outreach Magazine</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FloridaEnvironmental />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}