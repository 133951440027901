import React from "react";
import { Typography, Divider } from "@mui/material";
import WhitneyL from "../Images/Whitney-Laboratory-for-Marine-Bioscience.jpg";
import WhitneyL2 from "../Images/Whitney-Laboratory-for-Marine-Bioscience-2.jpg";
import WhitneyL3 from "../Images/Whitney-Labs-proposed-Sea-Turtle-Hospital-and-Research-Laboratory.jpg";
import WhitneyL4 from "../Images/young-green-sea-turtle-with-large-fibropapilloma.jpg";
import WhitneyL5 from "../Images/Fibropapillomatosis-Training-and-Research-Initiative.jpg";

export default function WhitneyLab() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl clear-both'>
      <div>
        <div className="lg:float-right">
          <img className="rounded-2xl mx-auto" src={WhitneyL} alt="" title="" />
        </div>
        <div>
          <div className="py-5">
            <h1 className="text-2xl font-bold">
              The University of Florida’s Whitney Laboratory for Marine Bioscience and Sea Turtle
              Hospital: Advancing Sea Turtle Disease Research
            </h1>
          </div>
        </div>
        <div>
          <Typography paragraph>
            David Duffy, Ph.D. <br />
          </Typography>
        </div>
        <div>
          <Typography paragraph>
            Assistant Professor of Wildlife Disease<br />
            Genomics.The Whitney Laboratory for Marine Bioscience and Sea Turtle Hospital,
            University of Florida, St. Augustine, Florida 32080, USA.
          </Typography>
        </div>
        <div>
          <Typography paragraph>
            Department of Biology, College of Liberal Arts and Sciences, University of Florida,
            Gainesville, Florida, 32611, USA.
          </Typography>
        </div>

        <div>
          <Divider className='dark:bg-[#032546] bg-white/70 clear-both' />
        </div>
      </div>
      <div className="pt-5">
        <Typography paragraph>
          The University of Florida’s Whitney Laboratory for Marine Bioscience and Sea Turtle
          Hospital (www.whitney.ufl.edu) is located on Florida’s Atlantic coast near historic St.
          Augustine. This research institute is a hive of activity, conducting cutting-edge marine
          bioscience and conservation research and training, and extensive public outreach
          activities. The marine research carried out here is greatly facilitated by its unique
          location, bordering sea turtle nesting beaches to its east and extensive brackish
          estuarine and mangrove habitats to its west. The institute conducts basic biological
          research of marine organisms and environments to better understand marine animals and to
          apply novel results to problems of human health, natural resources and environmental
          conservation.
        </Typography>
      </div>
      <div className="lg:float-right lg:ml-5 mb-5"><img className="rounded-2xl mx-auto" src={WhitneyL2} alt="" title="" /></div>
      <div>
        <Typography paragraph>
          Key areas of focus at the Whitney Lab include understanding the evolution of animal form
          and function; how genetic programs regulate every aspect of animal life and how disease
          occurs when these programs are perturbed; understanding cellular development and the
          evolution of animal traits; understanding human senses using marine models; conducting
          drug discovery from environmental sources; and improving coastal conservation and habitat
          restoration
          (www.whitney.ufl.edu/conservation--sea-turtle-hospital/citizen-science-projects/oyster-restoration-initiative
          and www.whitney.ufl.edu/conservation--sea-turtle-hospital/clams).
        </Typography>
      </div>

      <div>
        <Typography paragraph>
          For the last five years the institute has had a renewed focus on the protection of sea
          turtles, with the opening of our Sea Turtle Hospital in 2015
          (www.whitney.ufl.edu/conservation--sea-turtle-hospital). Unfortunately, as with the human
          Covid-19 pandemic, sea turtles globally are suffering from a pandemic of their own,
          fibropapillomatosis, which is caused by the destructive combination of viral infection and
          environmental degradation. Our hospital rehabilitates stranded sea turtles, from
          post-hatchlings to adults and has particular expertise in rehabilitating sea turtles
          suffering from the fibropapillomatosis tumor disease. Having our hospital embedded in an
          active research institute offers unique opportunities to research this disease as well as
          the rehabilitation process. We can provide direct conservation services to individual
          turtles, while simultaneously enabling broader research to better understand
          population-level events. To obtain information that is widely relevant to the conservation
          of diverse sea turtle populations, we also partner with other rehabilitation facilities
          and researchers throughout the state and beyond.
        </Typography>
      </div>
      <img className="rounded-2xl" src={WhitneyL3} alt="" title="" />
      <div className="mt-5">
        <Typography paragraph>
          <h2>National Save The Sea Turtle Foundation Support</h2>
        </Typography>
      </div>

      <div className="lg:float-right lg:ml-5">
        <div>
          <img
            className="rounded-2xl mx-auto"
            src={WhitneyL4}
            alt=""
            title="Dr. Michael Johnson, Dean of the College of Sciences , Dr.
        Kate Mansfield, Dr. Larry Wood, and Dr. Graham Worthy
        attend UCF’s Scholarship Luncheon"
          />
        </div>
        <div className="pt-2">
          <em>
            A young green sea turtle with large fibropapilloma<br /> tumors will undergo
            treatment at Whitney Lab’s Sea<br /> Turtle Hospital.
          </em>
        </div>
      </div>


      <div>
        <Typography paragraph>
          Fibropapillomatosis is continuing to spread globally including throughout US regions beyond
          Florida. For instance, between 2009 and 2018, fibropapillomatosis prevalence in stranded
          green sea turtles in Texas has gone from 0% to 35%, while in Florida, greens
          fibropapillomatosis prevalence also continues to rapidly increase, now standing at over 40%
          in stranded turtles. We are pioneering a novel approach to fibropapillomatosis in Florida’s
          sea turtles, wildlife precision oncology, which draws heavily from emerging human medicine
          genomic approaches. This precision medicine approach has already provided unprecedented
          insights into this sea turtle disease. Our work on external fibropapillomatosis tumors has
          proven that they share many genetic similarities to human cancer types, most prominently
          human skin cancers. Our profiling of tumor genomes has also successfully identified drug
          treatments for fibropapillomatosis. Furthermore, our novel approaches to fibropapillomatosis
          have enabled us to obtain new information about how the fibropapillomatosis-associated
          herpes virus is shed into the water column and the environmental factors triggering this
          disease. We are also resolving the specific uncertainty about how the herpes virus interacts
          with the turtle immune system to give rise to tumors.
        </Typography>
        <Typography paragraph>
          Without improved understanding of this disease and its environmental trigger, treatment,
          containment and mitigation options are severely hampered. Therefore, with funding generously
          provided from the National Save The Sea Turtle Foundation (NSTSTF) for the
          Fibropapillomatosis Training and Research Initiative (FTRI), the Whitney Lab and Sea Turtle
          Hospital and our collaborators are addressing crucial knowledge gaps in our understanding of
          fibropapillomatosis. We are applying cutting-edge techniques from human cancer research to
          reveal the environmental drivers of fibropapillomatosis, the dynamic interactions between
          the herpes virus and its turtle hosts and novel treatment and management strategies. FTRI is
          helping to support three studentships, enabling students to be trained in ultra-modern
          approaches, meaning that they will be poised to tackle not only fibropapillomatosis, but the
          host of wildlife diseases predicted to emerge over the coming decades.
        </Typography>
      </div>
      <div className="mt-5">
        <Typography paragraph>
          <h2>Building Future Capacity</h2>
        </Typography>
      </div>

      <div className="clear-both pt-5">
        <Typography paragraph>
          Unfortunately, marine turtles are not immune to the growing number of threats and increasing
          rates of wildlife disease. Therefore, in addition to training and research expansions, the
          Whitney Lab is preparing to meet future challenges by undertaking capital investment in a
          new sea turtle hospital building and research laboratories. The project will provide a
          state-of-the-art <a
            href="https://www.whitney.ufl.edu/articles/expanding-our-facilities-to-meet-research-and-conservation-demands.html"
            class="text-blue-500 underline" > www.whitney.ufl.edu/articles/expanding-our-facilities-to-meet-research-and-conservation-demands.html</a> Nancy Condron Family Sea Turtle Research Center and Hospital, to secure our sea turtle
          activities. This ambitious project will greatly expand our capacity for rehabilitating sea
          turtles as well as further improve our research and training capabilities. Although plans
          are well advanced, gifting and naming opportunities for the new institute still exist <a
            href="https://www.whitney.ufl.edu/articles/expanding-our-facilities-to-meet-research-and-conservation-demands.html"
            class="text-blue-500 underline"
          > https://www.whitney.ufl.edu/articles/expanding-our-facilities-to-meet-research-and-conservation-demands.html
          </a>
          , with construction of Phase One scheduled to commence shortly.
        </Typography>
      </div>


      <div className="lg:flex lg:gap-5">
        <div className="lg:float-left lg:w-1/2">

          <div className="pb-5"><Typography>
            It is vital that we continue to improve how we tackle wildlife diseases and that we train
            large numbers of researchers and clinicians in how to apply these techniques as sea turtle
            and wildlife disease events are predicted to occur at greater frequency over the coming
            decades, due to ongoing detrimental human activities. Wildlife pathogens have been shown to
            exacerbate the effects of environmental degradation, habitat loss and the climate emergency
            on population levels, potentially leading to deteriorated health and eventually to local and
            global extinctions. Furthermore, in a vicious feedback loop, as the risk of extinction
            increases for a given species, the detrimental effect of disease on the population worsens.
            Human activities are not only stressing habitats, but the rapid environmental changes
            induced by these activities are also likely to be increasing cancer rates in wild
            populations. With a predicted rise in the number and incidence of diseases affecting
            wildlife populations set to soar in coming decades, NSTSTF-supported programs such as our
            FTRI are crucial to improving our ability to respond to wildlife pandemics, and ensure the
            health of sea turtles and other keystone species.
          </Typography></div>

        </div>
        <div className="lg:float-right lg:w-1/2">

          <div className="lg:ml-5">
            <div ><img
              className="rounded-2xl mx-auto"
              src={WhitneyL5}
              alt=""
              title="Dr. Michael Johnson, Dean of the College of Sciences , Dr.
        Kate Mansfield, Dr. Larry Wood, and Dr. Graham Worthy
        attend UCF’s Scholarship Luncheon"
            /></div>
            <div className="pt-2">
              <em>
                The National Save The Sea Turtle Foundation Fibropapillomatosis Training and <br />
                Research Initiative (FTRI) at the the Whitney Lab and Sea Turtle Hospital is<br />
                addressing crucial knowledge gaps in our understanding of fibropapillomatosis.
              </em>
            </div>
          </div>

        </div>
      </div>


    </div>
  );
}
