import React from 'react'
import CompanyName from './Component/CompanyName'
import { Button, Link, Typography } from '@mui/material'
import PhoneIcon from '@mui/icons-material/Phone';
import FaxIcon from '@mui/icons-material/Fax';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EmailIcon from '@mui/icons-material/Email';



const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#932B16", // custom secondary color
    },
  },
});

export default function Contact() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className='pb-5'><h1 className='text-2xl font-bold'><CompanyName /></h1></div></div>
      <div><Typography>
        <address>4419 West Tradewinds Avenue
          Ft. Lauderdale, Florida 33308</address>
      </Typography></div>


      <div className='lg:flex gap-10 mt-10'>
        <div className='lg:w-1/2'>
          <div><Typography paragraph variant='h6'>Tel:</Typography></div>
          <div className='my-5'><ThemeProvider theme={theme}><Link href='tel:9543519333' style={{ color: '#ffffff' }}><Button color="secondary" variant="contained" fullWidth startIcon={<PhoneIcon />}>954-351-9333</Button></Link></ThemeProvider></div>
          <div><Typography paragraph variant='h6'>Fax:</Typography></div>
          <div className='my-5'><ThemeProvider theme={theme}><Link href='fax:9543515549' style={{ color: '#ffffff' }}><Button color="secondary" variant="contained" fullWidth startIcon={<FaxIcon />}>954-351-5549</Button></Link></ThemeProvider></div>
        </div>
        <div className='lg:w-1/2'>
          <div><Typography paragraph variant='h6'>E-mail:</Typography></div>
          <div className='my-5'><ThemeProvider theme={theme}><Link href='mailto:Info@savetheseaturtle.org'><Button color="secondary" variant="contained" fullWidth startIcon={<EmailIcon />} style={{ wordBreak: 'break-all', textTransform: 'lowercase' }}>Info@savetheseaturtle.org</Button></Link></ThemeProvider></div>
        </div>
      </div>

    </div>
  )
}
