import React from "react";
import { Typography, Divider } from "@mui/material";
import LHM from "../Images/LH-M.png";
import LHB from "../Images/Loggerhead-B.jpg";

export default function Loggerhead() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex text-center">
        <div className="w-1/2">
          <Typography className="">
            <h1 className="text-2xl font-bold">Loggerhead Sea Turtles Fast Facts</h1>
          </Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Type: Reptile</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Diet: Carnivore</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">
            Average life span in the wild: More than 50 years (est.)
          </Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Size: 36 in (90 cm)</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Weight: 253 lbs (115 kg)</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Protection status: Endangered</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <div className="flex justify-center">
            <div>
              <Typography className="">Size relative to a 6-ft (2-m) man:</Typography>
            </div>
            <div>
              <img className="rounded-2xl" src={LHM} alt="" title="" />
            </div>
          </div>
        </div>
        <div className="w-1/2 flex justify-center">
          <img className="rounded-2xl" src={LHB} alt="" title="" />
        </div>
      </div>
      <div>
        <Typography paragraph>Did you know? </Typography>
        <Typography paragraph>
          Sea turtles can move through the water at speeds of up to 15 mi (24 km) per hour.
        </Typography>
        <Typography paragraph>
          Loggerhead turtles are the most abundant of all the marine turtle species in U.S. waters.
          But persistent population declines due to pollution, shrimp trawling, and development in
          their nesting areas, among other factors, have kept this wide-ranging seagoer on the
          threatened species list since 1978.
        </Typography>
        <Typography paragraph>
          Their enormous range encompasses all but the most frigid waters of the world's oceans.
          They seem to prefer coastal habitats, but often frequent inland water bodies and will
          travel hundreds of miles out to sea.
        </Typography>
        <Typography paragraph>
          They are primarily carnivores, munching jellyfish, conchs, crabs, and even fish, but will
          eat seaweed and sargassum occasionally.
        </Typography>
        <Typography paragraph>
          Mature females will often return, sometimes over thousands of miles, to the beach where
          they hatched to lay their eggs. Worldwide population numbers are unknown, but scientists
          studying nesting populations are seeing marked decreases despite endangered species
          protections.
        </Typography>
      </div>
    </div>
  );
}
