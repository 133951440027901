import React from "react";
import { Typography, Divider } from "@mui/material";
import MangroveB from "../Images/Flooded-pond-in-mangrove-basin-forest.jpg";
import MangroveB1 from "../Images/Surface-contour-plot.jpg";
import FeedingT from "../Images/Taenies-feeding-near-the-eye-of-a-nesting-loggerhead-turtle.jpg";
import RaftPupae from "../Images/A-raft-of-pupae-in-a-rain-flooded-mangrove-forest.jpg";
import Pneumatophores from "../Images/pneumatophores.jpg";

export default function BlackSaltmarsh() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <div className="pb-5">
          <h1 className="text-2xl font-bold">Black Saltmarsh Mosquitos - Things That Hum In The Night</h1>
        </div>
      </div>
      <div>
        <Typography paragraph>
          David S. Addison<br />
          Conservancy of Southwest Florida<br />
          Naples, Florida 34116
        </Typography>
        <Typography paragraph>
          Scott Alexander Ritchie, Ph.D.<br />
          James Cook University<br />
          Cairns Queensland 4870, Australia <br />
        </Typography>
      </div>
      <div className="my-5">
        <Divider className='dark:bg-[#032546] bg-white/70 clear-both' />
      </div>

      <div className="flex">
        <div>
          <div className="lg:ml-5 lg:float-right">
            <div><img className="rounded-2xl mx-auto" src={MangroveB} alt="" title="" /></div>
            <div className="pt-2 mb-5">
              <em>
                Fig.3. Flooded pond in mangrove basin forest. The larvae<br /> and pupae congregate
                in large assemblages called “rafts”.<br /> The presence of rafts can be detected
                because the larval<br /> activity makes water’s surface shimmer.
              </em>
            </div>
          </div>
          <Typography paragraph>
            Florida’s fame as a mosquito factory goes back to the 1500’s when Spanish explorers
            dubbed the entire east coast “Los Mosquitos”. Their numbers did not diminish over time
            either. In 1824, the area between Volusia and Palm Beach Counties was designated as
            Mosquito County. Florida’s southwest coast was equally infested if not more so. They
            were so ubiquitous that people mostly avoided the region until the late 19th century.
            Colloquially, they were referred to as the, “Defender of the Everglades”.
          </Typography>

          <Typography paragraph>
            Anybody who has conducted night surveys on sea turtle nesting beaches, especially in SW
            Florida, can tell you that, in spite of habitat loss and modern abatement measures, they
            are still around in what at times are stupefying numbers. How is this so? Thirty years
            ago a colleague (an entomologist) and I studied the ecology of the black saltmarsh
            mosquito (Aedes taeniorhynchus). We spent two years trudging about in the mangrove
            forests of SW Florida as we studied the ways of this oh so successful species.
          </Typography>

          <Typography paragraph>
            A. taeniorhynchus or “Taenies” for short, are a floodwater species that depends on
            fluctuations in water levels to drive their population dynamics. Florida’s 1,300 miles
            of coastline, flat topography and subtropical climate work in concert to create what
            amounts to an ecosystem made in heaven for Taenies. Those who have conducted field work
            in South Florida’s coastal ecosystems appreciate better than most just how well Taenies
            utilize their environment. So just how does this all work to produce so many mosquitos?
          </Typography>
          <div className="lg:mr-5 lg:float-left">
            <div><img className="rounded-2xl mx-auto" src={MangroveB1} alt="" title="" /></div>
            <div className="pt-2 mb-5">
              <em>
                Fig. 2. Surface contour plot showing the<br /> subtle changes in the topography of
                the<br /> floor of a mangrove basin forest. Tic marks<br /> on left are at 6.1 cm
                intervals. The study<br /> plots were is 6.1 m x 30.5 m.
              </em>
            </div>
          </div>
          <Typography paragraph>
            For starters, Taenies lay their eggs in moist soil where they can remain viable for
            months. The females lay about 25 eggs without a blood meal, however, with a blood meal
            they produce 50-200 eggs. The eggs hatch when the substrate floods. They are aggressive
            biters and will take a blood meal from any available source (Fig. 1). In South Florida,
            the fringes of the mangrove forests are regularly flooded by tides so their eggs don’t
            accumulate in great numbers, and most of the larvae that result are eaten by fish
            introduced by frequent tides. Further into the forest, there is typically a berm or
            natural levee that prevents inundation by all but the most extreme high tides. Forests
            with a lower berm are still frequently flooded by tides that introduce fish. However,
            forests with higher berms that contain potholes and depressions can become fish-free
            ponds when flooded by heavy rain (Fig. 2). These areas become ever dryer in late winter
            and during the dry season in spring. It is in these times of the year that their eggs
            accumulate in the soil. Sporadic rainfall triggers the intermittent production of broods
            of mosquitoes in these ponds. Taenies actively avoid oviposition in tidally flooded
            areas that contain predators such as fish, preferentially laying eggs in the higher,
            rain-flooded basins. The hatched eggs (or eggshells) persist in the substrate for years.
            By collecting soil samples and separating and counting the eggshells we were able to
            identify these features as preferred sites for oviposition. With flooding, the extant
            eggs hatch synchronously (Fig. 3). The cycle from larvae to adult proceeds quickly. The
            larvae feed on leaf litter and become pupae in 5-7 days, with adults then emerging 2
            days later (Fig. 4). For the first 24 hrs. after emerging, the females are not
            interested in a blood meal. They remain close to where they emerged. Mating occurs
            during this period of quiescence. One can walk through the forest, literally through
            clouds of mosquitoes (Fig. 5) during these periods and be totally ignored. Afterwards,
            they migrate out of the mangroves and begin looking for a blood meal. The distance they
            can migrate is impressive, as much as 50 miles. The shear density of these migratory
            swarms has, at times, confounded radar.
          </Typography>
          <div className="lg:mr-5 lg:float-left">
            <div><img className="rounded-2xl mx-auto" src={FeedingT} alt="" title="" /></div>
            <div className="pt-2 pb-5"><em>
              Fig. 1. Taenies feeding near the eye of a nesting<br /> loggerhead turtle. They are
              most active during<br /> crepuscular periods and at night; however, when<br />
              they are out and about during the day, their numbers<br /> will be even more
              daunting after sunset.
            </em></div>
          </div>
          <div className="lg:ml-5 lg:float-right">
            <div><img className="rounded-2xl mx-auto" src={RaftPupae} alt="" title="" /></div>
            <div className="pt-2 pb-5 "><em>
              Fig. 4. A raft of pupae in a rain-flooded<br /> mangrove forest. The reflected
              objects<br /> among the newly emerged adults are their<br /> molts or exuvia.
              They can be used to identify<br /> mosquitos to species and sex.
            </em></div>
          </div>
          <Typography paragraph>
            Several factors control the size of the broods of adults produced. Eggs gradually
            accumulate in basin forests over the course the winter and spring dry periods. Monthly
            high tides and infrequent rainfall produce smaller broods late in the spring. These
            adults, of course, continue to oviposit in the basin forests and, depending on the
            frequency and amount of rainfall, more adults are produced. Or, if rainfall is
            infrequent, more eggs accumulate in the substrate, which, given the right circumstances,
            can result in stupendous numbers of eggs accumulating. For example, the spring of 1988
            was unusually dry. No precipitation occurred through the normally rainy month of June
            until the last two days of the month when over 10 cm were reported.
          </Typography>
          <Typography paragraph>
            This rainfall event flooded local basin forests and triggered the synchronous production
            of a brood of Taenies whose numbers were almost beyond belief. The basin forests we were
            studying were so thick with them that without a bug veil we would have had difficulty
            breathing. A week or so after the onset of this event, three cows in a pasture about 5
            km from Rookery Bay National Estuarine Research Reserve died from complications
            resulting from blood loss. The good news about an event like this is that Taenies in
            this region are not known to vector human disease, however, they are a vector of the
            filarial worm Dirofilaria immitis, commonly known as the dog heartworm. But that is
            little comfort to those unlucky enough to be “carried away” on some warm, humid night by
            this, “Defender of the Everglades”.
          </Typography>
          <div className="lg:mr-5 lg:float-left">
            <div><img className="rounded-2xl mx-auto" src={Pneumatophores} alt="" title="" /></div>
            <div className="pt-2">
              <em>
                Fig. 5. Newly emerged adults on black mangrove pneumatophores.<br /> At times,
                there can be so many Taenies perched on<br /> pneumatophores, leaves and twigs that
                they appear to be fuzzy<br /> or hirsute as a Botanist would say.
              </em>
            </div>
          </div>
          <Typography paragraph variant="h6">
            References
          </Typography>
          <Typography paragraph>
            Addison, D. S., & S. A. Ritchie, (1989). Characterization of preferred habitats of Aedes
            taeniorhynchus in coastal mangroves habitats. FL HRS Grant #LCNP4. Jacksonville, FL. 106
            pp.
          </Typography>
          <Typography paragraph>
            Addison, D. S., & Ritchie, S. A. (1993). Cattle fatalities from prolonged exposure to
            Aedes taeniorhynchus in southwest Florida. Florida Scientist, 65-69. Ritchie, S. A.
            (1992). Mosquito Control Handbook: Salt Marshes and Mangrove Forests. In Mosquito
            Control Handbook, 1-9.
          </Typography>
          <Typography paragraph>
            Ritchie, S. A., & Addison, D. S. (1992). Oviposition preferences of Aedes taeniorhynchus
            (Diptera: Culicidae) in Florida mangrove forests. Environmental entomology, 21(4),
            737-744
          </Typography>
          <Typography>
            Ritchie, S. A., & Johnson, E. S. (1991). Aedes taeniorhynchus (Diptera: Culicidae)
            oviposition patterns in a Florida mangrove forest. Journal of medical entomology, 28(4),
            496-500.
          </Typography>
        </div>
      </div>
    </div>
  );
}
