import React from 'react';

// import TurtleVideo1 from 'https://webdesignexpressions.net/assets/SaveTheSeaTurtle.mp4'

import '../App.css';

function VideoPlayer() {



  return (
    <div>

      <video autoPlay playsInline loop muted className='print:hidden'>
        <source src="https://webdesignexpressions.net/assets/SaveTheSeaTurtle.mp4" type="video/mp4" />

        Your browser does not support the video tag.
      </video>

    </div>
  );
}

export default VideoPlayer;