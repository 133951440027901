import React from "react";
import { Divider } from "@mui/material";



export default function HawksbillProjectHighlightedinVideoProduction() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="pb-5">
        <h1 className="text-2xl font-bold">
          National Save The Sea Turtle Foundation’s Hawksbill Project Highlighted in Video Production
        </h1>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="my-5">


        <div>
          <iframe className='rounded-3xl border-2 border-[#032546] dark:border-[#fffb50] mx-auto w-full aspect-video align-middle' width="100%" height="auto" src="https://www.youtube.com/embed/K6hVdY7RmF8" title="National Save The Sea Turtle Foundation’s Hawksbill Project Highlighted in Video Production" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>


      </div>
    </div>
  );
}
