import React, { useState, useRef } from 'react';
import '../App.css';
import { styled } from '@mui/material/styles';
import {
  Drawer,
  Box,
  Link,
  Divider,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import { motion } from 'framer-motion';
import Logo from '../Images/Logo.png'



const drawerWidth = 240;



const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: drawerWidth,
    backgroundColor: '#032546',
    flexShrink: 0,
  },
  "& .MuiDrawer-paper": {
    backgroundColor: "#032546",
    //position: "relative",
    //overflow: "hidden",
    "&::before": {
      content: '""',
      // display: "block",
      // position: "absolute",
      top: 0,
      //left: 0,
      width: 240,
      backgroundColor: '#032546',
      zIndex: -1,
    },
  },
}));


const Navigation = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showProgramsSubmenu, setShowProgramsSubmenu] = useState(false);
  const [showHelpSubmenu, setShowHelpSubmenu] = useState(false);
  const programsSubmenuRef = useRef(null);
  const helpSubmenuRef = useRef(null);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [selectedSublink, setSelectedSublink] = useState(null);

  const handleProgramsClick = () => {
    setSelectedSublink(null); // Reset the selected sublink
    setShowProgramsSubmenu(!showProgramsSubmenu);
  };

  const handleHelpClick = () => {
    setSelectedSublink(null); // Reset the selected sublink
    setShowHelpSubmenu(!showHelpSubmenu);
  };

  function handleExternalLinkClick(event) {
    const confirmationMessage = "You are leaving the website. Are you sure you want to proceed?";
    const isConfirmed = window.confirm(confirmationMessage);

    if (!isConfirmed) {
      event.preventDefault(); // Prevent the link from navigating
    }
  }

  const handleOutsideClick = (event) => {
    if (
      (programsSubmenuRef.current && programsSubmenuRef.current.contains(event.target)) ||
      (helpSubmenuRef.current && helpSubmenuRef.current.contains(event.target))
    ) {
      // Clicked inside the submenu, do nothing
      return;
    }

    // Clicked outside the submenu, close it and reset the selected sublink
    setShowProgramsSubmenu(false);
    setShowHelpSubmenu(false);
    setSelectedSublink(null);
  };

  // const [darkMode, setDarkMode] = useState(
  //   localStorage.getItem("darkMode") === "true" ||
  //   (!("darkMode" in localStorage) &&
  //     window.matchMedia("(prefers-color-scheme: dark)").matches)
  // );

  const drawer = (
    <div>
      <div className='bg-[#032546] text-white dark:text-[#fffb50] print:hidden mb-5'>

        <div>

          <ul className='lg:flex flex-row list-none justify-center py-3 bg-[#032546]'>

            <li className='lg:py-1 py-2 pl-4 custom-font my-0'><Link href='/' style={{ textDecoration: 'none', color: '#ffffff' }} exact>Home</Link></li>
            <Divider variant="middle" className='bg-[#B76770]' />
            <li className='lg:py-1 py-2 pl-4 custom-font'>
              <div className="submenu-header" onClick={handleProgramsClick}>
                <span className='text-white'>Programs</span>
                {showProgramsSubmenu ? <ExpandLessIcon className='text-white cursor-pointer' /> : <ExpandMoreIcon className='text-white cursor-pointer' />}
              </div>
              {showProgramsSubmenu && (
                <ul className="submenu" ref={programsSubmenuRef}>
                  <li>
                    <Link href="/Education" style={{ textDecoration: 'none', color: '#ffffff' }}>Education/Awareness</Link>
                  </li>
                  <li>
                    <Link href="/HawksbillProject" style={{ textDecoration: 'none', color: '#ffffff' }}>Research</Link>
                  </li>
                  <li>
                    <Link href="/Conservation" style={{ textDecoration: 'none', color: '#ffffff' }}>Conservation</Link>
                  </li>

                  <li>
                    <Link href='/ScholarshipProgram' style={{ textDecoration: 'none', color: '#ffffff' }} exact>Scholarship program</Link>
                  </li>
                </ul>
              )}
            </li>
            <Divider variant="middle" className='bg-[#B76770]' />
            <li className='lg:py-1 py-2 pl-4 custom-font'><Link href='/FoundationNews' style={{ textDecoration: 'none', color: '#ffffff' }} exact>Foundation News</Link></li>
            <Divider variant="middle" className='bg-[#B76770]' />
            <li className='lg:py-1 py-2 pl-4 custom-font'><Link href='/SeaTurtleFacts' style={{ textDecoration: 'none', color: '#ffffff' }} exact>Sea Turtle Facts</Link></li>
            <Divider variant="middle" className='bg-[#B76770]' />
            <li className='lg:py-1 py-2 pl-4 custom-font'>
              <div className="submenu-header cursor-pointer" onClick={handleHelpClick}>
                <span className="text-white">How You Can Help</span>
                {showHelpSubmenu ? <ExpandLessIcon className='text-white cursor-pointer' /> : <ExpandMoreIcon className='text-white cursor-pointer' />}
              </div>
              {showHelpSubmenu && (
                <motion.ul className="submenu" ref={helpSubmenuRef}
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <li>
                    <Link href='/Membership/BecomeAMember' style={{ textDecoration: 'none', color: '#ffffff' }} exact>Become a member</Link>
                  </li>
                  <li>
                    <Link href='/AdoptSeaTurtle' style={{ textDecoration: 'none', color: '#ffffff' }} exact>Adopt a nest</Link>
                  </li>

                  <li>
                    <Link href='/HawksbillProject' style={{ textDecoration: 'none', color: '#ffffff' }} exact>Florida Hawksbill Project</Link>
                  </li>
                  <li>
                    <Link href='https://donatetheboattocharity.com/' onClick={handleExternalLinkClick} target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none', color: '#ffffff' }} exact>Boat/Property Donations and Leases</Link>
                  </li>
                </motion.ul>
              )}
            </li>


            <Divider variant="middle" className='bg-[#B76770]' />
            <li className='lg:py-1 py-2 pl-4 custom-font'><Link href='/WeSupport' style={{ textDecoration: 'none', color: '#ffffff' }} exact>We Support</Link></li>
            <Divider variant="middle" className='bg-[#B76770]' />
            <li className='lg:py-1 py-2 pl-4 custom-font'><Link href='/Sponsors' style={{ textDecoration: 'none', color: '#ffffff' }} exact>Sponsors</Link></li>
            <Divider variant="middle" className='bg-[#B76770]' />
            <li className='lg:py-1 py-2 pl-4 custom-font'><Link href='/Contact' style={{ textDecoration: 'none', color: '#ffffff' }} exact>Contact</Link></li>




          </ul>
        </div>

      </div>
    </div>
  );

  return (
    <>
      <Box className="print:hidden bg-[#032546]" Box sx={{ display: { md: 'none' } }
      }>
        <IconButton onClick={handleDrawerToggle}>
          <MenuIcon className='text-[#ffffff]' />
        </IconButton>
      </Box >
      <StyledDrawer variant="temporary" anchor="left" open={mobileOpen} onClose={handleDrawerToggle}>
        <div className='pt-5'>
          <Link href="/">
            <img src={Logo} alt='' className='mx-auto' title="National Save The Sea Turtle Foundation" />
          </Link>
        </div>
        {drawer}
      </StyledDrawer>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        {drawer}
      </Box>
    </ >
  );
};

export default Navigation;