import React from "react";
import { Typography, Divider } from "@mui/material";
import MORAES from '../Images/MORAES.jpg';
import MiamisVirginiaKey from '../Images/Miamis-Virginia-Key.jpg'
import MichelleMartinezBonilla from '../Images/Michelle-Martinez-Bonilla.jpg'

export default function MORAESVkey() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <div className='pb-5'><h1 className='text-2xl'>MORAES Manages Sea Turtle Nesting on Miami’s Virginia Key</h1></div>
      </div>
      <div><Typography paragraph>By: Analisa Duran, Nest Survey Coordinator</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className='lg:float-right lg:ml-5 mt-5'><img className="rounded-2xl mx-auto" src={MORAES} alt="" title="" /></div>
      <div className="pt-5"><Typography paragraph>During the 2021 sea turtle nesting season, the Marine Order for Research and Action through Environmental Stewardship (MORAES) ran a volunteer-led sea turtle conservation program throughout Virginia Key Beach (VKB), Florida. The program, supported by the National Save The Sea Turtle Foundation, trained 20 volunteers, with varying backgrounds and interests, to look for and identify nesting sea turtle activity on the 2.5 mile stretch of beach which is located in Miami-Dade County and managed by the City of Miami. MORAES took on the permit and developed the volunteer program for the first time this past nesting season and the group believes it was a huge success.</Typography></div>
      <div className='lg:float-left lg:mr-5 my-5'><img className="rounded-2xl mx-auto" src={MiamisVirginiaKey} alt="" title="" /></div>
      <div><Typography paragraph>The survey area for VKB extends from Norris Cut (northern tip of VKB) through Historic Virginia Key Beach Park to the northern side of Bear Cut Bridge. Historically, this area sees an average of 50-70 nests per season. This year, the MORAES team were able to complete a successful nesting season with a total of 70 nests and 83 false crawls, resulting in approximately 3,979 loggerhead turtle hatchlings making it into the ocean.</Typography></div>
      <div><Typography paragraph>Volunteers from many walks of life dedicated their time and effort to the conservation of loggerhead turtles this year. From university students to health care workers, the sea turtle survey volunteers, trained and supervised by permit holder Analisa Duran and MORAES co-founder, Shannon Jones, were an integral part of the success of this season. Since environmental stewardship is a core pillar within the MORAES’ mission, the ability to engage the citizen scientists in hands-on research, marine conservation, and data collection was paramount, giving the volunteers an opportunity to witness a unique natural phenomena they would not normally get to experience.</Typography></div>
      <div><Typography paragraph>Volunteer Michelle Martinez-Bonilla, who is a student at Florida International University studying English, said she decided to volunteer with MORAES because she was looking for ways to learn more about marine science. “Volunteering allowed me to be a part of something important while also giving me the opportunity to interact with marine life,” said Martinez-Bonilla.</Typography></div>
      <div className='lg:float-right lg:ml-5 mb-5'><img className="rounded-2xl mx-auto" src={MichelleMartinezBonilla} alt="" title="" /></div>
      <div><Typography paragraph>Another volunteer, Eric Eimstad, who is a retiree with a strong background in business management and marine science said, “The survey experience increased my awareness of the challenges that the (sea turtle) nests face due to predators, humans and the environment.”</Typography></div>
      <div><Typography paragraph>MORAES is a 501(c)(3) operating in the state of Florida, committed to the understanding, viability, and protection of our local marine environment through conservation, research, and environmental stewardship.</Typography></div>
      <div><Typography >All activities are conducted under FWC permitting MTP-21-153.</Typography></div>
    </div>
  );
}
