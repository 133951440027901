import React from "react";
import { Typography, Divider } from "@mui/material";
import IRG from '../Images/Inwater-Research-group-3.jpg'
import Journey from '../Images/Journey.png'

export default function NewTurtleModel() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className="pb-5"><h1 className='text-2xl font-bold'>A Star is Born! New Sea Turtle Model Under Development at the Inwater Research Group, Inc.</h1></div></div>
      <div><Typography paragraph>Rebecca Mott, Director of Education,<br />Inwater Research Group, Inc.</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />

      <div className="mt-5">
        <div className='lg:float-right lg:ml-5 my-5'><img className="rounded-2xl mx-auto" src={IRG} alt="" title="" /></div>
        <div><Typography paragraph>Recently, the National Save The Sea Turtle Foundation funded an endeavor to create new and improved sea turtle models based on a real juvenile green turtle encountered during a research trip. The turtle was part of a population study conducted by IRG in Jupiter, FL. While in hand, it was kind enough to allow us to take a number of photographs from all angles. The images were then used to create a 3D model created by our partners at Digital Life 3D (http://digitallife3d.org). This model is now being transformed into an anatomically correct, tangible model that will be a part of our Trunk Program in perpetuity.</Typography></div>
        <div><Typography paragraph>Journey, as the turtle has been named, will educate hundreds of thousands of students over its “lifetime” and will bring a new atmosphere of authenticity to our programs. Children from all over Florida will learn what it means to be a sea turtle biologist and how they can protect sea turtles in their daily lives. We are so excited to take this Journey into the classroom and hope the students love it as much as we do!</Typography></div>
        <div><img className="rounded-2xl mx-auto" src={Journey} alt="" title="" /></div>
      </div>
    </div>
  );
}
