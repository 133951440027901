import React from "react";
import { Typography, Divider, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Ozone from "./Images/Ozone.png";
import TurtleBags from "./Images/Turtle-Bags.png";
import HawksbillGroup from "./Images/Hawksbill-Group.png";
import NationwidePaymentSystems from "./Images/Nationwide-Payment-Systems.png";
import ForceEScubaCenters from "./Images/Force-E-Scuba-Centers.png";

const theme = createTheme({
  palette: {
    primary: {
      main: "#bbc7d6", // custom primary color
    },
    secondary: {
      main: "#nnn", // custom secondary color
    },
  },
});

export default function Sponsors() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <div>
          <Typography paragraph>
            <h1 className="text-2xl font-bold">Sponsors</h1>
          </Typography>
        </div>

        <Divider className='dark:bg-[#032546] bg-white/70' />
        <div className="mt-5 lg:flex gap-5 justify-center">
          <div className="mb-10">
            <ThemeProvider theme={theme}>
              <Button variant="contained" fullWidth color="primary" className="h-full w-full">
                <a
                  href="https://www.ozonesocks.com/"
                  target="_blank"
                  rel="noreferrer"
                  color={"inherit"}
                  style={{ textDecoration: "none", fontWeight: "bold" }}
                >
                  <img src={Ozone} alt="" className="mx-auto" />
                  <span className="text-[#2E1500]">Ozone Design, Inc.</span>
                </a>
              </Button>
            </ThemeProvider>
          </div>
          <div className="mb-10">
            <ThemeProvider theme={theme}>
              <Button variant="contained" fullWidth color="primary" className="h-full w-full">
                <a
                  href="https://turtlebagsinc.com/"
                  target="_blank"
                  rel="noreferrer"
                  color={"inherit"}
                  style={{ textDecoration: "none", fontWeight: "bold" }}
                >
                  <img src={TurtleBags} alt="" className="mx-auto scale-150 p-16" />
                  <span className="text-[#2E1500]">Turtle Bags Inc.</span>
                </a>
              </Button>
            </ThemeProvider>
          </div>
          <div className="mb-10">
            <ThemeProvider theme={theme}>
              <Button variant="contained" fullWidth color="primary" className="h-full w-full">
                <a
                  href="https://hawksbillgroup.com/"
                  target="_blank"
                  rel="noreferrer"
                  color={"inherit"}
                  style={{ textDecoration: "none", fontWeight: "bold" }}
                >
                  <img src={HawksbillGroup} alt="" className="mx-auto scale-150 p-16" />
                  <span className="text-[#2E1500]">The Hawksbill Group</span>
                </a>
              </Button>
            </ThemeProvider>
          </div>
          <div className="mb-10">
            <ThemeProvider theme={theme}>
              <Button variant="contained" fullWidth color="primary" className="h-full w-full">
                <a
                  href="https://nationwidepaymentsystems.com/"
                  target="_blank"
                  rel="noreferrer"
                  color={"inherit"}
                  style={{ textDecoration: "none", fontWeight: "bold" }}
                >
                  <img src={NationwidePaymentSystems} alt="" className="mx-auto" />
                  <span className="text-[#2E1500]">Nationwide Payment Systems, Inc.</span>
                </a>
              </Button>
            </ThemeProvider>
          </div>
          <div className="mb-10">
            <ThemeProvider theme={theme}>
              <Button variant="contained" fullWidth color="primary" className="h-full w-full">
                <a
                  href="https://www.force-e.com/"
                  target="_blank"
                  rel="noreferrer"
                  color={"inherit"}
                  style={{ textDecoration: "none", fontWeight: "bold" }}
                >
                  <img src={ForceEScubaCenters} alt="" className="mx-auto" />
                  <span className="text-[#2E1500]">Force-E Dive Centers</span>
                </a>
              </Button>
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
