import React from "react";
import { Typography, Divider } from "@mui/material";
import Link from "@mui/material/Link";
import Olszackcollectssamplesneartheshore from '../Images/Olszack-collects-samples-near-the-shore.jpg'
import Olszackcollectssamplesontheshore from '../Images/Olszack-collects-samples-on-the-shore.jpg'
import AviewoftheSargassumlandingatDaniaBeach from '../Images/A-view-of-the-Sargassum-landing-at-Dania-Beach.jpg'
import SamanthaOlszack from '../Images/Samantha-Olszack-presents-her-research-alongside-her-advisor-Ligia-Collado-Vides.jpg'
import Olszackcollects from '../Images/Olszack-collects-samples-near-the-shore-with-her-advisor-Ligia-Collado-Vides.jpg'

export default function HelpedBioAlum() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className='pb-5'><h1 className='text-2xl font-bold'>How an undergraduate scholarship helped a marine biology alum start her research
      </h1></div></div>
      <div><Typography paragraph>Written by Catherine Guinovart<br />
        December 16, 2021</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="mt-5">
        <div><Typography paragraph>Undergraduate research fellowships at FIU are helping researchers uncover more information about the Sargassum that frequently fouls our beaches.</Typography></div>
        <div><Typography paragraph>Marine biology alumna Samantha Olszack, credits the <Link href="/">National Save the Sea Turtle Foundation</Link> for making her research — delayed by COVID-19 — possible.</Typography></div>
      </div>


      <div className="lg:flex lg:gap-4 mt-5">
        <div className="lg:w-1/3">
          <div><img className="rounded-2xl mx-auto" src={Olszackcollectssamplesneartheshore} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>Olszack collects samples near the shore. Photo courtesy Samantha Olszack</em></div>
        </div>
        <div className="lg:w-1/3">
          <div><img className="rounded-2xl mx-auto" src={Olszackcollectssamplesontheshore} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>Olszack collects samples on the shore. Photo courtesy Samantha Olszack</em></div>
        </div>
        <div className="lg:w-1/3">
          <div><img className="rounded-2xl mx-auto" src={AviewoftheSargassumlandingatDaniaBeach} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>A view of the Sargassum landing at Dania Beach. Photo courtesy Samantha Olszack</em></div>
        </div>
      </div>


      <div>
        <div><Typography paragraph>Olszak, who graduated from FIU in Fall 2020 with a bachelor’s degree in marine biology, finished her research on the floating macroalgae that washes ashore on Florida beaches in 2021 as a volunteer intern at FIU while simultaneously beginning graduate school at Nova Southeastern University.</Typography></div>
        <div><Typography paragraph>“I’m so grateful to the National Save the Sea Turtle Foundation for their support of this project. I strongly felt this work needed to be followed through despite the challenges faced with COVID-19 and even after graduation,” Olszack said. “This project deals with real-world issues affecting the environment, wildlife, and sea turtles specifically.”</Typography></div>
        <div><Typography paragraph>Floating macroalgae serve as a refuge sheltering juvenile sea turtles and hatchlings from predators as they start their lives in the open ocean. On shore, Sargassum can pile up as much as 6 feet high, creating a barrier for female sea turtles searching for suitable nesting areas and hatchlings exiting their nests between May and October.</Typography></div>
        <div><Typography paragraph>Healthy sea turtle populations are critical to the protection of the world’s oceans. Turtles maintain seagrass and coral reef ecosystems, provide habitat for marine life, balance marine food webs and help cycle nutrients from water to land.</Typography></div>
        <div><Typography paragraph>Olszack’s undergraduate research focused on another important threat posed by Sargassum: when it decomposes, Sargassum may leach toxic chemicals such as arsenic into the sand. This poses a threat to not only human health but to sea turtle eggs and hatchlings, too.</Typography></div>
        <div><Typography paragraph>Sargassum accumulations had a big impact in 2011 because of an extreme anomaly of the North Atlantic Oscillation in 2010 that created the perfect conditions for Sargassum to grow in an area called “The Great Belt” between South America and Africa. Sargassum that is dislodged from this area usually washes ashore in the Caribbean and in South Florida.</Typography></div>
        <div className='lg:float-right lg:ml-5'>
          <div><img className="rounded-2xl mx-auto" src={SamanthaOlszack} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>Samantha Olszack presents her research<br />
            alongside her advisor Ligia Collado Vides.<br />
            Photo courtesy Samantha Olszack</em></div>
        </div>
        <div><Typography paragraph>“This is the largest bloom of macroalgae in the world and it’s here to stay,” said FIU Biological Sciences Department Associate Chair Ligia Collado-Vides, who was Olszak’s adviser. “Samantha’s work is critical because one of the big questions is ‘Can we use Sargassum as compost?’ But, we still have a lot to learn about its decomposition.”</Typography></div>
        <div><Typography paragraph>The National Save the Sea Turtle Foundation has also funded other FIU research projects related to sea turtle conservation. In the last year, they have also funded student internships.</Typography></div>
        <div className='lg:float-left lg:mr-5'>
          <div><img className="rounded-2xl mx-auto" src={Olszackcollects} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>Olszack collects samples near the shore with <br />
            her advisor, Ligia Collado Vides.<br />Photo courtesy:
            Samantha Olszack</em></div>
        </div>
        <div><Typography paragraph>“FIU students are truly making a difference in the field of sea turtle research and conservation,” said National Save the Sea Turtle Foundation Research Coordinator Larry Wood. “We are excited to support them so that they can gain lifelong experience by using their knowledge and skills to study these gentle creatures.”</Typography></div>
        <div><Typography paragraph>Students and faculty at the Coastlines and Oceans Division of FIU’s Institute of Environment are leading efforts to better understand the role sea turtles play in ocean ecosystems.</Typography></div>
        <div><Typography>​​In part because of the research conducted by the Institute of Environment, FIU was ranked the No. 3 public university in the U.S. and No. 11 in the world for SDG 14, Life Below Water, which measures universities’ research on life below water and their education on and support for aquatic ecosystems. Freshwater, coastal and ocean initiatives are led by the FIU Institute of Environment.</Typography></div>
      </div>

    </div>
  );
}
