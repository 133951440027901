import React from "react";
import { useEffect, useState } from "react";
import BadgeImg from '../Images/Badge.png'


function Since() {
  const [years, setYears] = useState(0);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearsSince1987 = currentYear - 1987;
    setYears(yearsSince1987);
  }, []);

  return (


    // <div>

    //   {/* <Divider >
    //     <Chip label={`Helping Sea Turtles Survive for ${years} Year${years === 1 ? '' : 's'}`}  style={{fontSize:'x-large', padding: '7px', position: 'relative'}}/>
    //   </Divider> */}



    //   <Typography></Typography></div>
    <div className="text-[#ffffff] dark:text-[#032546]" style={{
      position: 'relative',
      display: 'inline-block'
    }}>
      <img src={BadgeImg} alt='' title='' className="fill-[#032546] dark:fill-[#ffffff]" />
      <span style={{
        position: 'absolute',
        bottom: '1%',
        right: 0,
        //backgroundColor: 'rgba(0, 0, 0, 0.7)',
        padding: '.8rem 2.5rem',
        fontSize: '.7rem',
        fontWeight: 'bold',
        // color: '#808080',
        textAlign: 'center',
        minWidth: '3rem'
      }}>Helping Sea Turtles Survive for <br />{years} Year{years === 1 ? '' : 's'} </span>

    </div>
  );

}

export default Since;