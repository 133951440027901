import React from "react";
import { Typography, Divider } from "@mui/material";
import FigueredoBisbeOchoa from '../Images/Figueredo-Bisbe-Ochoa-and-Katherine-Comer-Santos.jpg'
import BisbeOchoarecordsdataonseaturtleeggs from '../Images/Bisbe-Ochoa-records-data-on-sea-turtle-eggs.jpg'
import FigueredoandBisbe from '../Images/Figueredo-and-Bisbe-Ochoa-observe-eggs-in-Styrofoam-boxes.jpg'
import Oliveridleyseaturtlehatchlings from '../Images/Olive-ridley-sea-turtle-hatchlings-begin.jpg'
import BisbeOchoameasuresoliveridley from '../Images/Bisbe-Ochoa-measures-an-olive-ridley-sea-turtle.jpg'

export default function StyrofoamBoxes() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className='pb-5'><h1 className='text-2xl font-bold'>Can long-lasting Styrofoam boxes incubate sea turtle eggs?
      </h1></div></div>
      <div><Typography paragraph>Written by Contributing Writer<br />
        September 28, 2021</Typography></div>
      <div><Typography paragraph>By: Carolina Maria</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="mt-5">
        <div><Typography paragraph>Two undergraduate students are helping conduct research to determine whether Styrofoam containers — long hated for their difficulty in recycling — can actually help incubate sea turtle eggs.</Typography></div>
        <div><Typography paragraph>The National Save the Sea Turtle Foundation scholars at FIU, political science and biology major Jose Bisbe-Ochoa and marine biology major Maria Clara Figueredo, spent the summer in Sayulita, Mexico working with The Science Exchange to find out.</Typography></div>
      </div>

      <div className="lg:flex gap-5">
        <div className="lg:w-1/2">
          <div><img className="rounded-2xl mx-auto" src={BisbeOchoarecordsdataonseaturtleeggs} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>Bisbe-Ochoa records data on sea turtle eggs. Photo courtesy Figueredo and Bisbe-Ochoa</em></div>
        </div>
        <div className="lg:w-1/2">
          <div><img className="rounded-2xl mx-auto" src={FigueredoandBisbe} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>Figueredo and Bisbe-Ochoa observe eggs in Styrofoam boxes. Photo courtesy Figuerdo and Bisbe-Ochoa</em></div>
        </div>
      </div>

      <div>
        <div><Typography paragraph>Bisbe-Ochoa and Figueredo’s research compares hatchling success between sea turtle eggs incubated in Styrofoam containers and those incubated in nests in the sand. Their research helps identify the potential long-term impacts of incubating eggs with this artificial incubation method on local sea turtle populations</Typography></div>
        <div><Typography paragraph>What the study discovers could have major implications for the future of sea turtles, which are critical to the protection of the world’s oceans. Sea turtles maintain seagrass and coral reef ecosystems, provide habitat for marine life, balance marine food webs and help cycle nutrients from land to sea.</Typography></div>
        <div><Typography paragraph>“While our research is very important and will serve to guide conservation efforts, our part is the easy part,” Bisbe Ochoa said. “We are here for a few months and then we are gone. The people in Sayulita giving their time and energy to try to save sea turtles and protect them from poachers and other threats. They deserve the real credit.”</Typography></div>
        <div><Typography paragraph>Today, only 1 in 1,000 sea turtle hatchlings reach adulthood. Soaring temperatures contribute to the population decline because they have changed the ratio between male and female sea turtles. By using incubators to help regulate temperatures, researchers hope to in some way restore the balance so that more sea turtle eggs are produced.</Typography></div>
        <div><Typography paragraph>In Mexico, Bisbe-Ochoa and Figueredo patrolled the beaches for nesting sea turtles. They collected eggs and transfer them to a hatchery where the eggs were either placed in Styrofoam containers or under shade in the sand.</Typography></div>
        <div><Typography paragraph>They measured parameters including the moisture of the sediment, the temperature of the nests and hatchling fitness. Hatchling fitness was determined through a series of motor tests. After measuring the hatchlings’ size and weight, Figueredo and Bisbe-Ochoa waited to see if the turtles could flip themselves over. They also tested the turtles’ ability to scurry toward a light.</Typography></div>
      </div>

      <div className="lg:flex gap-5">
        <div className="lg:w-1/2">
          <div><img className="rounded-2xl mx-auto" src={Oliveridleyseaturtlehatchlings} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>Olive ridley sea turtle hatchlings begin their trek to the shore. Photo courtesy Figueredo and Bisbe-Ochoa</em></div>
        </div>
        <div className="lg:w-1/2">
          <div><img className="rounded-2xl mx-auto" src={BisbeOchoameasuresoliveridley} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>Bisbe Ochoa measures an olive ridley sea turtle. Photo courtesy Figueredo and Bisbe-Ochoa</em></div>
        </div>
      </div>


      <div>
        <div className='lg:float-right lg:ml-5'>
          <div><img className="rounded-2xl mx-auto" src={FigueredoBisbeOchoa} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>Figueredo, Bisbe Ochoa and Katherine Comer Santos,<br />
            Director of The Science Exchange pose for a picture in<br />
            front of the sea turtle hatchery. Photo courtesy<br />
            Figueredo and Bisbe-Ochoa</em></div>
        </div>
        <div><Typography paragraph>“Maria Clara and Jose's work has the potential to improve nesting beach conservation efforts at sites around the globe,” said Elizabeth Whitman, an assistant teaching professor in the Department of Biological Sciences, who is Bisbe-Ochoa and Figueredo's advisor.</Typography></div>
        <div><Typography paragraph>Part of the solution also includes educating and inspiring children to help.</Typography></div>
        <div><Typography paragraph>“When we did a hatchling release, we invite children in the community to come to be a part of it,” Figueredo said. “We give them a talk about the importance of taking care of the species. We tell them that the reality of the threats they face. Sharing the numbers - the survival rates of hatchlings - truly makes something in their minds click.”</Typography></div>
        <div><Typography paragraph>This research was funded by the National Save the Sea Turtle Foundation as part of a joint-scholarship Bisbe-Ochoa and Figueredo earned in 2019. In addition to this scholarship, the students also received funding from Zoo Miami to support their research.</Typography></div>
        <div><Typography>In part because of the research conducted by the Institute of Environment, FIU was ranked the No. 3 public university in the U.S. and No. 11 in the world for SDG 14, Life Below Water, which measures universities' research on life below water and their education on and support for aquatic ecosystems. Freshwater, coastal and ocean initiatives are led by the FIU Institute of Environment.</Typography></div>
      </div>
    </div>
  );
}
