import React from "react";
import { Divider, Typography } from "@mui/material";
import WaterwayCleanup28th from "../Images/Broward-County-Waterway-Cleanup-for-28th-Year.jpg";
import WaterwayCleanup28th2 from "../Images/Broward-County-Waterway-Cleanup-for-28th-Year-1.jpg";
import WaterwayCleanup28th3 from "../Images/Waterway-Cleanup-for-28th-Year.jpg";

export default function WaterwayCleanup() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <div className="pb-5">
          <h1 className="text-2xl font-bold">
            The National Save The Sea Turtle Foundation Supports the Broward County Waterway Cleanup for 28th Year
          </h1>
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />

      <div className="lg:flex center gap-5">
        <div className="lg:w-1/2 my-3">
          <img src={WaterwayCleanup28th} alt="" title="" className="rounded-2xl" />
        </div>
        <div className="lg:w-1/2 my-3">
          <img src={WaterwayCleanup28th2} alt="" title="" className="rounded-2xl" />
        </div>
      </div>
      <div className="pt-2">
        <Typography>
          The National Save The Sea Turtle Foundation sponsored the 45th annual Broward County
          Waterway Cleanup Kick-off Party at the Marine Industries Association office in Fort
          Lauderdale on Thursday evening, February 5th from 5:30 to 7:30pm. Guests (including the
          Foundation’s own Kelly Flanagan and Bill Muller, upper left) enjoyed complimentary drinks
          and light hors d’oeuvres, and this year’s artwork created by MIASF’s Megan Piggott was
          unveiled. The partygoers also received limited edition Waterway Cleanup reusable cups. The
          45th annual Broward County Waterway Cleanup, which was held on Saturday, March 5th, is
          organized by the Marine Industries Association of South Florida and Marine Industry Cares
          Foundation. The event is the County’s largest and longest-running environmental event. The
          National Save The Sea Turtle Foundation has been a proud sponsor of the event since 1994.
        </Typography>
      </div>
      <div className="mt-5">
        <img src={WaterwayCleanup28th3} alt="" title="" className="rounded-2xl" />
      </div>
    </div>
  );
}
