import React from 'react'
import { Divider, Typography } from '@mui/material';
import Facts from './Component/Facts'

export default function SeaTurtleFacts() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>

      <div>
        <div><div className='pb-5'><h1 className='text-2xl'>Sea Turtle profiles</h1></div></div>
        <Divider className='dark:bg-[#032546] bg-white/70' />
        <div><Typography paragraph className='pt-5'>Sea turtles are large air-breathing reptiles remarkably adapted to life in the sea. They live in all but the coldest of the world's oceans, but nest only on tropical and subtropical beaches where it is warm enough to incubate their eggs. All sea turtles are protected by federal and state laws.</Typography></div>
        <div><Typography paragraph>Sea turtles have a low streamlined shell and powerful, oversized front limbs; adaptations that enable them to swim for great distances. They have no teeth but use their jaws to crush and tear food. The smallest sea turtle, the Kemp's Ridley, weighs about 75 to 100 pounds when mature, while adults of the largest species, the leatherback, can weigh almost 2,000 pounds and may be eight feet in length.</Typography></div>
        <div><Typography paragraph>Sea turtles spend most of their day feeding or sleeping under reef ledges or in the open ocean. Some travel hundreds or thousands of miles to feed or nest. Females lay their eggs on sandy beaches and are slow and awkward on land. A female will usually lay several nests during one season and may nest every two to three years.</Typography></div>


        <div><Typography paragraph>The difficult process of nesting takes up to three hours. A turtle must drag her great weight ashore, dig a nest with her back flippers, deposit about one hundred eggs, and cover and conceal the nest before returning to sea. The eggs incubate in the warm sand and the female never visits her nest again.</Typography></div>
        <div><Typography paragraph>After incubating for about two months, the two-inch long turtles hatch, erupt as a group from their nest in the cool of the night, and scurry down the beach to the sea. Many hatchlings swim offshore to live for several years in floating seaweed drifting along the edges of ocean currents.</Typography></div>
        <div><Typography paragraph>Eventually the young turtles take up residence in coastal waters. Many years pass before the few hatchlings that survive reach maturity. A sea turtle may live for 40 to 60 years or more.</Typography></div>
        <div><Typography paragraph>Sea turtles once roamed the oceans by the millions, but over the past few centuries the demand for sea turtle meat, eggs, shell, leather and oil has greatly reduced their numbers. Populations continue to decline as habitat is lost and the trade in sea turtle products continues. Every year, thousands of sea turtles drown in shrimp trawls and other fishing gear and others die from pollutants or from swallowing trash mistaken for food.</Typography></div>
        <div><Typography paragraph>Many hatchling sea turtles are disoriented by bright lights near beaches and wander away from the ocean to be crushed by cars or stranded. Concern for the plight of sea turtles is growing and people around the world are working to protect them on nesting beaches and at sea.</Typography></div>
      </div>

      <div><Facts /></div>


    </div>
  )
}
