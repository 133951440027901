import React from "react";
import { Typography } from "@mui/material";
import SavingFloridasSharks from "../Images/Saving-Floridas-Sharks.jpg";
import MelissaHelping from "../Images/Melissa-Marquez-helping-bring-a-great-white-shark-up.jpg";
import MelissaSpiny from "../Images/Melissa-with-a-spiny-dogfish.jpg";

export default function DaringExcitingToothy() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="mb-5">
        <img src={SavingFloridasSharks} alt="" title="" className="rounded-2xl mx-auto" />
      </div>
      <div>
        <Typography paragraph>
          Daring...exciting...toothy...these words describe the many shark-related thrillers that
          often bring to mind vivid images of dramatic and terrifying scenes. Portraying sharks as
          the monster, such films can be harmful to the innocent creatures swimming in our oceans
          because they evoke fear in people who play at the beach or dive over a coral reef. Some
          individuals may even find themselves worrying about what’s lurking beneath the waves — and
          if it’s hungry. But in reality, sharks are some of the planet’s most misunderstood
          creatures, an issue worsened by the media’s love for drama and violence.
        </Typography>
      </div>
      <div>
        <Typography paragraph>
          As an incredibly attractive location for marine life, Florida provides the ideal habitat
          for many sharks that suffer from this PR nightmare. In fact, a wide variety of shark
          species make our waters their home, including the protected hammerheads and angel sharks.
          In August 2013, for example, researchers at Ocearch in Cape Cod, Massachusetts, captured
          and tagged a 14-foot, 2,300-pound white shark named Katherine. Since that day, Katherine
          has traveled nearly 40,000 miles to visit Florida each year. Most recently, this
          incredible fish was spotted off the coast in Cape Canaveral.
        </Typography>
      </div>
      <div>
        <Typography paragraph>
          Because of their diversity and the critical role each shark plays in the ecosystem,
          conservation efforts in the Sunshine State have become more important than ever.
          Thankfully Ocearch, which aims to monitor marine life and thereby promote conservation, is
          not the only organization working to save sharks. Rather, many individuals, groups, and
          businesses are working together to dispel the terrifying myths surrounding these animals.
          By promoting better education and awareness, these actions provide new knowledge, such as
          insights into their behavior, and increase public understanding of their not-so-scary
          nature.
        </Typography>
      </div>
      <div>
        <div className="pb-5">
          <h2 className="text-2xl font-bold">The Issues Facing Sharks</h2>
        </div>
      </div>
      <div>
        <Typography paragraph>
          Although individual sharks may be blissfully unaware of the issues they face, their
          existence as a whole is largely threatened by human actions. Commercial shark finning — a
          practice that involves capturing sharks, cutting off their fins, and discarding the
          carcass — is considered a threat that’s not only harmful to shark populations but also
          extremely wasteful. Researchers have estimated that this practice leads to the death of
          over 100 million sharks per year.
        </Typography>
      </div>
      <div>
        <Typography paragraph>
          Even if sharks are not targeted by commercial fishing vessels, they may still be
          vulnerable as bycatch. A fishery that targets tuna or billfish, for instance, will likely
          catch some shark species in their nets. And while this isn’t intentional, it can still
          lead to the deaths of many sharks, decimating their populations. Other human impacts have
          also led to the reduction of shark populations, including habitat degradation. This
          all-encompassing term refers to the destruction of areas where sharks can survive, often
          as a result of climate change, pollution, or physical destruction.
        </Typography>
      </div>
      <div>
        <Typography paragraph>
          But why are sharks so vulnerable - and why do they need saving from these issues? Sharks
          can live pretty long lives (the oldest living vertebrate is a Greenland shark that’s
          estimated to be at least 300 years old!), it takes them years to mature, and when they do
          reproduce, they usually have only a few pups. Collectively, these traits are known as
          life-history characteristics, which have been adapted over time and allow the sharks to
          fulfill a specific niche in their environment. But when an exorbitant number of sharks are
          killed each year as a result of human activity, it reduces their ability to survive long
          enough to reproduce. And if they can’t reproduce, then it’s only a matter of time before
          their numbers run out. Thankfully, there are a few heroes working to raise awareness and
          save the sharks.
        </Typography>
      </div>
      <div>
        <div>
          <div>
            <div className="pb-5">
              <h2 className="text-2xl font-bold">Spreading Awareness of Shark Products</h2>
            </div>
          </div>
          <div className="lg:ml-5 lg:float-right">
            <img src={MelissaHelping} alt="" title="" className="rounded-2xl" />
            <div className="pt-2 pb-5">
              <em>
                Melissa Marquez helping bring a great white shark up for fin ID photos.<br /> Photo Provided by Melissa Marquez.
              </em>
            </div>
          </div>
          <div>
            <Typography paragraph>
              Although shark finning is prohibited on United States fishing vessels, the trade of
              shark fins remains legal in all but 12 states. Miami is a leading import/export nexus
              of the trade, according to the website for Save the Sharks, an organization led by
              Susana Ramirez. Dedicated to improving public awareness of such issues, Ramirez relies
              on a variety of tools, such as education, outreach, and general conservation. These
              tools allow her to focus much of the organization’s efforts on getting shark products
              out of stores and restaurants. “We find it best to focus on specific issues,” Ramirez
              explains, indicating that they can then achieve better results. Through Save the
              Sharks, Ramirez reminds people that shark meat poses health issues. In particular, the
              meat contains high levels of mercury which are typically over the FDA’s action level
              of one part per million. In addition, the ethical, conservation, and sustainable
              fishery issues involved in selling shark products can be a major problem.
            </Typography>
          </div>
          <div>
            <Typography paragraph>
              According to Save the Sharks, the shark fin trade is one of the leading causes of
              mortality in these animals - and there are simply no sustainably sourced shark fins
              available. If you ever find non-food shark products like shark teeth, bracelets, or
              necklaces, Ramirez warns that these aren’t necessarily safe to purchase either,
              stating “they are almost never ethically sourced.” With a focus on getting shark
              products off the menu, Ramirez and her team are working on relaunching their “Report
              an Entity” program, which will allow conservation-savvy consumers to take action
              whenever they find shark products in stores or restaurants.
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className="pb-5">
            <h2 className="text-2xl font-bold">Giving Sharks a Voice Through Social Media</h2>
          </div>
        </div>
        <div className="lg:flex lg:gap-5">

          <div className="lg:w-2/3 lg:float-left">
            <Typography paragraph>
              Currently residing in Sydney, Australia, Melissa Cristina Marquez began her own personal
              mission to save sharks from the halls of her college in Sarasota, Florida. It was here
              that she originally founded The Fins United Initiative (fondly referred to as TFUI),
              which is an education and conservation program geared towards uniting lovers of sharks
              and their relatives around the world.
            </Typography>
          </div>

          <div className="lg:float-right lg:w-1/3">
            <div className="bg-blue-700 text-center text-white rounded-lg shadow-lg lg:ml-5 p-10 mb-5">
              <div><h2 className="text-2xl font-bold">"Take out these predators, and it has been proven that the ecosystem suffers."</h2></div>
            </div>

          </div>


        </div>


        <div>
          <Typography paragraph>
            Through this organization — which hopes to soon have its own home base and a fleet of
            educational vehicles — Marquez is working to help everyone understand the importance of
            sharks. By partnering with educational institutions and other organizations, she has
            designed novel techniques that promote participation and learning.
          </Typography>
        </div>
        <div>
          <Typography paragraph>
            But her goals go far beyond providing easily accessible information. Marquez also
            strives to give sharks a voice through her social media accounts. “Sharks are one of the
            most important predators in our oceans, which is the largest ecosystem making up 75% of
            our planet,” Marquez stated. “Take out these predators, and it has been proven that the
            ecosystem suffers. If you don’t protect sharks, then you aren’t protecting our oceans”.
            Whether she’s working through TFUI or tweeting about the latest shark research, she
            hopes to help us all understand the importance of these animals so that we can better
            promote their well-being.
          </Typography>
        </div>
      </div>
      <div>
        <div>
          <div className="lg:mr-5 lg:float-left">
            <img src={MelissaSpiny} alt="" title="" className="rounded-2xl" />
            <div className="pt-2">
              <em>
                Melissa with a spiny dogfish caught in Wellington,<br /> New Zealand. Photo:
                Provided by Melissa Marquez.
              </em>
            </div>
          </div>
          <div>
            <Typography paragraph>
              In addition to promoting awareness of the sharks themselves, Marquez also shines her
              spotlight on the other end of the diver’s mask - the people who research them. “I want
              people to go to my various channels, be it TFUI or my Twitter account, and see the
              diversity within the STEM fields that the media doesn’t typically show,” she stated.
              As a strong advocate for diversity and inclusivity, Marquez devotes her time working
              with two sides of the same coin. Not only is she saving sharks by raising awareness
              and promoting education about their role in the ecosystem, but she is also giving the
              diverse researchers who study them a voice.
            </Typography>
          </div>
          <div>
            <div>
              <h2 className="text-2xl mb-5">How Can You Help?</h2>
            </div>
          </div>
          <div>
            <Typography paragraph>
              Developing a better understanding of sharks and the key role they play in the
              environment is one of the most important things an individual can do to save sharks.
              But if you want to take more action, there a few ways anyone can help these incredible
              creatures. One of Melissa Marquez’s favorite tips is to remind individuals “to eat
              sustainable seafood” by avoiding unsustainable seafood. Susana Ramirez also recommends
              avoiding shark foods, including shark steak, shark meat, and shark fin soup.
              Additionally, remember to read the ingredients list whenever purchasing fragrances,
              medications, or oils, because “these products may have shark in them,” Ramirez
              advises. Marquez offers other great ways to help sharks - and the ocean as a whole.
              Consider spending the day with your family volunteering at reputable nonprofits in the
              area, or try writing to policy-makers to ask what they’re doing to help the oceans.
            </Typography>
          </div>
          <div>
            <Typography paragraph>
              It is often all too easy to overlook the importance of sharks in the ecosystem or to
              feel overwhelmed by the complexities of saving them. But the work of Melissa Cristina
              Marquez and Susana Ramirez proves just how important — and possible — it is to get
              started. The mission to save Florida’s sharks has already begun — will you be a part
              of it?
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
