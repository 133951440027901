import React from "react";
import { Typography, Divider } from "@mui/material";
import FAUanimalB1 from "../Images/FAU-Animal-Behavior-and-Visual-Perception.jpg";
import FAUnow2 from "../Images/Scholarship-Program-at-FAU-Now-in-Second-Year.jpg";

export default function FAUsecondYear() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex">
        <div>
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              National Save The Sea Turtle Foundation
              Scholarship Program at FAU Now in Second Year
            </h1>
          </div>
          <Typography paragraph>
            Larry Wood, Ph.D., Research Coordinator,<br />
            National Save The Sea Turtle Foundation
          </Typography>
        </div>
        <div className="lg:float-right p-2">
          <img className=" border-4 mx-auto border-white rounded-2xl" src={FAUanimalB1} alt="" title="" />
        </div>

      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2 clear-both' />
      <div className="pt-5">
        <Typography paragraph>
          For many years, the National Save The Sea Turtle Foundation has partnered with Florida
          Atlantic University to support students, faculty, and facilities to assist with their
          world-class studies with sea turtles and the coastal environment. Most recently, the
          Foundation established a scholarship program within the Charles E. Schmidt College of
          Science to provide 4 undergraduate scholarships $15,000 each during the 2018-2019 academic
          year. The scholarships provided financial assistance to seniors enrolled at the Boca Raton
          Campus who demonstrated academic excellence, financial need, and a commitment to become
          scholars and ambassadors that represent the Marine Science program at Florida Atlantic
          University. As such, they are expected to develop as well-rounded, skilled, and committed
          individuals who are motivated to engage the public through education, research and
          outreach activities appropriate to both their specific interests in the field, as well as
          those of importance in the field of marine biology.
        </Typography>
      </div>
      <div>
        <div className="lg:flex">
          <div className="lg:w-1/2">
            <Typography paragraph>
              As these students prepare to finish their senior year in the spring, we are looking
              forward to continuing our support for new students in the upcoming year. We’ll be
              getting updates and summaries from each of the former scholarship recipients to share in
              future issues of Outreach, and we wish them the very best as they continue to pursue
              their careers. The ideal candidate is an undergraduate about to enter their senior year
              with interest in any aspect of marine biology research at the ecological, evolutionary,
              or behavioral level and educational outreach. Preference will be given to applicants in
              good standing in the biology department’s Honors Thesis Program who demonstrate
              financial need, coupled with a commitment to community service, academic excellence, and
              a desire to enhance their ability to engage the public. These scholarships shall be
              awarded on the basis of need and merit without regard to race, color, religion, age,
              sex, national origin, or disability.
            </Typography>
          </div>

          <div className="lg:w-1/2">
            <div className="lg:ml-5"><img className="rounded-2xl mx-auto" src={FAUnow2} alt="" title="" /></div>
          </div>
        </div>


      </div>
    </div>
  );
}
