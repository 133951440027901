import React from "react";
import { Divider, Typography } from "@mui/material";
import UCF1 from "../Images/University-of-central-florida.jpg";
import JuvenileT from "../Images/juvenile-loggerhead-turtle-carries-a-specialized-transmitter.jpg";
import DocEhr1 from "../Images/Lew-Doc-Ehrhart.jpg";
import Greennest from "../Images/green-sea-turtle-nests.jpg";

export default function UCFMarineTurtleResearchGroup() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex gap-5 mb-2">
        <div className="lg:w-3/4">
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              The UCF Marine Turtle Research Group – A Whole-Life Approach to Understanding Sea Turtle Biology and Informing Conservation
            </h1>
          </div>
          <Typography paragraph>
            Kate Mansfield, PhD, Associate Professor<br />
            Director, Marine Turtle Research Group<br />
            Department of Biology, University of Central Florida<br />
          </Typography>
        </div>
        <div className="lg:w-1/4 lg:float-right">
          <img className="mx-auto rounded-2xl" src={UCF1} alt="" title="" />
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="lg:flex gap-5 my-5">
        <div className="lg:w-1/2">
          <Typography paragraph>
            In 2013, I won the job lottery. At the time, I’d been working for both Florida
            International University and the National Oceanic and Atmospheric Administration’s
            (NOAA) Southeast Fisheries Science Center in Miami, Florida. I’d been writing grant
            after grant to support myself while working on exciting new satellite tracking
            techniques to finally track what was known as the ‘sea turtle lost years’—the early few
            years of a sea turtle’s life that is spent in the open ocean. It was about two to three
            years after the 2010 Deep Water Horizon oil spill in the Gulf of Mexico and I’d received
            funding to head offshore in the northern and eastern Gulf of Mexico to catch baby sea
            turtles, put small, solar-powered satellite tags on them using a combination of manicure
            acrylic, old wetsuit material, and aquarium silicone, and release them with
            oceanographic drifters (a fancy term for old PVC buckets with satellite tags and floats
            strapped to them). The data we received from these turtles, and others we’d tracked in
            the Atlantic was exciting—we were starting to better understand what baby turtles did
            during their first few years at sea, and we were turning old assumptions about these sea
            turtle “lost years” upside down with our new observations.
          </Typography>
          <Typography paragraph>
            I’ve worked with sea turtles since 1994; by 2012-2013 I’d certainly heard of the UCF
            Marine Turtle Research Group and its founder, Doc Ehrhart. I’d taken Florida’s required
            Index Nesting Beach Survey trainings including one up near the Archie Carr National
            Wildlife Refuge, one of the UCF MTRG’s study sites back in the late 1990s. I remember
            heading out to the beach early in the morning to see examples of sea turtle crawls and
            learn the difference between what a nest looks like on the beach and a “false crawl” or
            non-nesting emergences. The beach was covered in fresh crawls—all highlighted in shadowy
            relief with the early morning sun. I’d not seen anything quite like it. At that time,
            I’d worked on beaches where six crawls in a night was considered a huge (and hectic)
            night—not 100s!
          </Typography>
          <Typography paragraph>
            Fast-forward to 2013—I’d just been hired to take over the UCF MTRG; I’d “won” what I now
            consider the job lottery. The program was founded in the late 1970s by “Doc” Ehrhart.
            He’d originally trained as a small mammal biologist. But when he was a young professor
            at UCF conducting some of the environmental assessments for the new launch sites at
            Kennedy Space Center, he was asked by University of Florida’s Dr. Archie Carr, the
            preeminent sea turtle researcher at the time and now considered the grandfather of sea
            turtle research, to check out the beaches south of the Space Center for sea turtle
            nesting activity.
          </Typography>
          <Typography paragraph>
            After founding the UCF Marine Turtle Research Group in the late 70s and early 80’s, Doc
            devoted decades to monitoring central and south Brevard County’s beaches from Patrick
            Air Force Base south to Sebastian Inlet State Park. These beaches, especially in the
            southern portion of the county, support some of the most active sea turtle nesting sites
            in the Western Hemisphere. Data from the UCF MTRG were used to help establish the Archie
            Carr National Wildlife Refuge (ACNWR) in southern Brevard County in 1991. This 20 km
            (~13 mile) stretch of beach now hosts 15-20% of all loggerhead, and about 33% of all
            green sea turtle nesting in the state of Florida, along with 20-50 leatherback nests
            each year. Twenty kilometers of beach—let that sink in. Yet these numbers weren’t always
            the case. Back in the 1980s, Doc and his crew were lucky to observe 40-50 green turtle
            nests along these beaches. But with the establishment of the ACNWR, the implementation
            of the Endangered Species Act and fisheries bycatch reduction policies, and local
            beachfront lighting ordinances, one turtle generation later, the lab and program I
            inherited is starting to see the conservation successes for which Doc’s and others’
            early work laid the foundation.
          </Typography>
          <Typography paragraph>
            In 2013, the lab was experiencing a record green turtle nesting year. I’d accepted the
            job as the new director of the UCF Marine Turtle Research Group and was to start that
            August. I made several trips up that spring and summer to get a feel for the program and
            its field sites. But I’d never experienced a nesting season quite like the one in 2013
            (nor had my new lab). Green turtle nesting had exploded and the lab counted 11,000 green
            turtle nests that summer—a record. The first night I spent on the beach with what was to
            become my new crew, was incredible. I was visiting with a close friend and mentor from
            Brazil, Neca Marcovaldi. She is one of the founders of Brazil’s TAMAR—another long-term
            sea turtle research and conservation program. We walked out on the beach and turtles
            were emerging everywhere. We were trapped—when female turtles first emerge from the
            water to nest, they are easily spooked. We could barely walk 100 m without having to
            wait quietly, low on the beach while turtles emerged and crawled up the beach before we
            could continue on. After about an hour, we started to giggle and couldn’t stop. Neither
            of us had experienced anything quite like this beach and its turtles. What I later
            learned was that during the peak of nesting, the ACNWR beaches may have more turtles
            nesting in one night than most Florida nesting beaches see in an entire season.
            Conceptually, I’d known that it was a high density nesting beach, but until I’d spent a
            night, and now several summers, on the beach, “high density” was just a concept, not an
            experience.
          </Typography>
          <Typography paragraph>
            Sea turtle research is “slow science”. Some species of sea turtle take upwards of 25-35
            years to reach maturity. That’s 2-3 decades before they can reproduce and contribute to
            the growth of their population. After their first years in the open ocean as part of the
            sea turtle “lost years”, larger juvenile turtles recruit back to coastal waters where
            they may remain until maturity. These coastal waters include places like the Indian
            River Lagoon in Florida where Doc also started long-term in-water monitoring program.
            Twice a month, year-round, a crew of students, staff, and faculty take three boats and
            set a half-kilometer long tangle net just south of the Sebastian Inlet. The net soaks
            for 3 hours, and all turtles snagged in the net are immediately removed to a “work-up”
            boat where they are measured, weighed, and their health and diet are assessed through
            blood draws and tissue samples. About half of the juvenile green turtles caught in the
            IRL have a disease called fibropapillomatosis (FP). This disease results in large tumors
            found on the soft tissue of sea turtles infected with a virus thought to be associated
            with the disease.
          </Typography>
          <div className="my-5">
            <img className="rounded-2xl mb-2 mx-auto" src={JuvenileT} alt="" title="" />
            <em>
              A juvenile loggerhead turtle carries a specialized transmitter to reveal its movements
              in the open ocean. Activity authorized under NMFS Permit #19508. Photo: K. Mansfield.
            </em>
          </div>
          <Typography paragraph>
            Gustavo’s work will help us understand whether these numbers reflect broad genetic
            diversity or whether the thousands of turtles now found in our study areas are part of a
            genetically fragile group with little diversity stemming from those few but persistent
            turtles in the 1980s.
          </Typography>
          <Typography paragraph>
            We continue to push the boundaries of our offshore “lost years” work. We have active
            research programs to tag and track baby turtles in the North and South Atlantic, and
            collaborations elsewhere in the world. We will also be testing newly developed, smaller
            solar GPS tags with more sensors that will allow us to better understand the behavior
            and movements of baby turtles during their first years at sea. In collaboration with the
            ICARUS Iniative, PhD student Katrina Phillips is determining the likely stock and
            rookery of origin among the wild-caught turtles we’ve satellite tagged in the Gulf of
            Mexico. She is using oceanographic models to determine how they arrived at their capture
            locations from their source rookeries. Alex Sacco, another PhD student will build on
            Katrina’s work using remotely sensed habitat data to better predict when baby sea
            turtles are found in Sargassum, a transient, floating brown algae that provides a warm,
            protected, and food-filled habitat for young ocean-going turtles.
          </Typography>
        </div>
        <div className="lg:w-1/2">
          <Typography paragraph>
            That request changed the trajectory of Doc’s career (though there are hints of Doc’s
            past research training in the lab including old mouse bone preps and hints from old sea
            turtle datasheets.
          </Typography>
          <div className=""><img className="rounded-2xl mx-auto" src={DocEhr1} alt="" title="" /></div>
          <div className="pt-2">
            <em>
              UCF Marine Turtle Research Group’s founder, Lew “Doc” Ehrhart with a juvenile green
              turtle. Activity authorized under FWC MTP-231 and NMFS 19508. Photo: K. Mansfield
            </em>
          </div>

          <div className="my-5">
            <img className="rounded-2xl mb-2 mx-auto" src={Greennest} alt="" title="" />
            <em>
              A green sea turtle nests in the Archie Carr National Wildlife Refuge. Florida’s
              Brevard County is one of the busiest sea turtle nesting beaches in the world. Activity
              authorized under FWC MTP-186. Photo: G. Stahelin
            </em>
          </div>
          <Typography paragraph>
            When I joined the UCF MTRG, I brought with me my research on the oceanic stage turtles.
            Combining this work with the long-term nesting beach and in-water programs established
            by Doc, the UCF MTRG is now able to tackle key conservation questions across the entire
            life cycle of sea turtles. With close to 40 years of research data from important
            nesting and coastal foraging habitats, including decades-worth of blood and tissue
            samples collected, we can start to answer questions about sea turtle health, population
            changes, and diet changes over time, as well as examine responses in all of these
            factors with changes in the environment. One of my PhD students, Chris Long, is studying
            the effects of Harmful Algal Blooms (HABs) in the IRL on green sea turtle catch rates
            and diet—green turtles are plant-eaters as adults, changing from a broad to specialized
            plant-based diet as juveniles in coastal habitats. Chris is documenting changes in green
            turtle diet before and after large-scale algal bloom events. Another PhD student,
            Gustavo Stahelin, is re-examining population genetics using new techniques to search for
            any changes in genetic make-up of adults and juvenile green turtles found at our study
            sites over the last couple of decades. Back in the 80s, there were only a handful of
            green turtles nesting in what is now the ACNWR. Every other season since 2013, we have
            had successive record-breaking green turtle nesting years with nest numbers that now
            exceed that of the loggerheads.
          </Typography>
          <Typography paragraph>
            Sea turtle research is, by necessity, “collaborative science” given the time scales
            associated with these animals’ long lives as well as the geographic scale across which
            an individual may be found during its life. Doc Ehrhart’s work has allowed me to build
            on the decades of data he collected, and to think about sea turtle research in new,
            creative ways, across all stages of a sea turtle’s life. We have exciting new
            collaborations with disease experts including Dr. Anna Savage, another junior faculty
            member in the UCF Biology Department who specializes in disease ecology. We are jointly
            working with or advising students who are working on the FP problem, blood health
            parameters across all life stages, as well as a PhD student, Katie Martin, who will be
            looking at gene expression and developmental immune questions across all life stages,
            from eggs to adults. Finally, with the addition of sea turtle diet expert Dr. Erin Seney
            to the lab in 2014 as an Assistant Research Scientist and faculty, we have active diet
            and novel microbiome projects resulting in award-winning research by undergraduate
            students working with Dr. Seney.
          </Typography>
          <Typography paragraph>
            A sea turtle generation roughly equals the span of one scientific career. This is why it
            is incredibly important to maintain and support long-term collection of data for sea
            turtles and other long-lived, late maturing species. Sea turtles are incredibly lucky
            that UCF had the foresight to continue Doc’s legacy and hire a successor to take the UCF
            MTRG into the next 30+ years and provide continuity to the research program started all
            those decades ago. I am incredibly lucky to have been hired as that successor. It is
            gratifying to know that data from this project helps to directly inform management
            agencies who in turn create policy to manage imperiled sea turtle species. More
            importantly, this lab has a strong legacy of providing hands-on research experience to
            early career scientists, including undergraduates and graduate students. Many of the
            alumni from this lab are now among the top sea turtle researchers or managers in this
            field. It is easy to understand how students are hooked by sea turtles. One of my
            favorite things is to observe our new undergraduate summer interns each May as they
            encounter and get data from their first nesting turtles on the ACNWR. And later as they
            see their first hatchlings. By the end of the summer, these interns are like a NASCAR
            pit crew, jumping in to take measurements, biopsies, and tag nesting females before
            moving on to the next turtle, and the next, and the next... The confidence they get from
            the program, as well as the experience of the ACNWR nesting beach and the joy of working
            with such ancient and interesting creatures is lasting and a pretty phenomenal legacy to
            instill.
          </Typography>
        </div>
      </div>
    </div>
  );
}
