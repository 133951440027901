import React from "react";
import { Typography, Divider } from "@mui/material";
import DrMSalmon from '../Images/Dr-Mike-Salmon.jpg'

export default function MikeSalmon() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className="pb-5"><h1 className='text-2xl font-bold'>FAU’s Dr. Mike Salmon Joins the National Save The Sea Turtle Foundation’s Research Team</h1></div></div>
      <div><Typography paragraph>Larry Wood, Ph.D.</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className='lg:float-right lg:ml-5 my-5'><img className="rounded-2xl" src={DrMSalmon} alt="" title="" /></div>
      <div className="pt-5"><Typography paragraph>We are pleased to announce that our long-time colleague and friend Dr. Mike Salmon will be joining the NSTSTF team as our new Research Professor. Mike has had a long and distinguished career in marine biology, and will complement the Foundation’s research efforts as he continues to pursue his interests in sea turtle research and public education.</Typography></div>
      <div><Typography paragraph>I first came to know Mike as a valued mentor when I began my work with sea turtles in the 1990’s, then again when I became a student at Florida Atlantic University. As I made my way through FAU’s graduate programs, I would turn to Mike for that extra help and encouragement when things weren’t going according to plan; as most biology grad students know there are times when you kind of get stuck in some aspect of your research, and not sure how to move forward. Mike was always there to calm me down, refer me to some new references, and give that spark of hope that there was a way to get back on track!</Typography></div>
      <div><Typography paragraph>Mike is a pioneer of sea turtle biology, and one of the foremost experts in the world on the behavior of hatchling sea turtles. Among other things, he has extensively studied how hatchlings orient themselves to the sea after emerging from their nests, and how they navigate their way, both directionally and energetically, from the beach to the offshore environment. The results of his work have aided enormously in the conservation and management of sea turtles along developed coastlines, and have no doubt saved untold numbers of hatchlings that otherwise would never have found the sea.</Typography></div>
      <div><Typography paragraph>Over his career, he has advised and mentored dozens of graduate students, some of which are among the brightest minds working today in sea turtle biology. His partnership (and marriage) with Dr. Jeanette Wyneken has resulted in the establishment of a world-class marine turtle program at Florida Atlantic University, which remains a leader in the advancement of our understanding of these species. Mike’s remarkable career and contributions were recognized by the International Sea Turtle Society with a Lifetime Achievement Award at the 39th Annual Sea Turtle Symposium last year in Charleston, SC.</Typography></div>
      <div><Typography paragraph>I’m excited and honored to have Dr. Salmon working with us, and I look forward to his aid in expanding the scope and impact of the Foundation’s research goals. Don’t tell him, but I’m gonna get him hooked on hawksbills... :-)</Typography></div>
      <div><Typography paragraph>Welcome Aboard Mike!</Typography></div>
    </div>
  );
}
