import React from "react";
import { Typography, Divider } from "@mui/material";
import HawksB from "../Images/Hawksbill-B.jpg";
import HBM from "../Images/HB-M.png";

export default function Hawksbill() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex text-center">
        <div className="w-1/2">
          <Typography className="">
            <h1 className="text-2xl font-bold">Hawksbill Sea Turtles Fast Facts</h1>
          </Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Type: Reptile</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Diet: Carnivore</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Average life span in the wild: 30 to 50 years (est.)</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Size: 24 to 45 in (62.5 to 114 cm)</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Weight: 100 to 150 lbs (45 to 68 kg)</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Protection status: Endangered</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <div className="flex justify-center">
            <div>
              <Typography className="">Size relative to a 6-ft (2-m) man:</Typography>
            </div>
            <div>
              <img className="rounded-2xl" src={HBM} alt="" title="" />
            </div>
          </div>
        </div>
        <div className="w-1/2 flex justify-center">
          <img className="rounded-2xl" src={HawksB} alt="" title="" />
        </div>
      </div>
      <div>
        <Typography paragraph>Did you know? </Typography>
        <Typography paragraph>
          Young hawksbill turtles are unable to dive deep and spend their early years floating
          amongst sea plants near the water’s surface.
        </Typography>
        <Typography paragraph>
          Hawksbill turtles are found throughout the tropical waters of the Atlantic, Pacific, and
          Indian Oceans. They avoid deep waters, preferring coastlines where sponges are abundant
          and sandy nesting sites are within reach.
        </Typography>
        <Typography paragraph>
          Not particularly large compared with other sea turtles, hawksbills grow up to about 45
          inches (114 centimeters) in shell length and 150 pounds (68 kilograms) in weight. While
          young, their carapace, or upper shell, is heart-shaped, and as they mature it elongates.
          Their strikingly colored carapace is serrated and has overlapping scutes, or thick bony
          plates. Their tapered heads end in a sharp point resembling a bird’s beak, hence their
          name. A further distinctive feature is a pair of claws adorning each flipper. Male
          hawksbills have longer claws, thicker tails, and somewhat brighter coloring than females.
        </Typography>
        <Typography paragraph>
          They are normally found near reefs rich in the sponges they like to feed on. Hawksbills
          are omnivorous and will also eat mollusks, marine algae, crustaceans, sea urchins, fish,
          and jellyfish. Their hard shells protect them from many predators, but they still fall
          prey to large fish, sharks, crocodiles, octopuses, and humans.
        </Typography>
        <Typography paragraph>
          Like other sea turtles, hawksbills make incredible migrations in order to move from
          feeding sites to nesting grounds, normally on tropical beaches. Mating occurs every two to
          three years and normally takes place in shallow waters close to the shore. The nesting
          procedure begins when the turtles leave the sea to choose an area to lay their eggs. A pit
          is dug in the sand, filled with eggs, and then covered. At this stage the turtles retreat
          to the sea, leaving the eggs, which will hatch in about 60 days. The most dangerous time
          of their lives comes when hatchlings make the journey from their nests to the sea. Crabs
          and flocks of gulls voraciously prey on the young turtles during this short scamper.
        </Typography>
        <Typography paragraph>
          Like many sea turtles, hawksbills are a critically endangered species due mostly to human
          impact. Hawksbill eggs are still eaten around the world despite the turtle’s international
          protected status, and they are often killed for their flesh and their stunning shells.
          These graceful sea turtles are also threatened by accidental capture in fishing nets.{" "}
        </Typography>
      </div>
    </div>
  );
}
