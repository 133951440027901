import React from "react";
import { Typography, Divider } from "@mui/material";
import logoFinal from '../Images/National-Save-The-Sea-Turtle-Foundation-logo-Final.png'
import CoastalCleanup from '../Images/Coastal-Cleanup.jpg'
import CoastalCleanup2 from '../Images/waterway-cleanup-2.jpg'

export default function CostalCleanup() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className='pb-5'><h1 className='text-2xl font-bold'>National Save The Sea Turtle Foundation’s Coastal Cleanup Program Still Afloat
      </h1></div></div>
      <div><Typography paragraph>Larry Wood, Ph.D., NSTSTF Research Coordinator</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className='lg:float-right lg:ml-5 mt-5'><img className="mx-auto" src={logoFinal} alt="" title="" /></div>
      <div className="pt-5"><Typography paragraph>Over three decades ago, the founding members of the Foundation’s Board of Directors became very concerned with the seemingly ever-degrading condition of the natural environment in their beloved Florida Keys. They realized that they could make a real difference with tools at their disposal, and developed the idea into one of the most successful non-profit sea turtle-related conservation organizations in the world. Since then, the Foundation has maintained a number of donated boats for use in coastal cleanup projects from Broward County all the way through Key West. One of the Foundation’s primary goals is to make the best use of the donated vessels while they are temporarily owned by the Foundation. Currently, the Foundation maintains two research vessels, the Hawksbill I and Hawksbill II that are used for various projects that range from the Florida Hawksbill Project to FWC’s nesting surveys in the Marquesas Keys to hosting university-based game-fish research. Due primarily to covid-related restrictions last year, however, most of the hosted research projects were cancelled, so the National Save The Sea Turtle Foundation’s Marine Operations Manager, Captain Mike Osborne, and his associate Captain Troy Phillips doubled-up on their efforts to collect trash and marine debris from the waters around Key West.</Typography></div>
      <div><Typography paragraph>The stuff they’ve found even within a short ride from the dock is simultaneously amazing and disheartening. They’ve collected tons (literally) of debris ranging from toothbrushes to lawn chairs, untold yards (probably miles) of fishing lines and ropes of all gauges and materials, and even helped a small Kemp’s Ridley turtle free itself from a big, tangled, garbage-filled drift net out in deep water. The hardest part of the job is sorting and disposing of the debris they bring back, but nonetheless Captains Mike and Troy separate out the recyclables, load them into their pickups and make sure the non-recyclables are disposed of properly. It is amazing how much difference a few hours can make, and we encourage all vessel operators to boat responsibly and do their part in the larger effort to keep Florida’s waterways clean. Photo on right: Captain Troy (left) and Captain Mike aboard the Hawksbill II with s small sample of the debris they’ve collected</Typography></div>
      <div className="lg:flex lg:gap-5">
        <div className="lg:w-1/2 mt-5"><img className="rounded-2xl" src={CoastalCleanup} alt="" title="" /></div>
        <div className="lg:w-1/2 mt-5"><img className="rounded-2xl" src={CoastalCleanup2} alt="" title="" /></div>
      </div>
    </div>
  );
}
