import React from "react";
import { Typography, Divider } from "@mui/material";
import UVIp from "../Images/University-of-the-Virgin-Islands.jpg";
import JessicaMichael from "../Images/Jessica-Michael.jpg";
import PaulJobsis from "../Images/Paul-Jobsis.jpg";
import MakaylaKelso from "../Images/Makayla-Kelso.jpg";
import KatieAyres from "../Images/Katie-Ayres.jpg";

export default function UVIpartnership() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex">
        <div>
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              National Save The Sea Turtle Foundation Partners with the University of the Virgin
              Islands to Study Hawksbill Turtles
            </h1>
          </div>
          <Typography paragraph>Larry Wood, Ph.D.</Typography>
        </div>
        <div className=" p-2">
          <img className="lg:float-right rounded-2xl mx-auto" src={UVIp} alt="" title="" />
        </div>
      </div>
      <div className="my-5">
        <div>
          <Divider className='dark:bg-[#032546] bg-white/70 clear-both' />
        </div>
      </div>
      <Typography paragraph>
        For a number of years now, the National Save The Sea Turtle Foundation has supported a
        leatherback sea turtle research and conservation program at Sandy Point National Wildlife
        Refuge on the beautiful island of St. Croix, USVI. This Refuge was created to protect the
        large leatherback population that uses their beaches to nest. On one of our recent visits,
        we were lucky enough to observe a hawksbill turtle nesting as well, which led to a
        discussion of how we may also be able to help that species in the Refuge, and by extension,
        in the whole USVI. Turns out, the Virgin Islands are a great place to study and protect
        hawksbills, so we’ve decided to develop an Action Plan to determine the current status of
        hawksbills in the Islands and create a roadmap for future studies and conservation programs.
      </Typography>
      <Typography paragraph>
        While we’re developing the Action Plan, we’ve created an associated scholarship program for
        graduate students at the University of the Virgin Islands who plan to focus on hawksbill
        turtles for their theses. Under the direction of Dr. Paul Jobsis, these students will have
        the opportunity to work with experts to begin our journey toward the full recovery of this
        species in these historically rich foraging and nesting grounds.
      </Typography>
      <Typography paragraph>
        This year’s scholarship recipients are Jessica Michael, Makayla Kelso, and Katie Ayres, who
        will be focusing on hawksbill genetics and population densities on St. Croix and St. John.
        We wish them luck!
      </Typography>
      <div className="lg:float-left">
        <img className="rounded-2xl lg:mr-5 mx-auto mb-5" src={JessicaMichael} alt="" title="" />
      </div>
      <Typography paragraph>
        My name is Jessica Michael and I am a Masters student at the University of the Virgin
        Islands. I completed my undergraduate degree in biology with a focus on Caribbean ecology at
        Hollins University in Virginia. During my time at Hollins I participated in two research
        trips to St. John, US Virgin Islands where I studied tropical fish biodiversity and found my
        passion for exploring marine life. The summer between the two trips, I lived at the research
        field station for two months and taught marine biology to summer camp students. After
        graduation, I decided to pursue my love of sea turtles by interning with US Fish and
        Wildlife in Georgia on Blackbeard Island where I did nesting beach work. From there I
        decided to apply to the University of the Virgin Islands (UVI) in order to work more closely
        with sea turtles. Working with the turtle program at UVI has allowed me to be very involved
        in the in-water research on green and hawksbill sea turtles in Brewers Bay.
      </Typography>
      <Typography paragraph>
        I am currently working with Dr. Paul Jobsis and the sea turtle program at UVI to complete my
        Masters thesis on the relative abundance of green and hawksbill sea turtles in the US Virgin
        Islands. We have completed turtle surveys in thirteen locations around St. Thomas and St.
        John. We have gathered some interesting data that contradicts anecdotal accounts and appears
        to show the effects of legal harvest of sea turtles in the British Virgin Islands. My
        abstract was accepted to the International Sea Turtle Symposium in Cartagena which was
        unfortunately postponed. I hope to present at the rescheduled Symposium in 2021. As I
        continue my studies, my goal is to be able to continue researching sea turtles, share my
        passion for these animals, and educate people on the importance of conservation.
      </Typography>
      <div className="lg:float-right lg:ml-5 mb-5"><img className="mx-auto rounded-2xl " src={PaulJobsis} alt="" title="" /></div>
      <Typography paragraph>
        Paul Jobsis did not always dream of being a sea turtle researcher but knew that he was most
        happy when in or near the ocean. So after studying engineering and working in manufacturing
        for he returned to college to study marine biology at the University of the North Carolina
        Wilmington. After finishing his studies in Wilmington in 1988, he traveled across the
        country to work as a laboratory technician for Dr. Gerald Kooyman at Scripps Institution of
        Oceanography. This work was ideal for him as he could use his marine biology degree and his
        engineering expereince to help with Dr. Kooyman’s diving physiology and behavior research
        first as a technician and later a graduate student. His work on tissue oxigenation during
        diving/breath-holding in harbor seals is still cited today. Following a teaching position in
        Iowa and a post-doctoral position at the National Institutes of Health in Maryland, Dr.
        Jobsis felt the need to return to the ocean and follow the path of marine science researcher
        and professor at the University of the Virgin Islands. Being aware of the research of his
        friends and colleagues Scott and Karen Echart on St Croix, as well as Robert van Dam and
        Carlos Diez in Puerto Rico he hoped to pursue a research career on sea turtles and other
        large marine vertebrates.
      </Typography>
      <Typography paragraph>
        His arrival at UVI coincided with the start of the masters in Marine and Environmental
        Science program which was extremely fortuitous. Quickly Dr. Jobsis found himself with
        excellent graduate students and very motivated undergraduates who wanted to study sea
        turtles with him. This lead to research collaborations with local and international experts
        on critical nesting parameters, habitat selection, movement ecology, and genetic
        determination of sea turtle origins. Located on Brewer Bay St Thomas, the University is an
        excellent location to study sea turtles, for not only is it near to known research sites on
        St Croix and Puerto Rico, but the bay itself is a foraging ground for juvenile green and
        hawksbill sea turtles. In the past six years, Dr. Jobsis and his students have tagged 160
        juvenile turtles in Brewers Bay alone. Many have been tracked with acoustic tags, which as
        shown the extremely high residency of hawksbills and greens in the bay despite the effects
        of major hurricanes. His research focus now is to better understand the factors that attract
        significant numbers of juvenile hawksbills to an artificial reef created by the extension of
        the St Thomas airport runway, and the effects of invasive seagrasses on the diet and
        behavior of juvenile green sea turtles. The in-water research is understandably his passion,
        but he wants to integrate research on sea turtle nesting, space use, behavior, biogeography,
        genetics, and physiology to better inform the management of these endangered animals.
      </Typography>
      <div className="lg:float-right">
        <img className=" rounded-2xl lg:ml-5 mb-5 mx-auto" src={MakaylaKelso} alt="" title="" />
      </div>
      <Typography paragraph>
        Hello, I’m Makayla Kelso! I’m originally from Texas, and attended Texas A&M University at
        Galveston where I completed a double degree program in Marine Biology and Marine Fisheries
        in 2017. During my undergraduate career I took every opening I could find to be involved in
        a diverse array of marine biology related research topics and hands-on field experiences,
        and it was there that I determined I wanted to pursue a career in scientific research!
        Following my graduation, I wanted to spend some time gaining more field experience before
        deciding on a path to graduate school. In 2018 I was invited to participate in a volunteer
        research assistantship in St. Croix, USVI working on the Leatherback Monitoring Project at
        the Sandy Point National Wildlife Refuge. I spent the summer working alongside Dr. Kelly
        Stewart (The Ocean Foundation) and her colleagues at NOAA and U.S. Fish and Wildlife to
        collect nesting activity data for leatherback, green, and hawksbill sea turtles at Sandy
        Point, and I absolutely fell in love with the work being done there!
      </Typography>
      <Typography paragraph>
        Upon leaving St. Croix at the end of the summer, I accepted a two-year employment offer as a
        contractor for USGS in Ohio, where I worked on a team focused on identifying and monitoring
        spawning grounds for the invasive Grass Carp in Lake Erie and its tributaries. While I
        thoroughly enjoyed my time there and gained some invaluable fisheries management experience,
        my heart was still in the Caribbean. As my term there came to an end, and I began my search
        for a graduate program, I decided to pursue programs that would allow me to conduct research
        related to sea turtles, and that led me to apply to the University of the Virgin Islands in
        St. Thomas, USVI.
      </Typography>
      <Typography paragraph>
        The generous scholarship awarded to me by the National Save The Sea Turtle Foundation will
        support my pursuit of a Masters in Marine and Environmental Science at UVI, where I will be
        working under the mentorship of Dr. Paul Jobsis and Dr. Kelly Stewart on a project focusing
        on nesting activities of hawksbill sea turtles in the U.S. Virgin Islands. Through this
        study, I hope to use historical nesting data and current data being collected to analyze the
        changes in hawksbill nesting in the USVI over time. This information is crucial to hawksbill
        conservation, and to efforts to establish the USVI as a priority for future protective
        measures and continued monitoring. This important work would not be possible without the
        support of the NSTSTF, and I am so grateful and excited to have the chance to contribute to
        the conservation efforts of these incredible creatures!
      </Typography>
      <div className="lg:float-left">
        <img className=" rounded-2xl mx-auto lg:mr-5 mb-5" src={KatieAyres} alt="" title="" />
      </div>
      <Typography paragraph>
        My name is Katie Ayres. I am originally from Washington State, where I received my degree in
        Environmental Science with a focus on Marine Ecology. At Western Washington University I
        studied rocky intertidal ecosystems, but I decided to broaden my marine knowledge to include
        tropical ecosystems. This gave me the opportunity to learn more about my favorite marine
        organism, the sea turtle. Much like how the sea turtle travels thousands of miles to find
        the best feeding grounds, I have traveled over 3,000 miles to discover more about Hawksbill
        sea turtles and their marine environment. The first stop on my journey was an internship at
        the NOAA Regional Office in St. Petersburg, Florida. There, I organized NOAA’s Sea Turtle
        Stranding data base and I discovered the many threats that sea turtle species are faced with
        every day. These include boat strikes, entanglement in fishing gear, and plastic ingestion.
        While reading through this data, I became curious as to what happened to these turtles after
        they were rescued. This curiosity led me to an internship at the Gumbo Limbo Nature Centers
        Sea Turtle Rehabilitation Facility. I spent five months at Gumbo Limbo learning about the
        rescue and rehabilitation process. One of the common themes that we saw throughout the
        rehabilitation process was plastic. Plastic is not only entangling sea turtles but is also
        being found in the digestion tracts of turtles of all ages, including hatchlings. Plastic
        ingestion causes many hatchlings to become lethargic and wash back up onto nesting beaches.
        We cared for hundreds of hatchlings that were washed back into shore, many of them suffering
        from microplastic ingestion. Seeing this issue first hand inspired me to do more educational
        outreach while I continued to learn about different sea turtle species.
      </Typography>
      <Typography paragraph>
        To gain more educational outreach experience, I traveled to Jekyll Island, GA, to become an
        AmeriCorps member at the Georgia Sea Turtle Center. For five months I was able to interact
        with the public while observing sea turtle behaviors during night tagging activities,
        actively protecting sea turtle nests by screening the nests from predators, and excavating
        nests to determine hatch success and related nesting data. It was very rewarding to be able
        to share the nesting process with community members that had never seen a sea turtle before.
      </Typography>
      <Typography>
        The excitement that I feel when seeing a turtle is one that I hope to continue to be able to
        share with others in the future. The survival rate of a hatchling is very low, possibly less
        than 1 in 1,000. Human interactions with sea turtles and the environment can cause this
        number to either increase or decrease. I hope to create better resource management practices
        in the future to increase their odds and ensure the survival of all turtle species
        populations. In order to achieve my goals, the next stop on my journey is the University of
        the Virgin Islands to earn my Masters in Marine and Environmental Science, where I will be
        studying hawksbills and gaining the skills needed to become a successful marine ecologist.
      </Typography>
    </div>
  );
}
