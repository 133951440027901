import React from 'react'
import { Divider, Typography } from '@mui/material';
import UCF from '../Images/UCF-Marine-Turtle-Research-Group.jpg'
import UCFBoat from '../Images/new-boat-UCF.jpg'

export default function FloridaSeaTurtlesGetaBoost() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className='lg:float-right mb-5 ml-5'><img src={UCF} alt='' title='' className='rounded-2xl' /></div>
      <div><div className='pb-5'><h1 className='text-2xl font-bold'>Florida Sea Turtles Get a Boost with Gift of New Boat</h1></div></div>
      <div><h2 className='text-xl'>The Marine Turtle Research Group has received a much-needed new boat that offers improved safety and research capabilities</h2></div>
      <div className='py-5'><Typography>By Kyle Martin | February 25, 2022 - UCF Today</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className='mt-5'><Typography paragraph>Thanks to a trio of donors with a passion for supporting the state’s marine life , the new boat debuting this spring replaces a 1976 craft reaching the end of its lifespan. “I always joke our bimonthly team building exercise is fixing something on the boats,” says Kate Mansfield, an associate professor and director of the MTRG.</Typography></div>
      <div><Typography paragraph>Researchers will now be using a new and specially outfitted 17-foot Boston Whaler 170 Guardian for their work. The craft was provided by donations from the Folke H. Peterson Foundation and National Save The Sea Turtle Foundation, and made possible with a purchase discount from Brunswick Commercial & Government Products, the parent company of Boston Whaler.</Typography></div>
      <div><Typography paragraph>“Boston Whaler and the Brunswick Commercial & Government Products Group supports environmental resource management and research efforts around the world,” says Kris Neff, president of Boston Whaler. “Our boats are ideally designed to handle the rigors and long-term serviceability of demanding applications, so we are confident that we are helping make a difference. Supporting the University of Central Florida and the Marine Turtle Research Group is particularly satisfying because we are a Florida-based company, and because conserving sea life directly aligns with our customers interests as boaters.”</Typography></div>
      <div><Typography paragraph>The MTRG is respected by scientists around the globe for its work unraveling sea turtle mysteries. Their legacy includes contributing to the establishment of the Archie Carr National Wildlife Refuge — a critical pocket of protected shoreline for annual nest sites. In 2021, they were the first to track young green sea turtles to the Sargasso Sea during their “lost years.”</Typography></div>
      <div><Typography paragraph>Some of their research happens on shore, but much more comes from interacting with sea turtles on the water. Strict protocols allow them to safely net the turtles without endangering other marine life in the Indian River Lagoon, particularly dolphins and manatees. Deploying the net requires a careful choreography of boats, a procedure that quickly falls apart when an engine fails. “I need to know that the boat is safe and functional,” Mansfield says. “If we’re getting knocked around by the wind and the engine conks out, it’s dangerous for us and the animals.”</Typography></div>
      <div><Typography paragraph>When MTRG began working with Boston Whaler to order a new boat they had several specific requests, including stripping all the creature comforts, like coolers. With limited room aboard and multiple people handling different roles, it’s critical the net doesn’t snag on anything, Mansfield says. Another customization that sets this new craft apart is the additional balancing and buoyancy. Full-grown turtles weigh hundreds of pounds and hauling them, and even smaller turtles, aboard takes coordination. A heavy soggy net on the bow can also skew the balance.</Typography></div>
      <div><Typography paragraph>Mansfield says the new boat offers foremost increased efficiency and safety for the MTRG research team, but it’s also a win for turtle research. “MTRG has been providing critical insights into the habits and behaviors of sea turtles for decades, and we still have so much more to learn,” said Larry Wood, research coordinator for the National Save the Sea Turtle Foundation. “We are excited to partner with them and play a part in furthering their research.”</Typography></div>
      <div><Typography paragraph>“I know this one is going to hold up under the stress. It essentially ensures our long-term projects continue,” Mansfield says.</Typography></div>
      <div><Typography paragraph>Reprinted with permission: University of Central Florida News | UCF Today UCF NEWS | STORIES OF IMPACT + INNOVATION | ORLANDO, FL</Typography></div>

      <div>
        <div><img src={UCFBoat} alt='' title='' className='rounded-2xl' /></div>
        <div className='pt-2'><em>Kate Mansfield and other researchers on the new boat UCF’s Marine Turtle Research Group will use to conduct research. (Photo by Nick Leyva ’15)</em></div>
      </div>
    </div>
  )
}
