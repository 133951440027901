import React from "react";
import { Divider, Typography } from "@mui/material";
import FAUbehav from "../Images/FAU-Animal-Behavior-and-Visual-Perception.jpg";
import Headon from "../Images/A-head-on-view-of-a-juvenile-green-turtle.jpg";
import Wavelength from "../Images/transmission-of-light-wavelengths.jpg";
import Sensivtive from "../Images/Sensitivity-to-the-spectrum-of-light-wavelengths.jpg";
import EyeLens from "../Images/sea-turtle-eye-showing-its-lens.jpg";
import FeedOpen from "../Images/Animals-that-feed-in-open.jpg";
import WaterFilled from "../Images/water-filled-y-maze-used-to-train-loggerheads.jpg";
import Juvenile from "../Images/A-juvenile-leatherback-photographed-as-it-dives-down-to-attack-a-jellyfish.jpg";
import Retinal from "../Images/Retinal-anatomy-of-hatchling-loggerhead-leatherback-green-turtle.jpg";

export default function AnimalBehaviorandVisualPerception() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex mb-2">
        <div className="lg:w-3/4 ">
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              Animal Behavior and Visual Perception: <br />
              How Does Sea Turtle Vision Differ from Ours?
            </h1>
          </div>
          <Typography paragraph>
            Mike Salmon, Ph.D. <br />
            Research Professor, Department of Biological Sciences <br />
            Florida Atlantic University <br />
          </Typography>
        </div>
        <div className="lg:w-1/4 lg:float-right">
          <img className="mx-auto border- border-white rounded-2xl" src={FAUbehav} alt="" title="" />
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="lg:flex my-5">
        <div>
          <Typography paragraph>
            I entered graduate school in zoology without really knowing what area in that enormous
            field was of greatest interest. I did know that spending time around marine habitats,
            head on view of a juvenile green turtle be they marshes,
            <div className="lg:float-left mt-5 lg:mr-5">
              <div><img className="mx-auto rounded-2xl" src={Headon} alt="" title="" /></div>
              <div className="pt-2">
                <em>
                  Figure 1. A head-on view of a juvenile <br /> green turtle, showing its large eyes.
                </em>
              </div>
            </div>
            bays or the open ocean, was where I was happiest, especially when observing the amazing
            activities of their inhabitants. All that uncertainty disappeared two weeks after I
            enrolled in an Animal Behavior class taught by the professor who ultimately guided my
            studies toward an advanced degree. His class was an introduction to the entire field and
            covered a wide range of topics in two general areas: (i) what we knew (at the time)
            about what animals did and why they did it, and (ii) how to design proper experiments to
            find out more. I was soon totally “hooked”!
          </Typography>
          <Typography paragraph>
            I was particularly interested in studies showing that animals differed in how they
            perceived their world. Those differences were caused by the unique properties of their
            sense organs (eyes, ears, touch, smell, etc.) used to inform them about their
            environment. Those aspects sometimes included information that we can’t detect, such as
            the earth’s magnetic field or high-frequency “ultrasound”. Collectively, those
            discoveries led to the realization that how we view the world is unique to us, but not a
            “better” version than one possessed by another species. The common denominator is that
            each complement of sense organs provides a perceptual “window” into the external world.
            That window is modified through evolution to detect what each species needs to know. A
            proper understanding of that perceptual world requires knowing not only where each
            species lives (on land or under water), but also what it needs to know to survive in a
            hostile world.
          </Typography>
          <div>
            <div>
              <div className="lg:float-left"><img className="rounded-2xl mx-auto lg:mr-5" src={Wavelength} alt="" title="" /></div>
              <div>
                <div className="pt-2">
                  <em>
                    Figure 3. The transmission of light wavelengths through clear seawater varies with
                    br depth. Near the surface , most of the light wavelengths present in air are also
                    found under water. But, with an increase in depth, wavelengths shorter or longer
                    than 475 nm rapidly disappear.
                  </em>
                </div>
              </div>
            </div>


            <div className="lg:w-1/2">

            </div>
            <div className="lg:w-1/2 mt-10">

            </div>
          </div>
          <div></div>

          <Typography paragraph>
            Sensitivity measurements (1) have confirmed these relationships (Figure 4). All species
            of marine turtles are sensitive to most of the light present near the ocean surface.
            <div className="lg:mr-5 mt-5">
              <img className="rounded-2xl" src={Sensivtive} alt="" title="" />
            </div>
            <div className="pt-2">
              <em>
                Figure 4. Sensitivity to the spectrum of light wavelengths varies among species of
                marine turtles and is correlated to the depth where they spend the most time. Green
                turtles that feed in shallow water are most uniformly sensitive to the wavelengths
                present there. Leatherbacks feed in deep water and are most sensitive to bluish
                light near 475 nm that penetrates deepest. Such “tuned” sensitivity helps them see
                objects at those locations. Loggerheads show light sensitivities roughly
                intermediate between those two patterns
              </em>
            </div>
            <div className="mt-5">
              <img className="rounded-2xl mx-auto" src={Retinal} alt="" title="" />
            </div>
            <div className="pt-2">
              <em>
                Figure 6. Retinal anatomy of hatchling loggerhead (left),
                <br /> leatherback (middle) and green turtle (right). Yellow, red <br /> and black
                regions show low, medium, and high <br /> concentrations of visual analyzers,
                respectively, at those locations.
              </em>
            </div>
          </Typography>
          <Typography paragraph>
            On the other hand, there is a relatively simple and easily understood relationship
            between the concentration of these analyzers on the retinal surface and the visual
            ecology of animals living in different places. Animals found in open areas with a clear
            view of the horizon are most interested in visual events that take place on that horizon
            (for example, the approach of a predator). For that reason, they concentrate their
            retinal analyzers in a narrow, horizontal “visual streak” that parallels that location
            (Figure 5). Visual streaks are less prominent among animals that must maneuver through
            or around obstacles, such as trees in a forest or reefs under water. On the other hand,
            predators that detect discrete targets often concentrate their retinal analyzers in
            circular spots called “areas”. These areas are appropriately placed in the retina to
            register the location of those targets in space.
          </Typography>
          <Typography paragraph>
            One of our students (2) took on the very laborious task of identifying the concentration
            of those retinal analyzers in hatchling green turtles, loggerheads and leatherbacks.
            (These were all turtles that had recently died inside the nest so none were sacrificed.)
            The results were quite interesting! Green turtles and loggerheads had visual streaks but
            those streaks were especially narrow in green turtles (Figure 6) that feed in open
            seagrass meadows (Figure 5). The retinas of leatherback hatchlings contained both a
            visual streak and an area, with the latter located in the upper half of the retina
            toward the rear of its concavity. That area would focus on targets located in the
            opposite direction, that is, ahead and below the turtle. Leatherbacks sometimes hunt for
            jellyfish while swimming near the surface. When they see one beneath and ahead of them,
            they dive down to consume it (Figure 7). That’s why our student called the leatherback
            area a “jellyfish detector”.
          </Typography>
          <Typography paragraph>Do Sea Turtles have Color Vision?</Typography>
          <Typography paragraph>
            Color vision is characteristic of many, but not all, day-active animals. It plays an
            important role in recognizing mates, in finding suitable food, and in detecting enemies.
            Because color vision requires a lot of additional neural machinery, it doesn’t always
            evolve because there is another, simpler alternative.
          </Typography>
          <Typography paragraph>
            Objects can also be distinguished on the basis of the intensity of light reflected from
            their surface; objects then appear white, black, or show different shades of grey
            between those extremes (think black & white movies!). Thus, to demonstrate color vision
            requires experiments that eliminate the possibility that objects might be distinguished
            on the basis of light intensity cues. If that’s done and the objects are still
            distinguished, then the only alternative is that color vision is used.
          </Typography>
          <Typography paragraph>
            With these generalizations in mind, let’s explore how sea turtles use one sense organ,
            their eyes (Figures 1 and 2), to better understand why their visual world differs from
            ours. I’m proud to say that much of this information has been recently provided by
            biology graduate students at Florida Atlantic University. Their names and thesis titles
            are cited at the end of this article.
          </Typography>
          <Typography paragraph>Oceanic Light Transmission and Spectral Sensitivity</Typography>
          <Typography paragraph>
            Light consists of a range of wavelengths transmitted from the sun to the earth’s
            atmosphere that penetrate the sea as downwelling energies. The eyes of oceanic animals
            like marine turtles function in an environment where the available light not only
            fluctuates between night and day, but also with depth (Figure 3). Blue light at ~ 475 nm
            (nanometers, or 10-9 m) is transmitted best through clear seawater while with depth,
            light wavelengths shorter or longer gradually disappear. Sea turtles usually search for
            food visually during the daylight hours, but different species most commonly feed at
            different depths. Green turtles, for example, are herbivores that feed on algae and
            seagrasses.
          </Typography>
        </div>
        <div >
          <Typography paragraph>
            Those plants must live in shallow water where they are exposed to adequate levels of
            sunlight. Loggerheads, in contrast, hunt along the bottom for their hard- shelled prey
            (bivalves, crabs, conches) in coastal waters at depths that average 30 m while
            leatherbacks feed on jellyfishes and other “gelatinous” creatures in open water,
            sometimes near the surface but often at depths that can exceed hundreds of meters. Since
            all marine turtles must spend some time near the surface (to breathe), one might expect
            that they should detect much of the broad spectrum of light wavelengths present there.
            But to the extent that they spend even more time in deeper water they need to be
            especially sensitive to the light wavelengths present there since those best reveal the
            location of food, shelter, mates, and sources of danger such as predators. Is that what
            they do?
          </Typography>
          <div className="m-5">
            <img className="rounded-2xl mx-auto" src={EyeLens} alt="" title="" />
          </div>
          <div className="text-center mb-5">
            <em>
              Figure 2. A sea turtle eye showing its lens which focuses the image on the retina
              (rear half of the eye surface, delineated by the dotted white lines). Notice that the
              image of the outside world (arrow) is inverted on the retina. That situation is
              corrected by the brain.
            </em>
          </div>
          <Typography paragraph>
            However, green turtles which spend the most time at shallow depths are the most
            uniformly sensitive to this entire spectrum. Loggerheads (feeding at intermediate
            depths) and leatherbacks (feeding at deeper depths) have eyes that are “tuned”, or
            especially sensitive, to a range of wavelengths centered around the light energies
            transmitted best to the depths where they live. That tuning, as one would predict, is
            most strongly expressed in the deepest diving species – the leatherback. Note, also,
            that all sea turtles are sensitive to light wavelengths than we can’t see, specifically
            UV light. We still do not understand how they use that capacity to their advantage.
          </Typography>
          <Typography paragraph>Retinal Specialization: Visual “Streaks” and “Areas” </Typography>
          <Typography paragraph>
            The vertebrate eye is sometimes called a “camera eye” since it possesses several similar
            components, specifically an iris opening (like an f-stop) that adjusts to different
            light levels, a lens that focuses the image, and a retina that (like a photosensitive
            screen) receives the image (Figure 2). But that’s where the similarities end! Notice
            that the image cast on the retina is upside down;
          </Typography>
          <div className="mt-5">
            <img className="rounded-2xl mx-auto" src={FeedOpen} alt="" title="" />
          </div>
          <div className="pt-2 pb-5">
            <em>
              Figure 5. Animals that feed in open, unobstructed areas (top) have retinas with
              horizontal “streaks”. The green turtle, swimming in the seagrass meadow below, is an
              example.
            </em>
          </div>
          <Typography paragraph>
            the brain comes to the rescue by making our visual world right-side up. Another
            important difference between eyes and cameras is in how the image is analyzed. The
            retina, unlike a photosensitive screen, does not preserve the image as a point by point
            representation; rather, it consists of millions of cells organized in clusters (let’s
            call them “analyzers”) with specialized functions. Some analyzers respond to shapes or
            edges, some to colors and movement, still others to movement speed or movement
            direction, while others to a sudden decrease in light intensity (as might occur if a
            large predator, casting a shadow, approached from overhead). These analyzers, then,
            reflect biologically significant visual events and, at the same time, provide the brain
            with version of the outside world that is far more abstract than a picture.
          </Typography>

          <Typography paragraph>
            One of our students took on the task of testing juvenile loggerheads to see if they had
            color vision (3). That task involved training the turtles to distinguish between three
            colored targets (blue, green and yellow) using a seawater-filled Y maze (Figure 8). The
            turtles had to swim toward a colored target at the end of an arm. A piece of shrimp was
            their reward for doing so but even though turtles love shrimp, learning that task took
            weeks. Several turtles never learned and flunked this class but six “high achiever”
            turtles graduated to the next level. Each of these turtles then had to learn to swim
            toward one target when simultaneously presented with a pair of colored targets, one of
            which had been previously rewarded. That took only a few days. Finally, and in the
            crucial test, each turtle had to choose the correct target from a pair when each
            target’s brightness varied from one trial to the next. All the turtles passed that test
            almost immediately, showing conclusively that targets were distinguished by their color.
            Bravo! Sea turtles have color vision!
          </Typography>
          <div className="text-center mb-5">
            <em>
              Figure 7. A juvenile leatherback photographed as it dives down to attack a jellyfish
              located below and ahead in the water column.
            </em>
          </div>
          <div className="m-5">
            <img className="rounded-2xl mx-auto" src={Juvenile} alt="" title="" />
          </div>
        </div>
      </div>
      <div className="m-5">
        <img className="rounded-2xl mx-auto" src={WaterFilled} alt="" title="" />
      </div>
      <div className="text-center">
        <em>
          Figure 8. The water-filled Y-maze used to train loggerheads to swim toward colored targets
          (in this instance, one yellow and another blue) projected at the end of each arm. This
          turtle has learned to ignore the yellow target and approach the blue target. A well
          trained turtle, like this one, even points toward the blue target while confined to the
          maze start area.
        </em>
      </div>
      <div className="m-5">
        <Typography paragraph>
          Theses:
          <strong>Theses:</strong>
        </Typography>
        <Typography paragraph>
          Judith Gocke (2011) Visual spectral sensitivity of loggerhead and leatherback hatchlings:
          a comparative study.
        </Typography>
        <Typography paragraph>
          Lisa Oliver (2010) Retinal anatomy of hatchling sea turtles: anatomical specializations
          and behavioral correlates.
        </Typography>
        <Typography paragraph>
          Morgan Young (2011) Visual wavelength discrimination by the loggerhead sea turtle.
        </Typography>
      </div>
    </div>
  );
}
