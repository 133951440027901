import React from "react";
import { Typography, Divider } from "@mui/material";
import UF1 from "../Images/UF.jpg";
import UFJessica from "../Images/Jessica-Farrell.jpg";
import UFWhitney from "../Images/The-Whitney-Labs-Sea-Turtle-Hospital.jpg";
import ElizabethK from "../Images/Elizabeth-Kaweesa.jpg";
import DorothyMitchell from "../Images/Dorothy-Mitchell.jpg";

export default function MeetStudents() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <div>
          <div className="lg:float-right mb-5"><img className=" rounded-2xl border-4 border-white mx-auto" src={UF1} alt="" title="" /></div>
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              Meet the Students:<br /> National Save The Sea Turtle Foundation Scholarship
              <br /> Recipients Chosen at the University of Florida
            </h1>
          </div>
          <Typography paragraph>
            David Duffy, Ph.D., Assistant Professor1,2 Sandra Loesgen, Ph.D., Associate Professor1,3
          </Typography>
          <Typography paragraph>
            1The Whitney Laboratory for Marine Bioscience and Sea Turtle Hospital, University of
            Florida, St. Augustine, Florida 32080, USA.<br />
            2Department of Biology, College of Liberal Arts and Sciences, University of Florida,
            Gainesville, Florida, 32611, USA.<br />
            3Department of Chemistry, College of Liberal Arts and Sciences, University of Florida,
            Gainesville, Florida, 32611, USA.<br />
          </Typography>
        </div>
      </div>
      <div className="my-5">
        <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      </div>
      <Typography paragraph>
        The Whitney Laboratory for Marine Bioscience and Sea Turtle Hospital
        (www.whitney.ufl.edu/conservation--sea-turtle-hospital) on Florida’s Northeastern Atlantic
        coast is a research institute of the University of Florida (UF). With support from the
        National Save The Sea Turtle Foundation’s (NSTSTF) Scholarship Fund, we are advancing our
        understanding of and ability to mitigate threats to marine turtles, primarily the sea turtle
        tumor disease fibropapillomatosis. Fibropapillomatosis is thought to be caused by a
        combination of viral infection and environmental degradation, and as such requires better
        understanding of the complex interplay between viral pathogens, the sea turtle immune system
        and environmental pollutants. NSTSTF has generously supported the research of three
        promising graduate students who are tackling various aspects of this devastating disease,
        Jessica Farrell, Elizabeth Kaweesa and Dorothy Mitchell.
      </Typography>
      <div className="my-5">
        <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      </div>
      <div className="lg:float-right lg:ml-5 mb-5"><img className="rounded-2xl mx-auto" src={UFJessica} alt="" title="" /></div>
      <Typography paragraph>
        Jessica A. Farrell (Duffy & Martindale Labs) Project: Genomic and Environmental FP drivers.
      </Typography>

      <Typography paragraph>
        My name is Jessica Farrell and I am a PhD candidate at the University of Florida’s (UF)
        Whitney Laboratory for Marine Bioscience and Sea Turtle Hospital. Originally from England,
        my dream has always been to study marine biology, and an Operation Wallacea research
        expedition to Indonesia in 2011 inspired me to go after my dream career in marine
        conservation. In 2016 I graduated with a BSc from the University of Sheffield, UK, with a
        new-found love for using virology and pathology to help protect endangered species, and in
        2017 I was lucky enough to intern with the University of Sydney and Sydney Institute of
        Marine Science on a “Marine Cloud Brightening” project to save the Great Barrier Reef,
        Australia. It is this combined love for marine conservation and the study of aquatic
        diseases that guided me to UF’s Whitney Lab and Sea Turtle Hospital, where I get to pursue
        both areas of biology when investigating the FP panzootic (animal pandemic) in Florida’s
        vulnerable green sea turtle populations.
      </Typography>
      <div className="lg:float-left lg:mr-5"><img className=" rounded-2xl" src={UFWhitney} alt="" title="" /></div>
      <Typography paragraph>
        The Whitney Lab’s Sea Turtle Hospital specializes in treating sea turtles afflicted with
        this debilitating tumor-causing disease and is therefore the perfect facility to use this
        unique opportunity to learn more about FP and how we can mitigate it, while sea turtles are
        cared for and rehabilitated by the highly experienced veterinary team. Fibropapillomatosis
        is a complex disease, involving not only the presence of a sea turtle specific herpesvirus,
        but the exposure to an environmental stressor. Juvenile green turtles are the species and
        life-stage most impacted by FP, and their unique habitat of shallow, human-proximate coastal
        regions highly suggests that the environmental stressors responsible for exacerbating the
        severity of this disease are due to human activities. As such, my PhD research, although
        focusing on FP, utilizes a variety of approaches and scientific disciplines to tackle this
        disease from as many angles as possible. I have profiled FP tumor growth and examined the
        effect of targeted drug treatments (human precision-medicine inspired) on slowing such
        growth (Farrell et al. 2021, Farrell et al. 2018, Yetsko et al. 2021). I’ve applied advanced
        microscopy to determine fine detail tissue and cellular information regarding the make-up of
        the tumors themselves and I have investigated the host and virus gene expression activity
        within tumors (Farrell et al. 2021; Yetsko et al. 2020). I have also used molecular biology
        approaches to compare the level of virus in different tumor types, as well as to develop a
        highly specific methodology (environmental [e]DNA) to detect and monitor several species of
        sea turtle and their FP-associated virus (ChHV5) from just sand and water samples (Farrell
        et al. 2021). In addition, as environmental stressors are a key piece to the FP-puzzle, I
        have examined plastic ingestion in Florida’s vulnerable post-hatchling “washback” turtles
        (Eastman et al. 2020), with shocking results (96% of turtles had plastics in their GI
        tract). This work would not be possible without the donation of such generous scholarships,
        like that from the National Save The Sea Turtle Foundation. Thank you so much for helping us
        to expand our knowledge on this devastating disease and helping us get closer day by day to
        helping conserve these beautiful marine creatures.
      </Typography>
      <div className="my-5">
        <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      </div>
      <div className="lg:float-right lg:ml-5 mb-5"><img className="rounded-2xl" src={ElizabethK} alt="" title="" /></div>
      <Typography paragraph>
        Elizabeth N. Kaweesa (Loesgen Lab) Project: Viral Drivers of FP and Anti-Cancer Drugs.
      </Typography>

      <Typography paragraph>
        Hello. My name is Elizabeth Kaweesa and I am a graduate student at the Whitney Lab for
        Marine Bioscience at the University of Florida. I joined Prof. Sandra Loesgen’s laboratory
        in 2015 with an interest in drug discovery. I employ cellular biology to better understand
        cancer, its progression, metabolism, and metastasis. This work helps us to develop
        anti-cancer drugs to fight these malignant diseases.
      </Typography>

      <Typography paragraph>
        I grew up in a fishing village on the shores of Lake Victoria in Uganda, Africa. The
        delightful tropical weather and thick rainforest gave me a deep appreciation for the
        environment and wildlife. From early on, my family instilled in me the importance of
        conserving the environment as well as the values of education. From my primary and secondary
        education in Uganda, to college and graduate school here in the United States, I’ve been
        mentored by amazing educators and researchers who have inspired me to pursue a career in
        academia.
      </Typography>
      <Typography paragraph>
        Thanks to the National Save The Sea Turtle Foundation Scholarship I am now combining the
        cancer research techniques developed during my PhD, with sea turtle FP research, to gain a
        greater understanding of the viral drivers of FP, and identify novel drug treatments for
        this debilitating disease. My doctoral research involves the screening of natural sources
        for new cancer drug leads. For example, the widely used clinically anti-tumor drugs
        doxorubicin and paclitaxel are both derived from natural sources, from bacteria and plants
        respectively. In fact, over 50% of all FDA approved drugs are inspired by metabolites found
        in nature. The Loesgen laboratory is screening microbe derived metabolites for new cancer
        treatments and drug leads. We have found several new chemical entities and tested them in
        vitro in our laboratory and in collaboration with the National Cancer Institute. In one of
        my projects, I have been investigating the mode of action of the new bacterial metabolite
        called mensacarcin. The compound was tested by the US National Cancer Institute and
        exhibited potent and selective activity against some tumor types. I have characterized in
        depth its cellular and anti-tumor properties and discovered that mensacarcin’s unique
        ability to disrupt cell energy metabolism gives it selective cellular toxicity to skin
        cancer (melanoma) cells, even having potent activity in treatment-resistant melanoma.
      </Typography>
      <Typography paragraph>
        In collaboration with Dr. Dave Duffy, we have been looking at the chemo-induction of tumors
        in Floridian sea turtles. His laboratory has been studying fibropapillomatosis, a now common
        potentially fatal virulent tumor-causing disease in Floridian sea turtles. My National Save
        The Sea Turtle Foundation supported work includes investigating anti-tumor drugs in FP and
        imaging of virions in turtle tissues and creating imaging probes to better understand the
        relationship of viral load and tumor progression in afflicted sea turtles.
      </Typography>
      <div className="my-5">
        <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      </div>
      <div className="lg:float-right lg:ml-5 mb-5"><img className="rounded-2xl" src={DorothyMitchell} alt="" title="" /></div>
      <Typography paragraph>
        Dorothy Mitchell (Martindale Lab) Project: Genomic and Cellular Drivers of FP
      </Typography>
      <Typography paragraph>
        Hi, I’m Dorothy Mitchell and I am a graduate student in Prof. Martindale’s lab at the
        University of Florida’s Whitney Laboratory for Marine Bioscience, where I study the genetic
        control of stem cells and their involvement in health and disease.
      </Typography>
      <Typography paragraph>
        I grew up on the coast of the Gulf of Mexico in Fairhope, Alabama. I have always been
        passionate about protecting and learning from the environment. My interest in marine
        wildlife is longstanding; I have been interested in the various marine organisms in the
        diverse coastal estuary systems surrounding my home, from the numerous invertebrates to
        charismatic megafauna like sea turtles. I received my Bachelor of Science in Marine Biology
        from Auburn University in Alabama. During college, I discovered a passion for using marine
        model systems to study cellular processes when I started working on tentacle formation in
        the comb jellyfish Mnemiopsis leidyi. I previously completed an undergraduate research
        project which allowed me to become familiar with the innovative research going on at the
        Whitney Lab. I then joined Dr. Mark Martindale’s Lab for my graduate studies, for which I
        investigate stem cell development and evolution in relation to reproduction and regeneration
        in comb jellies 1. SeaTurtleHospital-Logo-Teal.jpg. Thanks to the National Save The Sea
        Turtle Foundation Scholarship I am now using my computational, molecular biology and stem
        cell expertise to investigate the genome and cancer stem cells of FP. Cancer stem cells are
        crucial in driving the growth of tumors, and their spread around the body. Cancer stem cells
        are often also responsible for the regrowth of tumors after surgery or chemotherapy.
        Regrowth of tumors after surgical removal is a huge issue when treating FP-afflicted
        turtles. Over 70% of eye tumors that are removed by surgery end up regrowing. Based on the
        known role of cancer stem cells in human and other animal cancers, they are also very likely
        to be the key agents responsible for FP tumor regrowth. My ongoing work is examining which
        cancer stem cell genes are activated in FP, and visualizing those cancer stem cells within
        established and regrowth tumors. Improved understanding of the precise role of cancer stem
        cells in FP tumor growth, regrowth and spread (metastasis), can ultimately lead to improved
        anti-cancer treatments to lessen the burden of FP tumors, especially post-surgical regrowth
        on sea turtles.
      </Typography>
      <Typography paragraph>Future Research Directions</Typography>
      <Typography>
        We are extremely grateful to the National Save The Sea Turtle Foundation and its donors, for
        supporting our sea turtle research. In future years we hope to expand our research to
        encompass additional threats to sea turtles (unfortunately, of which there are many). We
        have recently begun to investigate the effect of plastic ingestion in sea turtles, from even
        the earliest life stages (Eastman et al. 2020). The next step of our plastic ingestion work
        is to apply the molecular health assessment tools (molecular biology and histology-based)
        developed for our FP research, to determine what the health consequences are of so much
        plastic in these vulnerable post-hatchlings. In Fall 2021 we will host an NSTSTF supported
        collaborator meeting to discuss current needs and future research directions of FP research,
        in order to progress our ability to rehabilitate and release FP-afflicted turtles as
        efficiently and successfully as possible. In future (post-Covid) years we plan on developing
        this meeting into an open invitation symposium (oral and poster presentations) on the latest
        sea turtle fibropapillomatosis molecular research. It is an exciting time for sea turtle
        disease research, advances in technology are allowing us to figure out many of the
        long-standing mysteries about sea turtles, particularly in relation to the enigmatic
        fibropapillomatosis disease. With continued support we will uncover yet more mysteries of
        sea turtle health, and make meaningful impacts towards the rehabilitation and conservation
        of these marvelously enigmatic animals.
      </Typography>
    </div>
  );
}
