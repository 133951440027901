import { Typography } from '@mui/material'
import React from 'react'
import FLEnvironment from '../Images/Florida-Environmental-Outreach-Magazine.jpg'
import Button from '@mui/material/Button';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { createTheme, ThemeProvider } from "@mui/material/styles";


const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#932B16", // custom secondary color
    },
  },
});


export default function FloridaEnvironmentalOutreach() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><img className='rounded-3xl mx-auto' src={FLEnvironment} alt='' title='' /></div>
      <div className='pt-5'><Typography paragraph>Published quarterly, Florida Environmental Outreach Magazine is a full-color publication full of environmental news, current research, and National Save the Sea Turtle Foundation updates. A sure hit with readers of all ages! If you have an interesting topic or project you&apos;d like our readers to know about, contact the Editorial Team at the National Save the Sea Turtle Foundation to discuss your topic or submit an article.</Typography></div>


      <div className='lg:float-right mb-5 clear-both'>
        <ThemeProvider theme={theme}>
          <Button href="https://savetheseaturtle.org/Forms/FLOutreachMagazin.html" color="secondary" rel='noreferrer noopener' variant="contained" endIcon={<DriveFileRenameOutlineIcon />} className='mx-auto'>
            Outreach Subscribtion
          </Button>
        </ThemeProvider></div>
      <div><Typography paragraph>To get your own copies delivered straight to your home, sign up for your subscription at</Typography></div>



    </div>
  )
}

