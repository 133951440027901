import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from '@mui/icons-material/Instagram';
import ShareIcon from '@mui/icons-material/Share';
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import { Link } from "react-router-dom";
import Fade from "@mui/material/Fade";
// import { Divider, Link } from "@mui/material";
// import { GiSeaTurtle } from 'react-icons/gi';


export const SocialMedia = () => {

  const handleClick = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'National Save The Sea Turtle Foundation',
          text: 'Check out National Save The Sea Turtle Foundation!',
          url: 'https://savetheseaturtle.org/'
        });
      } else {
        fallbackShare();
      }
    } catch (error) {
      console.log('Error sharing:', error);
    }
  };

  const fallbackShare = () => {
    const shareUrl = 'https://savetheseaturtle.org/';
    if (navigator.clipboard) {
      navigator.clipboard.writeText(shareUrl);
      alert('Website link copied to clipboard!');
    } else {
      alert('Your device or browser does not support sharing.');
    }
  };



  return (
    <div className="dark:bg-[#032546]/90 bg-[#032546] container mx-auto">
      <div className="flex px-3 py-3 ">
        <div className="float-right">
          <Fade direction="down" in={true} timeout={1000} mountOnEnter unmountOnExit>
            <a className="text-[#932B16] hover:text-white"
              href="https://www.facebook.com/savetheseaturtl/"
              target="_blank"
              rel="noreferrer"

              style={{ paddingRight: "5px", paddingLeft: "5px" }
              }

              title="Save The Sea Turtle Facebook" >
              <FacebookIcon fontSize="large" style={{ color: "primary" }}></FacebookIcon>
            </a >
          </Fade >
        </div>
        <div className="float-right">
          <Fade direction="down" in={true} timeout={2000} mountOnEnter unmountOnExit>
            <a className="text-[#932B16] hover:text-white"
              href="https://twitter.com/savetheseaturtl"
              target="_blank"
              rel="noreferrer"
              style={{ paddingRight: "5px", paddingLeft: "5px" }} title="Save The Sea Turtle Twitter" >
              <TwitterIcon fontSize="large" style={{ color: "primary" }}></TwitterIcon>
            </a>
          </Fade>
        </div>
        <div className="float-right">
          <Fade direction="down" in={true} timeout={3000} mountOnEnter unmountOnExit>
            <a className="text-[#932B16] hover:text-white"
              href="https://www.instagram.com/savetheseaturtl/"
              target="_blank"
              rel="noreferrer" style={{ paddingRight: "5px", paddingLeft: "5px" }} title="Save The Sea Turtle Instagram">
              <InstagramIcon fontSize="large" style={{ color: "primary" }}></InstagramIcon>
            </a>
          </Fade>
        </div>

        <div className="float-right">
          <Fade direction="down" in={true} timeout={4000} mountOnEnter unmountOnExit>
            <button
              className="text-[#932B16] hover:text-white"
              onClick={handleClick}
              style={{ paddingLeft: '5px' }}
            >
              <ShareIcon fontSize='large' />
            </button>
          </Fade>
        </div>

        {/* <div><GiSeaTurtle size={40} /></div> */}

      </div>

    </div >
  );
};
export default SocialMedia;
