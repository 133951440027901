import React from 'react'
import { Divider, Typography } from '@mui/material';
import SeaTurtleAdventures from '../Images/Sea-Turtle-Adventures-2.png'
import seaturtlenests from '../Images/sea-turtle-nests.jpg'
import nestingbeaches24 from '../Images/24-nesting-beaches.png'
import JackieKingstonHannahZegel from '../Images/Jackie-Kingston-Hannah-Zegel.jpg'

export default function SeaTurtleAdventures2021() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>

      <div><div className='pb-5'><h1 className='text-2xl font-bold'>Sea Turtle Adventures: 2021 Recap
      </h1></div></div>
      <div><Typography paragraph>Jackie Kingston, Executive Director, Sea Turtle Adventures</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className='lg:float-right lg:ml-5 mt-5'><img src={SeaTurtleAdventures} alt='' title='' className='mx-auto' /></div>
      <div className='pt-5'><Typography paragraph>Sea Turtle Adventures (STA) has been monitoring local beaches in Palm Beach County for nesting sea turtles for over two decades. Based out of West Palm Beach, STA has three core focus areas: sea turtle conservation, public education and engagement, and providing nature-based programs to adults with special needs. In this article, we touch on the highlights from 2021!</Typography></div>
      <div className='lg:float-left lg:mr-5 mb-5'><img src={seaturtlenests} alt='' title='' className=' rounded-2xl mx-auto' /></div>
      <div><Typography paragraph>During the 2021 nesting season, STA documented 766 sea turtle nests on the 3-mile section of beach we have been monitoring for the past 21 years. The nest count breaks down as follows: 666 loggerhead, 76 green, and 24 leatherback nests. To put it in perspective, our beach saw an average number of loggerhead nests, a below average number of green nests, and a record-breaking number of leatherback nests (the previous record of 19 was set in 2020). STA also responded to 14 stranding and salvage events, and assisted FWC with an additional four sea turtle “transfer only” cases (where we simply transported a stranded turtle on behalf of FWC from one location to another, typically a rehabilitation center).</Typography></div>

      <div className='lg:float-right lg:ml-5'>
        <div><img src={nestingbeaches24} alt='' title='' className='' /></div>

        <div><em>Figure 1. As a new alternative to tradionally<br />
          plastic signs, aluminum turtle nest signs will be<br />
          tested in 24 nesting beaches around the State.</em></div></div>

      <div><Typography paragraph>STA’s efforts to develop an alternative non-plastic sea turtle nest sign finally came to fruition. After soliciting proposals for multiple vendors to produce a (relatively) light-weight aluminum sign, STA partnered with Pride Enterprises based out of Central Florida. PRIDE is a self-funded enterprise whose mission makes a positive difference in Florida. They make communities safer and save taxpayers money by training eligible inmates in vocational skills and transitioning them into the job market upon completion of their sentences. Nearly 2,000 aluminum sea turtle nest signs are in production and will be shipped to 24 organizations throughout Florida (see Figure 1 for testing locations) who agreed to test these new signs during the 2022 nesting season.</Typography></div>
      <div className='lg:float-left lg:mr-5 mb-5'>
        <div><img src={JackieKingstonHannahZegel} alt='' title='' className='rounded-2xl mx-auto' /></div>
      </div>
      <div><Typography paragraph>STA’s “ride along” program continues to gain interest from residents and visitors (including many who joined us from out of state)! During a “ride along”, participants join us for a 1hr adventure aboard our ATV. They enjoy a personalized experience where they observe our permitted sea turtle monitor excavate a previously hatched nest and learn all about the nesting process. This one-of-a-kind opportunity has grown significantly over the past three years since its first offering. Please visit our website for more information or if you would like guidance in starting a similar program (www.seaturtleadventures.com).</Typography></div>
      <div><Typography paragraph>STA remains one of only a few organizations who is willing to respond to calls regarding injured seabirds and shorebirds. In 2021, we responded to calls for about 12 injured seabirds and transported all to Busch Wildlife for rehabilitation. Keep our phone number handy if you are in need of a seabird rescuer in Palm Beach County (561-704-5911).</Typography></div>
      <div><Typography paragraph>STA’s nature-based program for adults with special needs also continued to see growth. The iCare Program grew from 90 to 135 members in 2021 and participants had their fair share of environmental experiences. STA hosted three weeklong “Biology Bootcamps” where special needs adults learned firsthand what it is like to be a biologist. iCare members also participated in over 40 cleanups throughout the year, primarily in mangrove habitats which are often overlooked as potential cleanup locations but are oftentimes full of debris.</Typography></div>



      <div className='pt-2'><em>Jackie Kingston, STA Executive Director, teaching Hannah Zegel, iCare member, how to properly measure this large deceased loggerhead which washed ashore Oct 20 2021 in Palm Beach County, FL.</em></div>


    </div>
  )
}
