import React from "react";
import { Divider, Typography } from "@mui/material";
import Nick from "../Images/Nick.jpg";
import UCF1 from "../Images/University-of-central-florida.jpg";
import Nick1 from "../Images/Nick6-17-19.jpg";
import Nick2 from "../Images/Nick6-17-19-2.jpg";
import Wayne1 from "../Images/Wayne.jpg";

export default function MaleGreenTurtles() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <div className="pb-5">
          <h1 className="text-2xl font-bold">Update: Tracking Male Green Turtles in SE Florida </h1>
        </div>
      </div>
      <div>
        <Typography paragraph>Larry Wood and Dean Bagley </Typography>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="lg:flex gap-5">
        <div className="mt-5">
          <div className="">
            <Typography paragraph>
              It has become widely recognized among the sea turtle research and conservation
              community that we know comparatively little about the lives of male sea turtles. At
              first this may seem odd, but researchers have traditionally had relatively easy access
              to females and hatchlings, since they are, at one time or another, found on beaches.
              Males, on the other hand, can’t be found so easily because in almost all cases, they
              never come to shore.
            </Typography>
            <div>

              <div>

                <div>
                  <div >
                    <div className="lg:float-left">
                      <div><img className="rounded-2xl lg:mr-5 mx-auto" src={Nick} alt="" title="" /></div>
                      <div className="pt-2"><em>“Nick”</em></div>
                    </div>
                    <div><Typography paragraph>
                      Ms. Bagley and her team have been focusing on a population of green turtles that nest
                      on the beaches at the Archie Carr National Wildlife Refuge in Melbourne, Florida. The
                      green turtle nesting population is quite large there, and offers her team unusual
                      access to male turtles. In their zeal to reproduce, male turtles may continue to grasp
                      females even as they come ashore to nest, effectively getting carried into the surf
                      zone by their companions.
                    </Typography></div>
                  </div>

                </div>
              </div>

            </div>


          </div>
        </div>
        <div className="mt-5">
          <div className="lg:float-right lg:ml-5 mb-5">
            <img className="rounded-2xl mx-auto border-4 border-white " src={UCF1} alt="" title="" />
          </div>
          <div className="">
            <Typography paragraph>
              This provides Ms. Bagley’s team the opportunity to capture the males and attach
              satellite transmitters to their shells. The results are among the first ever that
              describe the reproductive migrations of male green turtles in Florida. The National
              Save The Sea Turtle Foundation is excited to support Ms. Dean Bagley’s important work.
              This year, she dedicated two of the satellite tags to members of the Foundation’s
              team. The first was named for the late Captain Nick Reeves, who sadly passed away
              recently but remains in our memories. The second was named for Mr. Wayne Kurian, one
              of the key members of the Foundation’s fundraising team whose work continues to be so
              important to the Foundation’s success.
            </Typography>
            <Typography paragraph>
              Nick’s transmitter was deployed on 5-29-19 . He immediately took off to the south, and
              swam to beyond the 17th Street Bridge in Vero Beach (green dots) before turning around
              on 5-30-19 and coming back north to Sebastian Inlet, where he stayed until 6-4-19
              (light green dots). At that time, he began swimming south again (yellow orange dots,
              orange, red-orange and finally red dots) along the coast, in some places practically
              on the beach as he continued south.
            </Typography>
          </div>
        </div>
      </div>

      <div className="lg:flex">
        <div className="lg:w-1/2">
          <div className="p-2">
            <img className="rounded-2xl" src={Nick1} alt="" title="" />
          </div>
        </div>
        <div className="p-2">
          <img className="rounded-2xl" src={Nick2} alt="" title="" />
        </div>
      </div>
      <div className="lg:flex pt-5 gap-5">
        <div className="lg:w-1/2">
          <div>
            <img className="rounded-2xl" src={Wayne1} alt="" title="" />
          </div>
        </div>
        <div className="lg:w-1/2 ">
          <Typography paragraph>
            He arrived just off Elliott Key, at the south end of Biscayne Bay on 6-9-19, looked
            around (maybe rested?) a bit and started north again on 6-10-19. His last location was
            opposite Key Biscayne on 6-10-19 at 9:09 p.m. He may have set a new record for the trip
            south. He made it from Sebastian inlet to Elliott Key in about 7 days.
          </Typography>
          <Typography paragraph>
            Wayne took us a little longer to find. Wayne was deployed on 6-4-19. Upon release, he
            headed immediately north, spending most of his time south of Eau Gallie Blvd in our
            Central Brevard Study Area, but going as far north as Satellite Beach before leaving
            that area and coming back south on 6-9-19. He crossed Sebastian Inlet later that day and
            continued south, uninterrupted, until the last location on Hutchinson Island, just south
            of Jensen Beach and north of the St. Lucie Inlet on 6-11-19 at 1:55 a.m. When we left
            off , Nick was east of Elliott Key, at the south end of Biscayne Bay. He left on June 10
            and has since made the journey back north to Jupiter, arriving on the 13th and turning
            around to come back south, also on the 13th. It’s like he forgot something and went back
            to get it.... he didn’t spend any time there, and was back off Elliott Key by 6-17-19.
          </Typography>
        </div>
      </div>
      <div>
        <Typography className="p-10" paragraph>
          Meanwhile, Wayne was just south of Jensen Beach and north of the St. Lucie Inlet on
          6-11-19. He has continued south and was still migrating at his last reported location,
          also east of Elliott Key. We’ll keep you updated in upcoming issues of Florida
          Environmental Outreach Magazine!
        </Typography>
      </div>
    </div>
  );
}
