import React from "react";
import { Typography, Divider } from "@mui/material";
import ConnerSharrow from '../Images/Conner-Sharrow.jpg'
import FloridaGulfCoastUniversity from '../Images/Florida-Gulf-Coast-University.jpg'

export default function FGCUawards() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className="pb-5"><h1 className='text-2xl font-bold'>Florida Gulf Coast University Awards National Save The Sea Turtle Foundation Scholarships</h1></div></div>
      <div><Typography paragraph>Larry Wood, Ph.D.</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className='lg:float-right lg:ml-5 mt-5'><img className="rounded-2xl mx-auto border-4 border-white" src={FloridaGulfCoastUniversity} alt="" title="" /></div>
      <div className="pt-5"><Typography paragraph>The National Save The Sea Turtle Foundation recently established an undergraduate scholarship fund at Florida Gulf Coast University in Ft. Meyers, Florida. Under the guidance of Dr. Phil Allman, the student awardees will be working on understanding the effects of increasing temperature on nest success and movements of leatherback turtles in the eastern Atlantic Ocean.</Typography></div>
      <div className='lg:float-left lg:mr-5 mb-5'><img className="rounded-2xl mx-auto" src={ConnerSharrow} alt="" title="" /></div>
      <div><Typography paragraph>My name is Conner Sharrow, and I’m a senior undergraduate student at Florida Gulf Coast University. I am pursuing a Bachelor of Science degree in biology with a concentration in organismal biology and ecology. The courses I have taken (i.e. Conservation Biology, Biological Statistics) made me realize there are tangible ways I can contribute to the conservation of wildlife by conducting research. My introduction to field research came when I began participating in projects developed by the FGCU Herpetology Research Laboratory. I worked at both Delnor-Wiggins Pass State Park and Railhead Scrub Preserve. In the lab we use radio telemetry on populations of gopher tortoises to track their movement patterns in order to learn how these animals utilize different habitats in southwest Florida. The information will inform future conservation and management activities. I have since become a Research Assistant for a sea turtle nesting survey program at Cayo Costa State Park. We conduct nest surveys on primarily two species of sea turtle (loggerhead and green), but we did document a leatherback nest in summer 2020. The team ensures the nests are protected, marked, and inventoried as instructed by Florida Fish and Wildlife Commission’s sea turtle management protocols. This experience has allowed me to develop my field research skills as well as my passion for sea turtles and ocean conservation.</Typography></div>
      <div><Typography paragraph>My career aspirations are to become a wildlife biologist in order to explore organisms and how they interact with their ecosystems. I hope my work will contribute to wildlife conservation and management. For my senior research project I plan to explore the movement patterns of endangered leatherback sea turtles in the eastern Atlantic Ocean. Identifying their migratory routes will help us identify human-caused mortality risk they may face in these locations. This generous scholarship can ensure this project can come to fruition while also securing my senior year for further productivity. Through my experiences conducting field research, I have come to realize and appreciate the significance of a scientists’ work, no matter how big or small. This is because our actions for these animals will always have an impact. As I continue my studies onwards towards my research and graduate school, the lessons I learn will prove fundamental in the protection of these incredible creatures and spreading the word on what we can do to help. Thank you for helping me on this journey, and I know I can create a positive impact for the sea turtles and the pursuit for their conservation.</Typography></div>
    </div>
  );
}
