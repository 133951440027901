import { useState, useEffect } from "react";
import Footer from "./Component/Footer";
import Home from "./Home";
import Sponsors from "./Sponsors";
import WeSupport from "./WeSupport";
import SeaTurtleFacts from "./SeaTurtleFacts";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Contact from "./Contact";
import HawksbillProject from "./HawksbillProject";
import FoundationNews from "./FoundationNews";
import Education from "./Education";
import WaterwayCleanup from "./FoundationNews/WaterwayCleanup";
import FloridaSeaTurtlesGetaBoost from "./FoundationNews/FloridaSeaTurtlesGetaBoost";
import SargassumBloomsandHatchlingProduction from "./FoundationNews/SargassumBloomsandHatchlingProduction";
import Conservation from "./Conservation";
import AReelDilemma from "./FoundationNews/AReelDilemma";
import memoryofLlewellynEhrhart from "./FoundationNews/memoryofLlewellynEhrhart";
import NewAluminumNestMarkingSigns from "./FoundationNews/NewAluminumNestMarkingSigns";
import StatewideNestingSummary2018 from "./FoundationNews/StatewideNestingSummary2018";
import Link from '@mui/material/Link';
import SeaTurtleAdventures from "./FoundationNews/SeaTurtleAdventures";
import EducationatFIUCenterOceans from "./FoundationNews/EducationatFIUCenterOceans";
import DaringExcitingToothy from "./FoundationNews/DaringExcitingToothy";
import ClassroomEducation from "./FoundationNews/ClassroomEducation";
import OutandAbout from "./FoundationNews/OutandAbout";
import SeaTurtleAdventures2021 from "./FoundationNews/SeaTurtleAdventures2021";
import SeaTurtleMonitoringandSatelliteTracking from "./FoundationNews/SeaTurtleMonitoringandSatelliteTracking";
import NavigatingtheFuture from "./FoundationNews/NavigatingtheFuture";
import SupportsEducation from "./FoundationNews/SupportsEducation";
import WhatAreYouDoing from "./FoundationNews/WhatAreYouDoing";
import MaleGreenTurtles from "./FoundationNews/MaleGreenTurtles";
import LeatherbackNesting from "./FoundationNews/LeatherbackNesting";
import SaltyAirSea from "./FoundationNews/SaltyAirSea";
import SandyPoint from "./FoundationNews/SandyPoint";
import LearningLeatherbacks from "./FoundationNews/LearningLeatherbacks";
import FAUsecondYear from "./FoundationNews/FAUsecondYear";
import NovaMember from "./FoundationNews/NovaMember";
import SupportFLgulf from "./FoundationNews/SupportFLgulf";
import GlobalSeaTurtle from "./FoundationNews/GlobalSeaTurtle";
import TrunksTravelSouth from "./FoundationNews/TrunksTravelSouth";
import InwaterResearch from "./FoundationNews/InwaterResearch";
import MEECadds from "./FoundationNews/MEECadds";
import UCFReceives from "./FoundationNews/UCFReceives";
import FinalReport2020 from "./FoundationNews/FinalReport2020";
import TemperatureInterview from "./FoundationNews/TemperatureInterview";
import MarineTurtleHistory from "./FoundationNews/MarineTurtleHistory";
import MikeSalmon from "./FoundationNews/MikeSalmon";
import RecipientsChosen from "./FoundationNews/RecipientsChosen";
import WhitneyLab from "./FoundationNews/WhitneyLab";
import IndianRiver from "./FoundationNews/IndianRiver";
import FAUlabRefreshed from "./FoundationNews/FAUlabRefreshed";
import FGCUawards from "./FoundationNews/FGCUawards";
import BlackSaltmarsh from "./FoundationNews/BlackSaltmarsh";
import DrErinSeney from "./FoundationNews/DrErinSeney";
import NewTurtleModel from "./FoundationNews/NewTurtleModel";
import UCFscholarshipAward from "./FoundationNews/UCFscholarshipAward";
import UVIpartnership from "./FoundationNews/UVIpartnership";
import FLHawkbill2020 from "./FoundationNews/FLHawkbill2020";
import InwaterJupiter from "./FoundationNews/InwaterJupiter";
import LeatherbackLight from "./FoundationNews/LeatherbackLight";
import SandyPoint2020 from "./FoundationNews/SandyPoint2020";
import MeetStudents from "./FoundationNews/MeetStudents";
import AmazingMantas from "./FoundationNews/AmazingMantas";
import CostalCleanup from "./FoundationNews/CostalCleanup";
import TurlteAdventureExpand from "./FoundationNews/TurlteAdventureExpand";
import InsightsKeewaydin from "./FoundationNews/InsightsKeewaydin";
import EastCoastBiologists from "./FoundationNews/EastCoastBiologists";
import WarmingOliveRidley from "./FoundationNews/WarmingOliveRidley";
import SargassoSea from "./FoundationNews/SargassoSea";
import InsightsKeewaydin2 from "./FoundationNews/InsightsKeewaydin2";
import EducationDuringCOVID from "./FoundationNews/EducationDuringCOVID";
import SargassumNowWorld from "./FoundationNews/SargassumNowWorld";
import StcroixProject from "./FoundationNews/StcroixProject";
import PHDstudents from "./FoundationNews/PHDstudents";
import HelpedBioAlum from "./FoundationNews/HelpedBioAlum";
import StyrofoamBoxes from "./FoundationNews/StyrofoamBoxes";
import BrowardCountryNesting from "./FoundationNews/BrowardCountryNesting";
import Hawksbill2021 from "./FoundationNews/Hawksbill2021";
import InsideTurtleNest from "./FoundationNews/InsideTurtleNest";
import InwaterTeachesPasco from "./FoundationNews/InwaterTeachesPasco";
import MORAESVkey from "./FoundationNews/MORAESVkey";
import UFwhitneyLab from "./FoundationNews/UFwhitneyLab";
import { motion } from "framer-motion";
import Differencesinvisualperception from "./Research/Differencesinvisualperception";
import UCFMarineTurtleResearchGroup from "./Research/UCFMarineTurtleResearchGroup";
import FloridaHawksbillProject2020 from "./Research/FloridaHawksbillProject2020";
import AMDRO from "./Research/AMDRO";
import IdentifyingSexinHatchlingTurtles from "./Research/IdentifyingSexinHatchlingTurtles";
import MigrationofLoggerheadHatchlings from "./Research/MigrationofLoggerheadHatchlings";
import AnimalBehaviorandVisualPerception from "./Research/AnimalBehaviorandVisualPerception";
import EpibiontsofHawksbill from "./Research/EpibiontsofHawksbill";
import StudyingFloridasLivingDinosaurs from "./Research/StudyingFloridasLivingDinosaurs";
import SatelliteTracking from './Research/SatelliteTracking';
import RecoveryatGumboLimbo from "./Research/RecoveryatGumboLimbo";
import StudySeaTurtlesinBiscayneNationalPark from "./Research/StudySeaTurtlesinBiscayneNationalPark";
import EvaluatingtheEffectsofPaternity from "./Research/EvaluatingtheEffectsofPaternity";
import CanMarineTurtlesNavigate from "./Research/CanMarineTurtlesNavigate";
import OctopusSuperpowers from "./Research/OctopusSuperpowers";
import SeekingTheGreenTurtles from "./Research/SeekingTheGreenTurtles";
import HawksbillTurtleAggregation from "./Research/HawksbillTurtleAggregation";
import Whysexingaseaturtlehatchlingisimportant from "./Research/Whysexingaseaturtlehatchlingisimportant";
import HawksbillProjectHighlightedinVideoProduction from "./Research/HawksbillProjectHighlightedinVideoProduction";
import Hawksbill from './SeaTurtles/Hawksbill'
import KempsRidley from "./SeaTurtles/KempsRidley";
import Loggerhead from './SeaTurtles/Loggerhead';
import Green from './SeaTurtles/Green';
import Leatherback from './SeaTurtles/Leatherback'
import LeatherbackSeaTurtleVision from "./FoundationNews/LeatherbackSeaTurtleVision";
import AdoptSeaTurtle from "./AdoptSeaTurtle";
import SocialMedia from "./Component/socialMedia";
import Navigation from './Component/Navigation';
import TungstenIcon from '@mui/icons-material/Tungsten';
import BecomeAMember from "./Membership/BecomeAMember";
import ScholarshipProgram from "./ScholarshipProgram";
import SeaTurtleBG from "./Component/SeaTurtleBG";
import CashDonation from "./CashDonation";
// import HawksbillProjectForm from "./Component/HawksbillProject";

//Dark Background: #032546 Blue Whale

const history = createBrowserHistory();



function App() {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true" ||
    (!("darkMode" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  );



  useEffect(() => {
    const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (e) => setDarkMode(e.matches);
    mediaQueryList.addEventListener("change", handleChange);
    return () => mediaQueryList.removeEventListener("change", handleChange);
  }, []);

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode.toString());
  }, [darkMode]);

  return (
    <div className="bg-[#032546]/50">
      <div>
        <SeaTurtleBG />

      </div>
      <div className={`relative z-10 justify-center  ${darkMode ? "dark" : ""}`} >



        <div className="flex bg-[#032546]">
          <div className="flex-auto"><Navigation /></div>
          <div className="flex-initial pt-4 pb-2 pr-5 text-white dark:text-[#B76770]"><TungstenIcon onClick={() => setDarkMode(!darkMode)} className="cursor-pointer text-3xl" /></div>
        </div>

        <div>
          <BrowserRouter history={history}>
            <div>
              <motion.div
                initial={{ x: "100%" }}
                animate={{
                  x: "0%",
                  transition: { type: "easeInOut", duration: 0.4, frame: 120 },
                }}
                className="container mx-auto lg:mt-10"
              >


                <div>

                  <Link hidden href="/" />
                  <Link hidden href="/Education" />
                  <Link hidden href="/HawksbillProject" />
                  <Link hidden href="/Conservation" />
                  <Link hidden href="/FoundationNews" />

                  <Link hidden href="/FoundationNews/LeatherbackSeaTurtleVision" />
                  <Link hidden href="/FoundationNews/WaterwayCleanup" />


                  <Link hidden href="/FoundationNews/FloridaSeaTurtlesGetaBoost" />
                  <Link hidden href="/FoundationNews/SargassumBloomsandHatchlingProduction" />
                  <Link hidden href="/FoundationNews/AReelDilemma" />
                  <Link hidden href="/FoundationNews/memoryofLlewellynEhrhart" />
                  <Link hidden href="/FoundationNews/NewAluminumNestMarkingSigns" />
                  <Link hidden href="/FoundationNews/SeaTurtleMonitoringandSatelliteTracking" />
                  <Link hidden href="/FoundationNews/SeaTurtleAdventures2021" />
                  <Link hidden href="/FoundationNews/StatewideNestingSummary2018" />
                  <Link hidden href="/FoundationNews/SeaTurlteAdventures" />
                  <Link hidden href="/FoundationNews/EducationatFIUCenterOceans" />
                  <Link hidden href="/SeaTurtleFacts" />
                  <Link hidden href="/SeaTurtles/Hawksbill" />
                  <Link hidden href="/SeaTurtles/KempsRidley" />
                  <Link hidden href="/SeaTurtles/Loggerhead" />
                  <Link hidden href="/SeaTurtles/Green" />
                  <Link hidden href="/SeaTurtles/Leatherback" />

                  <Link hidden href="/Membership/BecomeAMember" />
                  <Link hidden href="/Membership/Member25" />
                  <Link hidden href="/Membership/Member25Friend" />
                  <Link hidden href="/Membership/Member50" />
                  <Link hidden href="/Membership/Member50Friend" />
                  <Link hidden href="/Membership/Member100" />
                  <Link hidden href="/Membership/Member100Friend" />
                  <Link hidden href="/Membership/Member300" />
                  <Link hidden href="/Membership/Member500" />
                  <Link hidden href="/Membership/Member500Friend" />
                  <Link hidden href="/Membership/Member1000" />
                  <Link hidden href="/Membership/Member1000Friend" />
                  <Link hidden href="/Membership/Member5000" />
                  <Link hidden href="/Membership/Member5000Friend" />

                  <Link hidden href="/ScholarshipProgram" />


                  <Link hidden href="/CashDonation" />

                  {/* <Link hidden href="/Component/HawksbillProjectForm" /> */}


                  <Link hidden href="/FoundationNews/DaringExcitingToothy" />
                  <Link hidden href="/FoundationNews/ClassroomEducation" />
                  <Link hidden href="/FoundationNews/OutandAbout" />
                  <Link hidden href="/FoundationNews/NavigatingtheFuture" />
                  <Link hidden href="/FoundationNews/SupportsEducation" />
                  <Link hidden href="/FoundationNews/WhatAreYouDoing" />
                  <Link hidden href="/FoundationNews/MaleGreenTurtles" />
                  <Link hidden href="/FoundationNews/LeatherbackNesting" />
                  <Link hidden href="/FoundationNews/SaltyAirSea" />
                  <Link hidden href="/FoundationNews/SandyPoint" />
                  <Link hidden href="/FoundationNews/LearningLeatherbacks" />
                  <Link hidden href="/FoundationNews/FAUsecondYear" />
                  <Link hidden href="/FoundationNews/NovaMember" />
                  <Link hidden href="/FoundationNews/SupportFLgulf" />
                  <Link hidden href="/FoundationNews/GlobalSeaTurtle" />
                  <Link hidden href="/FoundationNews/TrunksTravelSouth" />
                  <Link hidden href="/FoundationNews/InwaterResearch" />
                  <Link hidden href="/FoundationNews/MEECadds" />
                  <Link hidden href="/FoundationNews/UCFReceives" />
                  <Link hidden href="/FoundationNews/FinalReport2020" />
                  <Link hidden href="/FoundationNews/TemperatureInterview" />
                  <Link hidden href="/FoundationNews/MarineTurtleHistory" />
                  <Link hidden href="/FoundationNews/MikeSalmon" />
                  <Link hidden href="/FoundationNews/RecipientsChosen" />
                  <Link hidden href="/FoundationNews/WhitneyLab" />
                  <Link hidden href="/FoundationNews/IndianRiver" />
                  <Link hidden href="/FoundationNews/FAUlabRefreshed" />
                  <Link hidden href="/FoundationNews/FGCUawards" />
                  <Link hidden href="/FoundationNews/BlackSaltmarsh" />
                  <Link hidden href="/FoundationNews/DrErinSeney" />
                  <Link hidden href="/FoundationNews/NewTurtleModel" />
                  <Link hidden href="/FoundationNews/UCFscholarshipAward" />
                  <Link hidden href="/FoundationNews/UVIpartnership" />
                  <Link hidden href="/FoundationNews/FLHawkbill2020" />
                  <Link hidden href="/FoundationNews/InwaterJupiter" />
                  <Link hidden href="/FoundationNews/LeatherbackLight" />
                  <Link hidden href="/FoundationNews/SandyPoint2020" />
                  <Link hidden href="/FoundationNews/MeetStudents" />
                  <Link hidden href="/FoundationNews/AmazingMantas" />
                  <Link hidden href="/FoundationNews/CostalCleanup" />
                  <Link hidden href="/FoundationNews/TurlteAdventureExpand" />
                  <Link hidden href="/FoundationNews/InsightsKeewaydin" />
                  <Link hidden href="/FoundationNews/EastCoastBiologists" />
                  <Link hidden href="/FoundationNews/WarmingOliveRidley" />
                  <Link hidden href="/FoundationNews/SargassoSea" />
                  <Link hidden href="/FoundationNews/InsightsKeewaydin2" />
                  <Link hidden href="/FoundationNews/EducationDuringCOVID" />
                  <Link hidden href="/FoundationNews/SargassumNowWorld" />
                  <Link hidden href="/FoundationNews/StcroixProject" />
                  <Link hidden href="/FoundationNews/PHDstudents" />
                  <Link hidden href="/FoundationNews/HelpedBioAlum" />
                  <Link hidden href="/FoundationNews/StyrofoamBoxes" />
                  <Link hidden href="/FoundationNews/BrowardCountryNesting" />
                  <Link hidden href="/FoundationNews/Hawksbill2021" />
                  <Link hidden href="/FoundationNews/InsideTurtleNest" />
                  <Link hidden href="/FoundationNews/InwaterTeachesPasco" />
                  <Link hidden href="/FoundationNews/MORAESVkey" />
                  <Link hidden href="/FoundationNews/UFwhitneyLab" />

                  <Link hidden href="/Research/Differencesinvisualperception" />
                  <Link hidden href="/Research/UCFMarineTurtleResearchGroup" />
                  <Link hidden href="/Research/FloridaHawksbillProject2020" />
                  <Link hidden href="/Research/AMDRO" />
                  <Link hidden href="/Research/IdentifyingSexinHatchlingTurtles" />
                  <Link hidden href="/Research/MigrationofLoggerheadHatchlings" />
                  <Link hidden href="/Research/AnimalBehaviorandVisualPerception" />
                  <Link hidden href="/Research/EpibiontsofHawksbill" />
                  <Link hidden href="/Research/StudyingFloridasLivingDinosaurs" />
                  <Link hidden href="/Research/SatelliteTracking" />
                  <Link hidden href="/Research/RecoveryatGumboLimbo" />
                  <Link hidden href="/Research/StudySeaTurtlesinBiscayneNationalPark" />
                  <Link hidden href="/Research/EvaluatingtheEffectsofPaternity" />
                  <Link hidden href="/Research/CanMarineTurtlesNavigate" />
                  <Link hidden href="/Research/OctopusSuperpowers" />
                  <Link hidden href="/Research/SeekingTheGreenTurtles" />
                  <Link hidden href="/Research/HawksbillTurtleAggregation" />
                  <Link hidden href="/Research/Whysexingaseaturtlehatchlingisimportant" />
                  <Link hidden href="/Research/HawksbillProjectHighlightedinVideoProduction" />


                  <Link hidden href="/AdoptSeaTurtle" />

                  <Link hidden href="/WeSupport" />
                  <Link hidden href="/Sponsors" />
                  <Link hidden href="/Contact" />

                </div>


                <Switch>
                  <Route exact="true" path="/" component={Home} />
                  <Route exact="true" path="/Education" component={Education} />
                  <Route exact="true" path="/HawksbillProject" component={HawksbillProject} />
                  <Route exact="true" path="/Conservation" component={Conservation} />
                  <Route exact="true" path="/FoundationNews" component={FoundationNews} />
                  <Route exact="true" path="/FoundationNews/LeatherbackSeaTurtleVision" component={LeatherbackSeaTurtleVision} />
                  <Route exact="true" path="/FoundationNews/WaterwayCleanup" component={WaterwayCleanup} />
                  <Route exact="true" path="/FoundationNews/FloridaSeaTurtlesGetaBoost" component={FloridaSeaTurtlesGetaBoost} />
                  <Route exact="true" path="/FoundationNews/SargassumBloomsandHatchlingProduction" component={SargassumBloomsandHatchlingProduction} />
                  <Route exact="true" path="/FoundationNews/AReelDilemma" component={AReelDilemma} />
                  <Route exact="true" path="/FoundationNews/memoryofLlewellynEhrhart" component={memoryofLlewellynEhrhart} />
                  <Route exact="true" path="/FoundationNews/NewAluminumNestMarkingSigns" component={NewAluminumNestMarkingSigns} />
                  <Route exact="true" path="/FoundationNews/SeaTurtleMonitoringandSatelliteTracking" component={SeaTurtleMonitoringandSatelliteTracking} />
                  <Route exact="true" path="/FoundationNews/SeaTurtleAdventures2021" component={SeaTurtleAdventures2021} />
                  <Route exact="true" path="/FoundationNews/StatewideNestingSummary2018" component={StatewideNestingSummary2018} />
                  <Route exact="true" path="/FoundationNews/SeaTurlteAdventures" component={SeaTurtleAdventures} />
                  <Route exact="true" path="/FoundationNews/EducationatFIUCenterOceans" component={EducationatFIUCenterOceans} />
                  <Route exact="true" path="/SeaTurtleFacts" component={SeaTurtleFacts} />
                  <Route exact="true" path="/SeaTurtles/Hawksbill" component={Hawksbill} />
                  <Route exact="true" path="/SeaTurtles/KempsRidley" component={KempsRidley} />
                  <Route exact="true" path="/SeaTurtles/Loggerhead" component={Loggerhead} />
                  <Route exact="true" path="/SeaTurtles/Green" component={Green} />
                  <Route exact="true" path="/SeaTurtles/Leatherback" component={Leatherback} />
                  <Route exact="true" path="/Membership/BecomeAMember" component={BecomeAMember} />
                  <Route exact="true" path="/ScholarshipProgram" component={ScholarshipProgram} />
                  <Route exact="true" path="/CashDonation" component={CashDonation} />

                  {/* <Route exact="true" path="/Component/HawksbillProjectForm" component={HawksbillProjectForm} /> */}
                  <Route exact="true" path="/FoundationNews/DaringExcitingToothy" component={DaringExcitingToothy} />
                  <Route exact="true" path="/FoundationNews/ClassroomEducation" component={ClassroomEducation} />
                  <Route exact="true" path="/FoundationNews/OutandAbout" component={OutandAbout} />
                  <Route exact="true" path="/FoundationNews/NavigatingtheFuture" component={NavigatingtheFuture} />
                  <Route exact="true" path="/FoundationNews/SupportsEducation" component={SupportsEducation} />
                  <Route exact="true" path="/FoundationNews/WhatAreYouDoing" component={WhatAreYouDoing} />
                  <Route exact="true" path="/FoundationNews/MaleGreenTurtles" component={MaleGreenTurtles} />
                  <Route exact="true" path="/FoundationNews/LeatherbackNesting" component={LeatherbackNesting} />
                  <Route exact="true" path="/FoundationNews/SaltyAirSea" component={SaltyAirSea} />
                  <Route exact="true" path="/FoundationNews/SandyPoint" component={SandyPoint} />
                  <Route exact="true" path="/FoundationNews/LearningLeatherbacks" component={LearningLeatherbacks} />
                  <Route exact="true" path="/FoundationNews/FAUsecondYear" component={FAUsecondYear} />
                  <Route exact="true" path="/FoundationNews/NovaMember" component={NovaMember} />
                  <Route exact="true" path="/FoundationNews/SupportFLgulf" component={SupportFLgulf} />
                  <Route exact="true" path="/FoundationNews/GlobalSeaTurtle" component={GlobalSeaTurtle} />
                  <Route exact="true" path="/FoundationNews/TrunksTravelSouth" component={TrunksTravelSouth} />
                  <Route exact="true" path="/FoundationNews/InwaterResearch" component={InwaterResearch} />
                  <Route exact="true" path="/FoundationNews/MEECadds" component={MEECadds} />
                  <Route exact="true" path="/FoundationNews/UCFReceives" component={UCFReceives} />
                  <Route exact="true" path="/FoundationNews/FinalReport2020" component={FinalReport2020} />
                  <Route exact="true" path="/FoundationNews/TemperatureInterview" component={TemperatureInterview} />
                  <Route exact="true" path="/FoundationNews/MarineTurtleHistory" component={MarineTurtleHistory} />
                  <Route exact="true" path="/FoundationNews/MikeSalmon" component={MikeSalmon} />
                  <Route exact="true" path="/FoundationNews/RecipientsChosen" component={RecipientsChosen} />
                  <Route exact="true" path="/FoundationNews/WhitneyLab" component={WhitneyLab} />
                  <Route exact="true" path="/FoundationNews/IndianRiver" component={IndianRiver} />
                  <Route exact="true" path="/FoundationNews/FAUlabRefreshed" component={FAUlabRefreshed} />
                  <Route exact="true" path="/FoundationNews/FGCUawards" component={FGCUawards} />
                  <Route exact="true" path="/FoundationNews/BlackSaltmarsh" component={BlackSaltmarsh} />
                  <Route exact="true" path="/FoundationNews/DrErinSeney" component={DrErinSeney} />
                  <Route exact="true" path="/FoundationNews/NewTurtleModel" component={NewTurtleModel} />
                  <Route exact="true" path="/FoundationNews/UCFscholarshipAward" component={UCFscholarshipAward} />
                  <Route exact="true" path="/FoundationNews/UVIpartnership" component={UVIpartnership} />
                  <Route exact="true" path="/FoundationNews/FLHawkbill2020" component={FLHawkbill2020} />
                  <Route exact="true" path="/FoundationNews/InwaterJupiter" component={InwaterJupiter} />
                  <Route exact="true" path="/FoundationNews/LeatherbackLight" component={LeatherbackLight} />
                  <Route exact="true" path="/FoundationNews/SandyPoint2020" component={SandyPoint2020} />
                  <Route exact="true" path="/FoundationNews/MeetStudents" component={MeetStudents} />
                  <Route exact="true" path="/FoundationNews/AmazingMantas" component={AmazingMantas} />
                  <Route exact="true" path="/FoundationNews/CostalCleanup" component={CostalCleanup} />
                  <Route exact="true" path="/FoundationNews/TurlteAdventureExpand" component={TurlteAdventureExpand} />
                  <Route exact="true" path="/FoundationNews/InsightsKeewaydin" component={InsightsKeewaydin} />
                  <Route exact="true" path="/FoundationNews/EastCoastBiologists" component={EastCoastBiologists} />
                  <Route exact="true" path="/FoundationNews/WarmingOliveRidley" component={WarmingOliveRidley} />
                  <Route exact="true" path="/FoundationNews/SargassoSea" component={SargassoSea} />
                  <Route exact="true" path="/FoundationNews/InsightsKeewaydin2" component={InsightsKeewaydin2} />
                  <Route exact="true" path="/FoundationNews/EducationDuringCOVID" component={EducationDuringCOVID} />
                  <Route exact="true" path="/FoundationNews/SargassumNowWorld" component={SargassumNowWorld} />
                  <Route exact="true" path="/FoundationNews/StcroixProject" component={StcroixProject} />
                  <Route exact="true" path="/FoundationNews/PHDstudents" component={PHDstudents} />
                  <Route exact="true" path="/FoundationNews/HelpedBioAlum" component={HelpedBioAlum} />
                  <Route exact="true" path="/FoundationNews/StyrofoamBoxes" component={StyrofoamBoxes} />
                  <Route exact="true" path="/FoundationNews/BrowardCountryNesting" component={BrowardCountryNesting} />
                  <Route exact="true" path="/FoundationNews/Hawksbill2021" component={Hawksbill2021} />
                  <Route exact="true" path="/FoundationNews/InsideTurtleNest" component={InsideTurtleNest} />
                  <Route exact="true" path="/FoundationNews/InwaterTeachesPasco" component={InwaterTeachesPasco} />
                  <Route exact="true" path="/FoundationNews/MORAESVkey" component={MORAESVkey} />
                  <Route exact="true" path="/FoundationNews/UFwhitneyLab" component={UFwhitneyLab} />
                  <Route exact="true" path="/Research/Differencesinvisualperception" component={Differencesinvisualperception} />
                  <Route exact="true" path="/Research/UCFMarineTurtleResearchGroup" component={UCFMarineTurtleResearchGroup} />
                  <Route exact="true" path="/Research/FloridaHawksbillProject2020" component={FloridaHawksbillProject2020} />
                  <Route exact="true" path="/Research/AMDRO" component={AMDRO} />
                  <Route exact="true" path="/Research/IdentifyingSexinHatchlingTurtles" component={IdentifyingSexinHatchlingTurtles} />
                  <Route exact="true" path="/Research/MigrationofLoggerheadHatchlings" component={MigrationofLoggerheadHatchlings} />
                  <Route exact="true" path="/Research/AnimalBehaviorandVisualPerception" component={AnimalBehaviorandVisualPerception} />
                  <Route exact="true" path="/Research/EpibiontsofHawksbill" component={EpibiontsofHawksbill} />
                  <Route exact="true" path="/Research/StudyingFloridasLivingDinosaurs" component={StudyingFloridasLivingDinosaurs} />
                  <Route exact="true" path="/Research/SatelliteTracking" component={SatelliteTracking} />
                  <Route exact="true" path="/Research/RecoveryatGumboLimbo" component={RecoveryatGumboLimbo} />
                  <Route exact="true" path="/Research/StudySeaTurtlesinBiscayneNationalPark" component={StudySeaTurtlesinBiscayneNationalPark} />
                  <Route exact="true" path="/Research/EvaluatingtheEffectsofPaternity" component={EvaluatingtheEffectsofPaternity} />
                  <Route exact="true" path="/Research/CanMarineTurtlesNavigate" component={CanMarineTurtlesNavigate} />
                  <Route exact="true" path="/Research/OctopusSuperpowers" component={OctopusSuperpowers} />
                  <Route exact="true" path="/Research/SeekingTheGreenTurtles" component={SeekingTheGreenTurtles} />
                  <Route exact="true" path="/Research/HawksbillTurtleAggregation" component={HawksbillTurtleAggregation} />
                  <Route exact="true" path="/Research/Whysexingaseaturtlehatchlingisimportant" component={Whysexingaseaturtlehatchlingisimportant} />
                  <Route exact="true" path="/Research/HawksbillProjectHighlightedinVideoProduction" component={HawksbillProjectHighlightedinVideoProduction} />
                  <Route exact="true" path="/AdoptSeaTurtle" component={AdoptSeaTurtle} />
                  <Route exact="true" path="/WeSupport" component={WeSupport} />
                  <Route exact="true" path="/Sponsors" component={Sponsors} />
                  <Route exact="true" path="/Contact" component={Contact} />
                </Switch>
              </motion.div>
            </div>
          </BrowserRouter>


        </div>
        <div >
          <SocialMedia />
        </div>
        <div className="lg:mb-10">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;