import React from "react";
import { Typography, Divider } from "@mui/material";
import FIUCenterResearch1 from "../Images/FIUs-Center-for-Coastal-Oceans-Research.jpg";
import AquariusUnderwater from "../Images/Aquarius-Underwater-Laboratory-in-the-Florida-Keys.jpg";
import JRAquanaut from "../Images/JR-Aquanaut.jpg";
import FIUsamples1 from "../Images/FIU-Research-Team-collecting-samples.jpg";

export default function EducationatFIUCenterOceans() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex gap-5">

        <div className="py-2">
          <div className="lg:w-3/4">
            <h1 className="text-2xl font-bold pb-5">
              Education and Research at FIU’s Center for Coastal Oceans Research
            </h1>
            <div>
              <Typography paragraph>
                Analisa Duran Education & Outreach Program Coordinator, Center for Coastal Oceans
                Research Florida International University
              </Typography>
            </div>
          </div>
        </div>
        <div className="lg:w-1/4">
          <div className="lg:float-right mb-5">
            <img className="rounded-2xl mx-auto" src={FIUCenterResearch1} alt="" title="" />
          </div>

        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="mt-5">
        <Typography paragraph>
          The Center for Coastal Oceans Research at Florida International University (FIU)
          provides a unique educational opportunity for learners of all ages and serves as a
          marine-related research hub for conservation, preservation, and action-oriented
          solutions for some of our ocean’s most pressing challenges.
        </Typography>
      </div>
      <div>
        <div>
          <div className="lg:float-right lg:ml-5">
            <div>
              <img className="rounded-2xl mx-auto" src={AquariusUnderwater} alt="" title="" />
            </div>
            <div className="pt-2">
              <em>
                Aquarius Underwater Laboratory in the Florida Keys National<br />

                Marine Sanctuary. Photo credit: DJ Roller
              </em>
            </div>
          </div>
          <div className="pt-5">
            <Typography paragraph>
              As the rate of faculty and graduate student publications based on work in the Florida
              Keys increased tremendously, a program to improve the academic environment for
              residents was created. The initiative continued to grow and was known as the Marine
              Education and Research Center (MERC), housed within the Institute of Water and
              Environment (InWE) in the College of Arts, Sciences and Education. Today, MERC has
              rebranded and is known as the Center for Coastal Oceans Research (CCOR).
            </Typography>
          </div>

          <div>
            <Typography paragraph>
              The educational programs in the Florida Keys include Family Science Night, Ocean Life
              Series seminars, acting as judges for school and county science fairs, visiting
              schools in Monroe county to educate about CCOR initiatives, and attending and creating
              community science-driven events. The desired outcomes of these programs are to inspire
              environmental stewardship, educate about the marine world by connecting science to the
              community, and peak the curiosity of students to be interested in science. CCOR also
              facilitates global Skype in the Classroom programs that have reached over 1 million
              students since 2013.
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className="lg:float-left lg:mr-5">
            <div>
              <img className="rounded-2xl mx-auto" src={JRAquanaut} alt="" title="" />
              <div className="pt-2">
                <em>
                  Education and Outreach
                  <br />
                  JR Aquanaut trying on a real dive helmet.
                  <br />
                  Photo credit: CASE News
                </em>
              </div>
            </div>
          </div>
          <div>
            <Typography paragraph>
              The research projects within the center range from seagrass and blue carbon, to the
              Global Finprint project and shark populations & finning. There are a total of 17
              faculty members whose PhD, graduate, and undergraduate students are working toward
              research and discoveries within the marine environment. The center encompasses
              Aquarius Undersea Laboratory in Islamorada, Florida as well as successful partnerships
              with Rookery Bay Research Reserve in Naples, Florida and the Florida Keys National
              Marine Sanctuary.
            </Typography>
          </div>

          <div>
            <Typography paragraph>
              Aquarius Undersea Laboratory is an integral part of the center and has been a part of
              FIU since 2013. Aquarius is the world’s only underwater research laboratory and is
              deployed in 60 feet of water in the Florida Keys National Marine Sanctuary about 5
              nautical miles off shore from Tavernier Florida. Here, scientists can be immersed in
              their research for weeks at a time through a process called saturation diving.
              Saturation diving gives aquanauts the opportunity to spend nine hours a day diving
              down to 95 feet to conduct their research. When their mission is accomplished aboard
              Aquarius, aquanauts need to only decompress once before returning to the surface. It
              could take as long as six months of surface-based diving to conduct as much research
              done during a 10-day Aquarius mission!
            </Typography>
          </div>
          <div>
            <Typography paragraph>
              Aquarius provides scientists with the opportunity to conduct crucial research covering
              a variety of areas including water quality, coral reef biology, ecology, and
              physiology, long-term ocean monitoring, restoration science, ocean acidification, and
              global climate change. Aquarius also serves as a space analog for astronauts who are
              preparing to go into space. Over 700 publications have resulted Aquarius missions.
            </Typography>
          </div>
          <div>
            <Typography paragraph>
              If you are interested in learning more about CCOR or participating in one of their
              events, please contact the center’s Education & Outreach Coordinator at:
              anduran@fiu.edu or visit ccor.fiu.edu
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="mx-auto">
          <img className="rounded-2xl mx-auto" src={FIUsamples1} alt="" title="" />
          <div className="pt-2 flex justify-center items-center">
            <em>FIU Research Team collecting samples. Photo credit: CASE News</em>
          </div>
        </div>
      </div>
    </div>
  );
}
