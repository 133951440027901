import React from "react";
import { Divider, Typography } from "@mui/material";
import Hawk1 from "../Images/Hawksbill-turtles.jpg";
import HawkShells from "../Images/Hawksbill-turtles-shells.jpg";
import Logo from "../Images/florida-hawksbill-project-logo.jpg";
import LesTan from "../Images/Science-educators-Leslie-and-Tanner.jpg";
import YoungH from "../Images/young-hawksbill.jpg";
import NickR from "../Images/Ms-Cole-Reintsma-Mr-Rick-Newman-and-Captain-Nick-Reeves.jpg";

export default function HawksbillTurtleAggregation() {
  return (
    <div className="dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl">
      <div className="pb-5">
        <h1 className="text-2xl font-bold">
          Hawksbill Turtle Aggregation Being Documented in the Lower Florida Keys
        </h1>
      </div>
      <Divider className="dark:bg-[#032546] bg-white/70" />
      <div className="lg:flex my-5">
        <div className="lg:w-2/3">

          <Typography paragraph>Larry Wood, Ph.D.</Typography>
          <Typography paragraph>
            Since its inception in 2004, the Florida Hawksbill Project has aimed to gather
            comprehensive data concerning the abundance, distribution, and behavior of hawksbills in
            Florida waters. The majority of the effort has been focused on the reefs of Palm Beach
            County, where their abundance has been important in developing a baseline from which we
            can compare aggregations elsewhere.
          </Typography>
          <div className="lg:float-left mr-5">
            <img className="rounded-2xl mx-auto" src={HawkShells} alt="" title="" />
            <em>
              Hawksbill turtles are known for the unparalleled <br /> beauty of their shells. Each
              plate, known a s a <br /> “scute”, is made of keratinized tissue, just like <br />
              your fingernails. Hawksbill scutes have been <br /> prized by artisans for centuries.
            </em>
          </div>
          <div className="pt-5">
            <Typography paragraph>
              With the support of the National Save The Sea Turtle Foundation, the Project has been
              able to extend its focus to the opposite end of the Florida Reef Tract, in the beautiful
              shallow coral reefs just off the shores of Key West. Here, team members were able to
              discover areas most likely to be inhabited by hawksbills, and were able to capture six
              individuals for evaluation and documentation. They were healthy young turtles with
              strikingly patterned heads and beautiful shells. As expected, these turtles were, on
              average, smaller than those we typically find in the deeper waters of Palm Beach County,
              indicating that they have more recently arrived from pelagic ‘juvenile-stage’ habitats
              to the nearshore ‘subadult-stage’ environment. We suspect that some may move northward
              along Florida’s east coast as they mature, and carry with them clues of their
              whereabouts as they go. To test our hypothesis, we are taking small samples of blood,
              skin, and shell from hawksbills all along the coastline to help track their intake of
              carbon, nitrogen, and sulfur as they’ve grown. Some of the variants of these elements
              are known to be unique to certain food sources and locations along Florida’s coast, thus
              leaving a timeline of their intake (and associated location) within the slow-growing
              tissues of the turtles.{" "}
            </Typography>
          </div>
        </div>
        <div className="lg:w-1/3 m-5">
          <img className="rounded-2xl" src={Hawk1} alt="" title="" />
          <em>
            Hawksbill turtles were briefly retained aboard the RV Hawksbill II for measurements,
            tagging, sampling and photographs. They were then released where they were found.{" "}
          </em>
          <img className="rounded-2xl my-5 mx-auto" src={Logo} alt="" title="" />
        </div>
      </div>


      <div className="lg:flex gap-5">
        <div className="lg:w-1/3 my-3">
          <div><img className="rounded-2xl mx-auto" src={LesTan} alt="" title="" /></div>
          <div className="pt-2 text-center">
            <em>
              Science educators Leslie and Tanner Thomas <br /> joined us as volunteers as we searched
              for <br /> hawksbills offthe coast of Key West.
            </em>
          </div>
        </div>
        <div className="lg:w-1/3 my-3">
          <div><img className="rounded-2xl" src={YoungH} alt="" title="" /></div>
        </div>
        <div className="lg:w-1/3 my-3">
          <div> <img className="rounded-2xl" src={NickR} alt="" title="" /></div>
          <div className="pt-2">
            <em>
              Ms. Cole Reintsma, Mr. Rick Newman, and Captain Nick <br /> Reeves with a newly tagged
              young hawksbill turtle, <br /> Eretmochelys imbricata.
            </em>
          </div>
        </div>
      </div>




    </div>
  );
}
