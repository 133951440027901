import React from "react";
import { Typography, Divider } from "@mui/material";
import loggerheadiscourtedbytwomales from '../Images/loggerhead-is-courted-by-two-males.jpg'
import FAUCESCOS from '../Images/FAU-CES-COS.jpg'
import variationinhatchlingproduction from '../Images/variation-in-hatchling-production.jpg'
import distributionofclutchsizesnest from '../Images/distribution-of-clutch-sizes-in-nest.jpg'
import Ratiosnitrogentocarbon from '../Images/Ratios-of-nitrogen-to-carbon.jpg'

export default function InsightsKeewaydin2() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:float-right lg:ml-5 mb-5"><img className="rounded-2xl border-4 border-white mx-auto" src={FAUCESCOS} alt="" title="" /></div>
      <div><div className='pb-5'><h1 className='text-2xl font-bold'>Insights from Keewaydin Island, II. How Are
        Reproductively Superior Female Loggerheads Made?
      </h1></div></div>
      <div><Typography paragraph>Mike Salmon, Research Professor, Florida Atlantic University</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="lg:flex lg:gap-4 mt-5">
        <div className="lg:w-1/2">
          <div><Typography>There is no question that the way large creatures go about the business of reproduction is complicated and diverse, so much so that there seems to be no end to how the process is described. Biologists have tried over the years to come up with overarching “principles” based upon characteristic of the two main participants, specifically, males and females.</Typography></div>
          <div className="mt-5"><img className="rounded-2xl" src={loggerheadiscourtedbytwomales} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>Figure 1. A mating scene in which a female loggerhead is courted by two males, only one of which (clinging to her shell) is mating with her. The second (top) male will periodically attempt to dislodge the male below him, and then also try to inseminate the female</em></div>
          <div><Typography paragraph>Darwin was the first to do so with competence. His distinctions were based upon both morphology and behavior. He characterized males as the sex most likely to possess specialized weaponry (e.g. horns, claws, teeth) used to compete aggressively with other males for access to females. A consequence was the increased likelihood of injuries and a shortened life span. But for males, “success” was measured by the number of matings, not by how long they lived! Left relatively unstudied until recently, however, was an understanding of the qualities serving as indicators of success among females.</Typography></div>
        </div>
        <div className="lg:w-1/2">
          <div></div>
          <div><Typography paragraph>Those are now better appreciated and include remaining inconspicuous, avoiding conflict or danger whenever possible, using food to grow efficiently and producing as many quality offspring as possible over a long life span. Females, unlike males, also selected mates carefully using either direct benefits (nuptual gifts; prime territories or nesting sites; help defending and rearing offspring) or indirect benefits (attractive courtship displays that might be passed on to that female’s sons; dominance in contests with other males). Under yet other circumstances, females let males sort themselves though contests, then mated with the winner.</Typography></div>
          <div><Typography paragraph>In marine turtles, any benefits provided by males are indirect as after mating, males depart leaving females to select nesting sites and deposit clutches of eggs. Mating (Figure 1) occurs at sea, usually while females are migrating from their foraging sites to the nesting beach. Females, once receptive, do not appear to exert much mate choice; after mating with two or three males, they avoid additional suitors as overly zealous males, fighting with one another for matings, can inadvertently injure females. Biologists studying female reproductive success (r.s.) were thus forced to shift focus to more relevant and obvious variables: (i) the number of eggs she deposits in her nests; (ii) the proportion of those eggs that develop into hatchlings that enter the sea; and (iii) the number of years she takes to accumulate enough energy to nest again (the “remigration interval”). Studies done at two sites in Florida (Keewaydin Island; the Archie Carr National Wildlife Refuge [hereafter, the Refuge]), and at Yakushima Island, Japan, have begun to illuminate what it takes to be a “superior” female. Most surprising, it appears that chance events during earlier development play a dominant role in determining a female’s r.s. years later, once she achieves sexual maturity.</Typography></div>
          <div><Typography paragraph>Female Reproductive Success at Keewaydin Island.</Typography></div>
          <div><Typography paragraph>As a graduate student at Florida Atlantic University, Shelby Hoover took on the formidable task of analyzing 33 years of data (1986 - 2018) obtained from the loggerhead nesting beach at Keewaydin Island on the West Coast of Florida. Each summer, most of the nesting females are tagged so they can be identified when they return, years later. Their nests are also monitored to determine how many hatchlings ultimately enter the sea. </Typography></div>
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="lg:flex lg:gap-4 mt-5">
        <div className="lg:w-1/2">
          <div><Typography paragraph>When Hoover examined the data, she found that clutch size (the number of eggs/nest) averaged 102 eggs per nest, but was highly variable among females (Figure 2). In addition, so was hatchling production (Figure 3). The 55 most successful females (the upper 17 % of the population) on average placed more eggs (118) in each nest than the 60 least successful (bottom 17 % of) females, that placed 90 eggs in each nest. More hatchlings (mean of 93 turtles) emerged from the nests of the successful than the unsuccessful females (mean of 42 hatchlings; Figure 3). The two female groups, however, did not differ in remigration intervals (on average, 2 years), in where on the beach they placed their nests, or in their age and breeding experience. The most obvious difference was that the successful females were about 1.3 x larger than the unsuccessful females. That difference was important because larger females at Keewadin (and in loggerheads nesting elsewhere) produce larger clutches (1).</Typography></div>
          <div><Typography paragraph>Isotope Ratios and Female R.S.: Yakushima Island and the Refuge</Typography></div>
          <div><Typography paragraph>Two additional studies took advantage of a relatively new and important tool for identifying differences among females in r.s.: ratios between carbon and nitrogen isotopes. Those ratios reflect the nutritional value and abundance of prey where females feed during the remigration interval. The basic rule in isotope research is: you are what you eat! That means that the isotope ratios in small tissue samples (skin and blood) from females reflect the ratios present in the food they consumed so those ratios can be used to explore relationships between where females forage, what they eat and their r.s.</Typography></div>
          <div><img className="rounded-2xl mx-auto" src={variationinhatchlingproduction} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>Figure 3. Graph showing the variation in hatchling production that occurs among the nests at Keewaydin Island. On average, 67 hatchlings emerge from each nest but those numbers are vastly different for the least (red distribution) and most (blue distribution) successful females. (5)</em></div>
        </div>
        <div className="lg:w-1/2">
          <div><img className="rounded-2xl mx-auto" src={distributionofclutchsizesnest} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>Figure 2. Graph showing the distribution of clutch sizes in nest deposited by females at Keewaydin Island. Nests on average contain 102 eggs but clutch size shows lots of variation. (5)</em></div>
          <div><Typography paragraph>Other studies, in which female movements have been tracked using satellite telemetry, have established that they return with great fidelity to specific foraging sites. As one might expect, more productive females feed at sites where food is abundant and rich, whereas less productive females feed at sites where food is scarce or of lower nutritional value.</Typography></div>
          <div><Typography paragraph>We’ve also learned that once a female loggerhead reaches sexual maturity, she stops diverting energy to growth; instead, that energy is used to fuel migration from the foraging site to the nesting beach, and to produce hundreds of eggs. Thus, females achieving greater than average size do so while still immature, most likely because on average they also show strong fidelity to generally richer foraging sites than do other females. This leads to the conclusion that differences between females in size (and r.s.) are to a large extent the consequence of events that occur years before they reach sexual maturity. Recent studies done on turtles inhabiting the Pacific and Atlantic ocean basins reinforce these ideas.</Typography></div>
          <div className="pb-5"><Typography paragraph>Pacific loggerheads usually nest in Japan. In studies done at Yakushima Island (2), females differed significantly in size and, as expected, smaller females (16 % of the nesting population) deposit fewer eggs in fewer nests/season than did larger females. Their remigration intervals were also longer (~ 4 years) than those of the larger females (~ 2 years). Isotope analyses revealed that both female groups showed strong fidelity to specific foraging areas.</Typography></div>
        </div>

      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />

      <div className="mt-5">
        <div className="lg:float-right lg:ml-5">
          <div><img className="rounded-2xl mx-auto" src={Ratiosnitrogentocarbon} alt="" title="" /></div>
          <div className="pt-2"><em>Figure 4. Ratios of nitrogen to carbon found in tissue samples taken from<br />loggerhead females
            nesting at Yakushima Island, Japan.<br />Those ratios are lower at the less productive oceanic sites
            where the smaller females<br />forage (open circles)and higher at the more productive coastal sites
            where<br />the larger females forage (closed circles; modified from [2]).</em></div>
        </div>
        <div><Typography paragraph>Smaller turtles fed on planktonic prey found in less productive oceanic sites whereas larger turtles fed on bottom-dwelling, nutritionally richer prey found in shallow coastal waters (Figure 4). However, and unlike the Keewaydin turtles, a similar proportion of the eggs placed in the nests of both groups developed into hatchlings that left the nest. There were also no differences between those hatchlings in either their growth rates or vigor, nor was there any evidence that the two groups of females differed genetically; they differed only in size and in choice of foraging location that, once made, didn’t change (3).</Typography></div>
        <div><Typography paragraph>Isotope analyses revealed that females nesting at the Refuge on Florida’s East Coast foraged at one of 4 locations: sites to the north of Cape Hatteras, in the Bahamas, in the Florida Keys, and in the shallows of the southeastern Gulf of Mexico (4). The most productive sites were northern and, as expected, the largest females nesting at the Refuge fed there. But contrary to expectations, those females produced clutches similar in size to the females feeding in the Bahamas and Florida Keys. The smallest clutches were produced by females foraging in the SE Gulf. That outcome may have resulted from the interaction costs associated with food availability and migration distance. Females feeding at northern sites and in the SE Gulf experienced high migratory costs as they swam longer distances than others to reach the refuge. But those costs for the northern females were probably tempered by their access to a rich food supply. The Gulf of Mexico females, however, fed where food quality was low and so experienced a combined negative migratory as well as foraging cost. And so while larger adult females have the potential to express greater r.s., whether that happens depends not only upon where she feeds, but also upon where she nests! (4)</Typography></div>
      </div>



      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="pt-5"><Typography paragraph>Conclusions and Speculations</Typography></div>
      <div><Typography paragraph>Collectively, these studies reveal that female marine turtles vary from one another in r.s. and that this variation is strongly associated with how large they grow. Growth rates in all animals are determined by both genetics and environment. We raise loggerheads in our lab and commonly observe that hatchlings, even siblings from the same nest reared under identical conditions, grow at different rates. Those differences are manifestations of genetics. But the correlation between female size and her food supply is a consequence of the environment where she feeds. Thus, both genetics and environment shape how females grow.</Typography></div>
      <div><Typography paragraph>Unfortunately, we don’t know what processes are involved in the selection of foraging sites. Based upon presently available information, it appears that chance plays a major role. For example, juvenile loggerheads shift between foraging sites both seasonally, and as they grow. Those sites vary in productivity, every bit as much as those selected by adult turtles that do not shift foraging location over time. The obvious conclusion is that in a highly migratory species like a marine turtle, environmental factors that eventually determine a female’s r.s. are highly variable. How they might affect the development of any individual turtle can’t be predicted in advance of sexual maturity. At the same time, that uncertainty may explain why differences among females in r.s. persist. Natural selection can’t impose penalties on less productive females if the hatchlings they produce are, like those at Yakushima Island, as likely as those of more productive females to produce offspring with an equal potential for growth. (Thanks to Dave Addison and Samantha Trail for comments that improved the quality of this essay.)</Typography></div>
      <div><Typography paragraph>Further Reading and References</Typography></div>
      <div><Typography>(1) K. R. Phillips et al. 2021. Postnesting migration routes and fidelity to forging sites among loggerhead turtles in the western North Atlantic. Bulletin of Marine Science https://doi.org/ 10.5343/ bms.2019.0099.</Typography></div>
      <div><Typography>(2) H. Hatase et al. 2013. A mechanism that maintains alternative life histories in a loggerhead sea turtle population. Ecology 94: 2583-2594</Typography></div>
      <div><Typography>(3) H. Hatase et al. 2018. Effect of maternal foraging habitat on offspring quality in the loggerhead sea turtle (Caretta caretta). Ecology and Evolution 8:3543–3555. https://doi.org/10.1002/ece3.3938</Typography></div>
      <div><Typography>(4) S. A. Ceriani et al. 2015. Carry-over effects and foraging ground dynamics of a major loggerhead breeding aggregation. Marine Biology 162(10):1955–1968. https://doi.org/10.1007/ s00227-015-2721-x</Typography></div>
      <div><Typography>(5) S. Hoover et al. 2019. Establishing baselines to identify the correlates of declining emergence success of loggerhead nests at Keewaydin Island, Florida, U.S.A. Poster presentation, International Conference on Sea Turtle Biology and Conservation. 4</Typography></div>
    </div>
  );
}
