import React from "react";
import { Typography, Divider } from "@mui/material";
import NumbersIn from "../Images/The-Numbers-Are-In-2018.jpg";
import GreenNestFL from "../Images/Green-turtle-nesting-in-Florida.jpg";
import LoggerHeadNest from "../Images/Floridas-loggerhead-turtle-nest-counts.jpg";
import LeatherbackNest from "../Images/Leatherback-turtle-nesting-occurs-in-Florida.jpg";

export default function StatewideNestingSummary2018() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <h1 className="text-2xl my-5">2018 Nesting Summary</h1>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div>
        <img className="rounded-3xl py-5 mx-auto" src={NumbersIn} alt="" title="" />
      </div>
      <div className="lg:flex gap-4">
        <div className="lg:w-1/2">
          <div>
            <Typography paragraph>
              Florida&apos;s beaches are famous for more than one reason... not only are they
              beautiful, but they are also host to a globally significant number of sea turtle nests
              each summer. This remarkable natural phenomenon has inspired a virtual army of turtle
              enthusiasts accomplish what would otherwise be an overwhelming task for our State
              environmental agencies: From professional biologists to retired volunteers, nearly
              3,000 people from all around the State of Florida participate in organized sea turtle
              nesting counts that are critical to answering one of our most commonly asked
              questions: How are the turtles doing?
            </Typography>
          </div>
          <div>
            <Typography paragraph>
              One of the big challenges of conserving any long lived, wide-ranging marine creature
              is knowing how many there actually are. We can&apos;t easily count them to estimate
              population numbers, and generation times can often match ours, so it takes a long time
              to recognize either a recovery or a decline in a population that we may be interested
              in studying. Lucky for us, sea turtles need to return to land to nest, so there&apos;s
              a relatively easy way for us to keep track of population trends through careful
              documentation of each
            </Typography>
          </div>
        </div>
        <div className="lg:w-1/2 py-5">
          <img className="rounded-2xl" src={GreenNestFL} alt="" title="" />
        </div>
      </div>
      <div className="lg:flex gap-4">
        <div className="lg:w-1/2 pb-5">
          <img className="rounded-2xl" src={LoggerHeadNest} alt="" title="" />
        </div>
        <div className="lg:w-1/2">
          <Typography paragraph>
            <em>
              “Green turtle nesting in Florida is interesting in two ways. First, there seems to be
              a burgeoning population of females using Florida as nesting grounds, so it looks as if
              green turtles are responding well to long-term regional conservation efforts. Second,
              they have a unique and conspicuous bi-annual pattern of nesting activity. If the
              pattern holds, there may be another record set in 2019!”
            </em>
          </Typography>
          <div>
            <Typography paragraph>
              species&apos; reproductive effort over time. Of course, there are limitations; e.g.
              only females come ashore, and we don&apos;t get a total &apos;count&apos; of the
              number of individuals actually involved each year because each female may nest
              multiple times, but we&apos;re mostly interested in how each population is doing, and
              careful nest counts have proven to be a very useful proxy for studying sea turtle
              population trends.
            </Typography>
          </div>
          <div>
            <Typography paragraph>
              In Florida, there are three species of sea turtle that nest each year with any
              regularity; the loggerhead, green, and leatherback turtles. Of particular interest to
              Florida&apos;s sea turtle scientists are the impressive numbers of loggerhead turtle
              nests that are documented each year, which can exceed 100,000. Because this
              reproductive effort represents ~90% of the entire northwest Atlantic loggerhead
              population, the data collected on loggerhead turtles in Florida are especially
              meaningful for the preservation of this species in the Atlantic Ocean.
            </Typography>
          </div>
        </div>
      </div>
      <div className="lg:flex gap-4">
        <div className="lg:w-1/2">
          <Typography paragraph>
            <em>
              “Florida&apos;s loggerhead turtle nest counts have fluctuated from year to year and
              even decade to decade. However, long-term data are critical to understanding
              population trends, and it appears the loggerhead nesting population has remained
              fairly stable since the early 1990&apos;s.”
            </em>
          </Typography>
          <Typography paragraph>
            Thanks to nearly two decades of data, we see encouraging signs that the population,
            though it may have &apos;ups&apos; and &apos;downs&apos;, is fairly stable, and
            hopefully continues to have success in this extremely important rookery.
          </Typography>
          <Typography paragraph>
            For the greens and leatherbacks, Florida is only one of many nesting sites around the
            Caribbean, but still has important significance in maintaining the region&apos;s genetic
            diversity for both of these species. Green turtles come in second after the loggerheads
            in overall nest production in Florida, and in some locations actually produced more
            nests per kilometer than loggerheads over the last few years. Though no one knows why
            for sure, female green turtles have somehow synchronized their nesting activity to form
            a dramatic bi-annual pattern of high vs. low productivity, allowing us to
            &apos;expect&apos; high or low years. For those who count nests, 2019 should be a
            doozie!
          </Typography>
          <Typography paragraph>
            Leatherback turtles come in third on the list of Florida&apos;s nesters, certainly not
            for any other reason than they, comparatively, have a much smaller nesting population;
            notice the scale on the leatherback nesting chart is in the hundreds, not thousands.
            Leatherbacks are amazing giants that have begun to take Florida a little more seriously
            as a nesting site since around the year 2000, but remain somewhat of a mystery
            concerning nesting trends with lots of annual variation that doesn&apos;t seem to yet
            form a pattern. Either way, we wish them the best, and we hope the more recent declines
            are just a temporary glitch in a long-term positive trend.
          </Typography>
          <Typography paragraph>
            Many thanks to the Florida Fish and Wildlife Conservation Commission for providing the
            data and charts.
          </Typography>
        </div>

        <div>
          <img className="rounded-2xl" src={LeatherbackNest} alt="" title="" />
        </div>
      </div>
    </div>
  );
}
