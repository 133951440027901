import React from "react";
import { Typography, Divider } from "@mui/material";
import FloridaHawksbillProject2 from '../Images/Florida-Hawksbill-Project-2.jpg'
import BarnaclesthegenusChelonibia from '../Images/Barnacles-of-the-genus-Chelonibia.jpg'
import Researchteamsmallhawksbillturtle from '../Images/The-research-team-with-a-small-hawksbill-turtle.jpg'
import FloridaHawksbillProject2021 from '../Images/Florida-Hawksbill-Project-2021.png'
import newlyEmergedHatchlingHawksbill from '../Images/A-newly-emerged-hatchling-hawksbill-in-the-US-Virgin-Islands.jpg'

export default function Hawksbill2021() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className='pb-5'><h1 className='text-2xl font-bold'>Florida Hawksbill Project 2021
      </h1></div></div>
      <div><Typography paragraph>Larry Wood, Ph.D., NSTSTF Research Coordinator</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />

      <div className="lg:flex lg:gap-4 mt-5">
        <div className="lg:w-1/2">
          <div><Typography paragraph>Now in its 18th year, the Florida Hawksbill Project remains the only research and conservation program in Florida that is focused on this Critically Endangered species. Known best for their beautiful shells (the original source of ‘tortoiseshell’ products), hawksbills are among the least adventuresome of the sea turtles, preferring to remain near tropical coral reefs for pretty much their whole lives.</Typography></div>
          <div className='lg:float-left lg:mr-5 mb-5'><img className="rounded-2xl mx-auto" src={FloridaHawksbillProject2} alt="" title="" /></div>
          <div><Typography paragraph>Florida’s hawksbills were a bit of a mystery for quite a while because even though we find them foraging on nearby coral reefs, none were coming ashore to nest. It was soon revealed by our team that local hawksbill populations consisted mostly of young individuals that had not yet fully grown to maturity. Sea turtles take a while to reach adulthood (15+ years), so juveniles and sub-adults are tasked with finding suitable habitat to nourish themselves and find protection from predators for well over a decade. Florida’s SE coast is lined from Palm Beach County all the way to Key West by a barrier reef known as the SE Florida Continental Reef Tract. It’s a little unusual for a coral reef to thrive in a sub-tropical climate, but the warm water of the Gulf Stream flows in from the Caribbean, providing the right conditions for a highly diverse reef ecosystem, which is exactly what young hawksbills are looking for.</Typography></div>
          <div className='mx-auto'><img className="rounded-2xl mx-auto" src={BarnaclesthegenusChelonibia} alt="" title="" /></div>
          <div className="pt-2"><em>Barnacles of the genus Chelonibia are commonly found associated with sea turtles. This species, C. caretta, has become specialized to nearly exclusively attach to hawksbill shells.</em></div>
        </div>
        <div className="lg:w-1/2">
          <div><img className="rounded-2xl lg:mx-auto" src={FloridaHawksbillProject2021} alt="" title="" /></div>
          <div className='lg:float-right lg:ml-5 mt-5'>
            <div><img className="rounded-2xl mx-auto" src={Researchteamsmallhawksbillturtle} alt="" title="" /></div>
            <div className="pt-2"><em>The research team with a small<br />hawksbill
              turtle in the beautiful<br />Florida Keys. *The
              Florida Hawksbill<br />Project is authorized by
              State and<br />Federal agencies to handle
              hawksbill<br />turtles for research purposes.</em></div>
          </div>
          <div className="pt-5"><Typography paragraph>Further studies have revealed that Florida’s reefs offer temporary refuge for hawksbills that were hatched all around the Caribbean, some from as far east as the leeward islands. Most, however, are part of a large group of hawksbills that prefer to nest on the beaches of Mexico’s Yucatan Peninsula, which geographically speaking, isn’t all that far away. Once they’ve reached maturity, most of the individuals we’ve hosted in Florida will head back that way to begin their new lives as reproductive adults, likely never to return.</Typography></div>
          <div><Typography paragraph>The Florida Hawksbill Project’s primary goal is to fill in some important gaps in our growing undertanding of this species’ life cycle, particularly in the Caribbean. Due to a combination of behavioral peculiarities on their part and logistical hurdles to overcome on our part, comparatively little is known about the biology of this species as a whole. So far, the Florida population has provided some valuable insights concerning movements, growth, abundance, and distribution in this part of their range. Plus, we’ve discovered alot about their territoriality, feeding strategies, and preferred habitat types.</Typography></div>
          <div><Typography paragraph>Although the 2021 field season was delayed until mid-year by covid, our team was able to conduct well over a dozen turtle surveys ranging from Jupiter to Key West. We added 10 turtles to the grand total, which is now 273 individual hawksbills we’ve tagged and sampled for our research. We also came across several we’ve seen before, which helps us assess survivorship and movement patterns.</Typography></div>
        </div>
      </div>



      <div className="mt-5">
        <div className='lg:float-left lg:mr-5'>
          <div><img className="rounded-2xl mx-auto" src={newlyEmergedHatchlingHawksbill} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>A newly-emerged hatchling hawksbill<br />
            in the U.S. Virgin Islands.</em></div>
        </div>
        <div><Typography paragraph>One small hawksbill we captured near Key West this year was first encountered as a stranded post-hatchling on the beaches of Texas, near Galveston in 2018. It was lucky enough to reside at the NOAA Fisheries Lab in Galveston for a while, then was released in partnership with our team near Key West in 2019. Sure enough, this one adapted well to the natural reef system after captivity; it appeared to be in excellent health and had grown at an impressive rate.</Typography></div>
        <div><Typography paragraph>The data we collect in the field eventually culminates in peer-reviewed publications, sometimes even unintentionally. A recent example resulted from a partnership with Ms. Liberty Boyd, a student who became interested in identifying and describing the recruitment patterns that barnacles employ to colonize hawksbill turtle shells. Upon closer inspection, our team discovered that although they all live together, two similar barnacle species prefer different shells to grow on, and manage to segregate themselves between green and hawksbill turtles. Specialized symbioses like these provide otherwise-hidden clues to evolutionary processes, and provide students with invaluable experience in conducting detailed research projects in marine ecology. In partnership with my colleagues at Florida International University and the Citadel, our paper entitled “Evidence for Host Selectivity and Specialization by Epizoic Chelonibia Barnacles Between Hawksbill and Green Sea Turtles” is soon to be published in a special issue of Frontiers in Ecology and Evolution, and will be Ms. Boyd’s first peer-reviewed publication as the first author.</Typography></div>
        <div><Typography paragraph>As I mentioned before, Florida provides many great opportunities to conduct hawksbill turtle research, but also limits us to those individuals that haven’t yet reached maturity, e.g. juveniles and sub-adults. To complement our work in Florida, we have partnered with the University of the Virgin Islands and the Ocean Foundation to create a Hawksbill Conservation Action Plan (HCAP) tailored specifically to the U.S. Virgin Islands, which are host to significant numbers of nesting females. We are compiling historical and current data concerning the abundance and distribution of hawksbills in the USVI, and will provide an outline for scientists and conservationists to help guide future efforts to stabilize and enhance the Islands’ hawksbill populations.</Typography></div>
        <div><Typography>Meanwhile, our Florida team will continue to seek hawksbills throughout State waters, and hopefully discover some additional aggregations we haven’t encountered before. Our two-pronged approach includes consistent, long-term surveys in focused areas (Key West and Palm Beach), along with exploratory surveys in areas with appropriate hawksbill habitat. Many thanks to the National Save The Sea Turtle Foundation for supporting the Florida Hawksbill Project!</Typography></div>
      </div>
    </div>
  );
}
