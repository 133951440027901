import React from 'react';
import { Typography, Divider, Link } from '@mui/material'
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AdoptSeaTurtleNest from './Component/AdoptSeaTurtleNest';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
// import { GiTurtle } from "react-icons/gi";



const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5", // custom primary color
        },
        secondary: {
            main: "#932B16", // custom secondary color
        },
    },
});

const CustomAccordion = styled(Accordion)`
  &.dark {
    color: white;
    background-color: #032546;
    
  }
`;



export default function AdoptSeaTurtle() {

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
            <div><div className='pb-5'><h1 className='text-2xl font-bold'>Adopt a Sea Turtle Nest</h1></div></div>
            <Divider className='dark:bg-[#032546] bg-white/70' />
            <div className='mt-5'><Typography>You will receive a free one-year subscription to</Typography></div>


            <div className='my-5'>
                <ThemeProvider theme={theme}>
                    <Button href="https://savetheseaturtle.org/Forms/FLOutreachMagazin.html" target='_blank' rel='noopener noreferrer' color="secondary" variant="contained" endIcon={<DriveFileRenameOutlineIcon />} className='mx-auto'>
                        Florida Environmental Outreach Magazine
                    </Button>
                </ThemeProvider>
            </div>

            <div><Typography paragraph>With your tax-deductible contribution in the amount of only $50.00, you will receive a certificate and a picture of the nest assigned to you or your group.</Typography></div>
            <div><Typography paragraph>After the nest hatches, you will receive information about the nest and how many of the hatchlings were released into the ocean.</Typography></div>
            <div><Typography paragraph>In Broward County 3,539 sea turtle nests were laid last year. Marine biologists invest time and energy to protect these endangered animals. Frequently these nests must be relocated to prevent hatchling disorientation.</Typography></div>

            <div>
                <CustomAccordion
                    expanded={expanded === 'panel1'}
                    onChange={handleChange('panel1')}
                    className="dark"
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='text-[#932B16]' />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="dark"
                    >
                        <div><h2 className='text-xl'>Read more about Adopt A Nest</h2></div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AdoptSeaTurtleNest />
                    </AccordionDetails>
                </CustomAccordion>
            </div>

            <div className='text-center mt-5'><Typography paragraph><h2 className='text-3xl'>YOU CAN HELP US SAVE A SEA TURTLE BY</h2></Typography></div>
            <div className='lg:w-1/2 text-center mx-auto mb-5'><Divider className='dark:bg-[#032546] bg-white/70' /></div>
            <div className='lg:flex gap-5'>
                <div className='lg:w-1/2 my-3'>

                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/Adopt40.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div className='text-center'>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>
                                    <div className='text-2xl font-bold text-center py-2 text-[#CBC132]'>$50.00</div>
                                    <div className='text-center'>Adopt a Sea Turtle Nest</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>


                </div>
                <div className='lg:w-1/2 my-3'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/Adopt60.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div className='text-center'>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>
                                    <div className='text-2xl font-bold text-center py-2 text-[#CBC132]'>$75.00</div>
                                    <div className='text-center'>Adopt a Sea Turtle Nest</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>
            </div>

            <div className='lg:flex gap-5'>
                <div className='lg:w-1/2 my-3'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/AdoptForFriend40.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div className='text-center'>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>
                                    <div className='text-2xl font-bold text-center py-2 text-[#CBC132]'>$50.00</div>
                                    <div className='text-center'>Adopt a Sea Turtle Nest for a friend</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>
                <div className='lg:w-1/2 my-3'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/AdoptForFriend60.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div className='text-center'>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>
                                    <div className='text-2xl font-bold text-center py-2 text-[#CBC132]'>$75.00</div>
                                    <div className='text-center'>Adopt a Sea Turtle Nest for a friend</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>
            </div>


        </div>
    )
}
