import React from "react";
import { Typography, Divider } from "@mui/material";
import FellowsSociety from "../Images/Fellows-Society.jpg";
import BarbaraB from "../Images/Barbara-Buckbee-Kelly-Flanagan-Larry-Wood-Richard-Dodge-Alissa-Hechter.jpg";

export default function NovaMember() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex">
        <div>
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              National Save The Sea Turtle Foundation Becomes a New Member of Nova
              Southeastern’s Fellows Society
            </h1>
          </div>
          <Typography paragraph>Larry Wood, Ph.D.</Typography>
        </div>
        <div className=" p-2">
          <img className="lg:float-right rounded-2xl" src={FellowsSociety} alt="" title="" />
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="lg:flex mt-5">
        <div className="lg:w-2/3">
          <Typography paragraph>
            On November 12th, the National Save The Sea Turtle Foundation was invited to celebrate a
            lovely evening with Nova administrators, faculty, staff, and distinguished alumni to
            welcome the Foundation and 13 other new Nova Southeastern University Fellows Society
            Members. Each of these individuals, corporations, foundations, and trusts have committed
            cumulative giving to NSU that exceeds $50,000. The Foundation strongly supports the
            marine education center at Nova’s MEEC facility in Hollywood, Florida, and has been
            supporting Nova’s sea turtle nesting survey work in Broward County for well over a
            decade through its popular Adopt-A-Nest program.
          </Typography>
          <Typography paragraph>
            Barbara Buckbee (left), Kelly Flanagan (right), and Larry Wood (upper right) were
            welcomed by Richard Dodge, Dean of the Halmos College of Natural Sciences and
            Oceanography and Alissa Hechter, Senior Director of Development for Nova Southeastern.
          </Typography>
        </div>
        <div className="lg:ml-5 lg:w-1/3">
          <img
            className="mx-auto lg:float-right rounded-2xl"
            src={BarbaraB}
            alt=""
            title="Barbara Buckbee (left), Kelly Flanagan (right), and Larry Wood (upper right) were welcomed by Richard Dodge, Dean of the Halmos College of Natural Sciences and Oceanography and Alissa Hechter, Senior Director of Development for Nova Southeastern."
          />
        </div>
      </div>
    </div>
  );
}
