import React from 'react'
import { Typography, Divider } from "@mui/material";
import fibropapillomatosis from '../Images/fibropapillomatosis.jpg'
import WhitneyLaboratoryMarine from '../Images/Whitney-Laboratory-for-Marine-Bioscience.jpg'

export default function UFwhitneyLab() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className='pb-5'><h1 className='text-2xl'>Inwater Research Group Teaches the Teachers in Pasco County, Florida
      </h1></div></div>
      <div><Typography paragraph>Larry Wood, Ph.D., NSTSTF Research Coordinator</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className='lg:float-right lg:ml-5 mt-5'><img className="rounded-2xl mx-auto" src={WhitneyLaboratoryMarine} alt="" title="" /></div>
      <div className='pt-5'><Typography paragraph>Technically known as fibropapillomatosis, FP is a herpesvirus-associated disease in sea turtles that causes often-debilitating tumors to grow to sometimes extreme proportions. The disease was first documented in the early 1900s, but was exceedingly rare. Then, beginning in the 1990s, the prevalence of tumor-covered turtles began to rise, and the disease became particularly noticeable in both Florida and Hawaii as it over-proportionately afflicted young green turtles that lived in or near highly urbanized bays and estuaries. Early research into the disease revealed the tumors themselves were clearly overloaded with a particular kind of virus, named ChHV5, but it was impossible to tell which came first; was the virus actually causing the tumors, or was it just taking advantage of another metabolic circumstance that was already underway? Meanwhile, sea turtle rehabilitation experts were faced with a whole new challenge as extremely sick turtles were presenting with often shocking loads of cauliflower-like growths growing from various soft tissues around the body, including the eyes.</Typography></div>
      <div><Typography paragraph>Fast-forward about 30 years, and the disease has managed to spread all around the globe. Similarities still exist; it still primarily affects juvenile green turtles, it still disproportionately affects turtles living in close proximity to human activity, and unfortunately we still haven’t identified the 100% definitive ‘cause’ of the disease. But we’ve learned a heck of a lot over the years, and together with our colleagues at UF’s Whitney Lab we thought it was time to bring experts together again to review their findings and update each other on all the exciting research and clinical advances that have been underway to combat this frustrating ailment.</Typography></div>
      <div className='lg:float-right lg:ml-5'>
        <div><img className="rounded-2xl mx-auto" src={fibropapillomatosis} alt="" title="" /></div>
        <div className='pt-2'><em>Photo Credit: David Duffy/UF</em></div>
      </div>
      <div><Typography paragraph>Held both in-person and virtually at the Whitney Lab near St. Augustine Florida, the two-day FP summit in October was attended by over 130 experts from all around the world. The talks were grouped into general categories that included molecular/cellular research, cancer immunology, environmental FP research, veterinary/pathology research, and virology. Diseases like FP are extremely complicated, and require multi-disciplinary approaches to understanding the cascade of events that must occur both internally and externally for it to eventually result in a clinically ill animal. Then, once manifested, the challenges of humanely treating these animals to reverse the disease’s course present themselves, and must be addressed with a completely different skill set. This meeting was the first in quite a while to bring all the perspectives together in one place, and feedback from the meeting confirmed how much new information was exchanged among experts working in disparate fields and locations. It was also encouraging to see the interest among graduate students in studying the disease, and well-deserving presenters were recognized with student paper awards.</Typography></div>
      <div><Typography>What did we learn, you may ask? As usual, the typical response is we’ve got more new questions than new answers, which certainly is true, but major headway has been made. Today’s miraculous tools for studying genomes have identified the mechanisms that the virus uses to infect cells, potentially leading to treatments that block the infection at its starting point. And, a whole new suite of tools and treatments have been developed by veterinarians and clinical scientists that give hope to even the most severely afflicted individuals. Lastly, big steps have been taken to explore which environmental factors may trigger immunosuppression in the turtles, allowing an otherwise harmless pathogen to cause such enormous cellular damage. Though FP is, and will remain, a major concern for sea turtle biologists and conservationists around the world, it is generally accepted that sea turtle populations, as a whole, are not threatened with extinction by this disease alone. It is only one of many challenges sea turtles face, and the National Save The Sea Turtle Foundation is committed to addressing this and other important issues affecting these animals.</Typography></div>
    </div>
  )
}
