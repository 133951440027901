import React from "react";
import { Typography, Divider } from "@mui/material";
import FAUtop from "../Images/FAU-Animal-Behavior-and-Visual-Perception.jpg";
import FAUmarinelab1 from "../Images/FAU-Marine-Lab.jpg";
import FAUuv from "../Images/ultraviolet-light.jpg";
import Repaired1 from "../Images/The-repaired-super-structure.jpg";
import Tank3000 from "../Images/3000-gallon-seawater-tank.jpg";

export default function FAUlabRefreshed() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:float-right mb-5">
        <img className="rounded-2xl border-4 border-white mx-auto" src={FAUtop} alt="" title="" />
      </div>
      <div>
        <div className="pb-5">
          <h1 className="text-2xl font-bold">FAU Marine Science Laboratory Refreshed</h1>
        </div>
      </div>
      <div>
        <Typography paragraph>
          Jeanette Wyneken, Ph.D.<br />
          Director, FAU Marine Science Laboratory
        </Typography>
      </div>
      <div className="my-5">
        <Divider className='dark:bg-[#032546] bg-white/70 clear-both' />
      </div>

      <div className="lg:flex gap-5">
        <div className="lg:w-1/2">
          <Typography paragraph>
            Thirty years ago the walls were clean, the floors spotless, counters gleaming, and the
            promise of marine studies to come were still dreams. Twenty years ago a few small-scale
            studies of sea turtles, algae, sea grasses and cryptic marine fish were starting. The
            floors were no longer spotless, some counters were stained from a decade of chemicals,
            but the lab was about to get a more public face. The visitors’ gallery was cleaned, some
            modest signage was hung, and the labs activities were about to become more accessible.
            Initially, through some modest grants, then through the concerted efforts of the
            National Save the Sea Turtle Foundation and the Royal Caribbean Cruises Ltd., the lab’s
            walls, visitors’ gallery, and the now abundant turtle and ray tanks as well as their
            associated life support systems, have received some much needed TLC.
          </Typography>

          <Typography paragraph>
            The FAU Marine Laboratory, one of the facilities within the Gumbo Limbo Environmental
            Complex, is owned and run by Florida Atlantic University’s Charles E. Schmidt College of
            Science. Like many University facilities, the Marine Lab depends upon donors to help
            support its infrastructure and programs directed toward student training. Thanks to the
            generosity of the National Save The Sea Turtle Foundation, this year we were able to
            repair the facility’s 30 year-old siding, give the interior walls a fresh coat of paint,
            repair the superstructure providing the electricity and freshwater to the turtle tanks,
          </Typography>
        </div>

        <div className="lg:w-1/2">
          <div className="lg:float-right">
            <div className="mx-auto">
              <img
                className="rounded-2xl mx-auto"
                src={FAUmarinelab1}
                alt=""
                title="Overview of the FAU Marine Lab showing the improved and repaired
          superstructure over the tanks, tank lighting with full spectrum lights hung at the
          necessaryheight to supply the turtles with essential UV light, and
          the newly installed sea water tank is seen in the upper left. "
              />
            </div>
            <div className="pt-2 mb-5">
              <em>
                Figure 1. Overview of the FAU Marine Lab showing the improved and repaired
                superstructure over the tanks, tank lighting with full spectrum lights hung at the
                necessaryheight to supply the turtles with essential UV light, and the newly
                installed sea water tank is seen in the upper left.
              </em>
            </div>
          </div>

        </div>
      </div>
      <div className="lg:flex lg:gap-5">
        <div className="lg:mr-5 lg:w-1/2">
          <div>
            <img
              className="rounded-2xl"
              src={FAUuv}
              alt=""
              title="All sea turtles require ultraviolet light to metabolize calcium. The light
              fixtures over these green turtle hatchling tanks must be close enough to provide the
              turtles with the appropriate light levels, and the fixtures have to be
              rust-resistant in this saltwater environment"
            />
          </div>
          <div className="pt-2">
            <em>
              Figure 2. All sea turtles require ultraviolet light to metabolize calcium. The light
              fixtures over these green turtle hatchling tanks must be close enough to provide the
              turtles with the appropriate light levels, and the fixtures have to be
              rust-resistant in this saltwater environment.
            </em>
          </div>
        </div>
        <div className="lg:w-1/2">
          <Typography paragraph>
            hang new rust-resistant light fixtures over the tanks, and install a 3000 gallon sea
            water treatment tank which is essential for maintaining the leatherback turtles. The
            cost for these improvements was more than $37,000. Such generosity and investment in
            marine lab essential infrastructure makes possible the expansion and success of our sea
            turtle, shark, and marine algal research programs, thus enabling 5 internationally known
            faculty, as well as their associated undergraduate and graduate students, to pursue
            their research plans, and to communicate what they do and its importance to the general
            public.
          </Typography>

          <Typography paragraph>
            To date, 65 leatherback turtles, more than 120 loggerheads, and 40 green turtles have
            come through the lab in summer 2020; our season is not yet over. Ongoing studies include
            continued work on annual variation in hatchling sea turtle sex ratios, experimental
            studies of the effects of rising beach temperatures on hatchling loggerhead learning,
            comparative studies of the success of newly formulated diets for leatherback neonates,
            lab testing of novel satellite tracking tags for neonate leatherbacks, and measures of
            the impact of accumulated Sargassum seaweed on the ability of hatchling loggerheads to
            locate the sea from the nest.
          </Typography>
        </div>
      </div>
      <div className="lg:flex lg:gap-5 mt-5">
        <div className="lg:w-1/2">
          <div><img className="rounded-2xl" src={Repaired1} alt="" title="" /></div>
          <div className="pt-2 mb-5"><em>
            Figure 3. The repaired super structure over these leatherback tanks supports the
            full spectrum lights and freshwater showers (vertical blue hoses) that provide the
            turtles with drinking water. GFI electric outlets are also supported along the
            pressure treated wood structures.
          </em></div>

        </div>
        <div className="lg:w-1/2">
          <div><img className="rounded-2xl" src={Tank3000} alt="" title="" /></div>
          <div className="pt-2"><em>
            Figure 4. The newly installed 3000-gallon seawater tank allows us to prepare enough
            cleaned and sanitized seawater for the leatherbacks when they are in the lab. The
            FAU Marine Lab has the only successful leatherback husbandry program in the world.
            Improvements to the husbandry system , made possible by our generous donors, make it
            possible for our pioneering work in husbandry of this fragile species.
          </em></div>

        </div>
      </div>
    </div>
  );
}
