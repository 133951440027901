import React from "react";
import { motion } from "framer-motion";
import { useRef, useEffect, useState } from "react";
import Hawksbill from "./Hawksbill";
import Loggerhead from "./Loggerhead";
import KempsRidley from "./KempsRidley";
import Green from "./Green";
import Leatherback from "./Leatherback";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import IconButton from "@mui/material/IconButton";

import "./motion.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#932B16", // custom secondary color
    },
  },
});

const Offers = [
  {
    id: "0",

    text: <Hawksbill />,
  },
  {
    id: "1",

    text: <KempsRidley />,
  },
  {
    id: "2",

    text: <Loggerhead />,
  },
  {
    id: "3",

    text: <Green />,
  },
  {
    id: "4",

    text: <Leatherback />,
  },

];

function Offer() {
  const [width, setWidth] = useState(0);
  const carousel = useRef();

  useEffect(() => {
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
  }, []);

  const handleScroll = (scrollOffset) => {
    const carouselWidth = carousel.current.offsetWidth;
    const scrollPos = carousel.current.scrollLeft;
    const maxScroll = carousel.current.scrollWidth - carouselWidth;

    let newPos = 0;
    if (scrollOffset === "left") {
      newPos = Math.max(0, scrollPos - carouselWidth);
    } else {
      newPos = Math.min(maxScroll, scrollPos + carouselWidth);
    }

    carousel.current.scrollTo({
      left: newPos,
      behavior: "smooth",
    });
  };

  return (
    <div className="mt-5">
      <div className="text-2xl my-5 text-left dark:text-[#032546]">
        CLASSIFICATION OF FLORIDA SEA TURTLES
      </div>

      <div className="clear-both">
        <div className="float-left">
          <ThemeProvider theme={theme}>
            <IconButton onClick={() => handleScroll("left")}>
              <ArrowCircleLeftRoundedIcon color="secondary" fontSize="large" />
            </IconButton>
          </ThemeProvider>
        </div>
        <div className="float-right">
          <ThemeProvider theme={theme}>
            <IconButton onClick={() => handleScroll("right")}>
              <ArrowCircleRightRoundedIcon color="secondary" fontSize="large" />
            </IconButton>
          </ThemeProvider>
        </div>
      </div>
      <motion.div className="carousel" ref={carousel} drag="x" whileTap={{ cursor: "grabbing" }}>
        <motion.div drag="x" dragConstraints={{ right: 0, left: -width }} className="inner-carousel ">
          {Offers.map((offer) => {
            return (
              <div className="flex lg:min-w-[50%] min-w-[100%] p-2" key={offer.id}>
                <div>{offer.text}</div>
              </div>
            );
          })}
        </motion.div>
      </motion.div>
    </div>
  );
}

export default Offer;
