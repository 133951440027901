import React from "react";
import { Typography, Divider } from "@mui/material";
import DrMichaelJ from "../Images/Dr-Michael-Johnson-Dr-Kate-Mansfield-Dr-Larry-Wood-Dr-Graham-Worthy.jpg";

export default function UCFReceives() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <div className="pb-5">
          <h1 className="text-2xl font-bold">
            Inwater Research Group Leads in Environmental Education Through Innovative Programming
          </h1>
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="my-5">
        <Typography paragraph>
          Three students with big ambitions to curb the dwindling sea turtle population will benefit
          from a new scholarship at the University of Central Florida started by the National Save
          The Sea Turtle Foundation.
        </Typography>

        <div>
          <div><img
            className="rounded-2xl float-right mx-auto ml-5"
            src={DrMichaelJ}
            alt=""
            title="Dr. Michael Johnson, Dean of the College of Sciences , Dr.
        Kate Mansfield, Dr. Larry Wood, and Dr. Graham Worthy
        attend UCF’s Scholarship Luncheon"
          /></div>
          <div className="pt-2 pb-5">
            <em>
              Dr. Michael Johnson, Dean of the College of Sciences , Dr. Kate Mansfield, <br />
              Dr. Larry Wood, and Dr. Graham Worthy attend UCF’s Scholarship Luncheon
            </em>
          </div>
        </div>



        <Typography paragraph>
          The Foundation chose to partner with UCF in recognition of the work conducted by Kate
          Mansfield, Ph.D., who leads the Marine Turtle Research Group (MTRG). Mansfield is a marine
          scientist and sea turtle biologist on the forefront of using satellite technology to track
          the movement of baby turtles.
        </Typography>
        <Typography paragraph>
          “UCF’s sea turtle program combines experience with innovation, resulting in a world-class
          lab” said Larry Wood, Ph.D., Research Coordinator for the Foundation. “I’m glad that we
          are able to give back to such an amazing academic community through this scholarship.”
        </Typography>
        <Typography paragraph>
          The National Save the Sea Turtle Foundation Scholarship awards three senior-year
          undergraduate students $10,000 each in support of tuition, books, and academic fees. It is
          meant to alleviate financial stress so that these students can focus on their research and
          post-graduation plans.
        </Typography>
        <Typography paragraph>
          In addition, the Scholarship provides funding for general use in support of the MRTG’s
          ongoing research.“We are very glad to help those that have a bit more difficulty getting
          their projects off the ground,” said Wood. “The sea turtles are fascinating animals that
          provide great research opportunities. We hope that our commitment to the turtles and
          contribution to academic institutions will inspire others to build upon that same
          foundation,” said Wood.
        </Typography>
        <Typography paragraph>
          The first two recipients of this inaugural fellowship are Monica Reusche and Jennifer
          Rote. Both undergraduates were interns with the UCF Marine Turtle Research Group, Monica
          starting in 2018, and Jennifer in 2019. Monica is a senior conducting an Honors in the
          Major thesis examining the genetic diversity of green turtles in the Indian River Lagoon
          and other Central Florida habitats. Jennifer is also in the last years of her degree and
          is working with Dr. Erin Seney on a sea turtle diet study. The scholarships will be
          awarded for the Spring 2020 semester. Applications for the third scholarship will be
          accepted in the Spring semester of 2020 &nbsp;
          <a
            href="https://sciences.ucf.edu/scholarships/national-save-the-sea-turtle-foundation-scholarship-fund/"
            className=" underline"
          >
            UCF-National Save the Sea Turtle Foundation Scholarship Fund
          </a>
          &nbsp;and will be awarded for Fall 2020.
        </Typography>
      </div>
    </div>
  );
}
