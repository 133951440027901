import React from "react";
import { Divider } from "@mui/material";
import InwaterResearchGroupStudies from '../Images/Inwater-Research-Group-Studies-Green-Turtles-at-the-Jupiter-Inlet.jpg'
import InwaterResearchGroupStudies2 from '../Images/Inwater-Research-Group-Studies-Green-Turtles-at-the-Jupiter-Inlet-2.jpg'

export default function InwaterJupiter() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className='pb-5'><h1 className='text-2xl font-bold'>Inwater Research Group Studies Green Turtles at the Jupiter Inlet</h1></div></div>

      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="mt-5">
        <img className="rounded-2xl mx-auto" src={InwaterResearchGroupStudies} alt="" title="" />
      </div>
      <div className="mt-5">
        <img className="rounded-2xl mx-auto" src={InwaterResearchGroupStudies2} alt="" title="" />
      </div>
    </div>
  );
}
