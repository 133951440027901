import React from 'react'
import { Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#932B16", // custom secondary color
    },
  },
});

export default function Conservation() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className='pb-5'><h1 className='text-2xl'>Conservation</h1></div></div>

      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className='pt-5'><Typography paragraph>The National Save The Sea Turtle Foundation recognizes the importance of targeted sea turtle conservation programs. While research informs and education inspires, conservation is where they come together for action. All around the State and beyond, small grass-roots organizations are having a huge impact on sea turtle conservation within their local communities. The Foundation is proud to sponsor these fine organizations!</Typography></div>
      <div className='underline'><Typography paragraph >Broward County Nest Surveys -NOVA Southeastern</Typography></div>
      <div><Typography paragraph>For over 20 years, the National Save The Sea Turtle Foundation’s Adopt-A-Nest program has supported NOVA Southeastern University Oceanographic Center’s Students and Broward County Sea Turtle Conservation Program. The operation employs 23 field workers who are primarily NSU graduate students or recent graduates. They patrol over 38 km of Broward County beaches each morning at dawn, from March 1 through September 30. Endangered sea turtle nests are identified, marked, located by GPS and sometimes relocated from intensely lighted beaches to avoid hatchling disorientation.</Typography></div>
      <div className='float-right mb-5'>
        <ThemeProvider theme={theme}><Button color="secondary" href='https://hcas.nova.edu/seaturtles/index.html' target='_blank' rel='noreferrer' variant="contained" endIcon={<ArrowOutwardIcon />}>
          Visit Broward County Sea Turtle Conservation Program
        </Button>
        </ThemeProvider>
      </div>
      <div className='underline mt-5 clear-both'><Typography paragraph>Sandy Point NWR, St. Croix, USVI</Typography></div>
      <div><Typography paragraph>The National Save The Sea Turtle Foundation has had a long-standing relationship with our colleagues at the Sandy Point National Wildlife Refuge in St. Croix, USVI. Their groundbreaking leatherback turtle research and conservation programs have changed the trajectory for this species in that area.</Typography></div>
      <div className='lg:float-right mb-5'>
        <ThemeProvider theme={theme}><Button color="secondary" href='https://seaturtlecensus.com/' target='_blank' rel='noreferrer' variant="contained" endIcon={<ArrowOutwardIcon />}>
          Visit The St. Croix Sea Turtle Project
        </Button>
        </ThemeProvider>
      </div>
      <div className='pt-5 clear-both'><Typography paragraph>Recently, we initiated the development of a Hawksbill Conservation Action Plan for the U.S. Virgin Islands that will provide a roadmap for the recovery of this critically endangered species in this part of its range. Built upon historic and current data, the HCAP will inform researchers, conservationists, and educators who wish to make a difference for the Island’s hawksbills now and into the future.</Typography></div>
      <div className='underline pt-5'><Typography paragraph>Sea Turtle Adventures</Typography></div>
      <div><Typography paragraph>Established in 2016 when biologist Jacquelyn Kingston, after working with several adults with special needs, recognized that increased conservation awareness can enrich the lives of this under-served audience. For 18 years, Kingston has been authorized by the Florida Fish and Wildlife Conservation Commission to monitor sea turtle nests along a three-mile stretch of beach in southern Palm Beach County, Florida. Creating Sea Turtle Adventures offered a way to provide more environmental education through hands-on activities such as beach cleanups, observation of turtle monitoring, and presentations that effectively engage adults with disabilities in the community.</Typography></div>
      <div className='lg:float-right mb-5'>
        <ThemeProvider theme={theme}><Button color="secondary" href='https://www.seaturtleadventures.com/' target='_blank' rel='noreferrer' variant="contained" endIcon={<ArrowOutwardIcon />}>
          Visit Sea Turtle Adventures
        </Button>
        </ThemeProvider>
      </div>
      <div className='underline pt-5 clear-both'><Typography paragraph>Coastal Connections</Typography></div>
      <div><Typography paragraph>After finishing up her graduate studies and moving to Vero Beach, FL, Kendra Cope noticed a need in the communities in and around Florida’s Indian River County to provide interactive and educational programs that contribute to the protection of sea turtles and the habitats they need to survive. In 2017, Coastal Connections, Inc (CCinc) became a reality. Featuring programs that inspire communities to protect turtles and their nests on the beach, encourage sustainable practices via their “Vero Goes Zero” program, and Sea Turtle-Friendly Hotel Certifications, Coastal Connections is making a big difference for sea turtles in Indian River County.</Typography></div>
      <div className='lg:float-right mb-5'>
        <ThemeProvider theme={theme}><Button color="secondary" href='https://coastal-connections.org/' target='_blank' rel='noreferrer' variant="contained" endIcon={<ArrowOutwardIcon />}>
          Visit Coastal Connections
        </Button>
        </ThemeProvider>
      </div>
      <div className='underline pt-5'><Typography paragraph>Virginia Key Nest Surveys</Typography></div>
      <div className='clear-both'><Typography paragraph>During the 2021 sea turtle nesting season, the Marine Order for Research and Action through Environmental Stewardship (MORAES) initiated a volunteer-led sea turtle conservation program throughout Virginia Key Beach (VKB), near Miami Florida. The program, supported by the National Save The Sea Turtle Foundation, trained 20 volunteers, with varying backgrounds and interests, to look for and identify nesting sea turtle activity on the 2.5 mile stretch of beach which is located in Miami-Dade County and managed by the City of Miami. MORAES took on the permit and developed the volunteer program for the first time this past nesting season and the group believes it was a huge success.</Typography></div>
      <div className='pb-10'>
        <div className='lg:float-right clear-both mb-10'>
          <ThemeProvider theme={theme}><Button color="secondary" href='https://www.soflomoraes.com/' target='_blank' rel='noreferrer' variant="contained" endIcon={<ArrowOutwardIcon />}>
            Visit MORAES
          </Button>
          </ThemeProvider>
        </div>
      </div>
    </div>
  )
}
