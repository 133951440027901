import { Typography } from '@mui/material'
import React from 'react'
import FundUnder from '../Images/Fund-Undergraduate-Scholarships.jpg'
import ScholarshipProgram1 from '../Images/Scholarship-Program.jpg'
import Shelby from '../Images/Shelby-Creager-2.jpg'
import Victoria from '../Images/Victoria-Tori-Erb-2.jpg'

export default function ScholarshipProgram() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className='py-2'><Typography><h1 className='text-2xl'>Propelling young scientists to save our seas</h1></Typography></div>



      <div className='py-2'><Typography><h1 className='text-2xl'>National Save The Sea Turtle Foundation Donates $60,000 to Fund Undergraduate Scholarships</h1></Typography></div>

      <div><Typography paragraph>The National Save The Sea Turtle Foundation has donated $60,000 to Florida Atlantic University in support of four $15,000 undergraduate scholarships during the 2019-2020 academic year. The scholarships will provide financial assistance to Charles E. Schmidt College of Science seniors enrolled at the Boca Raton Campus who demonstrate academic excellence, financial need, and a commitment to become scholars and ambassadors that represent the Marine Science program at Florida Atlantic University. As such, they are expected to develop as well-rounded, skilled, and committed individuals who are motivated to engage the public through education, research and outreach activities appropriate to both their specific interests in the field, as well as those of importance in the field of marine biology. </Typography></div>

      <div><Typography paragraph>The ideal candidate is an undergraduate about to enter their senior year with interest in any aspect of marine biology research at the ecological, evolutionary, or behavioral level and educational outreach. Preference will be given to applicants in good standing in the biology department&apos;s Honors Thesis Program who demonstrate financial need, coupled with a commitment to community service, academic excellence, and a desire to enhance their ability to engage the public. These scholarships shall be awarded on the basis of need and merit without regard to race, color, religion, age, sex, national origin, or disability. </Typography></div>

      <div className='center'><img className='rounded-2xl mx-auto' src={FundUnder} alt='' title='' /></div>

      <div className='py-2'><Typography><h1 className='text-2xl'>Scholarship Program Suports FAU Student</h1></Typography></div>

      <div><Typography paragraph>For many years, the Department of Biological Sciences at Florida Atlantic University has been the recipient of numerous scholarships from the National Save The Sea Turtle Foundation. These funds are used to support graduate research at the Master of Science and Doctoral levels. While in the past the Foundation has provided funding for projects involving marine turtles, this year the decision was made to support broader topics of research dealing with the conservation, management, and/or basic biology of marine fishes as well as turtles. This topical expansion also better reflects the interests of the department, which in recent years has expanded by hiring of several new and outstanding faculty members in the various subdisciplines of marine biology. Their students can now apply and compete for these important scholarships. You can help support and expand this important program by becoming an Elite Member of the National Save The Sea Turtle Foundation. </Typography></div>

      <div className='center'><img className='rounded-2xl mx-auto' src={ScholarshipProgram1} alt='' title='' /></div>

      <div className='lg:flex mx-auto pt-5 gap-4'>
        <div className='lg:w-1/2 my-5'><img className='rounded-2xl' src={Shelby} alt='' title='' /></div>
        <div className='lg:w-1/2 my-5'><img className='rounded-2xl mx-auto' src={Victoria} alt='' title='' /></div>
      </div>

    </div>
  )
}