import React from "react";
import { Typography, Divider } from "@mui/material";
import InwaterResearchGrouplogo from "../Images/Inwater-Research-Group-logo-3.jpg";
import TeachersinPascoCounty from "../Images/Teachers-in-Pasco-County.jpg";
import TeachersinPascoCounty2 from "../Images/Teachers-in-Pasco-County-2.jpg";
import TeachersinPascoCounty3 from "../Images/Teachers-in-Pasco-County-3.jpg";

export default function InwaterTeachesPasco() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <div className="pb-5">
          <h1 className="text-2xl font-bold">
            Inwater Research Group Teaches the Teachers in Pasco County, Florida
          </h1>
        </div>
      </div>
      <div>
        <Typography paragraph>Rebecca Mott, IRG Education Coordinator</Typography>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="lg:float-right lg:ml-5 mt-5">
        <img className="rounded-2xl mx-auto" src={InwaterResearchGrouplogo} alt="" title="" />
      </div>
      <div className="lg:float-left lg:mr-5 mt-5">
        <img className="rounded-2xl mx-auto" src={TeachersinPascoCounty} alt="" title="" />
      </div>
      <div className="pt-5">
        <Typography paragraph>
          This past summer our education partners, Inwater Research Group (IRG), brought an
          immersive conservation experience to teachers in Pasco County, FL. Pasco County School
          District, a recent partner of ours and IRG’s Traveling Trunk program purchased twelve
          trunks to use in their classrooms. These trunks allow students to get a hands-on glimpse
          into the life of marine biologists and into the world of sea turtle conservation while
          learning school curriculum. This means every student in Pasco County Schools will now have
          a chance to experience these award-winning programs.
        </Typography>
      </div>
      <div className="lg:float-right lg:ml-5 mb-5">
        <img className="rounded-2xl mx-auto" src={TeachersinPascoCounty2} alt="" title="" />
      </div>
      <div>
        <Typography paragraph>
          In addition to this, Pasco County found grant funding to host a professional development
          day with their teachers. This would mean a trip over to the west coast, a research recon
          mission, and a field day with local educators for our IRG partners. With picture perfect
          weather, local hotspots of turtle activity, and a boat full of excited teachers, the day
          was looking promising. Within no time, IRG’s research crew had already located several
          turtles to bring aboard our boat for work up. Teachers were able to see first-hand how
          they collect turtles, amass valuable data from each individual, and what that means
          overall for the protection of these animals. They also learned how they can participate in
          sea turtle conservation themselves and how to encourage their students to do so as well.
        </Typography>
      </div>
      <div className="lg:float-left lg:mr-5 mb-5">
        <img className="rounded-2xl mx-auto" src={TeachersinPascoCounty3} alt="" title="" />
      </div>
      <div>
        <Typography paragraph>
          From here teachers joined the crew in the classroom of the Energy and Marine Center, a
          local gem that educates thousands of students a year on STEM initiatives. Teachers would
          now have a chance to see how the trunk programs reflect actual sea turtle research. Our
          programs utilize the same tools and techniques we use in the field, so teachers were able
          to step into the role of biologist and practice (on turtle models) what they had just seen
          on the water.
        </Typography>
      </div>
      <div>
        <Typography>
          Programs such as these are so important to the conservation community as well as to
          research and education sectors. Bringing together scientists with teachers allows us to
          remove many barriers that currently exist between those practicing science and those
          teaching it. From here, Pasco County teachers are now fully equipped and motivated to
          present our programs to the entire school district. This means thousands of students, who
          have sea turtles in their own “backyard” will learn about their reptilian neighbors and
          how to be good ambassadors for their recovery and longevity.
        </Typography>
      </div>
    </div>
  );
}
