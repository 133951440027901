import React from "react";
import { Typography, Divider } from "@mui/material";
import SeaTurtleAd1 from "../Images/Sea-Turtle-Adventures.png";
import HannaZ from "../Images/Hannah-Zegel-and-Jacquelyn-Kingston-attending-an-outreach-event.jpg";
import DrLarryW1 from "../Images/Dr-Larry-Wood-Jacquelyn-Kingston-Alessandra-Schnirel-Joe-Kingston.jpg";
import Lauren1 from "../Images/Lauren-Maheady-Alessandra-Schnirel.jpg";
import MrsJoan1 from "../Images/Ms-Joan-Lorne.jpg";

export default function SeaTurtleAdventures() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="">
        <div className="">
          <div className="lg:float-right ml-5 mb-5">

            <img className="rounded-2xl mx-auto" src={SeaTurtleAd1} alt="" title="" />
          </div>
          <div className="pb-2">
            <Typography>
              <h1 className="text-2xl font-bold">
                Sea Turtle Adventures Continues to Develop Innovative Programming for Adults with
                Special Needs
              </h1>
            </Typography>
          </div>
          <div>
            <Typography paragraph>
              Jackie Kingston, Executive Director, Sea Turtle Adventures, Inc.
            </Typography>
          </div>
        </div>
        <Divider className='dark:bg-[#032546] bg-white/70 clear-both' />
      </div>
      <div>
        <div className="mt-5">
          <Typography paragraph>
            Sea Turtle Adventures, Incorporated (STA) is a 501(c)(3) non-profit organization based
            out of North Palm Beach, FL. The mission of STA is to conserve local populations of sea
            turtles, educate the public about the marine environment, and provide nature-based
            programs to adults with special needs. While STA was officially formed in 2016, the work
            that we have been doing on the beach to protect sea turtle's dates back nearly two
            decades. STA has monitored a 3-mile section of beach in southern Palm Beach County for
            19 years, documenting nearly 1,000 nests annually.
          </Typography>
        </div>
        <div>
          <div className="lg:float-left lg:mr-5">
            <div><img className="rounded-2xl mx-auto" src={HannaZ} alt="" title="" /></div>
            <div className="pt-2 mx-auto">
              <em>Hannah Zegel attending an outreach event.</em>
            </div>
          </div>
          <div className="lg:float-right lg:ml-5 mt-5">
            <div><img className="rounded-2xl mx-auto" src={DrLarryW1} alt="" title="" /></div>
            <div className="pt-2 pb-5">
              <em>
                Dr. Larry Wood (left) presents a ‘big check’ to Jacquelyn
                <br />
                Kingston, Alessandra Schnirel, and Joe Kingston
                <br />
                in support of STA’s programs.
              </em>
            </div>
          </div>
          <div className="">
            <Typography paragraph>
              One of the programs that makes STA unique is our iCare program. This one-of-a-kind
              program was specifically designed for adults with special needs who enjoy nature. The
              program began in November 2017 with seven participants and as of March 2019, the
              program has 53 members. The purpose of iCare is to enhance life skills through
              interaction with the natural environment. The group meets several times a month and
              helps paint sea turtle nest stakes, conduct beach cleanups, and enjoy adventurous
              activities including kayaking and boating.
            </Typography>

            <Typography paragraph>
              STA has appreciated our relationship with The National Save The Sea Turtle Foundation
              (NSTSTF) since we formed in 2016. With support from NSTSTF, we were able to purchase a
              projector to launch our offsite educational program in early 2017 and in 2018 we were
              able to purchase a side by side ATV.
            </Typography>
          </div>
        </div>


        <div className="lg:flex lg:gap-5 clear-both">
          <div className="lg:w-1/4">
            <div>
              <img className="rounded-2xl mx-auto" src={Lauren1} alt="" title="" />
            </div>
            <div className="pt-2">
              <em>
                Lauren Maheady and Alessandra Schnirel

                tend to mangrove seedlings that will one

                day be used in restoration projects
              </em>
            </div>
          </div>
          <div className="lg:w-1/2">
            <div>
              <Typography paragraph>
                Now in 2019, NSTSTF is helping support our ongoing sea turtle conservation program
                including a research and development project to identify a more environmentally
                friendly material that can be used for sea turtle nest signs as opposed to the
                traditionally used plastic signs.
              </Typography>
            </div>
          </div>
          <div className="lg:w-1/4">
            <div><img className="rounded-2xl mx-auto" src={MrsJoan1} alt="" title="" /></div>
            <div className="pt-2">
              <em>
                Ms. Joan Lorne performs a sea turtle nest productivity

                assessment in southern Palm Beach County.
              </em>
            </div>
          </div>

        </div>


      </div>
    </div>
  );
}
