import React from 'react';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import CompanyName from './CompanyName';
import Developed from './Developed';
import Logo from '../Images/Logo.png'


const today = new Date();

export default function Footer() {
  return (
    <div className='container mx-auto w-full clear-both'>
      <div className='text-[#ffffff] bg-[#032546]/90 dark:bg-[#ffffff]/70 dark:text-[#032546] clear-both text-center'>



        <div className='py-5 clear-both'>
          <div >
            <Link href='/'><img className='mx-auto' src={Logo} alt='' title="National Save The Sea Turtle Foundation" /></Link>
          </div>
          <div className='font-bold'><Typography><CompanyName /></Typography></div>
          <div className='font-bold'>
            <address>
              <Typography>4419 West Tradewinds Avenue, Ft. Lauderdale Florida 33308</Typography>
              <Typography>(954) 351-9333 – Toll Free (877) Turtle 3</Typography>
            </address>
            <div><Typography variant='button'>A NON-PROFIT ORGANIZATION</Typography></div>
            <div><Typography>State of Florida Registration Number CH-2841. Internal Revenue Code 501 (c) (3)</Typography></div>
            <div><Typography><Link href='/' exact="true">&copy; {today.getFullYear()} - <CompanyName /></Link></Typography></div>
            <div><Typography><Developed /></Typography></div>
          </div>
        </div>


      </div>
    </div>
  )
}
