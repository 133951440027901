import React from 'react'
import { Divider, Typography } from '@mui/material';
import HawksbillProject1 from './Images/The-Florida-Hawksbill-Project.jpg';
import HawksbillProject2 from './Images/The-Florida-Hawksbill-Project-2.jpg';
import HawksbillProject3 from './Images/The-Florida-Hawksbill-Project-3.jpg';
import HawksbillProject4 from './Images/The-Florida-Hawksbill-Project-4.jpg';
import HawksbillProject5 from './Images/The-Florida-Hawksbill-Project-5.jpg';
import HawksbillProject6 from './Images/The-Florida-Hawksbill-Project-6.jpg';
import FloridaHBProject from './Images/THE-FLORIDA-HAWKSBILL-PROJECT.png';
import { Button, Link } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#932B16", // custom secondary color
    },
  },
});

export default function HawksbillProject() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>

      <div><div className='pb-5'><h1 className="text-2xl font-bold">THE FLORIDA HAWKSBILL PROJECT</h1></div></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />



      <div className='lg:float-right mt-10 lg:ml-7 mb-10'>

        <div className='pb-10'>
          <div className='text-center mx-auto'>
            <ThemeProvider theme={theme}>
              <Link href='https://savetheseaturtle.org/Forms/HawksbillProject.html' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                <Button color="secondary" variant="contained" fullWidth>
                  <div>
                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>

                    <div className='text-center'>Florida Hawksbill Project</div>
                  </div>

                </Button>
              </Link>
            </ThemeProvider>
          </div>
        </div>

        <div>
          <img src={FloridaHBProject} alt='florida hawksbill project' title='The Florida Hawksbill Project' className='mx-auto hover:mix-blend-plus-lighter' />
        </div>

      </div>
      <div className='pt-5'><div className='pb-5'> The mission of The Comprehensive Florida Hawksbill Research and Conservation Program (a.k.a. The Florida Hawksbill Project) is to study and protect the region&apos;s hawksbill sea turtles and the habitats in which they live. Within the scope of this project, numerous studies have been undertaken to characterize the hawksbill aggregations found in SE Florida waters, and educational programs have been developed to engage the local dive community in the protection of hawksbill sea turtles and coral reef habitats. This program is hosted by the National Save the Sea Turtle Foundation, located in Fort Lauderdale, Florida.</div></div>

      <div><div className='pb-5'><h1 className='text-2xl'>THE FLORIDA HAWKSBILL PROJECT</h1></div></div>
      <div><div className='pb-5'> Unlike several other species of sea turtles, hawksbills don't nest on Florida's beaches and have been traditionally thought of as rare in Florida waters. As a result, little is known of their role in Florida&apos;s marine habitats, though for decades they have been so familiar to SCUBA divers along Florida's Southeast Coast. As a highly endangered species and an important member of the coral reef community, understanding and conserving hawksbill turtles in this part of their range is important to the future of both. The Florida Hawksbill Project is the only long-term study of hawksbill turtles in this part of their range.</div></div>


      <div><img className='rounded-3xl' src={HawksbillProject1} alt='' title='' /></div>
      <div className='pt-2'><Typography paragraph><em>The Hawksbill Sea Turtle (Eretmochelys imbricata)</em></Typography></div>

      <div><div className='pb-5'><h2 className='text-2xl'>A Tough Road...</h2></div></div>
      <div><div className='pb-5'>For centuries, a lucrative global trade in hawksbill sea turtle shell (a.k.a. "tortoiseshell", which was used primarily for stylish personal items and artwork) reduced hawksbill turtle populations to near extinction. Though international laws prevent the trade of products derived from endangered species, these practices continue throughout their range. Combined with the additional pressures of egg harvesting and habitat loss, the future of this species remains uncertain. International conservation efforts over the last four decades have and will continue to work to stabilize hawksbill populations around the world, and we are proud to contribute to that effort.</div></div>

      <div><div className='pb-5'><h1 className='text-2xl'>What Can We Do?</h1></div></div>

      <div><div className='pb-5'>By studying the hawksbills of Florida, we are contributing to the ongoing effort to recover Caribbean hawksbill populations. Multi-national efforts are required to stimulate hawksbill conservation, in large part due to their large ranges and close association with the region&apos;s coral reefs. The hawksbill turtles we encounter in Florida&apos;s waters have immigrated from and will return to a variety of distant locations around the Caribbean, creating a melting pot of regional populations that can provide valuable insight to the biology and conservation of hawksbills on a regional scale. </div></div>

      <div>
        <div className='pb-5'><h2 className='text-2xl'>Our Research</h2></div>
        <div>
          <div className='lg:float-right'>
            <div className='ml-5'><img className='rounded-3xl' src={HawksbillProject2} alt='' title='' />
              <div className='pt-2 clear-both'><em>The Hawksbill Sea Turtle (Eretmochelys imbricata)</em></div></div>
          </div>
          <div><div className='pb-5'>The most important long-term task of the Florida Hawksbill Project is to provide a basic census of hawksbill turtles in Florida State waters. Once we know where they are most likely to be found, we can focus our efforts on estimating what the overall population may be. From there, we can determine the relative contribution Florida makes to the larger-scale regional recovery of this species.  </div> </div>

          <div><div className='pb-5'>Throughout their global range, hawksbill turtles are known to closely associate with coral reef habitats, mostly due to their preference for eating sponges and corals. Due to the large extent of Florida&apos;s barrier reefs (approx. 350 linear miles), the Hawksbill Project focuses on representative sites in the northern, central, and southern sections of the Southeast Florida Reef Tract. The barrier reefs of northern Palm Beach County, the patch reefs of the northern Keys, and the finger reefs of Key West are the primary locations for our sampling efforts. </div></div>

          <div><div className='pb-5'>Hawksbill turtles from each site are hand captured, tagged, and sampled for a number of studies related to their movements, behavior, and physiology. Each individual is equipped with markers (a.k.a. “tags”) that enable the research team to identify them should they be encountered again. To date, over 225 hawksbill turtles have been individually tagged, at least 60 of which have been encountered more than once.  </div> </div>
          <div><div className='pb-5'><h2 className='text-2xl'>Key Findings:</h2></div></div>

          <div><div className='pb-5'><h2 className='text-2xl'>Abundance:</h2></div></div>
          <div><div className='pb-5'>One of the first things we learned is that there are many more hawksbills than were expected, which reveals how important the reefs of Palm Beach County are for young hawksbill turtles. We estimate their abundance by keeping track of how many we encounter during our overall time searching. The resulting &apos;catch per unit effort&apos;s generated by our work can be compared with those from other study sites around the Caribbean, and we have so far firmly established that Florida is host to a regionally significant number of hawksbill turtles.  </div> </div>

          <div><div className='pb-5'><h2 className='text-2xl'>Population Structure:</h2></div></div>
          <div><div className='pb-5'>The hawksbills of Palm Beach are primarily sub-adults, or immature turtles that have not yet grown to reproductive maturity. Hawksbills, like other sea turtles, can be found in different geographic locations depending on their life-stage. We call Florida “developmental habitat” for young hawksbill turtles who will someday depart to spend the rest of their lives elsewhere as adults. </div></div>
        </div>

      </div>


      <div className='lg:flex lg:gap-5 justify-content-between'>
        <div className='lg:w-1/2'>
          <div><img src={HawksbillProject4} className='rounded-3xl' alt='' title='' /></div>
          <div className='pt-2'><Typography paragraph><em>Hawksbill sea turtles primarily feed on sea sponges, corals, and small invertebrates.</em></Typography></div>
        </div>
        <div className='lg:w-1/2'>
          <div><img src={HawksbillProject3} className='rounded-3xl' alt='' title='' /></div>
          <div className='pt-2'><Typography paragraph><em>The Florida Hawksbill Project focuses on the abundance and distribution of hawksbill turtles in Florida. Tags are placed on each turtles&apos; flippers for future identification.</em></Typography></div>
        </div>
      </div>

      <div><Typography paragraph><h2 className='text-2xl'>Movements and Growth:</h2></Typography></div>
      <div> <Typography paragraph>Once tags are placed on a turtle, it can be distinguished from others. When these individuals are encountered repeatedly, we gain valuable insight to their movements and growth patterns. Dozens of tagged hawksbills have been reported within the study site, some individuals since the inception of the study in 2004, all within close proximity to where they were originally found. To gain more details, our satellite tracking study examined the home-range, or territory, of six hawksbills that all stayed within close proximity (about a half-mile) to their favorite underwater caves, which they returned to each night for months on end. These results suggest that at least some of Florida&apos;s hawksbills may stick around for the duration of their sub-adult life-stage, which is likely to be around 15 years. We&apos;re now using chemical traces of carbon and nitrogen in the turtles&apos; tissues to follow their steps from their initial arrival in Florida as young juveniles through to their impending departure as young adults. We are also developing shell-mounted underwater cameras to record their interactions with each other and their environment. </Typography>
      </div>

      <div>
        <div><Typography paragraph><h2 className='text-2xl'>Origins:</h2></Typography></div>
        <Typography paragraph>Since we know that the hawksbills found in Florida did not come from Florida (hawksbills don&apos;t nest with any regularity there), we were curious about their origins, and by extension, their future destinations. Since sea turtles return to their natal beaches to nest, we can detect subtle differences in DNA sequences that have developed between various regional sub-populations. The young hawksbills we find in Florida carry their DNA &apos;fingerprint&apos; with them, which reveals their place of origin. Turns out, Florida is a melting pot of Caribbean hawksbills from as far south as Panama to as far east as the leeward islands, but most hatched on the beaches of Mexico's Yucatan Peninsula.</Typography>
      </div>
      <div>
        <iframe className='rounded-3xl border-2 border-[#032546] dark:border-[#fffb50] mx-auto w-full aspect-video align-middle' width="100%" height="auto" src="https://www.youtube.com/embed/vH8HIg2tF1g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div className='mt-5 '><img className='rounded-3xl' src={HawksbillProject1} alt='' title='' /></div>

      <div className='lg:flex lg:gap-5 mt-5'>

        <div className='lg:w-1/2'>
          <div><img src={HawksbillProject5} className='rounded-3xl' alt='' title='' /></div>
          <div className='pt-2'><Typography paragraph><em>Because hawksbill sea turtles don&apos;t nest in Florida withy any regularity, the Florida Hawksbill Project used DNA fingerprinting to determine the origins of Florida&apos;s populations. Though most come from the beaches of Mexico, Florida is a &apos;melting pot&apos; of hawksbill turtles from around the Caribbean.</em></Typography></div>
        </div>

        <div className='lg:w-1/2'>
          <div><img src={HawksbillProject6} className='rounded-3xl' alt='' title='' /></div>
          <div className='pt-2'><Typography paragraph><em>The Florida Hawksbill Project team used satellite transmitters to examine the movement patterns of local hawksbill turtles living off Palm Beach, Florida. They all stayed in small areas surrounding a preferred nightly refuge for months on end. </em></Typography></div>

        </div>
      </div>

      <div>
        <div><Typography paragraph><h2 className='text-2xl'>How You Can Help:</h2></Typography></div>
        <Typography paragraph>You can support the groundbreaking research of the Florida Hawksbill Project through your individual, corporate, or institutional sponsorship. Consider becoming a member at any level, or join the distinguished members of the Elite Sea Turtle Society and earmark your contribution to The Florida Hawksbill Project. Thank You!</Typography>
      </div>

      <div>
        <Typography>Contacts: Dr. Larry Wood, Founder and Director of the Comprehensive Florida Hawksbill Research and Conservation Program (a.k.a The Florida Hawksbill Project) <a href='mailto:larry@floridahawkbills.com'>larry@floridahawkbills.com</a>, or the National Save The Sea Turtle Foundation.</Typography>
      </div>


    </div>
  )
}
