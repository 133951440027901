import React from 'react'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, Button, Divider } from '@mui/material';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5", // custom primary color
        },
        secondary: {
            main: "#932B16", // custom secondary color
        },
    },
});

export default function CashDonation() {
    return (
        <div className="dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl">
            <div><div className='pb-5'><h1 className='text-2xl font-bold'>Cash Donation</h1></div></div>
            <Divider className='dark:bg-[#032546] bg-white/70' />
            <div className='lg:flex gap-10'>

                <div className='lg:w-1/2 my-5'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/CashDonation.html' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div className='text-center'>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>

                                    <div className='text-center pt-5'>Cash Donation</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>                </div>
                <div className='lg:w-1/2 my-5'>
                    <ThemeProvider theme={theme}>
                        <Link href='https://savetheseaturtle.org/Forms/CashDonationFriend.html' style={{ color: '#ffffff' }}>
                            <Button color="secondary" variant="contained" fullWidth>
                                <div className='text-center'>
                                    <div className='text-center'><VolunteerActivismIcon fontSize='large' /></div>

                                    <div className='text-center pt-5'>Cash Donation for a Friend</div>
                                </div>

                            </Button>
                        </Link>
                    </ThemeProvider>
                </div>

            </div>

        </div>
    )
}
