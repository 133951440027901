import React from "react";
import { Divider, Typography } from "@mui/material";
import Seek1 from "../Images/Seeking-The-Green-Turtles-of-the-Marquesas-Keys.jpg";
import Seek2 from "../Images/Seeking-The-Green-Turtles-of-the-Marquesas-Keys-2.jpg";
import Seek3 from "../Images/Seeking-The-Green-Turtles-of-the-Marquesas-Keys-3.jpg";
import Flager from "../Images/Flagler.png";
import LGturtle from "../Images/Large-turtles.png";

export default function SeekingTheGreenTurtles() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>

      <Typography className="pb-5">
        <h1 className="text-2xl font-bold">Seeking Green Turtles</h1>
      </Typography>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <img className="mx-auto rounded-2xl my-5" src={Seek1} alt="" title="" />
      <div>
        <img className="float-right rounded-2xl my-5" src={Seek2} alt="" title="" />
        <Typography paragraph>
          Understanding the distribution of sea turtles at each of their life stages is fundamental
          to their conservation. This is the goal of the Inwater Research Group, Inc. (IRG), a group
          of dedicated biologists who study in-water populations of sea turtles (as opposed to
          land-based nesting research) throughout Florida.
        </Typography>
        <Typography paragraph>
          Recently, the National Save The Sea Turtle Foundation partnered with IRG to spend several
          days surveying the waters west of Key West in the Marquesas Keys in search of loggerhead,
          green, and hawksbill turtles.
        </Typography>
      </div>
      <div className="float-right">
        <div className="float-left my-5 mr-5">
          <img className="rounded-2xl" src={Seek3} alt="" title="" />
          <em>
            The June 2016 Marquesas Keys Team with “Flagler”, a 270 lb. green sea turtle that had
            been first identified in 2006.
          </em>
        </div>
        <Typography paragraph>
          The conditions were ideal for turtle counts, which entail two observers perched in a
          boat’s tuna tower calling out the species, size, and location of each turtle they spot
          down to data recorders on deck. Don’t be fooled; this isn’t always an easy chore...the
          turtles are so abundant in some locations that the data recorders can barely keep up.
          During just four 6-km survey transects one day, the team counted in excess of 250 large
          subadult and adult green turtles, many zipping away from the boat at near-light speed and
          all too often colliding with others, scattering turtles in all directions.
        </Typography>
        <Typography paragraph>
          In some cases, turtles are captured to gather basic information concerning their body
          condition, size, weight etc. Easier said than done. Out here, the turtles are big; two-
          to- four-hundred pounds big. This imposing trait, however, becomes their disadvantage
          during tests of stamina.
        </Typography>
      </div>
      <div className="clear-right">
        <Typography paragraph>
          The chosen turtles quickly tire after a short boat chase, and unwillingly permit their
          exhausted selves to be hand-captured and carefully hauled through a specially-designed
          access hatch in the boat’s port side.
        </Typography>
        <Typography paragraph>
          On June 28th, a previously-tagged turtle was captured that has an interesting history.
          “Flagler”, as she is known, was first captured near the Marquesas Keys in July of 2008 as
          a subadult weighing in at 70.5 kg (155 lbs.). She carried a satellite transmitter for 116
          days before it stopped transmitting. Eight years later, there she was (definitely a she),
          still hanging out near her original capture site, only she’s 56 kg (123 lbs.) heavier, now
          topping the scales at 275 lbs.!
        </Typography>
      </div>
      <div className="lg:flex justify-between">
        <div>
          <img className="rounded-2xl" src={Flager} alt="" title="" />
        </div>
        <div>
          <img className="rounded-2xl" src={LGturtle} alt="" title="" />
        </div>
      </div>
    </div>
  );
}
