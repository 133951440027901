import React from "react";
import { Typography, Divider } from "@mui/material";
import LBM from "../Images/LB-M.png";
import LBB from "../Images/Leatherback-B.jpg";

export default function Leatherback() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex text-center">
        <div className="w-1/2">
          <Typography className="">
            <h1 className="text-2xl font-bold">Leatherback Sea Turtles Fast Facts</h1>
          </Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Type: Reptile</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Diet: Carnivore</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Average life span in the wild: 45 years (est.)</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Size: Up to 7 ft (2 m)</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Weight: Up to 2,000 lbs (900 kg)</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <Typography className="">Protection status: Endangered</Typography>
          <div className="my-2">
            <Divider className='dark:bg-[#032546] bg-white/70' />
          </div>
          <div className="flex justify-center">
            <div>
              <Typography className="">Size relative to a 6-ft (2-m) man:</Typography>
            </div>
            <div>
              <img className="rounded-2xl" src={LBM} alt="" title="" />
            </div>
          </div>
        </div>
        <div className="w-1/2 flex justify-center">
          <img className="rounded-2xl" src={LBB} alt="" title="" />
        </div>
      </div>
      <div>
        <Typography paragraph>Did you know? </Typography>
        <Typography paragraph>
          Leatherbacks are the largest turtles on Earth, growing up to seven feet (two meters) long
          and exceeding 1,000 pounds (450 kilograms). These reptilian relics are the only remaining
          representatives of a family of turtles that traces its evolutionary roots back more than
          100 million years. Once prevalent in every ocean except the Arctic and Antarctic, the
          leatherback population is rapidly declining in many parts of the world.
        </Typography>
        <Typography paragraph>
          While all other sea turtles have hard, bony shells, the inky-blue carapace of the
          leatherback is somewhat flexible and almost rubbery to the touch. Ridges along the
          carapace help give it a more hydrodynamic structure. Leatherbacks can dive to depths of
          4,200 feet (1,280 meters)—deeper than any other turtle—and can stay down for up to 85
          minutes.
        </Typography>
        <Typography paragraph>
          Leatherbacks have the widest global distribution of all reptile species, and possibly of
          any vertebrate. They can be found in the tropic and temperate waters of the Atlantic,
          Pacific, and Indian Oceans, as well as the Mediterranean Sea. Adult leatherbacks also
          traverse as far north as Canada and Norway and as far south as New Zealand and South
          America. Unlike their reptilian relatives, leatherbacks are able to maintain warm body
          temperatures in cold water by using a unique set of adaptations that allows them to both
          generate and retain body heat. These adaptations include large body size, changes in
          swimming activity and blood flow, and a thick layer of fat.
        </Typography>
        <Typography paragraph>
          Leatherbacks undertake the longest migrations between breeding and feeding areas of any
          sea turtle, averaging 3,700 miles (6,000 kilometers) each way. After mating at sea,
          females come ashore during the breeding season to nest. The nighttime ritual involves
          excavating a hole in the sand, depositing around 80 eggs, filling the nest, leaving a
          large, disturbed area of sand that makes detection by predators difficult, and finally
          returning to the sea.
        </Typography>
        <Typography paragraph>
          The temperature inside the nest determines the sex of the hatchlings. A mix of male and
          female hatchlings occurs when the nest temperature is approximately 85.1 degrees
          Fahrenheit (29.5 degrees Celsius), while higher temperatures produce females and cooler
          temperatures produce males. Female hatchlings that make it to sea will roam the oceans
          until they reach sexual maturity, when they return to the same nesting areas to produce
          their own offspring. Males spend the rest of their lives at sea.
        </Typography>
        <Typography paragraph>
          Their lifespan is unknown but many leatherbacks meet an early end due to human activity.
          It is estimated that only about one in a thousand leatherback hatchlings survive to
          adulthood. Eggs are often taken by humans from nests to be consumed for subsistence or as
          aphrodisiacs. Many leatherbacks fall victim to fishing lines and nets, or are struck by
          boats. Leatherbacks also can die if they ingest floating plastic debris mistaken for their
          favorite food: jellyfish. Some individuals have been found to have almost 11 pounds (5
          kilograms) of plastic in their stomachs.
        </Typography>
        <Typography paragraph>
          Leatherbacks are currently designated as endangered under the U.S. Endangered Species Act.
          The number of leatherbacks in the Atlantic appears to be stable or increasing, but the
          Pacific population is declining at an alarming rate due to egg harvest, fishery by catch,
          coastal development, and highly variable food availability. Some Pacific populations have
          disappeared entirely from certain areas, such as Malaysia.
        </Typography>
        <Typography paragraph>
          Scientists around the world are tracking and studying leatherbacks to learn more about
          these reptilian giants and how they can be saved.
        </Typography>
      </div>
    </div>
  );
}
