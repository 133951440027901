import React from "react";
import { Divider, Typography } from "@mui/material";
import saltysea1 from "../Images/Salty-Air-and-Sea-Turtles.jpg";
import saltysea2 from "../Images/Salty-Air-and-Sea-Turtles-2.jpg";

export default function SaltyAirSea() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <Typography className="flex pb-5">
          <h1 className="text-2xl font-bold">Salty Air and Sea Turtles </h1>
        </Typography>
        <Typography paragraph className="flex">
          By Kelly Palmateer
        </Typography>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="my-5">
        <Typography paragraph>
          Imagine a place where sea turtles thrive and salty air blows with the sea winds. Well,
          there is such a place. It’s a little island called St. Croix. And it is incredible. I was
          so blessed this past summer to have the opportunity to visit this magical island that
          Kenny Chesney sings so radiantly about, and it changed my life. I went to the island as
          part of a yoga retreat/sea turtle conservation program with Kim Depasquale and Layna Mohl,
          our retreat leaders, the Sandy Point National Wildlife Refuge, led by Kelly Stewart and
          their volunteers, and the St. Croix Leatherback Project, led by Larry Wood. We all worked
          together to study and help to conserve leatherback sea turtles as they migrate to the
          island to lay their little eggs. The experience was impactful to say the least. These
          animals are unbelievably amazing. They swim thousands of miles to this small stretch of
          sand on the West end of the island to climb up the dunes, to then dig for hours at a time
          to be able to lay their eggs in what they know as a safe haven until the eggs are ready to
          hatch. Talk about perseverance.
        </Typography>
      </div>
      <div className="">
        <Typography paragraph>
          The heart-warming individuals in the yoga retreat that I traveled with, and myself, worked
          closely with the volunteers of each of the programs. The passion and determination these
          volunteers had was inspiring. For six days, we walked the beach every morning at sunrise
          to look for turtle tracks to new nests that had been dug the night before. And we found a
          lot! We would measure the width of the tracks to determine which type of sea turtle it
          was, because believe it or not, there are many different kinds of sea turtles. We would
          then study the nest and set a marker next to it with the date in order to keep track of
          when presumably those cute little hatchlings will want to come out. After we found all of
          the nests from the night before, our volunteering was put on pause and we were able to sit
          back and enjoy the magic this mesmerizing island has to offer. We spent our days doing
          yoga and snorkeling under the pier, swimming among hawksbill and green turtles in the
          crystal clear waters. We also went for an incredible hike up to an old lighthouse with a
          view that was simply indescribable.
        </Typography>
      </div>
      <div className="">
        <Typography paragraph>
          Most days as the sun would set into the Caribbean Sea, we would have a sunset yoga session
          on the sand. The tranquility we felt as the sun set and the calm waves would roll in, will
          never be surpassed. After the sun would set every evening – and as a side note, the
          sunsets from the West end of the island are some for the books, by far some of the most
          spectacular sunsets I have ever seen – so after the sun would set, we would prepare to
          walk the beach for a couple hours in the dark, hopeful to see one or two, or even three
          sea turtles appear from the turquoise waters and make their way up the dunes to begin
          digging. We thought we almost saw a few every night, but when we got closer to what we
          thought was the sea turtle, we learned it was just a shadow of a rock just underneath the
          shoreline. We were disappointed many times, unfortunately. We never did see a sea turtle
          come up to the beach at night, but what we witnessed one night at sunset was life
          changing. As we finished one of our sunset yoga sessions, we got a signal from one of the
          volunteers that one of the nests was actually hatching! We all ran quickly over to the
          nest to see what was going on. It didn’t seem that much was going on when we arrived at
          the nest, but within minutes, the sand in the middle of the nest began to bubble and move
          around and then – a baby sea turtle head popped up through the sparkling grains of sand.
          It was a miracle if I have ever seen one.
        </Typography>
      </div>
      <div className="lg:flex justify-center items-center">
        <img className="rounded-2xl" src={saltysea1} alt="" title="" />
      </div>
      <div className="lg:flex justify-between pt-5">
        <div className="lg:w-1/2">
          <Typography paragraph>
            We were all in awe of the little creature when one by one more started to peek their
            heads up through the sand. We stood back as they all emerged. It was a cluster of tiny
            heads and little flippers all on top of each other, and it was beautiful. As we waited
            patiently until all of the little babies were revealed, the volunteers allowed us to
            each hold one in our hands. This was the life changing part of the evening. These little
            creatures, so new and so tiny, had so much strength and so much determination to make it
            through to the top of the nest. You could literally feel the strength in their flippers.
            I have never felt so much nature, to put it simply, in my life. It was amazing. It
            doesn’t hurt that they are probably the most adorable baby animals to ever live on the
            planet.
          </Typography>
          <Typography paragraph>
            So, after we all had our time with the baby sea turtles, the volunteers took them to go
            conduct their research. They would then keep the hatchlings safe until it was time to
            release them to their fate of following after their ancestors. Later in the evening, we
            had the rare and heart-warming opportunity to set the babies free into the ocean.
            Another life changing event that putting into actual words would not even come close to
            the experience itself. But trust me when I tell you, it was amazing.
          </Typography>
          <Typography paragraph>
            As our retreat and volunteering came to an end, we all looked back on the past six days
            in complete awe and amazement of all of the things we had been given the opportunity to
            do. None of us wanted to leave the island, or the baby sea turtles, or each other for
            that matter. We all learned so much about sea turtles and the patience and drive it
            takes to study the graceful animals, along with learning so much about ourselves in the
            process. The passion and determination that all of the volunteers had will forever stay
            with me, and forever inspire me. I think that we all left the island with a little more
            passion, so much gratitude for all of the people we met and for these such empowering
            animals, and of course so many adorable memories. I cannot thank all of the people
            involved enough for the unbelievable trip to this magical island. And I am available at
            any time in the future, should they want or need any temporary volunteers for a few days
            during nesting season.
          </Typography>
        </div>
        <div className="lg:flex justify-center ml-5 lg:w-1/2">
          <img className="rounded-2xl" src={saltysea2} alt="" title="" />
        </div>
      </div>
    </div>
  );
}
