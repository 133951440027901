import React from 'react'
import { Typography } from '@mui/material'
import EduOutreach from '../Images/Classroom-Programming.jpg'
import EduOutreach2 from '../Images/Classroom-Programming-2.jpg'
import EduOutreach3 from '../Images/Classroom-Programming-3.jpg'


export default function EducationalOutreachPrograms() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className='pb-2'><Typography><h1 className='text-2xl'>Classroom Programming</h1></Typography></div>
      <div><Typography paragraph>The National Save The Sea Turtle Foundation works closely with the Inwater Research Group (IRG) to promote quality educational programming. The Foundation recently donated $31,350 to IRG to continue their international education initiative for K-12 students.</Typography></div>

      <div><Typography>The initiative is entirely free to teachers and includes tailor-made lesson plans, in-class outreach presentations, and new “traveling trunks” containing all the education material teachers would need to carry out four conservation-based lesson plans. Just this past year alone, the partnership has been able to reach over 20,000 students with these programs. </Typography></div>

      <div className='lg:flex gap-4'>
        <div className='lg:w-1/2 my-5'><img className='rounded-2xl' src={EduOutreach} alt='' title='' /></div>
        <div className='lg:w-1/2 my-5'><img className='rounded-2xl' src={EduOutreach2} alt='' title='' /></div>
        <div className='lg:w-1/2 my-5'><img className='rounded-2xl' src={EduOutreach3} alt='' title='' /></div>
      </div>

      <div><Typography paragraph><h1 className='text-2xl'>North Beaches Art Walk</h1></Typography></div>
      <div><Typography paragraph>The National Save The Sea Turtle Foundation brings awareness about sea turtle conservation to the visitors of the North Beaches Art Walk in the Atlantic/Neptune Beach areas of Jacksonville Florida. The goal is to meet, educate, and encourage people to help protect sea turtles. On a typical art walk night (third Thursday of every month), the local community and visitors pack the streets of Atlantic/Neptune Beach Town Center area to enjoy this fun event.</Typography></div>



    </div>


  )
}
