import React from "react";
import { Typography } from "@mui/material";
import OutandAbout1 from "../Images/Out-and-About.jpg";
import OutandAbout2 from "../Images/Out-and-About-1.jpg";
import OutandAbout3 from "../Images/Out-and-About-2.jpg";
import GumboLimbo from "../Images/Gumbo-Limbo-Nature-Center.jpg";
import GumboLimbo3 from "../Images/Gumbo-Limbo-Nature-Center-3.jpg";
import GumboLimbo4 from "../Images/Gumbo-Limbo-Nature-Center-4.jpg";
import GumboLimbo2 from "../Images/Gumbo-Limbo-Nature-Center-2.jpg";

export default function OutandAbout() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <img className="mb-5 w-full rounded-2xl mx-auto" src={OutandAbout1} alt="" title="" />
      </div>
      <div>
        <div className="lg:float-left lg:mr-5">
          <div className="my-3"><img className="rounded-2xl mx-auto" src={OutandAbout2} alt="" title="" /></div>
        </div>
        <div className="lg:float-right lg:ml-5">
          <div className="my-3"><img className="rounded-2xl mx-auto" src={OutandAbout3} alt="" title="" /></div>
        </div>
        <div>
          <Typography paragraph>
            The National Save The Sea Turtle Foundation sponsored the 42nd annual Broward County
            Waterway Cleanup Kick-off Party at the Marine Industries Association office in Fort
            Lauderdale on Thursday evening, February 7 at 6:00pm. Guests enjoyed complimentary
            drinks and light hors d’oeuvres, and this year’s artwork created by Steve Diossy was
            unveiled. The 42nd annual Broward County Waterway Cleanup, which was held on Saturday,
            March 2, is organized by the Marine Industries Association of South Florida and Marine
            Industry Cares Foundation. The event is the County’s largest and longest-running
            environmental event. The National Save The Sea Turtle Foundation has been a proud
            sponsor of the event since 1994.
          </Typography>
        </div>
      </div>
      <div>
        <div className="">
          <Typography paragraph>
            Each spring, the Gumbo Limbo Nature Center in Boca Raton hosts “Sea Turtle Day” to help
            ring in the upcoming sea turtle nesting season. This year’s event, held March 2nd,
            included numerous environmentally-themed informational and interactive booths, arts and
            crafts, great local food, and of course guided tours of the Nature Center, Sea Turtle
            Rehabilitation Center, and Florida Atlantic University’s on-site Laboratory facilities.
            It was also an opportunity to meet with our friends from Nova Southeastern University,
            who were on-hand to receive the annual proceeds from the National Save The Sea Turtle
            Foundation’s Adopt-A-Nest Program. These funds will be used to conduct nesting surveys
            and nest productivity analyses on the beaches of Broward County in 2019.
          </Typography>
        </div>
        <div className="lg:flex lg:gap-4">
          <div className="lg:w-1/4">
            <div className="mt-5">
              <img className="rounded-2xl mx-auto" src={GumboLimbo3} alt="" title="" />
            </div>
            <div className="pt-2">

              <em>
                Gumbo Limbo Nature Center is host
                <br />
                to “Sea Turtle Day” each spring to
                <br /> help bring awareness to the
                <br />
                community about the upcoming
                <br />
                nesting season. Larry Wood and
                <br />
                Connie Versteeg (below) enjoyed
                <br />
                the beautiful day at the
                <br />
                Foundation’s booth.
              </em>
            </div>
          </div>
          <div className="lg:w-1/4">
            <div className="mt-5">
              <img className="rounded-2xl mx-auto" src={GumboLimbo4} alt="" title="" />
            </div>
            <div className="pt-2" >
              <em>
                Nova Southeastern University’s Sea Turtle team received the proceeds from the
                National Save The Sea Turtle Foundation’s 2018 Adopt-A-Nest Program.
              </em>
            </div>
          </div>
          <div className="lg:w-1/4">
            <div className="mt-5">
              <img className="rounded-2xl mx-auto " src={GumboLimbo} alt="" title="" />
            </div>
            <div className="pt-2">

              <em>
                Gumbo Limbo Nature Center is host to “Sea Turtle Day” each spring to help bring
                awareness to the community about the upcoming nesting season. Larry Wood and Connie
                Versteeg (below) enjoyed the beautiful day at the Foundation’s booth.
              </em>
            </div>
          </div>
          <div className="lg:w-1/4">
            <div className="mt-5">
              <img className="rounded-2xl mx-auto " src={GumboLimbo2} alt="" title="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
