import React from "react";
import { Divider, Typography } from "@mui/material";
import Msalmon from "../Images/MichaelSalmonPHD.jpg";
import SamT from "../Images/SamT.jpg";
import Lunar1 from "../Images/Lunar1.png";
import Ymaze from "../Images/TheYmaze.png";

export default function LeatherbackSeaTurtleVision() {
  return (
    <div className="dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl">
      <div className="">
        <div>
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              Experimental Analysis of Wavelength Preferences Shown by Hatchling Leatherback Sea
              Turtles (Dermochelys coriacea)
            </h1>
          </div>
          <Typography paragraph>
            Article in Chelonian Conservation and Biology · October 2022
          </Typography>
          <Typography paragraph>
            <div className="flex items-center">
              <div className="p-4">
                <img className="h-12 w-12 lg:h-16 lg:w-16 rounded-full" src={SamT} alt="" />
              </div>

              <div>
                <div>Samantha Trail</div>
                <div>Florida Atlantic University</div>
              </div>
              <div className="p-4 mx-auto flex items-center">
                <img className="h-12 w-12 lg:h-16 lg:w-16 rounded-full mr-4" src={Msalmon} alt="" />
                <div>
                  <div>Michael Salmon</div>
                  <div>Florida Atlantic University</div>
                </div>
              </div>
            </div>
          </Typography>

        </div>
      </div>
      <div className="my-5">
        <Divider className="dark:bg-[#032546] bg-white/70" />
      </div>
      <div className="lg:flex">
        <div className="lg:w-1/2 mr-4">
          <Typography paragraph className="text-center">
            <em>
              Chelonian Conservation and Biology, 2022, 21(2): 283–286 doi:10.2744/CCB-1535.1 &copy;
              2022 Chelonian Research Foundation
            </em>
          </Typography>

          <Typography paragraph className="text-center">
            <div>
              <strong className=" text-xl">
                Experimental Analysis of Wavelength Preferences Shown by Hatchling Leatherback Sea
                Turtles <em>(Dermochelys coriacea)</em>
              </strong>
            </div>
          </Typography>
          <Typography paragraph className="text-center">
            <div>
              <strong>SAMANTHA E. TRAIL* AND MICHAEL SALMON</strong>
            </div>
          </Typography>
          <Typography paragraph className="text-center">
            <em>
              Department of Biological Sciences, Florida Atlantic University, Boca Raton, Florida
              33431-0991 USA [strail2019@fau.edu; salmonmichael07@gmail.com] *Corresponding author
            </em>
          </Typography>
          <Typography paragraph className="">
            <div>
              <strong>
                ABSTRACT. – In marine turtles it is well established that the shorter light
                wavelengths in the visible and near- ultraviolet spectrum provide more potent, and
                pre- ferred, cues for nocturnal seafinding orientation than the longer light
                wavelengths. In this study, we simultaneously presented leatherback hatchlings (Der-
                mochelys coriacea) with a short near-ultraviolet (380 nm) and a longer visible (500
                nm) light stimulus to determine whether that preference was based upon differences
                in light intensity, light wavelength, or a combination of both variables. We found
                that under light conditions mimicking those at the nesting beach on the darkest
                evenings, the behavioral preference for the shorter light wavelengths was based upon
                intensity cues, though we speculate that under brighter illumi- nation, wavelength
                cues might also be utilized.
              </strong>
            </div>
          </Typography>
          <Typography paragraph className="">
            <div>
              Marine turtles crawl up the beach to nest, generally at night. They dig an egg chamber
              where they deposit 50– 200 eggs (depending upon species) before covering the nest with
              sand and returning to the sea. After completing development, the embryos break out of
              the egg and, as hatchlings, dig their way upward until they emerge from the nest.
              Emergence usually occurs at night and is followed almost immediately by an oriented
              crawl from the nest to theocean — an innate behavior known as ‘‘seafinding’’ (Lohmann
              et al. 1997).
            </div>
          </Typography>
          <Typography paragraph className="">
            <div>
              Evidence gathered since the 1940s has established that seafinding is mediated by
              visual cues (Daniel and Smith 1947) and takes the form of a positive phototaxis; the
              turtles crawl towards the lowest and brightest (that is, most light-radiant) horizon,
              generally located in a seaward direction (Mrosovsky and Shettleworth 1968; Limpus
              1971). The wavelengths they detect emanate from celestial sources (stars, moon) and
              consist of both visible and near- ultraviolet (near-UV) portions of the
              electromagnetic spectrum. Behavioral experiments have revealed that the wavelengths
              used by the hatchlings during seafinding range between 340 and 600 nm (Celano et al.
              2018).
            </div>
          </Typography>
        </div>
        <div className="lg:w-1/2">
          <Typography paragraph className="">
            <div>
              Previous studies have also demonstrated that the turtles are more strongly attracted
              to the shorter than to the longer light wavelengths (Mrosovsky and Carr 1967;
              Ehrenfeld 1968; Mrosovsky and Shettleworth 1968; Witherington and Bjorndal 1991;
              Witherington 1992), leading to the assertion that these light stimuli are
              ‘‘preferred.’’ Why marine turtle hatchlings should show such a bias is not at all
              obvious. One possibility is that by doing so, they can more accurately discriminate
              differ- ences shown between the landward and seaward horizon. Recent measurements have
              shown that these horizons vary quantitatively in radiance (the seaward view is
              generally brighter), but not qualitatively in light spectra (Celano et al. 2018).
              Nevertheless, this issue remains an intriguing puzzle that requires further
              investigation.
            </div>
          </Typography>
          <Typography paragraph className="">
            <div>
              A second feature of the orientation response, and one we consider in this study,
              centers on how that preference is determined when it most commonly occurs: at night.
              In this paper we explored the possibilities that the preference was based upon
              differences in light intensity (radiance), light wavelength, or some combination of
              both variables. Similar designs have been used to test whether sea turtles are capable
              of discerning color (Young et al. 2012). On the basis of our experimental results, we
              conclude that most commonly at night light intensity, not wavelength, is the primary
              cue used by hatchlings to orient in a seaward direction.
            </div>
          </Typography>
          <Typography paragraph className="">
            <div>
              <em>Methods</em>. — The methods used in this study have been described in 2
              publications (Celano et al. 2018; Trail and Salmon 2022) and will be only briefly
              summarized here. We obtained leatherback (Dermochelys coriacea) hatch- ings from nests
              monitored between May and September 2020, at 2 nesting beaches in southeastern
              Florida: Juno Beach (lat 26.888N, long 80.058W) and Boca Raton (lat 26.378N, long
              80.138W). All of the hatchlings were collected from each nest in the late afternoon of
              the evening that they would naturally emerge. They were then transported to our lab on
              the campus of Florida Atlantic University, used for tests that night, then immediately
              released at the Boca Raton nesting beach.
            </div>
          </Typography>
          <Typography paragraph className="">
            <div>
              Experiments were done using a black PlexiglasTM Y- maze to simultaneously present the
              hatchlings with a short, near-UV (380 nm) and a longer, visible (500 nm) light
              wavelength stimulus. Each light was projected from the end of one maze arm (Fig. 1)
              and each induced a positive phototaxis. A preference for one light over the other was
              measured by the distribution of crawl entries into each arm.
            </div>
          </Typography>
          <Typography paragraph className="">
            <div>
              To determine if that preference was based upon light intensity or light wavelength, it
              was necessary to control for differences in stimulus intensity. That was done by using
              threshold data determined previously at each of the 2 chosen wavelengths (Fig. 2,
              upper graph; Trail and Salmon 2022). That threshold is defined as the dimmest light
              stimulus that evoked a statistically significant preference for one arm illuminated by
              that wavelength over the other arm, lacking any light stimulus. Each of the paired
              lights was then presented to the turtles at an identical quantal intensity (0.3-, 0.7-
              or 1.0-log unit) above threshold, and at radiance values that might duplicate those
              measured at the beach under new moon conditions. Thus, if the preference was based
              upon intensity cues, then the turtles should show a random (near 50:50) distribution
              of arm entries. However, if the preference was based upon light wavelength, then the
              turtles should prefer one arm (predictably, the one presenting the shorter, near-UV
              wavelength) over the other.
            </div>
          </Typography>
        </div>
      </div>
      <div className="my-5">
        <Divider className="dark:bg-[#032546] bg-white/70" />
      </div>
      <div className=" m-4 text-center">
        <strong>
          CHELONIAN CONSERVATION AND BIOLOGY , <em>Volume 21, Number 2 – 2022</em>
        </strong>
      </div>
      <div className="lg:flex">
        <div className="lg:w-1/2 mr-4">
          <div className="m-4 text-center">
            <img src={Ymaze} alt="" title="" className="rounded-2xl lg:w-1/2 mx-auto m-4" />
            <div>
              <em>
                <strong>Figure 1.</strong> The Y-maze system used to determine wavelength
                preferences. (1) near-ultraviolet wavelength (380 nm); (2) visible wavelength (500
                nm); (3) start area. The Y-maze measures 38 3 15 cm (length 3 width) within the
                start area and the split, and 41 3 13 cm within each arm.
              </em>
            </div>
          </div>
          <Typography paragraph className="">
            <div>
              The 500-nm light stimulus was projected at the end of one arm as a circular target, 6
              cm in diameter, using a Kodakt slide projector (Model 440) containing a 300-W tungsten
              halogen lamp. The 380-nm stimulus was projected at the end of the other arm using a
              Great Value TM 14-W fluorescent ‘‘blacklight’’ bulb (Model EDXO-14), placed inside a
              foil-lined, 21-l Styrofoam TM box containing a 6-cm circular hole positioned against
              the arm end. Interference (5-nm half band width) and neutral density filters (Edmund
              Scientific Optics, Blackwood, NJ; 25-mm diameter) were used to obtain the desired
              wavelength and radiance, excluding any variation that might occur due to voltage
              fluctuations. Each stimulus was confirmed to be within 6 3 nm of its designated
              wavelength by spectrometer measurements (SRI 2000, Allied Scientific Pro, Gatineau,
              Quebec, Canada). Intensity measurements were also verified regularly between trials
              and adjusted as necessary. Stimulus intensities were measured in W/sec using a UDT
              S471 Optometer (UDT, San Diego, CA), calibrated for use with a UDT 247 sensor for
              nearly equivalent sensitivity to wavelengths between 340 and 600 nm. Values were then
              converted into photons/ cm 2 /sec.
            </div>
          </Typography>
        </div>
        <div className="lg:w-1/2">
          <Typography paragraph className="">
            <div>
              Hatchlings were initially dark-adapted, then placed in the starting area of the Y-maze
              where they were briefly prevented from crawling ahead by a clear plastic barrier. Once
              that barrier was lifted, each hatchling had 5 min to crawl into one or the other arm.
              Between trials with different clutches, excess sand was removed and the maze was
              cleaned with 0.05% chlorohexidine solution. Up to 14 hatchlings (depending upon
              availability), each used only once, were tested to obtain an informative distribution
              of arm entries based upon a 1-tailed binomial test (at p ≤ 0.05; Zar 1999).
            </div>
          </Typography>
          <Typography paragraph className="">
            <div>
              <em>Results and Discussion.</em> — A total of 41 hatchlings from 7 nests, tested at
              each of the 3 perceptually equivalent paired intensities, showed no statistically
              significant preference for either arm (Table 1). That outcome indicated their choices
              were based upon the detection of what they perceived as equivalent radiances. These
              data also showed that because the turtles performed so consistently, only a small
              number of trials were required to obtain statistically significant results (Celano et
              al. 2018; Trail and Salmon 2022). We conclude that the preference for the shorter
              wavelengths, demonstrated by many others, was based upon differences in stimulus
              radiance. Given that the turtles are more sensitive to the shorter light wavelengths
              (Fig. 2), it is likely that at the nesting beach those stimuli were perceived as
              brighter than the longer light wavelengths.
            </div>
          </Typography>
          <Typography paragraph className="">
            <div>
              Different conclusions were reached in earlier studies carried out by Mrosovsky and
              Carr (1967) and Mrosovsky and Shettleworth (1968), working with green turtles
              (Chelonia mydas). They concluded that the preference was based upon both intensity and
              wavelength cues. However, the evidence for wavelength discrimination was based upon
              the assumption that physiological and behavioral thresholds for light were concordant.
              That assumption was subsequently proven false (see below).
            </div>
          </Typography>
          <Typography paragraph className="">
            <div>
              Specifically, Mrosovsky and Shettleworth (1968) presented hatchlings with a choice
              between crawling toward 2 light sources presented simultaneously: a red (long
              wavelength) and a blue (short wavelength) stimulus. As expected, the blue stimulus was
              initially preferred, but that preference was reversed when the intensity of the red
              light was 2.8 log units brighter than the intensity of the blue light. Mrosovsky and
              Shettleworth (1968) noted that the blue light threshold (as measured physiologically
              from the retina by Granda and O’Shea 1972) was 2 log units lower than the red light
              threshold. On that basis, they concluded that the ‘‘...0.8 log unit discrepancy
              between the brightness estimated by the ERG method and the brightness estimated by the
              behavioral method...’’ implied that both light intensity and light wavelength governed
              the preference. But in marine turtles, spectral sensitivities measured physiologically
              (Fig. 2, lower graph) differ from those measured perceptually (Celano et al. 2018;
              Trail and Salmon 2022) and so the 2 sources of data are not comparable.
            </div>
          </Typography>
        </div>
      </div>
      <div className="my-5">
        <Divider className="dark:bg-[#032546] bg-white/70" />
      </div>
      <div className=" m-4 text-center">
        <strong>NOTES AND FIELD REPORTS</strong>
      </div>
      <div className="lg:flex">
        <div className="lg:w-1/2 mr-4">
          <Typography paragraph className="text-center">
            <div className="m-4">
              <strong>Table 1. </strong>
              <em>
                Distribution of Y-maze arm entries by leatherback hatchlings when presented with
                380-nm and 500-nm light stimuli. Each wavelength was projected from one arm of the
                Y-maze at the same time, and at perceptually equivalent intensities relative to the
                threshold at that wavelength. Under those conditions, the turtles showed no
                statistical preference for the shorter light wavelength (by a 1-tailed binomial
                test).
              </em>
            </div>
            <div className="underline">
              <strong>Response distribution</strong>
            </div>
          </Typography>
          <div className="flex text-center my-2">
            <div className="w-1/5">
              <strong>Log intensity above threshold</strong>
            </div>
            <div className="w-1/5">
              <strong>380 nm </strong>
            </div>
            <div className="w-1/5">
              <strong>500 nm</strong>
            </div>
            <div className="w-1/5">
              <strong>No. of hatchlings tested </strong>
            </div>
            <div className="w-1/5">
              <strong>
                <em>p</em>
              </strong>
            </div>
          </div>
          <Divider className="dark:bg-[#032546] bg-white/70" />
          <div className="flex my-2">
            <div className="w-1/5">
              0.3 <br />
              0.7
              <br />
              1.0
              <br />
              Totals:
            </div>
            <div
              className="w-1/5
                 text-center"
            >
              9
              <br />
              4 <br /> 8 <br /> 21
            </div>
            <div
              className="w-1/5
                 text-center"
            >
              5
              <br />
              9 <br /> 6 <br /> 20
            </div>
            <div
              className="w-1/5
                 text-center"
            >
              14
              <br />
              13
              <br /> 14 <br /> 41
            </div>
            <div
              className="w-1/5
                 text-center"
            >
              0.21
              <br />
              0.13
              <br /> 0.40 <br /> -
            </div>
          </div>
          <Divider className="dark:bg-[#032546] bg-white/70" />
        </div>
        <div className="lg:w-1/2">
          <Typography paragraph>
            These experiments and resulting conclusions were made possible by obtaining information
            that was previ- ously unavailable, specifically, detailed measurements of the light
            spectra present at night, tests to determine which wavelengths elicited a positive
            phototaxis, and thresholds of the hatchlings to those wavelengths (Celano et al. 2018;
            Trail and Salmon 2022). This approach was long overdue as previous demonstrations that
            the turtles preferred the shorter wavelengths implied wavelength discrimination in the
            absence of relevant supporting data.
          </Typography>
          <Typography paragraph>
            Our conclusions are also consistent with the condi- tions under which visual tasks are
            usually accomplished by most vertebrates under nocturnal lighting conditions. At night,
            vision is dominated by rods, which typically provide no information useful for color
            discrimination, especially in eyes that are designed for function (as are those of
            marine turtles) primarily under bright lighting conditions (Fritsches and Warrant 2013).
            However, if hatchlings emerge just before dusk (as often occurs in this species;
            Gonzales and Stewart 2019) or under full moon illumina- tion, both rods and cones might
            be activated. Under those conditions, Mrosovsky and Shettleworth (1968) could be correct
            — color might also contribute to a phototaxis preference.
          </Typography>
        </div>
      </div>
      <div>
        <div className="m-6 text-center">
          <img src={Lunar1} alt="" title="" className="rounded-2xl lg:w-1/2 mx-auto m-4" />
          <div>
            <em>
              <strong>Figure 2.</strong> Above, spectral sensitivity of leatherback hatchlings to
              near-monochromatic light wavelengths (k) measured behaviorally as phototaxis
              thresholds relative to the nocturnal light radiance present at a (Juno Beach) Florida
              nesting site, and (below) physiologically as ERG response thresholds recorded from the
              retina. The 2 distributions differ in the following respects: First, the range of
              threshold values is greater behaviorally (. 2 log units) than physiologically (, 2 log
              units). Second, the wavelengths detected with the greatest sensitivity (open diamonds)
              are among the shortest behaviorally and among the longest physiologically. (Above,
              from Trail and Salmon [2022]; below, modified from Horch et al. [2008]).
            </em>
          </div>
        </div>
      </div>
      <div className="lg:flex">
        <div className="lg:w-1/2 mr-4">
          <Typography paragraph>
            <strong>
              <em>Acknowledgments.</em>
            </strong>
            — This study was completed by S.E.T. in partial fulfillment of the requirements for a
            Master of Science degree in the Department of Biological Sciences at Florida Atlantic
            University (FAU). We thank M.J. Saunders, J. Seminoff, and 2 anonymous referees for
            comments that improved manuscript organization and clarity. The project was supported by
            the department and by the National Save the Sea Turtle Foundation of Fort Lauderdale,
            Florida. The study was authorized by the State of Florida (FWC Permit no. MTP-19-173A)
            and by the FAU animal care committee (IACUC Protocol A20-13).
          </Typography>
          <Typography paragraph className="text-center">
            <strong>LITERATURE CITED</strong>
          </Typography>
          <Typography paragraph>
            CELANO, L., SULLIVAN, C., FIELD , A., AND S ALMON, M. 2018. Seafinding revisited: how
            hatchling marine turtles respond to natural lighting at a nesting beach. Journal of
            Comparative Physiology A: Neuroethology, Sensory, Neural & Behavioral Physiology
            204:1007–1015. <br />
            DANIEL, R.S. AND S MITH , K.U. 1947. The sea-approach of the neonate loggerhead turtle (
            <em>Caretta caretta</em>). Journal of Comparative Physiology and Psychology 40:413–420.
            <br />
            EHRENFELD, D.W. 1968. The role of vision in the sea-finding orientation of the green
            turtle (<em>Chelonia mydas</em>). Animal Behaviour 16:281–287. <br />
            FRITSCHES, K.A. AND WARRANT , E.J. 2013. Vision. In: Wyneken, J., Lohmann, K.J., and
            Musick, J.A. (Eds.). The Biology of Sea Turtles, Vol 3. Boca Raton, FL: CRC Press, pp.
            32–53. <br />
            GONZALES, C.M. AND STEWART, K.R. 2019. Emergence timing of leatherback hatchlings (
            <em>Dermochelys coriacea</em>) at Sandy Point National Wildlife Refuge, 2010–2014.
            Chelonian Conservation and Biology 18:241–248. <br />
            GRANDA , A.M. AND O’S HEA , P.J. 1972. Spectral sensitivity of the green turtle (
            <em>Chelonia mydas mydas</em>) determined by electrical responses to heterochromatic
            light. Brain, Behavior, and Evolution 5:143–154. <br />
          </Typography>
        </div>
        <div className="lg:w-1/2">
          <Typography paragraph>
            HORCH, K.W., GOCKE , J.P., SALMON , M. AND FORWARD, R.B. 2008. Visual spectral
            sensitivity of hatchling loggerhead (<em>Caretta caretta L.</em>) and leatherback (
            <em>Dermochelys coriacea L.</em>) seaturtles, as determined by single-flash
            electroretinography. Marine and Freshwater Behaviour and Physiology 41:79–91. <br />
            LIMPUS, C.J. 1971. Sea turtle ocean finding behaviour. Search 2: 385–387. <br />
            LOHMANN , K.J., W ITHERINGTON , B.E., LOHMANN , C.M., AND SALMON, M. 1997. Orientation,
            navigation, and natal beach homing in sea turtles. In: Lutz, P.L. and Musick, P.A.
            (Eds.). The Biology of Sea Turtles. Boca Raton, FL: CRC Press, pp. 107–136. <br />
            MROSOVSKY, N. AND CARR , A. 1967. Preference for light of short wavelengths in hatchling
            green sea turtles, <em>Chelonia mydas</em>, tested on their natural nesting beaches.
            Behaviour 28:217–231. <br />
            by MROSOVSKY , N. AND SHETTLEWORTH , S.J. 1968. Wavelength preferences and brightness
            cues in the water finding behaviour of sea turtles. Behaviour 32:211–257. <br />
            TRAIL , S.E. AND SALMON , M. 2022. Differences in visual perception are correlated with
            variation in sea-finding behaviour between hatchling leatherback
            <em>(Dermochelys cor- iacea)</em>
            and loggerhead <em>(Caretta caretta)</em> marine turtles. Animal Behaviour 187:47–54.
            <br />
            WITHERINGTON, B.E. 1992. Behavioral responses of nesting turtles to artificial lighting.
            Herpetologica 48:31–39. <br />
            WITHERINGTON, B.E. AND BJORNDAL, K.A. 1991. Influences of wavelength and intensity on
            hatchling sea turtle phototaxis: implications for sea-finding behavior. Copeia
            1991:1060– 1069. <br />
            YOUNG , M., SALMON , M., AND FORWARD , R. 2012. Visual wavelength discrimination by the
            loggerhead turtle, <em>Caretta caretta</em>. Biological Bulletin (Woods Hole) 222:46–55.
            <br />
            ZAR, J.H. 1999. Biostatistical Analysis. Fourth edition. Hoboken, NJ: Prentice-Hall.
          </Typography>
          <Typography>
            <em>
              Received: 20 December 2021 Revised and Accepted: 10 May 2022 Handling Editor: Jeffrey
              A. Seminoff
            </em>
          </Typography>
        </div>
      </div>
    </div>
  );
}
