import React from "react";
import { Typography, Divider } from "@mui/material";
import Sandy2019 from "../Images/Sandy-Point-Leatherback-Project-2019.jpg";
import FixSign from "../Images/fix-the-Sandy-Point-sign.jpg";
import DanaKelly1 from "../Images/Dana-Kelly-Claire-Nina-Emma-Angie-Shreya-Jeremy.jpg";
import Turtlecamp1 from "../Images/Turtle-Camp-for-the-Leatherback-Project.jpg";
import BreathSalt1 from "../Images/Breathe-Salt.jpg";

export default function SandyPoint() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <div className="pb-5">
          <h1 className="text-2xl font-bold">2019 Sandy Point Leatherback</h1>
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="mt-5">
        <img className="rounded-2xl mx-auto" src={Sandy2019} alt="" title="" />
      </div>




      <div className="lg:flex gap-5 pt-5">

        <div className="lg:w-1/2">
          <div className="mb-5 border-white bg-[#337ab7]">
            <div className="text-2xl font-bold  bg-[#337ab7] p-5">The Turtles!</div>
            <div className="bg-white p-5">
              <div className="text-[#032546]">55 leatherback turtle encounters</div>
              <div className="text-[#032546]">24 individual nesting females identified</div>
              <div className="text-[#032546]">2,100 leatherback hatchlings released</div>
              <div className="text-[#032546]">2 turtles found that have been nesting since 1988!</div>
              <div className="text-[#032546]">167.4 cm was the largest turtle</div>
              <div className="text-[#032546]">142.0 cm was the smallest turtle</div>
              <div className="text-[#032546]">6 was the record number of nests by one individual</div>
            </div>
          </div>


        </div>

        <div className="lg:w-1/2">
          <div>
            <Typography paragraph>
              Since 1977, leatherback turtles have been studied and protected at Sandy Point National
              Wildlife Refuge. Sandy Point lies at the southwestern tip of St. Croix (US Virgin
              Islands), jutting out into the Caribbean Sea. It has quick access to very deep water and
              dynamic nearshore currents that redistribute sand around the point seasonally. It is ideal
              nesting habitat for leatherbacks, although green and hawksbill turtles also use it. This
              refuge was officially created for the protection of leatherback nesting in 1984 and has
              supported the densest nesting of leatherbacks under US jurisdiction since that time. The
              refuge is closed to the public seasonally from April through August specifically for
              turtle nesting. Results of the intensive work here have resulted in a wealth of knowledge
              about leatherbacks, their nesting habits, site fidelity, nest environment and results of
              conservation practices
            </Typography>
          </div>

        </div>
      </div>
      <div className="lg:flex gap-5">
        <div className="lg:w-2/3">
          <div>
            <Typography paragraph>
              In order to complete the monitoring work, our team of students and volunteers patrol the
              3.2-km nesting beach by foot every 45 minutes between 7:30 pm and 5:00 am to encounter
              each nesting leatherback. Incredibly, some females have been nesting at the refuge for
              over 30 years; they are identified by flipper and PIT tags. Each turtle is measured,
              tagged, sampled for genetics and the location of her nest is recorded by triangulation.
              If the nest is laid in a known erosion zone, the eggs are relocated to an area of the
              beach not prone to washout.
            </Typography>
            <Typography paragraph>
              This has been a major management goal of the refuge for some time. Over 1,000 female
              leatherbacks have been identified since the project began. Nesting usually begins in
              March and continues through July. Another major component of the monitoring program
              involves protection of emerging nests from May through August. Leatherback hatchlings
              begin emerging in the early evening before dark and we have a team in place checking on
              nests that are due to emerge.
            </Typography>
          </div>
        </div>

        <div className="lg:w-1/3">
          <div>
            <div><img className="rounded-2xl mx-auto" src={FixSign} alt="" title="" /></div>
            <div className="pt-2"><em>Volunteers help Refuge Manager Mike Evans fix the Sandy Point sign.</em></div>
          </div>
        </div>

      </div>


      <div className="lg:flex gap-5 pt-5">
        <div className="lg:w-2/3">
          <div><img className="rounded-2xl mx-auto" src={DanaKelly1} alt="" title="" /></div>
          <div className="pt-2 text-center">
            <em>
              Some team members during late June: Dana, Kelly, Claire, Nina, Emma, Angie, Shreya, and
              Jeremy.
            </em>
          </div>
        </div>

        <div className="lg:w-1/3">
          <div>
            <Typography paragraph>
              Hatchlings are protected from predators (mainly birds) as they make their way up to the
              surface and down the beach to the water. We conduct a genetics research project with the
              hatchlings to determine paternity and identify male breeding turtles in the population.
              The Fish and Wildlife Service supports a community program called Turtle Watch that
              guides visitors through either a nesting event with a female leatherback (April through
              mid-June) or a hatching event (mid-June through July). This program allows local
              residents to realize the importance and wonder of leatherback nesting in St. Croix. Each
              year, over 1,000 people participate in this outreach effort.
            </Typography>
            <Typography paragraph>
              In 2017, the refuge was severely affected by Hurricane Maria and we are still evaluating
              the effects of the storm on nest distribution and success as well as habitat quality.
              The storm changed the nearshore environment by moving tremendous amounts of sand around
              and affecting the vegetation in a serious way. Most tall trees were knocked over,
              leaving gaps in the vegetation along the beach that usually block the light coming to
              the refuge beaches from nearby towns. Assessing the impacts of Maria will continue to be
              a major focus for our team this season, in addition to working with the turtles.
            </Typography>
          </div>
        </div>

      </div>

      <div className="lg:flex gap-5 pt-5">

        <div className="lg:w-2/3">

          <div>
            <Typography paragraph>
              Last season, the team did evaluations of nest distribution, and compared pre-hurricane
              and post- hurricane nest success. All of these studies will be presented at the
              International Sea Turtle Symposium!
            </Typography>
            <Typography paragraph>
              As seasons go, this past year on the Leatherback Project at Sandy Point was a weird one.
              We expected to see many of the females that had nested between 2009 and 2015. We hoped
              this might be the year, as recently turtles have been taking longer and longer to come
              back to nest. Instead, we had the lowest number of individual nesting turtles since
              1990. To answer the question of why the turtles are not returning, or to find out where
              they are going, we were planning to start a satellite tagging program, and while we got
              our equipment ready to go (with the help of National Save the Sea Turtle Foundation), we
              just didn’t have the right leatherback candidates to deploy our tags. We will be
              starting right away at the beginning of next season with this program.
            </Typography>
          </div>

        </div>
        <div className="lg:w-1/3">

          <div className="mb-5 border-white bg-[#337ab7]">
            <div className="text-2xl font-bold text-right bg-[#337ab7] p-5">The People!</div>
            <div className="bg-white p-5">
              <div className="text-[#032546]">88 all-night patrols for nesting turtles</div>
              <div className="text-[#032546]">80 evening hatchling patrols</div>
              <div className="text-[#032546]">3,520 miles walked</div>
              <div className="text-[#032546]">30 volunteers, students and interns</div>

            </div>
          </div>

        </div>


      </div>


      <div className="lg:flex gap-5 pt-5">
        <div className="lg:w-1/3">
          <div><img className="rounded-2xl" src={Turtlecamp1} alt="" title="" /></div>
          <div className="pt-2"><em>Turtle Camp for the Leatherback Project.</em></div>
        </div>
        <div className="lg:w-2/3">
          <div>
            <Typography paragraph>
              We did have two amazing turtle recaptures, however. Both Caroline (VI1231) and Max
              (VI1291) first nested in 1988, making it 31 years since they first nested. Both of them
              have been regular nesters over the years at Sandy Point. Between them, they have been
              seen over 100 times! They tie the record for maximum reproductive longevity (31 years)
              with AAG322, who was tagged in 1981 and last seen nesting in 2012. We think that these
              are possibly the longest records for a leatherback return anywhere in the world!
            </Typography>
          </div>
        </div>

      </div>



      <div className="lg:flex gap-5 pt-5">
        <div className="lg:w-1/2">
          <div>
            <Typography paragraph>
              Despite wishing for more turtles, our volunteer research assistants still gained
              plenty of experience with turtle tagging, nest relocations (especially near the end of
              the season), and data entry, checking and analysis. We were able to take care of many
              of the important data entry jobs that we just don’t have time for during a regular
              season. This will be very helpful as a few of our research assistants will use
              portions of the data in their masters degrees and presentations at the next
              International Sea Turtle Symposium in Cartagena, Colombia.
            </Typography>
          </div>
          <div>
            <Typography paragraph>
              We welcomed lots of visitors and short-term volunteers this season, including university
              students from Duke University, Winona State University and James Madison University. Dr.
              Larry Wood of The Florida Hawksbill Project joined us for a week along with yoga retreat
              participants from Breathe Salt & Yoga in Jupiter, FL. Our loyal St. Croix resident
              volunteers helped with night patrols, hatchling patrols and Turtle Watch. Thank you to
              everyone who helped this season!
            </Typography>
          </div>
          <div>
            <Typography paragraph>
              Our project is updated regularly on our website at SeaTurtleCensus.com. We also have a
              Facebook page (@stxleatherbacks) that we regularly update. Our sincere thanks to The
              National Save The Sea Turtle Foundation for the support of our program again this year!
              The Sea Turtle Census Initiative is a project of The Ocean Foundation, a 501(c)(3)
              organization, working in cooperation with the US Fish and Wildlife Service and NOAA
              National Marine Fisheries Service Marine Turtle Genetics Program in La Jolla, California.
            </Typography>
          </div>
        </div>
        <div className="lg:w-1/2">

          <div><img className="rounded-2xl" src={BreathSalt1} alt="" title="" /></div>
          <div className="pt-2"><em>Breathe Salt & Yoga volunteers compare body size to a turtle track</em></div>

        </div>

      </div>

    </div>
  );
}
