import { Typography, Divider } from '@mui/material'
import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import SeaTurtleNest1 from '../Images/SeaTurtleNest1.jpg'
import SeaTurtleNest2 from '../Images/SeaTurtleNest2.jpg'
import SeaTurtleNest3 from '../Images/SeaTurtleNest3.jpg'
import { BsFillLightbulbOffFill } from 'react-icons/bs';
import { RiPoliceCarFill } from 'react-icons/ri';
import { IoIosCall } from 'react-icons/io';

export default function AdoptSeaTurtleNest() {

    const phoneNumber = '(954) 328-0580';
    const formattedPhoneNumber = `tel:${phoneNumber}`;

    return (
        <div className='p-5'>
            <div className='lg:flex gap-5'>
                <div className='lg:w-2/3'>
                    <div className='pt-5'><Typography paragraph>The National Save the Sea Turtle Foundation supports NOVA Southeastern University Oceanographic Center’s students and Broward County Sea Turtle Conservation Program.</Typography></div>
                    <div><Typography paragraph>Oceanographic Center students are just beginning our 23rd Year of The Sea Turtle Conservation Program. The operation employs 23 field workers who are primarily NSUOC Graduate Students or recent graduates. Students patrol over 38 km of Broward County beaches each morning at dawn, from March 1 through September 30. Endangered Sea Turtle nests are identified, marked, located by GPS and sometimes relocated from intensely lighted beaches to avoid Hatchling disorientation.</Typography></div>
                    <div><Typography paragraph>Loggerhead yearly nest counts were in decline from 2000 To 2009, but in 2010, 2,282 Loggerhead nests were recorded, which was 26% higher than in 2009, the highest number since 2000.</Typography></div>
                    <div><Typography paragraph>Green Sea Turtles deposited the second highest number of nests since project inception which was only 4 nests below the record number of 272 recorded in 2008.</Typography></div>
                    <div><Typography paragraph>Leatherback Turtles continue to nest in Broward County in lower numbers (14 nests last year), but there is a long term increasing trend.</Typography></div>
                    <div><Typography paragraph>The project depends upon the support and cooperation of the public. A portion of the project is funded by The National Save the Sea Turtle Foundation from donations to their Adopt a Nest Program.</Typography></div>
                    <div><Typography paragraph><h2 className='text-xl'>Here are some ways that concerned and interested residents can help:</h2></Typography></div>
                </div>
                <div className='lg:w-1/3'>
                    <div className='mt-5'>
                        <div><img className='mx-auto rounded-2xl' src={SeaTurtleNest1} alt='adopt sea turtle nest' title='Adopt a Sea Turtle Nest, leatherback, green, hawksbill, loggerhead, kemps ridley turtles.' /></div>
                    </div>
                    <div className='my-5'>
                        <div><img className='mx-auto rounded-2xl' src={SeaTurtleNest2} alt='adopt sea turtle nest' title='Adopt a Sea Turtle Nest, leatherback, green, hawksbill, loggerhead, kemps ridley turtles.' /></div>
                    </div>
                    <div className=''>
                        <div><img className='mx-auto rounded-2xl' src={SeaTurtleNest3} alt='adopt sea turtle nest' title='Adopt a Sea Turtle Nest, leatherback, green, hawksbill, loggerhead, kemps ridley turtles.' /></div>
                    </div>
                </div>
            </div>



            <div>
                <List sx={{ width: '100%' }}>
                    <ListItem>
                        <ListItemAvatar >
                            <Avatar style={{ backgroundColor: '#032546' }}>
                                <BsFillLightbulbOffFill />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="As much as possible, refrain from beach activities after dark which may frighten crawling Sea Turtles back into the sea before nesting or cause them to abandon the nest." />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar style={{ backgroundColor: '#032546' }}>
                                <IoIosCall />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <>
                                    Remember that it is normal to see adult Sea Turtles crawling and digging on the beach in the summer. Do not call the Sea Turtle cell phone number unless you see an adult turtle trapped under furniture, etc. or crawling off the beach {' '}
                                    <a href={formattedPhoneNumber}>
                                        {phoneNumber}

                                    </a>
                                </>
                            }
                        />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar style={{ backgroundColor: '#032546' }}>
                                <IoIosCall />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <>
                                    You may see hatchling turtles on the beach. If they are crawling toward the water, do nothing but watch. If they are crawling away from the sea, call the cell phone number{' '}
                                    <a href={formattedPhoneNumber}>
                                        {phoneNumber}

                                    </a>
                                </>
                            }
                        />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar style={{ backgroundColor: '#032546' }}>
                                <BsFillLightbulbOffFill />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Keep bright lights from shining directly on the beach. This may disorient Hatchlings and cause them to crawl away from the water." />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar style={{ backgroundColor: '#032546' }}>
                                <RiPoliceCarFill />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="If you see someone harassing a Sea Turtle, collecting Hatchlings, poaching nests or disturbing nest markers, call the local police. Permitted Turtle workers will have clearly marked shirts." />
                    </ListItem>
                </List>
            </div>

            <div>
                <iframe
                    className="rounded-2xl mx-auto w-full aspect-video align-middle"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/JPRioZ0vCeM"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                ></iframe>
            </div>
        </div>
    )
}
