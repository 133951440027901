import React from "react";
import { Typography, Divider } from "@mui/material";
import AnimalFAU from "../Images/FAU-Animal-Behavior-and-Visual-Perception.jpg";
import NightNest from "../Images/A-view-of-the-nesting-beach-at-night.jpg";
import Ymaze from "../Images/Y-maze.jpg";
import GreenPhototaxis from "../Images/Phototaxis-thresholds-for-Green-Turtles.jpg";
import CrawlingTracks from "../Images/comparison-of-leatherback-hatchling-crawling-tracks.jpg";
import Loggerhead1 from "../Images/Diagrams-showing-leatherback-loggerhead.jpg";

export default function LeatherbackLight() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex">
        <div className="lg:float-left lg:w-3/4">
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              Leatherbacks, Light, and the Comparative Study of Seafinding Behavior
            </h1>
          </div>
          <Typography paragraph>
            Samantha Trail and Michael Salmon Florida Atlantic University .
          </Typography>
        </div>
        <div className="lg:float-right lg:w-1/4">
          <img className="rounded-2xl border-4 border-white mx-auto" src={AnimalFAU} alt="" title="" />
        </div>
      </div>
      <div className="my-5">
        <Divider className='dark:bg-[#032546] bg-white/70' />
      </div>
      <div className="lg:flex lg:gap-5">
        <div>
          <Typography paragraph>
            Sea turtles are unique in that they are large, long-lived species, but they produce a
            large number of offspring with very few surviving to reach maturity themselves. Assuming
            ideal nest conditions, that treacherous struggle to survive begins almost immediately.
            As hatchlings, sea turtles emerge from the sand with their clutch mates, experiencing
            the beach environment outside of the buried egg chamber for the very first time. The
            immediate objective: get to the ocean as quickly and accurately as possible.
          </Typography>
          <Typography paragraph>
            This journey from the nest to the ocean is called “seafinding” – an innate behavior
            under intense selection pressure requiring a consistent, reliable cue. It is now well
            documented that hatchlings of all species accomplish this feat by crawling in the
            direction of the lowest and brightest horizon. Regardless of lunar phase or position
            under natural lighting conditions, that lowest, brightest horizon will be toward the sea
            (Figure 1). The turtles then use a “positive phototaxis” (attraction to the brightest
            location) to crawl in that direction.
          </Typography>
          <Typography paragraph>
            However when animals respond to visual cues, they favor some wavelengths over others -
            those that are particularly important for promoting adaptive behavior. For example,
            foraging honeybees use the pattern of ultra-violet (UV)
          </Typography>
          <div className="mr-5">
            <div><img className="rounded-2xl" src={NightNest} alt="" title="" /></div>
            <div className="pt-2">
              <em>
                Figure 1. A view of the nesting beach at night. Hatchlings emerging from the nest will
                use vision to scan their environment before crawling toward the sea. The view toward
                land (here, a dune) will be darker as light is absorbed by vegetation. In contrast,
                light from the stars (and moon, if present) is reflected from the water surface making
                the view toward the sea brighter. This difference tells the hatchlings where the ocean
                is located, even if they can’t directly see it from the nest site, and triggers a
                positive phototaxis.
              </em>
            </div>
          </div>
          <div className="mr-5 my-5">
            <div><img className="rounded-2xl" src={Ymaze} alt="" title="" /></div>
            <div className="pt-2">
              <em>
                Figure 2. A Y-maze is used to reproduce a light intensity difference, similar to the
                one found at the nesting beach. A projector (P) is used to present a light stimulus in
                one of the Y-maze arms; the other arm remains dark. If hatchlings can detect the
                light, they will crawl into the illuminated arm. When the light intensity is reduced
                to a level that they can’t see, hatchlings are equally likely to crawl into either
                arm. That difference enables investigators to obtain wavelength phototaxis thresholds.
                CB is the clear barrier that keeps the turtle in the start area until the light is
                presented.
              </em>
            </div>
          </div>
          <Typography paragraph>
            That exquisite sensitivity meant they could easily distinguish between differences in
            light intensity in a landward vs. seaward direction, even on the darkest of evenings.
          </Typography>
          <Typography paragraph>
            Those results piqued our interest into whether leatherback hatchlings, which are
            distantly related to both green turtles and loggerheads, had evolved a similar or
            different pattern of sensitivity. Leatherbacks diverged from their hard-shelled
            relatives ~ 100 million years ago and are also very unique turtles! They are exclusively
            oceanic, deep-diving residents throughout their life, as opposed to mature loggerheads
            and green turtles that live primarily in shallow coastal waters. So, though visual cues
            are required for seafinding by all marine turtle hatchlings, those ecological
            differences and the long evolutionary separation between the two groups provide the
            potential for significant differences in how they accomplish their crawl to the ocean.
            Our objective was to find out the extent to which that potential was realized.
          </Typography>
          <Typography paragraph>
            We used the same methods and obtained turtles from the same nesting beaches as Celano’s
            group to measure leatherback spectral sensitivities (Figure 3).
          </Typography>
          <Typography paragraph>
            To investigate this question, we headed to the beach to observe and compare the natural
            paths of loggerhead and leatherback hatchlings crawling under full (or nearly full) and
            new moon conditions. Though loggerheads showed similar seafinding paths under full or
            new moon conditions, the same could not be said of the leatherbacks. Two major
            differences were revealed: under new moon conditions, leatherbacks (1) showed
            considerably more circling during their crawl (Figure 4) and (2) their crawling
            orientation angles under those conditions were significantly more variable (Figure 5).
            We think that outcome is related to their light sensitivity. Under new moon conditions,
            leatherback phototaxis thresholds are only slightly below background light levels (red
            lines in Figure 3), so distinguishing between landward and seaward light intensities
            might be more challenging. It’s likely, then, that circling follows in an attempt to
            make that intensity distinction clearer. Even so, leatherbacks usually manage to find
            the sea during new moon. It’s just more difficult!
          </Typography>
          <Typography paragraph>
            Our findings should be of interest to conservation managers as they also suggest that
            leatherback seafinding, whether by hatchlings or adult females after nesting, will be
            more prone to disruption by artificial light sources, including sky glow that currently
            poses a serious threat worldwide to all marine turtles at nesting beaches. We suspect
            that as a consequence, leatherback hatchlings at urban beaches may fail to locate the
            sea more often than the hard-shelled hatchlings. Given that various leatherback
            populations worldwide are in serious decline, further research into this problem is
            certainly appropriate.
          </Typography>
        </div>
        <div>
          <Typography paragraph>
            wavelengths shown by flowers to identify those that are currently producing nectar. Pit
            vipers, hunting at night, are sensitive to the longer, infrared wavelengths that
            generate heat as heat also radiates from their warm-blooded mammalian prey; that “heat
            image” directs the snake’s venomous strike. Similarly, we expect sea turtle hatchlings
            to be particularly sensitive to the light wavelengths present at the beach at night,
            especially if they can be used to distinguish between a landward and an oceanic view.
            But, how can that sensitivity best be measured? The solution is to create an environment
            in which, once again, hatchlings need to choose between two spatially separate
            locations, one visually darker than the other, so that a positive phototaxis response is
            released.
          </Typography>
          <Typography paragraph>
            Master’s graduate Lisa Celano and her colleagues were the first to use this technique to
            measure light spectral sensitivities in loggerhead and green turtle hatchlings, two
            species of “hard-shelled” marine turtle. They used a Y-maze with light wavelengths
            projected from one arm but not the other (Figure 2), and sequentially presented
            individual light wavelengths between 340 and 600 nm (near-UV to orange). They knew from
            measurements made at the beach that these light energies were present at night.
            Initially, the light stimulus was bright enough to induce all the turtles to crawl into
            the illuminated arm. That intensity was then systematically reduced until the preference
            for the illuminated arm was eliminated. The lowest light intensity that still induced a
            group of hatchlings to crawl into the illuminated arm was recorded as the “phototaxis
            threshold”, or sensitivity measure, for that particular wavelength.
          </Typography>
          <Typography paragraph>
            The data from that study (Figure 3) revealed three trends. First, loggerheads and green
            turtles had almost identical sensitivities, a sure sign of what biologists call
            “convergent evolution.” That finding meant that the two species, even though separately
            evolved for ~ 63 million years, had settled on the same solution to the seafinding
            problem. Second, both species were most sensitive to the shorter (&lt;500 nm)
            wavelengths. Third, both species were up to 100 times more sensitive to those
            wavelengths than to the background illumination presented by the stars or moonlight at
            the beach (Figure 1).
          </Typography>
          <Typography paragraph>
            Our results provide support for the idea that seafinding was accomplished in
            leatherbacks through somewhat different, or “divergent,” means. Like loggerheads and
            green turtles, leatherbacks were most sensitive to shorter wavelengths (&lt;500 nm) but
            leatherbacks were consistently less sensitive to all the tested wavelengths than their
            hard-shelled relatives. This difference in overall sensitivity was profound, as it meant
            that leatherbacks required upwards of 10 times more light at nearly all the utilized
            wavelengths to direct their crawl to the sea.
          </Typography>
          <Typography paragraph>
            Intrigued by these differences, we explored another question: How might this reduction
            in sensitivity translate into behavioral differences during seafinding on the beach?
            Previous observations by other naturalists (Archie Carr and Nicholas Mrosovsky) suggest
            that one peculiar seafinding behavior occurred more often in leatherback hatchlings than
            others: circling. More specifically, orientation circles are characteristic when a
            hatchling (or nesting females) during their crawl does not head in a straight direction
            but instead makes brief, tight circles, and less often as a larger, sweeping path. Was
            that circling somehow related to differences in light sensitivity between the two groups
            of marine turtles?
          </Typography>
          <div className="lg:ml-5 my-5">
            <div><img className="rounded-2xl" src={GreenPhototaxis} alt="" title="" /></div>
            <div className="pt-2">
              <em>
                Figure 3. Phototaxis thresholds for Green Turtles (
                <span className="relative inline-block ml-2">
                  <span className="absolute bottom-0 right-1 bg-red-500 rounded-full h-2 w-2"></span>
                </span>
                ) and Loggerheads (
                <span className="relative inline-block ml-2">
                  <span className="absolute bottom-0 right-1 bg-blue-900 rounded-full h-2 w-2"></span>
                </span>
                ) plotted relative to the mean thresholds for Leatherbacks (
                <span className="relative inline-block">
                  <span className="absolute inset-0 bg-yellow-500 h-1 transform -skew-x-12 top-3"></span>
                  <span className="relative inline-block w-10 bg-yellow text-center"></span>
                </span>
                ) between 340 - 400 nm, 400 - 500 nm, and 500 - 600 nm. Thresholds are shown relative
                to background lighting measured during a full moon, quarter moon and new moon (paired
                colored lines, above). All three species can detect the presence of light well below
                background, including the ultraviolet range (below 400 nm) that humans do not see but
                that is present at night. The two hard-shelled species are between 10 - 100 x more
                sensitive to light than leatherbacks and under all conditions should easily
                distinguish between the darker view toward land and the brighter view toward the sea.
                However, leatherbacks are much less sensitive and especially during new moon, when
                thresholds are not much lower than background, might find that task more difficult.
              </em>
            </div>
          </div>
          <div className="lg:ml-5">
            <div><img className="rounded-2xl" src={CrawlingTracks} alt="" title="" /></div>
            <div className="pt-2"><em>
              Figure 4. A comparison of leatherback hatchling crawling tracks during seafinding
              under full moon (blue) and new moon (red) lighting conditions. Hatchlings were
              released from the nest site and allowed to crawl unencumbered to the wrack line on the
              beach. During new moon, leatherback hatchling orientation was less accurate and
              contained several bouts of circling, as seen in this track record.
            </em></div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="mx-auto">
          <div className="mt-5"><img className="rounded-2xl" src={Loggerhead1} alt="" title="" /></div>
          <div className="pt-2 flex justify-center items-center">
            <em>
              Figure 5. Diagrams showing how leatherback (top row) and loggerhead (bottom row)
              <br />
              hatchlings oriented during their crawl between the nest and the location of the ocean,
              <br />
              as indicated by the blue arrow, on nights when exposed to a full moon (left column)
              <br /> or a new moon (right column). Each line represents the track of one turtle.
              Leatherback<br /> accuracy declines during new moon but not during full moon when
              light intensity cues<br /> are easier to distinguish from background (see Figure
              3). A similar decline does not occur<br /> in loggerheads, probably because of
              their greater sensitivity to nocturnal lighting.
            </em>
          </div>
        </div>
      </div>
    </div>
  );
}
