import React from "react";
import { Typography, Divider } from "@mui/material";
import FAUAnimalBehaviorandVisualPerception from '../Images/FAU-Animal-Behavior-and-Visual-Perception.jpg'
import FreshwaterturtlesMarineturtles from '../Images/Freshwater-turtles-Marine-turtles.jpg'
import experimentalstudieshatchlingcommunication from '../Images/Turtles-used-for-experimental-studies-of-hatchling-communication.png'
import experimenttodeterminesynchronous from '../Images/An-experiment-to-determine-how-synchronous-hatching-is-achieved.jpg'
import loggerheadhatchlingsemerging from '../Images/loggerhead-hatchlings-emerging-from-the-nest.jpg'

export default function InsideTurtleNest() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className='lg:float-right lg:ml-5 mb-5'><img className="rounded-2xl mx-auto" src={FAUAnimalBehaviorandVisualPerception} alt="" title="" /></div>
      <div ><div className='pb-5'><h1 className='text-2xl font-bold'>Inside a Turtle Nest: Embryos Communicate About When to Hatch and When to Emerge
      </h1></div></div>
      <div><Typography paragraph>Mike Salmon, Research Professor<br />
        Biological Sciences, Florida Atlantic University</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="lg:flex gap-4 pt-5">

        <div className="lg:w-1/2">
          <div><Typography paragraph>We mammals are a peculiar and highly specialized bunch when it comes to nurturing offspring. We provide for just a few eggs, sequestered inside our bodies, and nourished for extended periods in a uterus. After birth we follow up by providing offspring with nutrition, protection, and a prolonged period of socialization before they become independent. Only one other vertebrate group - the birds - matches that kind of parental devotion by protecting and incubating clutches of eggs and, after they hatch, feeding and defending the chicks.</Typography></div>
          <div><Typography paragraph>While lavished parental care does occur sporadically in other vertebrate groups, it is the exception among the fishes, amphibians and reptiles except for a few amphibians, snakes and some crocodilians. Typically, and instead, these creatures produce large numbers of eggs placed together in nests whose location is generally hidden, and then abandoned by the parent. Thus, the costs associated with parental care at each breeding are minimized. However, because many nests are discovered and destroyed by predators, attacked by micro-organisms, or fail because of adverse environmental changes (in temperature or rainfall), species that abandon nests incur additional costs as well.</Typography></div>
          <div className='mx-auto'><img className="rounded-2xl mx-auto" src={FreshwaterturtlesMarineturtles} alt="" title="" /></div>
          <div className="pt-2 mb-5"><em>Figure 1. Freshwater turtles (left) are relatively small and typically dig shallow nests that contain no more than 20 eggs. Eggs at the top of those nests are often heated by the sun and are warmer than those at the bottom. Marine turtles (right) are much larger and their nests that are too deep to be much affected by the sun. They also have much larger clutches - over 100 eggs in loggerhead nests. Eggs in the center aren’t able to dissipate metabolic heat during growth as well as those eggs at the periphery, and so are typically warmer. The expected outcome in all turtle nests is that the warmer eggs will develop faster than the cooler eggs and hatch first, but experiments show that doesn’t always happen. See the text for details. (Credit: Angela Field, with permission)</em></div>
        </div>
        <div className="lg:w-1/2">
          <div><Typography paragraph>They have to compensate for those losses by producing more clutches of eggs, usually during the same breeding season.</Typography></div>
          <div><Typography paragraph>Turtles are a good example of species showing this kind of orphaned nesting pattern. They also have served as excellent subjects for studying how and why embryos within the nest coordinate their development during incubation. It turns out that these processes involve communication between the embryos to synchronize hatching, and coordination between hatchlings to select a time to leave the nest. These rather unexpected findings make for fascinating stories that are the subject of this essay.</Typography></div>
          <div><Typography paragraph>The turtle nest and embryonic development</Typography></div>
          <div><Typography paragraph>The initial interest in what happens inside a turtle nest arose from a simple observation. All species of turtles nest in much the same way; they use their hind feet (or flippers, if a sea turtle) to dig a hole in the substrate. That’s followed by filling the hole with a clutch of eggs and finally, covering the eggs to hide the nest location. In most freshwater turtles, the nest usually contains less than 20 eggs but in marine turtles, it can contain up to 200 (depending upon the species; Figure 1). The nest is then abandoned and the eggs are left to incubate on their own. How fast that happens depends on temperature. Measurements confirm that in all turtle nests, some eggs are warmer than others (Figure 1) and therefore should incubate faster and hatch sooner than the cooler eggs. Yet, in the vast majority of turtle nests, that doesn’t happen. Instead, hatching (defined as breaking out of the egg) is often synchronous, that is, the entire clutch completes the process within a few hours.</Typography></div>
          <div><Typography paragraph>For synchronous hatching to happen, either the eggs exposed to warmer temperatures have to delay hatching until their cooler siblings complete development (the “waiting” hypothesis), or the cooler, slower developing eggs must “catch-up” to their warmer, and developmentally more advanced, siblings. An Australian biologist (R.-J. Spencer) and his colleagues designed an experiment to distinguish between these alternatives, using Murray River turtles (Figure 2). That was done by incubating a sample of the eggs from a single nest at two different temperatures (25°C or 30°C) so that one (warmer) group developed faster than the other (cooler) group ([1]; Figure 3).</Typography></div>
        </div>

      </div>

      <div className="lg:flex gap-4 pt-5">
        <div className="lg:w-1/2">
          <div className='mx-auto'><img className="rounded-2xl mx-auto" src={experimentalstudieshatchlingcommunication} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>Figure 2. Turtles used for experimental studies of hatchling communication. A - C are freshwater turtles. A, Murray River turtle (Emydura macquerii); B, Painted turtle (Chrysemys picta); C, pig-nosed turtle (Carettochelys insculpta); D, loggerhead (Caretta caretta).</em></div>
          <div><Typography paragraph>The two groups were then combined and incubated at either the warmer or cooler temperature until hatching occurred. The scientists knew how long incubation should take at each of those temperatures so they could tell if the warmer eggs hatched later than expected (favoring the “waiting” hypothesis) or if the cooler eggs hatched sooner than expected (favoring the “catch-up” hypothesis). The results indicated that the cooler eggs hatched sooner, sometimes by as much as 4 or 5 days, favoring the catch-up hypothesis. Somehow, those embryos must have sensed that their warmer siblings were ahead in development and took action to compensate.</Typography></div>
          <div><Typography paragraph>Presently, what remains unknown is how the embryos make those assessments. One possibility is that they can actually sense the metabolic rates of their neighbors! That might happen by detecting the mechanical changes associated with a faster heartbeat in an adjacent embryo, or even its rate of oxygen consumption. That could happen because the eggs are in contact and their shells are thin, flexible and permeable to permit gas exchange. A faster heartbeat or an increase in CO2 (like being in a crowded, stuffy, warm room!) are both associated with a higher metabolic rate that in turn, could serve as a proxy signal for faster and more advanced development.</Typography></div>
        </div>


        <div className="lg:w-1/2">
          <div><Typography paragraph>Regardless of how assessments are made, it’s already clear that the mechanisms underlying coordinated hatching are diverse. In Murray River turtles, embryos that were behind in development “catch up” by secreting hormones that increase their metabolic rate, even without any change in embryonic temperature. Another species, the Painted turtle (Figure 2), is native to temperate areas of North America. They nest in the summer, leaving the eggs to develop in the waning days of the season or during the following Spring. Hatching is synchronous but occurs even though the embryos vary somewhat in their stage of development. The hatchlings remain in the nest until their biological clock tells them that it’s warmer and the time has come to emerge from the nest. During that waiting period, the hatchlings behind in development, not the embryos, “catch up”! In the pig nose turtles (Figure 2), found in tropical Australia, the eggs complete development during the dry season but as long as the eggs stay dry the embryos, even when fully developed, remain quiescent inside their eggs. When the wet season and its rains come, the nest is flooded and the eggs no longer can “breathe”. Within minutes, that lack of oxygen stimulates all the embryos to hatch.</Typography></div>
          <div><Typography paragraph>Do hatching marine turtles communicate?</Typography></div>
          <div><Typography paragraph>Strangely enough, until recently no experiments had been done to determine if hatching synchrony occurs in marine turtles. Focus, instead, was on synchronous emergence from the nest which is well documented in marine turtles (Figure 4). But hatchling emergence occurs days after hatching because newly hatched turtles must dig their way out of the much deeper nests, then wait until surface sands cool (usually at night) before they risk a scamper to the sea. Synchronous emergence is therefore not closely paired in time with hatching so the two processes might well occur on different temporal schedules.</Typography></div>
          <div><Typography paragraph>Masters student Angela Field did an experiment to find out whether loggerhead embryos “paid attention” to the stage of development of their neighbors and, if so, whether that resulted in a faster rate of development and a shorter incubation time. To find out, she once again established artificial nests that contained eggs at different stages of development. To begin, she took 12 eggs from a nest deposited the night before, and divided them equally into two nest boxes (the A1 and A2 eggs; Figure 4). Each box was placed in an incubator at a steady 31▫ C for 2 weeks. She than added 6 eggs from another nest, deposited two weeks earlier (the B eggs) to the A1 nest box, and 6 eggs from a third nest, deposited the night before (the C eggs), to the other (A2) nest box. Both nest boxes were then returned to the incubator until all of the eggs hatched. Thus, one nest box (the experimental; Figure 4) contained eggs from two nests that were at the same stage of development; those embryos would hatch at about the same time and so could potentially stimulate one another to develop faster and hatch earlier.</Typography></div>
        </div>
      </div>


      <div className="lg:flex gap-4 pt-5">

        <div className="lg:w-1/2">
          <div><img className="rounded-2xl mx-auto" src={experimenttodeterminesynchronous} alt="" title="" /></div>
          <div className="pt-2 pb-5"><em>Figure 3. (left) An experiment to determine how synchronous hatching is achieved in the Murray River turtle. A dozen eggs from one nest are divided into two groups of 6 eggs each, then incubated in containers at two different temperatures (25▫ or 30▫ C) for one week. The warmer eggs develop faster, setting up differences between the eggs both in development and in when hatching should occur. After one week, the eggs are combined; then, all are incubated at either 25▫ or 30▫ C until they hatch. If at 30▫ C the initially warmer eggs delay and synchronize hatching with the cooler eggs, the results support the “wait” hypothesis. Alternatively, if at 25▫ C the initially cooler eggs hatch sooner than expected and in synchrony with the initially warmer eggs, the results support the “catch-up” hypothesis - which is what happened (Modified from [1])</em></div>
          <div><Typography paragraph>The other (control) nest box contained eggs from two nests that differed by 2 weeks in development. In that nest, the A2 embryos would hatch first without stimulation but could in that process stimulate the C embryos. The outcome was as predicted: all three of the eggs stimulated by embryos from other nests (the A1, B, and C eggs) hatched almost a full day before the A2 eggs, that were deprived of stimulation. Communication must have occurred!</Typography></div>
          <div><Typography paragraph>Conclusions</Typography></div>
          <div><Typography paragraph>Until experiments like these were carried out, biologists were unaware that late-stage embryos were capable of monitoring the development of their siblings or coordinating their behavior through communication channels that are yet to be fully explored. We now know that these kinds of interactions occur not only among turtle embryos, but also in other species - frogs and fishes, for example - and that they have evolved through natural selection because they convey survival advantages. In some examples, late stage embryos, especially when the nest is threatened by predators or parasites, hatch early to escape those threats. In other cases, they delay hatching when conditions deteriorate until environmental conditions improve. In retrospect, it seems perfectly plausible that especially when nests are abandoned, these kinds of adaptations would soon become almost ubiquitous.</Typography></div>
          <div><Typography paragraph>References</Typography></div>
          <div><Typography variant="caption">(1) Spencer, R.-J., Thompson, M.B. & Banks, P.B. 2001. Hatch or wait? A dilemma in reptilian incubation. Oikos 93:401–406.</Typography></div>
          <div><Typography variant="caption">(2) Field, A., McGlashan, J. K. & Salmon, M. 2021. Evidence for synchronous hatching in marine turtle (Caretta caretta) embryos and its influence on the timing of nest emergence. Chelonian Conservation and Biology, Vol. 20:(2) doi:10.2744/CCB-1489.1</Typography></div>
          <div><Typography variant="caption">(3) Warkentin, K.M. 2011. Environmentally cued hatching across taxa: embryos respond to risk and opportunity. Integrative and Comparative Biology 51:14–25.
            Doody, J.S. 2011. Environmentally cued hatching in reptiles. Integrative and Comparative Biology 49:49-61.</Typography></div>
          <div><Typography variant="caption">(Thanks to Jeanette Wyneken for edits that improved manuscript organization and clarity.)</Typography></div>
        </div>
        <div className="lg:w-1/2">
          <div className='mt-5'><img className="rounded-2xl mx-auto" src={loggerheadhatchlingsemerging} alt="" title="" /></div>
          <div className="pt-2"><em>Figure 4. Above, loggerhead hatchlings emerging from the nest (J. Wyneken photo). Below, an experiment to determine if loggerhead embryos communicate with their siblings and in the process, stimulate one another to complete development faster. Twelve eggs from one nest are divided into two groups (A1, A2), placed in separate nest boxes and incubated at a constant temperature (31▫ C) for 2 weeks. The A1 nest is then paired either with eggs from another nest deposited two weeks previously (the B eggs) while the other (A2) nest is paired with eggs from a nest deposited the previous evening (the C eggs). Incubation continues until all the eggs hatch. In this experiment, the A1, B and C eggs are stimulated by the hatching activity of the additional nest embryos. As a result, they incubate for a shorter time than the A2 eggs, not stimulated by the C eggs that are less developed. The A2 eggs take significantly longer to develop (2).</em></div>
          <div className="pt-5"><Typography >For those interested in learning more, the two references in (3) summarize the most important studies.</Typography></div>
        </div>


      </div>

    </div>
  );
}
