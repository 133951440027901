import React from "react";
import { Typography } from "@mui/material";
import VideoPlayer from "./Component/Video";
import "./App.css";
import Since from "./Component/Since";
import { Helmet } from "react-helmet";
import { Link, Button } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#932B16", // custom secondary color
    },
  },
});


export default function Home() {
  return (
    <div className="text-[#ffffff] bg-[#032546]/90 dark:bg-[#fff]/70 dark:text-[#032546]">

      <Helmet>
        <title data-react-helmet="true"></title>
        <meta data-react-helmet="true" name="description" content="" />
        <meta data-react-helmet="true" property="og:title" content="" />
        <meta data-react-helmet="true" property="og:type" content="" />
        <meta data-react-helmet="true" property="og:url" content="" />
        <meta data-react-helmet="true" property="og:image" content="" />
        <meta data-react-helmet="true" property="og:description" content="" />
      </Helmet>

      <div className="lg:flex">
        <div className="lg:w-1/2 print:hidden">
          <div className="lg:float-left lg:mr-5 lg:w-full"> {/* Adjusted width */}
            <div className="relative" >
              <div className="absolute inset-0">
                <VideoPlayer className="w-full h-full" />
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2">
          <div className="pl-6 pt-6 pr-6">
            <Typography paragraph>
              <h1 className="text-2xl font-bold">Welcome</h1>
            </Typography>
            <Typography>
              Since 1987, the National Save the Sea Turtle Foundation has worked tirelessly to provide educational programming, promote public awareness of Florida's marine ecosystems, and support sea turtle research both in the United States and abroad. The Foundation is dedicated to assisting those who devote endless hours in the battle to understand and preserve the marine environment and invites you to learn more about this important work.
            </Typography>
            <div className="pt-4 pb-3">
              <div className="lg:flex gap-5">
                <div className="lg:w-1/2 text-center">
                  <div className="mx-auto text-center"><Since /></div>
                </div>
                <div className="lg:w-1/2 text-center">
                  <div className="mx-auto text-center">
                    <div className="mx-auto text-center pt-4 pb-3">
                      <ThemeProvider theme={theme}>
                        <Link href="https://savetheseaturtle.org/forms/cashdonation.html" target="_blank" rel="noopener noreferrer" style={{ color: "#ffffff" }}>
                          <Button color="secondary" variant="contained" fullWidth>
                            <div>
                              <div className="text-center"><VolunteerActivismIcon fontSize="large" /></div>
                              <div className="text-center">Donate</div>
                            </div>
                          </Button>
                        </Link>
                      </ThemeProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}
