import React from "react";
import { Typography, Divider } from "@mui/material";
import femaleOliveRidleyreturnstotheocea from '../Images/female-Olive-Ridley-returns-to-the-ocean.jpg'
import logoFinal from '../Images/National-Save-The-Sea-Turtle-Foundation-logo-Final.png'
import FloridaGulfCoastUniversity from '../Images/Florida-Gulf-Coast-University.jpg'

export default function WarmingOliveRidley() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className='pb-5'><h1 className='text-2xl font-bold'>	The Effects of Warming Temperatures on Olive Ridley Sea Turtle Hatchlings</h1></div></div>
      <div><Typography paragraph>Conner Sharrow & Alannah Lofquist, NSTSTF Scholarship Recipients</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className='lg:float-right lg:ml-5 mt-5'><img className="rounded-2xl mx-auto" src={FloridaGulfCoastUniversity} alt="" title="" /></div>
      <div className="pt-5"><Typography paragraph>Our planet is growing warmer each year, with more animals starting to experience some strange side effects such as polar bears moving inland or the delayed southward migration of our beloved North American monarch butterfly. But there may be an even stranger effect felt on sea turtles across the globe. Studies are beginning to find that some sea turtle nesting sites are producing almost all female young!</Typography></div>
      <div><Typography paragraph>Unlike many organisms, sea turtles do not have sex-specific chromosomes that determine if the individual will become a male or female. Instead, it is almost all about the temperature the eggs incubate in. Warmer temperatures will produce more female offspring, while cooler temperatures will produce more males. Time spent above or below the species pivotal temperature (30.5°C in olive ridley sea turtles) is crucial in determining the offspring’s sex. A sea turtle hatchling is going to have a greater likelihood of becoming a female if the nest experiences temperatures above this pivotal temperature during the middle third of their development. More males are likely to be produced below that temperature. </Typography></div>
      <div><Typography paragraph>Understanding the full influence of temperature on whether an offspring will be male, or female, is often complicated due to the natural fluctuations of sand temperature, as well as the cooling effect of rainwater during storms. Furthermore, sea turtle embryos will begin to produce metabolic heat toward the end of development, and this collectively increases the temperature inside the nest. </Typography></div>
      <div><Typography paragraph>Sand temperatures on some sea turtle nesting beaches are now reaching temperatures that approach, or exceed, the upper lethal temperature that developing embryos can tolerate (35°C in olive ridleys). Eggs incubating near this temperature has a higher likelihood of developmental problems and even mortality. So it is no surprise that many sea turtle biologists are concerned that climate change may lead to beach temperatures increasing 2 or 3 degrees by the end of this century. Even in places where temperatures do not reach the upper limit, increasing temperatures may result the production of more females. Scientists are not sure what the population impacts will be of a highly skewed sex ratio, but it may reduce reproductive success and individual fitness of the females.</Typography></div>
      <div className='lg:float-left lg:mr-5 mb-5'><img className="mx-auto" src={logoFinal} alt="national save the sea turtle foundation logo" title="National Save The Sea Turtle Foundation" /></div>
      <div><Typography paragraph>Researchers have documented warming temperatures on many sea turtle nesting beaches across the globe. However, very little is known about the beach temperatures in West Africa where its shoreline supports nesting populations of five sea turtle species (olive ridley, leatherback, green, loggerhead, hawksbill). To help fill this gap of knowledge, we explored the temperatures of olive ridley nests in Ghana, West Africa. To measure nest temperatures, temperature data loggers were placed into the nest chamber after the mother laid 50 eggs. The mother would then finish the nest as the second temperature logger was buried at the same depth, 2 meters east/west of the nest location. This ensured the data logger in the sand was the same distance from the high-tide line as the one in the nest. This allowed us to measure the temperature of the nest core and compare it to just the sand (control). We could then document the beginning of metabolic heat production in the embryos at the point when the nest temperature began to exceed that of the sand temperature. We recorded temperatures from seven nests throughout the olive ridley nesting season in Ghana between September 2020 to January 2021.</Typography></div>
      <div>
        <div className='lg:float-left lg:mr-5'><img className="rounded-2xl" src={femaleOliveRidleyreturnstotheocea} alt="" title="A female Olive Ridley returns to the ocean after nesting. Photo: Rod Mast; S.W.O.T." /></div>
        <div className="pt-2"><em>A female Olive Ridley returns to the ocean after nesting. Photo: Rod Mast; S.W.O.T.</em></div>
      </div>
      <div><Typography paragraph>As expected, the nest temperatures consistently exceeded the pivotal temperature of olive ridleys, indicating these nests were likely producing more females than males. Most of the nesting activity takes place during Ghana’s dry season, so we expected these high temperatures since Ghana is a tropical nation positioned near the equator</Typography></div>
      <div><Typography paragraph>Our study suggests there is a strong female-biased sex ratio for olive ridley nests in Ghana, which is fundamentally important for providing a historical reference in anticipation of temperature increases due to climate change. As more beach temperatures are recorded, it will be important to carefully document changes in nesting patterns that may suggest behavioral responses to increasing temperatures. For example, more individuals may begin nesting in the wet season when the rain keeps the sand temperatures cooler. We also need to explore nest success to document any loss of hatchling production due to increasing sand temperatures.</Typography></div>
      <div><Typography>This scholarship allowed us to participate in vital sea turtle research that will inform conservation efforts for sea turtles nesting throughout the tropics. We very much appreciate the support from the National Save The Sea Turtle Foundation because we would not have been able to do this work without them.</Typography></div>
    </div>
  );
}
