import React from "react";
import { Typography, Divider } from "@mui/material";
import DrJeanetteW from "../Images/Dr-Jeanette-Wyneken.jpg";
import TRTs from "../Images/TRTs.jpg";

export default function TemperatureInterview() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <div>
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              Temperature-Dependent Sex Determination Unraveled: An Interview with Dr. Alex Lolavar
            </h1>
          </div>
        </div>
        <div>
          <Typography paragraph>Larry Wood, Ph.D.</Typography>
        </div>
        <div className="">
          <Divider className='dark:bg-[#032546] bg-white/70 clear-both' />
        </div>
      </div>
      <div className="pt-5">
        <Typography paragraph>
          Dr. Lolavar is a recent graduate of Florida Atlantic University’s Integrative Biology Ph.D.
          program. Her research on sex determination in sea turtles has been critical to future
          conservation measures given the expected effects of climate change. She is now the Research
          Coordinator and an Affiliate Instructor in the Department of Educational Leadership and
          Research Methodology at FAU Hight School, located in Boca Raton, Florida. Her research was
          partially funded through a gift to FAU’s Graduate Program from the National Save The Sea
          Turtle Foundation.
        </Typography>
      </div>
      <div className="py-5">
        <em>
          Hi Alex! Congratulations on your Ph.D.! How did you originally develop an interest in
          science?
        </em>
      </div>
      <Typography paragraph>
        Thank you, great to be here! Since I was very young, I have always been interested in
        learning new things about the world we live in. I have many memories of my father and I
        having long, in-depth discussions on different scientific topics, ranging from astronomy to
        animal behavior. Growing up, I was surrounded by different animals including cats, dogs,
        turtles, horses, hermit crabs, and many others. My childhood love of animals evolved into a
        desire to learn more and more about them and I quickly learned that science or research was
        how I could answer all these questions.
      </Typography>
      <Typography paragraph>
        <em>
          Were sea turtles always a passion, or just a good model organism for your research
          interests?
        </em>
      </Typography>
      <Typography paragraph>
        To be honest, I didn’t realize how amazing sea turtles are when I first began working with
        them. When I started working in Dr. Jeanette Wyneken’s lab as an undergraduate student, I
        really didn’t know much about them at all. However, I quickly realized what amazingly cool
        animals they are. Now, they are not just my study organism, but also a passion of mine for
        the rest of my life.
      </Typography>
      <Typography paragraph>
        <em>
          Temperature Dependent Sex Determination (TSD) may be something new to some of our readers,
          how does it work compared to genetic sex determination?
        </em>
      </Typography>
      <div>
        <div className="lg:float-left lg:mr-5 mb-5">
          <img className="rounded-2xl mx-auto" src={DrJeanetteW} alt="" title="" />
        </div>
        <Typography paragraph>
          TSD is a fascinating mechanism used by some invertebrate and vertebrate species to
          determine sex. In organisms with Genetic Sex Determination (GSD), like humans, the sex of
          the developing embryo is determined by the sex chromosomes inherited by the parents. Many
          of us are familiar with the terms “XX” and “XY”, which signify the chromosomes that
          females and males have, respectively. The presence of these chromosomes triggers the
          complicated pathways and mechanisms that result in the embryo developing as a female or
          male. The overall process in TSD is similar to GSD with one major difference. In TSD, that
          initial trigger is not caused by sex chromosomes (because many of these organisms do not
          have them), but rather temperature. In organisms with TSD, sex is determined by the
          temperatures the embryo experiences during development.
        </Typography>
        <Typography paragraph>
          <em>
            Evolutionarily speaking, how long has TSD been around? What types of organisms
            demonstrate TSD?
          </em>
        </Typography>

        <Typography paragraph>
          Temperature dependent sex determination (TSD) has been around for ~300 million years and
          in turtles, co-evolved with genetic sex determination ~200 million years ago. TSD can be
          found in many reptiles, including some species of lizards and snakes, many turtle species,
          and all crocodilians. Many species of fish also demonstrate TSD. Interestingly, all of
          these different organisms have evolved TSD multiple independent times, meaning there does
          not seem to be direct evolutionary lineage that results in an organism with TSD. In the
          case of other vertebrate species, TSD has not been observed in birds nor mammals, but some
          amphibians possess both genetic sex determination and TSD. Several invertebrate species
          also demonstrate TSD, as well other forms of environmental sex determination including sex
          determination by photoperiod, pH, and nutrition.
        </Typography>
      </div>
      <div>
        <Typography paragraph>
          <em>
            In turtles, what adaptive advantages does TSD provide the species who demonstrate it,
            and why might sea turtles be among those?
          </em>
        </Typography>
        <Typography paragraph>
          The adaptive advantage of TSD still remains in question and there are several hypotheses
          for why a species would evolve this sex determining mechanism. The most prominent and
          well-studied idea is that TSD promotes differential fitness, which basically suggests that
          there are sex-specific fitness benefits and some developmental temperatures are better for
          one sex. However, even this well-studied hypothesis has its short-comings as slight
          impacts on fitness are not easy to detect in long-lived animals with TSD. Another reason
          for the presence of TSD could be the quasi-neutral hypothesis which suggests that TSD
          actually provides a disadvantage but one that is so slight that it persists in these
          organisms.
        </Typography>
        <Typography paragraph>
          There are several potential reasons why sea turtles are among those species with TSD. TSD
          may be a more advantageous sex-determining strategy for organisms like sea turtles in
          which survival of early life stages is heavily affected by temperatures. Specifically,
          high temperatures can increase mortality of early stages like the embryonic stages. By
          increasing production of females at these temperatures, sea turtles may be buffering the
          negative effects of high temperatures on the population in the future. Other studies
          suggest that TSD is a better strategy when offspring inhabit “patchy” or spatially
          differing environments, such as beaches. Sea turtle nesting beaches can vary greatly in
          many different factors including weather and beach characteristics and developing sea
          turtle embryos incubate in fluctuating and variable conditions. For these reasons, TSD is
          likely a better approach for sex determination in sea turtles.
        </Typography>
        <Typography paragraph>
          <em>
            I’ve heard that alligator nests, for example, produce more males at higher temperatures
            while sea turtle nests produce more females. Are all turtles that demonstrate TSD like
            the sea turtles, or do some follow the alligator model? Is it known why these
            differences exist?
          </em>
        </Typography>
        <Typography paragraph>
          There are three different patterns of TSD. Sea turtles demonstrate TSD I-a which is
          defined by cooler temperatures producing more males and warmer temperatures producing more
          females, which is demonstrated by many turtle species. In organisms with TSD I-b, we see
          the opposite with cooler temperatures producing more females and warmer temperatures
          producing more males, which can be found in some turtle species as well as tuataras.
          Lastly, TSD II is characterized by females being produced at both low and high incubation
          temperatures, but predominantly males are produced at intermediate incubation
          temperatures. We observe TSD II in some turtle, lizard, and crocodilian species. It’s
          interesting that you mention alligators. Though some studies report them as having a
          female-male pattern (TSD I-b), other studies suggest that they in fact have a
          female-male-female pattern (TSD II) but experience an increase in mortality at higher
          temperatures.
        </Typography>
        <Typography paragraph>
          Similar to other aspects of TSD, the reason for these different TSD patterns is still
          debatable. Different TSD patterns may exist because the fitness of one sex may be higher
          at certain temperatures and the fitness of the other sex may be lower or unaffected by
          temperature. Defining which pattern a species demonstrates can be tricky because of the
          effect of temperature on both mortality and sex ratios, such as the case of alligators.
          There is a theory that suggests that organisms may not differ in which TSD patterns they
          demonstrate, but instead their survival at different temperatures directs which TSD
          pattern we observe.
        </Typography>
        <Typography paragraph>
          Based on this pattern of TSD, male ﬁtness might be expected to be greater than female
          ﬁtness under conditions that produce more male offspring; the converse might be true for
          female ﬁtness or female ﬁtness might be independent of temperature according to the
          Charnov–Bull model.
        </Typography>
        <Typography paragraph>
          <em>
            Could you explain the difference between the ‘pivotal temperature’ and the ‘transitional
            range of temperatures’ (TRT) in a turtle nest?
          </em>
        </Typography>
        <Typography paragraph>
          Both of these values help us describe the relationship between nest temperatures and sex
          ratios but they tell us different things. We can illustrate this relationship as a sigmoid
          or S-shaped curve. The transitional range of temperatures (TRT) is the range of
          temperatures that results in a mix of both sexes and where the sex ratio goes from all
          male to all female. The approximate midpoint of this range and the temperature that
          produces a 1:1 sex ratio is called the pivotal temperature. Both the pivotal temperature
          and TRT vary among species, populations, and even individuals. It is important to remember
          that both of these values are derived by incubating eggs at constant temperatures in the
          lab so they are not extremely accurate when applied to nests incubating naturally in the
          field.
        </Typography>
        <Typography paragraph>
          <em>
            What basic question did you seek to answer with your research, and how did you formulate
            your experimental design to answer it?
          </em>
        </Typography>
        <Typography paragraph>
          The basic question for my research is how does moisture influence sex determination in sea
          turtles? From past literature, we know that changes in nest moisture, usually from either
          periods of drought or rainfall, affect nest temperatures which then influence sex
          determination. I wanted to better understand how nest moisture affects sex determination
          beyond this effect on temperature. In order to do this, I used an experimental approach
          where I could control for different variables in the lab. Groups of eggs were incubated at
          different temperatures and one of three sand moistures that represented moisture
          conditions during years with drought, average conditions, or excessive rainfall.
        </Typography>
        <Typography paragraph>
          <em>
            Sea turtles are Federally protected species, how did you obtain authorization to conduct
            your research?
          </em>
        </Typography>
        <Typography paragraph>
          Most of my past research has involved relocating eggs and incubating them in the lab or
          another site on the nesting beach. Jeanette and I coordinate closely with FWC to obtain
          authorization to use these necessary methods in my research. There are certain guidelines
          and protocols that I always follow to ensure the safety of the eggs and turtles involved
          in my projects. Over the past several years, I have been able to demonstrate the success
          of these projects and my ability to relocate and incubate eggs safely.
        </Typography>
        <Typography paragraph>
          <em> Were you able to answer your question? What were your primary results?</em>
        </Typography>
        <Typography paragraph>
          The results of my study are very exciting and begin to shed light on this interesting
          question that I’ve been working on for several years now. The overall purpose of my
          research and this study was to determine if moisture conditions during incubation
          influence sex ratios beyond a temperature effect. This study identifies that loggerhead
          embryos respond to temperatures differently based on sand moisture while they incubate.
          Basically, eggs that incubate in moisture extremes, very dry or very wet, have a more
          rapid transition from one sex to another. Another way to describe these results is that
          the TRT (range of temperatures that produce all male to all female) is narrower for these
          embryos.
        </Typography>
      </div>
      <div>
        <div className="lg:float-right lg:ml-5 mb-5">
          <img className="rounded-2xl w-96 " src={TRTs} alt="" title="" />
        </div>
        <Typography paragraph>
          <em>What new research questions may have arisen from your conclusions?</em>
        </Typography>
        <Typography paragraph>
          We still have a lot more to learn about how moisture conditions during incubation affect
          sex determination. My research has centered around the relationships in loggerhead sea
          turtles but I would like to branch out to other species that nest in Florida, such as
          leatherback and green sea turtles. Previous literature has shown us that these sea turtle
          species exhibit different temperature-sex ratio responses, primarily by demonstrating
          narrower TRTs compared to the loggerhead. The other big question on my mind is how
          moisture may affect development and sex determination on a molecular level. Identifying
          moisture’s effects on the mechanism of sex determination would help us better understand
          its role outside of any temperature effects.
        </Typography>
        <Typography paragraph>
          <em>How might your results be applied to ongoing sea turtle conservation concerns?</em>
        </Typography>

        <Typography paragraph>
          As sea turtle biologists and conservationists, one of the biggest concerns we have on our
          minds is the ongoing threat of climate change. A better understanding of moisture’s
          overall effects on sea turtle development and biology will provide us with more accurate
          predictions of current and future sex ratios. Besides increasing temperatures, another
          effect of climate change is shifts in our precipitation patterns. In Florida, we can
          expect decreases in annual precipitation and increases in both periods of drought and
          storm severity. This means that Florida nests may be incubating in either very dry or very
          wet conditions, which can affect how they respond to temperatures and, ultimately,
          hatchling sex ratios.
        </Typography>
        <Typography paragraph>
          <em>
            Publishing your work in a peer-reviewed journal is a big accomplishment! What advice
            would you give to a young person thinking about pursuing a degree and/or career in
            biological research?
          </em>
        </Typography>
        <Typography paragraph>
          The best advice I can give is what I’ve learned from sea turtles themselves: Be resilient!
          There can be plenty of trying or frustrating moments while pursuing a degree and/or career
          in biological research but it is important to push through these times.
        </Typography>
        <Typography>
          Another piece of advice I would give is to embrace different opportunities and never
          hesitate to expand your network. Meeting new people and learning about their unique
          scientific backgrounds is how you learn new techniques or approaches which will ultimately
          make you a better researcher. Lastly, and likely most important, never forget why you
          entered this field in the first place. If you’re contemplating pursuing a career in
          biological research or you’ve already begun, it means you have an innate curiosity and
          passion for your field. Reminding yourself of why you chose this path makes the difficult
          times more bearable and the good times even better.
        </Typography>
      </div>
    </div>
  );
}
