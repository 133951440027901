import React from "react";
import { Typography, Divider } from "@mui/material";
import welloiledconservationmachine from '../Images/well-oiled-conservation-machine.jpg'
import NovaSoutheasternUniversity from '../Images/Nova-Southeastern-University.jpg'
import NSUsBrowardCountyConservation from '../Images/NSUs-Broward-County-Sea-Turtle-Conservation-Program.jpg'
import CurtisSlagle from '../Images/Curtis-Slagle.jpg'
import AbbyNease from '../Images/Abby-Nease.jpg'

export default function BrowardCountryNesting() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className='pb-5'><h1 className='text-2xl font-bold'>Broward County Reports Strong Nesting Season
      </h1></div></div>
      <div><Typography paragraph>Dr. Derek Burkholder, Nova Southeastern University</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="mt-5">
        <div className='lg:float-right lg:ml-5 mb-5'><img className="rounded-2xl mx-auto" src={NovaSoutheasternUniversity} alt="" title="" /></div>
        <div><Typography paragraph>Summertime in South Florida means several things: sun, sand, and sea turtles. Each year, tens of thousands of sea turtle nests are laid on Florida beaches. In Broward County, NSU’s Broward County Sea Turtle Conservation Program (BCSTCP) is ready to do our part to mark, monitor, and protect these nests throughout their incubation periods. Patrolling 24 miles of Broward County, Florida beaches every morning from March 1st through October 31st, the BCSTCP identifies upwards of 3,000 or more nests each season. In fact, for three out of the past five years, Broward County has set new personal nesting records! Many of these years saw records for each of the three species that nest here. In 2016, we had an all-time high of 3,400 loggerhead nests. In 2017, we set the record of 665 green turtle nests, which was smashed just two short years later with 787 green nests in ’19! Also in 2019, our small local contingency of leatherbacks set their own record, laying 43 nests locally. Each of these seasons, we climbed to new heights in total nest counts, with over 3,500 nests in the 2016, ’17, and ’19 seasons, and an all-time record of 3,647 nests in 2019! This season was not to be forgotten, though. The leatherbacks of Broward County nearly doubled their previous record, laying 86 nests on our beaches in 2021. Overall, with the addition of 2,548 loggerhead and 463 green turtle nests, we had a total of 3,099 nests this year.</Typography></div>
        <div className='lg:float-left lg:mr-5 mb-5'><img className="rounded-2xl mx-auto" src={NSUsBrowardCountyConservation} alt="" title="" /></div>
        <div><Typography paragraph>The 2021 sea turtle nesting season also saw the return of outreach for the BCSTCP. Scientifically informed public outreach and education that instructs and promotes environmentally positive behavioral changes is a core mission for the BCSTCP. While we had to take an extended hiatus from in-person conservational outreach for over a year to ensure the safety of our staff and the public, due to the Covid-19 pandemic, we were finally able to return to spreading the good word of sea turtle conservation in mid-July. Since then, we’ve participated in 63 educational/outreach events, connecting with over 5,100 individuals.</Typography></div>
        <div><Typography paragraph>This season also marked the end of a dynasty, as Curtis Slagle, our dearly beloved permit holder and program manager of 8 years moved on to pursue other opportunities. Under Curtis’ guidance, the program grew in many ways, changing us from a band of sea turtle marauders into a well-oiled conservation machine, carrying us forward all the while with humor and humble leadership. His goofy presence on the sands of Broward County will be sorely missed! Fortunately, he set the stage wonderfully for a new grand leader to take the lead. In 2021, Abby Nease earned the coveted role as permit holder and program</Typography></div>
        <div className="my-5">
          <div><img className="rounded-2xl mx-auto" src={welloiledconservationmachine} alt="" title="" /></div>
        </div>
      </div>



      <div className="mt-5">
        <div className='lg:float-left lg:mr-5 mb-5'><img className="rounded-2xl mx-auto" src={CurtisSlagle} alt="" title="" /></div>
        <div><Typography paragraph>manager for the BCSTCP. Part of the program since 2014, Abby has held nearly every position we have available, including acting as data manager for two years. She continued the legacy of greatness and ingenuity we’ve come to expect from program managers, guiding the program through the ever-changing challenges of operating during a pandemic with grace and assurance. We are truly lucky to have found another great manager and look forward to seeing the program continue to succeed with her at the helm!</Typography></div>
        <div className='lg:float-right lg:ml-5 mb-5'><img className="rounded-2xl mx-auto" src={AbbyNease} alt="" title="" /></div>
        <div><Typography paragraph>This season, the BCSTCP was pleased to continue to collaborate with our local sea turtle partner organizations who work tirelessly throughout hatchling season, making a tremendous impact towards sea turtle conservation. Sea turtle hatchlings typically emerge from their nests at night and use light as one of their cues to navigate towards the ocean. In areas where unshielded and short wavelength artificial lighting spills onto the beach, hatchlings may crawl away from the ocean and towards streets, pools, and storm drains. Though local lighting ordinances have led to improvements, Broward County continues to experience relatively high levels of hatchling disorientation due to coastal light pollution, as compared to naturally dark beaches. Sea Turtle Oversight Protection (STOP), South Florida Audubon Society (SFAS), and Sea Turtle Awareness Rescue and Stranding (STARS) are all volunteer based programs that redirect thousands of hatchlings annually that become disoriented due to artificial lighting. Their work is imperative for the overall success of nests deposited in Broward County and we consider ourselves fortunate to have them as allies in pursuit of sea turtle conservation!</Typography></div>
        <div><Typography>Proceeds from The National Save The Sea Turtle Foundation’s Adopt-A-Nest Program directly support the Broward County Sea Turtle Conservation Program. Adopt a nest today! <a href="https://www.savetheseaturtle.org" target={"_blank"} rel="noreferrer noopener">www.savetheseaturtle.org</a></Typography></div>
      </div>
    </div>
  );
}
