import {Typography} from "@mui/material";
import React from "react";
import LeatherbackImg from "../Images/Leatherback-B.jpg";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import {createTheme, ThemeProvider} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#932B16", // custom secondary color
    },
  },
});

export default function Leatherback() {
  return (
    <div className="dark:text-[#032546] dark:bg-white/70 p-5 w-full h-full bg-[#032546]/90 rounded-3xl text-[#ffffff]">
      <div>
        <Typography paragraph>
          <h2 className="text-2xl font-bold">Leatherback Sea Turtles</h2>
        </Typography>
      </div>

      <div className="lg:float-left lg:w-1/3 mb-5 lg:mr-5">
        <img className="rounded-3xl shadow-lg" src={LeatherbackImg} alt="" title="" />
      </div>
      <div>
        <Typography>
          The endangered leatherback turtle is the largest and most active of the sea turtles. They
          travel thousands of miles, dive thousands of feet deep, and venture into more colder water
          than any other kind of sea turtle. Up to eight feet in length, these huge turtles have a
          rubbery dark shell marked by seven narrow ridges that extend the length of the back.
          Remarkably, leatherbacks feed on jellyfish and soft-bodied animals that would appear to
          provide very little nutrition for such huge animals. Ingestion of plastic bags and egg
          collecting are reasons for mortality and population declines. About 100 to 200 leatherback
          nests are recorded in Florida each year.
        </Typography>
      </div>
      <div className="my-5 text-right">
        <ThemeProvider theme={theme}>
          <Link href="/SeaTurtles/Leatherback" title="Leatherback Sea Turtles">
            <Button color="secondary" variant="contained">
              More about Leatherback Sea Turtles
            </Button>
          </Link>
        </ThemeProvider>
      </div>
    </div>
  );
}
