import React from "react";
import { Typography, Divider } from "@mui/material";
import LoggerheadTurtlesUseArtificialReefs from '../Images/Loggerhead-Turtles-Use-Artificial-Reefs.png'
import LoggerheadTurtlesUseArtificialReefs2 from '../Images/Loggerhead-Turtles-Use-Artificial-Reefs.jpg'
import logoFinal from '../Images/National-Save-The-Sea-Turtle-Foundation-logo-Final.png'
import nonmatureloggerhead from '../Images/non-mature-loggerhead.jpg'
import EastCoastBiologistsImage from '../Images/East-Coast-Biologists.png'

export default function EastCoastBiologists() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div><div className='pb-5'><h1 className='text-2xl font-bold'>East Coast Biologists, Inc. Investigates How Young Loggerhead Turtles Use Artificial Reefs in Brevard County</h1></div></div>
      <div><Typography paragraph>Karen Holloway-Adkins, Ph.D., East Coast Biologists, Inc.</Typography></div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="lg:flex lg:gap-5 mt-5">
        <div className="lg:w-1/2">
          <div><Typography paragraph>East Coast Biologists, Inc. (ECB) is a not-for-profit organization founded in 2003, whose mission is to support scientific research and education for the benefit of preserving and maintaining natural resources for present and future generations.</Typography></div>
          <div >
            <div><img className="rounded-2xl mx-auto" src={LoggerheadTurtlesUseArtificialReefs2} alt="" title="" /></div>
            <div className="mt-5">
              <div className="lg:float-left lg:mr-5 mb-5"><img className="mx-auto" src={logoFinal} alt="national save the sea turtle foundation logo" title="National Save The Sea Turtle Foundation" /></div>
              <Typography paragraph>The greater part of our work is centered on marine research, specifically uncovering the movement, foraging behavior, and status of sea turtles. One of the greatest threats to the recovery of threatened and endangered sea turtle species is the loss of adequate habitat necessary for foraging, shelter, and refuge from predators. This is what pre-empted our study of sea turtles on nearshore reefs off the central east coast of Florida. During the first couple of decades, we primarily encountered juvenile green turtles in our main study area in Brevard County. We found that juvenile greens were using nearshore hardbottom reefs as developmental habitat (i.e., foraging on macroalgae and resting among narrow ledges in shallow water). We’ve captured &#62;200 juvenile green turtles, examining their size, feeding habits, and condition. We also have tracked 30 of these green turtles using acoustic telemetry. So far, our research has uncovered that green turtles in this coastal area primarily forage on red macroalgae, they are free of fibropapilloma tumors (caused by the herpes-type virus fibropapillomatosis),</Typography></div>
          </div>
        </div>
        <div className="lg:w-1/2">
          <div className="lg:float-right lg:ml-5 mb-5"><img className="bg-[#032546] p-3 rounded-2xl dark:bg-transparent mx-auto" src={EastCoastBiologistsImage} alt="" title="" /></div>
          <div><Typography paragraph>and they remain in the area for months at a time, and, in some cases, return or remain for more than a year along these shallow reefs. In 2019, some interesting changes to the marine turtle composition began to occur in the Brevard County coastal waters. Previously, only 1 out of 100 marine turtles observed along the nearshore waters was a loggerhead sea turtle. However, when mitigation (or artificial) reefs were built just offshore in 15’ (~5m) water depth, loggerhead observations increased. These artificial reefs were built in advance of Brevard’s Beach Restoration project to offset anticipated impacts to intertidal and shallow subtidal (0-3m water depth) hardbottom reef habitat used by juvenile green turtles (see photos on facing page). While some of the loggerhead turtles we observed were adults resting along the mitigation reefs in summer during the mating and nesting season, others were subadult loggerhead turtles. We decided to investigate how these subadult loggerheads use these new reef areas - if they are foraging and resting in these areas – and for how long?</Typography></div>
          <div className="mb-5"><img className="rounded-2xl" src={nonmatureloggerhead} alt="" title="" /></div>
          <div><Typography paragraph>Funding from National Save The Sea Turtle Foundation is supporting efforts by ECB to explore how these non-mature loggerhead turtles are using the artificial reefs. ECB researchers plan to collect skin and scute (thin layers of shell) tissue samples and place acoustic transmitters on several of these younger turtles.</Typography></div>
        </div>
      </div>




      <div><Typography paragraph>Analysis of the tissue samples will indicate what the turtles are foraging on and if the turtles are foraging locally. A regional array of acoustic receivers formed by the FACT (Florida Acoustic Coastal Telemetry) network will allow researchers to discover how long the turtles stay in the area and where they journey to after leaving Brevard County waters. It will be interesting to find out if the subadult loggerheads follow the same, or a different path, than adult turtles that we already know a lot more about from other researchers’ work. Information gained from ECB’s study will help state and federal agencies better understand how effective mitigation reef habitats are at providing necessary feeding and resting areas for marine turtles.</Typography></div>
      <div><Typography paragraph>*Marine turtle research activities are approved through appropriate permits from NOAA/National Marine Fisheries Service and Florida Fish and Wildlife Conservation Commission permitting agencies.</Typography></div>
      <div className="mt-5"><img className="rounded-2xl" src={LoggerheadTurtlesUseArtificialReefs} alt="" title="" /></div>
    </div>
  );
}
