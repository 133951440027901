import React from "react";
import { Typography, Divider } from "@mui/material";
import UCFGroup from "../Images/UCF-Marine-Turtle-Research-Group.jpg";
import DrSeney from "../Images/Dr-Seney.jpg";
import DrSeney2 from "../Images/Dr-Seney-2.jpg";

export default function DrErinSeney() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="mb-5">
        <div>
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              What’s in a Diet? An Interview with Dr. Erin Seney, Assistant Research Scientist,
              University of Central Florida
            </h1>
          </div>
        </div>
        <div>
          <Typography paragraph>Larry Wood, Ph.D.</Typography>
        </div>
        <div className="">
          <Divider className='dark:bg-[#032546] bg-white/70 clear-both' />
        </div>
      </div>
      <Typography paragraph>
        <em>
          Hello Erin! I’ve always been facinated by your work, how did you originally become
          interested in studying sea turtles?
        </em>
      </Typography>
      <Typography paragraph>
        As a kid, I loved visiting the turtles in a neighborhood pond, and I also liked the ocean.
        In high school, I was able to volunteer at a small aquarium and later had the opportunity to
        work with and study sea turtles in graduate school. I have been very fortunate to combine my
        interests in turtles and the ocean into a career working on sea turtles.
      </Typography>
      <Typography paragraph>
        <em>
          Your studies often explore what sea turtles eat. Though there is some overlap, it seems
          that most species have their own specialized diet. Can you help explain how these
          differences may have evolved?
        </em>
      </Typography>
      <Typography paragraph>
        Sea turtle diets are very diverse. There are species that largely consume plants (green
        turtles), sponges and corals (hawksbills), bottom-dwelling invertebrates (loggerheads and
        Kemp’s ridleys), and gelatinous animals like jellyfish (leatherbacks). These specializations
        likely evolved along with the species’ habitats and need to have a unique enough “niche” to
        survive alongside other organisms in those environments. The level of specialization among
        sea turtle species is reflected in their different head and beak shapes, as well as their
        digestive tract anatomy.
      </Typography>
      <Typography paragraph>
        <em>
          Given these semi-specialized diets, how impactful might different sea turtle species be on
          their respective environments?
        </em>
      </Typography>
      <Typography paragraph>
        Sea turtles can impact their environments in several ways through their feeding habitats.
        Consumption of a particular food item has impacts on the entire food chain, including not
        only the population of plant or animal that was eaten, but also other species that may
        compete for the same foods. Some animals may change their behavior to reduce the chance of
        being eaten or shift to other food items that are more readily available to them. Sea turtle
        foraging may also change the structure of the habitat, either by removing portions of
        stationary habitat features, such as seagrasses, sponges, and corals, or by digging into the
        seabed for food. Additionally, sea turtles circulate nutrients within the marine system by
        digesting food and excreting waste; this may be locally or over larger distances when
        individuals transition to new habitats or undertake seasonal migrations.
      </Typography>
      <Typography paragraph>
        <em>
          I see you’ve worked extensively with the Kemp’s Ridley sea turtle. Can you tell us a
          little about this species and why you decided to work with them?
        </em>
      </Typography>
      <div className="lg:float-left lg:mr-5 mb-5">
        <img className="rounded-2xl border-4 border-white" src={UCFGroup} alt="" title="" />
      </div>
      <Typography paragraph>
        I met my first Kemp’s ridleys while I was a master’s student in Virginia, where I studied
        their diets alongside that of the more-abundant loggerhead. There are important nearshore
        areas for Kemp’s ridley juveniles along the U.S. Atlantic coast, including the Chesapeake
        Bay, but Kemp’s ridleys are primarily found in the Gulf of Mexico, and I moved to Texas to
        study them for my Ph.D. The Kemp’s ridley is the smallest sea turtle species and has the
        smallest range of any species that occurs in the U.S. They reach a shell length of about two
        feet and weights up to about 100-120 pounds. Kemp’s ridleys are also one of the most
        conservation dependent species and were very close to extinction in the 1980s. Thanks to
        conservation and management efforts by Mexico and the U.S., the species has rebounded, but
        it remains endangered. I chose to study Kemp’s ridleys for my Ph.D. to learn more about
        their in-water biology and threats they experience, and I was able to satellite track
        nesting females from the upper Texas coast, as well as younger ridleys that were
        accidentally caught at local fishing piers. The Kemp’s ridley also has a personal appeal to
        me, in that they are small, tough, and feisty!
      </Typography>
      <Typography paragraph>
        <em>What techniques do you use to determine the diets of your research subjects?</em>
      </Typography>
      <div className="lg:float-right lg:ml-5">
        <div>
          <img
            className="rounded-2xl "
            src={DrSeney}
            alt=""
            title="Dr. Seney (center) and her most recent (June 2019) necropsy
session sampling crew (photo credit to Sarah Shaver,
UCF MTRG, selfie)"
          />
        </div>
        <div className="pt-2 pb-5">
          <em>
            Dr. Seney (center) and her most recent (June 2019)<br /> necropsy session sampling crew
            (photo credit to Sarah Shaver,<br /> UCF MTRG, selfie)
          </em>
        </div>
      </div>
      <Typography paragraph>
        The primary method I have used to study sea turtle diet is collecting and examining the gut
        contents of dead stranded sea turtles. While the death of any sea turtle is an unfortunate
        event, I have strived to help get the most information possible out these dead animals. In
        Virginia, my colleagues and I conducted necropsies (animal autopsies) of loggerheads and
        Kemp’s ridleys and collected all of the contents of the digestive tracts, which I later
        washed in a sieve and then identified visually. In Texas, live ridleys under observation
        after being accidentally hooked on fishing gear typically left me a sample in their tank;
        because ridleys eat mostly hard prey like crabs and snails, not everything they eat is
        digested and their poop is full of information about what they ate! More recently, I have
        been able to collaborate with the Florida Fish and Wildlife Conservation Commission and
        National Marine Fisheries Service to collect gut content samples from stranded loggerheads,
        Kemp’s ridleys, and green turtles from Florida and the northern Gulf of Mexico. For the
        loggerheads and ridleys, most prey identifications are done visually, and I have had a
        wonderful crew of past UCF Marine Turtle Research Group interns who have helped collect
        data. Green turtle samples, typically full of algae and/or seagrass, require a slightly
        different approach using microscopes to more closely examine the samples and cutting algae
        into sections to look at their cell structures to better identify species. I am still
        learning but have been fortunate to have the help of a colleague who is an expert on green
        turtle diet. I am also collaborating with a UCF colleague and one of her students to develop
        a molecular technique for characterizing green turtle diet, which will allow us to better
        identify food items using DNA sequencing.
      </Typography>
      <Typography paragraph>
        <em>
          How will the data you collect be used? Do you have hypothesis-driven research or are the
          data mostly descriptive?
        </em>
      </Typography>
      <div className="lg:float-right lg:ml-5">
        <div>
          <img
            className="rounded-2xl mx-auto"
            src={DrSeney2}
            alt=""
            title="Dr Seney releasing a loggerhead turtle. Photo credit goes to
Chris Long, UCF MTRG, and permits are NMFS
19508 and FWC MTP-231"
          />
        </div>
        <div className="pt-2 pb-5">
          <em>
            Dr Seney releasing a loggerhead turtle. Photo credit goes<br /> to Chris Long, UCF
            MTRG, and permits are NMFS 19508<br /> and FWC MTP-231.
          </em>
        </div>
      </div>
      <Typography paragraph>
        Characterization of sea turtles’ diet provides valuable information on their habitat,
        ecosystem role, and potential for interaction or competition with human activities. At the
        root of all of my diet research is the question “what do sea turtles eat?” but more
        specifically, I seek to better understand what different species eat in different locations,
        at different turtle sizes, and during different periods of time. This information fills gaps
        in our understanding of sea turtles and allows for better management of these protected
        species and the habitats they rely on. Diet research may also identify threats, such regions
        where sea turtles feed heavily on discarded fish from human fishing activities, which puts
        turtles at risk of entanglements, drowning, and boat strikes. With the larger sets of
        samples I have available for Florida and building on my existing knowledge from past
        studies, my students and I have started to ask more targeted research questions and make
        hypotheses about differences in a species’ diet in geographic regions or at different life
        stages. Recent student projects have examined the diets of turtles with different causes of
        death and the occurrence of fish in diets in the northern Gulf of Mexico. While we are
        interested in describing the diets of each species as a whole, it is really a moving target
        over space and time, so I hope to continue diet research over the long-term and continue to
        integrate other data, such as necropsy findings, with diet results.
      </Typography>
      <Typography paragraph>
        <em>Do you have other research interests you are also pursuing?</em>
      </Typography>
      <Typography paragraph>
        I am involved in several other research projects within the UCF Marine Turtle Research
        Group, including the lab’s long-term research and monitoring programs on central and
        southern Brevard County, Florida nesting beaches and in the central Indian River Lagoon. I
        am also involved in several collaborative projects related the movements, distribution, and
        ecology of smaller, oceanic-stage sea turtles in the Gulf of Mexico. Another newer project I
        am involved in, which is an offshoot of my diet research, is working with a UCF colleague to
        characterize the gut bacteria of sea turtles and examine connections to health and disease.
      </Typography>
      <Typography paragraph>
        <em>Do have any advice for young people considering a career in biology?</em>
      </Typography>
      <Typography paragraph>
        I would urge anyone considering a career in biology to explore things they are curious about
        by getting outside, visiting parks, aquariums, gardens, and museums (in person or
        virtually!), and taking advantage of public or online science events. It’s also important to
        ask questions and not be afraid to make mistakes – biology, like all sciences, is a learning
        process, driven by the urge to know more and address challenges. I would also urge them to
        broadly explore (and eventually study) a wide range of science and math-related fields. As a
        marine and conservation biologist studying sea turtles and working with diverse
        collaborators, I often need to think about oceanography, geology, physics, math, and
        statistics alongside ecology and biology.
      </Typography>
      <Typography>
        <em>
          Thanks Erin! Your work is really fascinating! Best of luck, and hope to see you at a
          conference soon!
        </em>
      </Typography>
    </div>
  );
}
