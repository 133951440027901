import React from "react";
import { Typography, Divider } from "@mui/material";
import InterSymposium from "../Images/International-Sea-Turtle-Symposium.jpg";
import InterSymposium2019 from "../Images/The-2019-sea-turtle-symposium.jpg";
import LibertyBoyd from "../Images/Ms-Liberty-Boyd.jpg";

export default function NavigatingtheFuture() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="">
        <div className="lg:float-right">
          <img className="rounded-2xl w-96 ml-5 mb-5" src={InterSymposium} alt="" title="" />
        </div>
        <div>
          <Typography className="pb-5">
            <h1 className="text-2xl font-bold">
              Navigating the Future: 39th Annual Sea Turtle Symposium Held in Charleston, South
              Carolina
            </h1>
          </Typography>
        </div>
        <div>
          <Typography paragraph>Larry Wood, Ph.D.</Typography>
        </div>
        <div className="">
          <Divider className='dark:bg-[#032546] bg-white/70 clear-both' />
        </div>
      </div>
      <div className="pt-5">
        <div className="lg:float-right ml-5">
          <img className="rounded-2xl mb-2" src={InterSymposium2019} alt="" title="" />
          <div>
            <em>
              The 2019 sea turtle symposium included 7 days of lectures and
              <br />
              poster presentations that cover topics from nesting biology to
              <br />
              fisheries management to publlic education. Representatives from
              <br />
              over 50 countries had the opportunity to present and discuss their
              <br />
              projects, results, and future plans with the international sea turtle
              <br />
              community. Next year, we’ll be visiting Cartagena, Colombia.
            </em>
          </div>
        </div>
        <div>
          <div>
            <Typography paragraph>
              The International Sea Turtle Symposium is an event that most of us in the sea turtle
              community look forward to every spring. It has provided the opportunity for us to
              travel the globe, visit new places and cultures, and create memories and friendships
              that will last a lifetime. The meeting typically alternates between domestic and
              international locations, and this year’s meeting was held in beautiful Charleston,
              South Carolina. The theme of the meeting was “Navigating the Future”; which reflects
              not only the amazing natural abilities of sea turtles, but also our ability to work
              together to ensure humans and sea turtles can share a bright tomorrow.
            </Typography>
          </div>
          <div className="lg:float-left">
            <img className="rounded-2xl mr-5 mb-2" src={LibertyBoyd} alt="" title="" />
            <em className=" float-left">
              Ms. Liberty Boyd presented the findings of her study
              <br />
              on the barnacles and corals that live on the shells
              <br />
              of hawksbill sea turtles. Her project was in
              <br />
              partnership with Dr. Larry Wood and The Florida
              <br />
              Hawksbill Project, sponsored by
              <br />
              the National Save The Sea Turtle Foundation.
            </em>
          </div>
          <div>
            <Typography paragraph>
              Over 800 participants from more than 50 countries attended lectures and viewed poster
              presentations on topics that included fisheries and threats, management and policy,
              anatomy and physiology, population biology, nesting biology, education, outreach, and
              advocacy. Throughout the week there were also special sessions and meetings, a video
              night, the ever-raucus Live Auction, and the parting Banquet where students and sea
              turtle ‘dignitaries’ are awarded for their work and accomplishments. We were proud to
              see Florida Atlantic University’s Boris Tezak receive the Archie Carr best oral
              presentation award in the biology section, and both Dr. Jeanette Wyneken and Dr. Mike
              Salmon receive recognition for their remarkable long-term contributions to sea turtle
              biology and conservation. Dr. Larry Wood presented a poster that discussed hawksbill
              sea turtle abundance in the Florida Keys, and co-presented a poster with a student
              from Florida international University about the various barnacles, corals, and other
              organisms that live on the shells of hawksbills. We were thrilled to be invited to
              barnacle-expert Dr. John Zardus’ lab at the Citadel to see his remarkable collection
              of specimens and discuss future collaborations
            </Typography>
            <Typography paragraph>
              <br />
              We were treated to extremely thought-provoking Keynote presentations from Dr. Elena
              Mustakova-Possardt, Dr. Colin Limpus, Dr. Blair Witherington, and Dr. Jay Nichols, and
              of course got to catch up with friends and colleagues from around the world. The
              National Save The Sea Turtle Foundation is a proud sponsor of the International Sea
              Turtle Society.
            </Typography>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}
