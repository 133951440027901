import React from "react";
import { Typography, Divider } from "@mui/material";
import InwaterR1 from "../Images/Inwater-Research-Group-logo-2.jpg";
import EduInnov from "../Images/Education-Through-Innovative-Programming.jpg";
import BigCheck1 from "../Images/big-check.jpg";

export default function InwaterResearch() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex">
        <div>
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              Inwater Research Group Leads in Environmental Education Through Innovative Programming
            </h1>
          </div>
          <Typography paragraph>
            Kara Muzia, Education Outreach Specialist,<br />
            Inwater research Group, Inc.
          </Typography>
        </div>
        <div className="p-2">
          <div className="lg:float-right">
            <img className="rounded-2xl mx-auto" src={InwaterR1} alt="" title="" />
          </div>
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="my-5">
        <Typography paragraph>
          As the end of this decade comes to a close, we take a look at all that has been
          accomplished here at Inwater Research Group (IRG). Ten years ago, our amazing research
          crew were out in full force, collecting data, providing valuable science, but there was
          something missing.
        </Typography>
        <Typography paragraph>
          What was missing at IRG was a component to make our knowledge more widely available and to
          bring the science that we do out into the public eye. Education was missing.
        </Typography>
        <Typography paragraph>
          This was remedied in 2014, when Rebecca Mott joined IRG as the Director of Education.
          Rebecca wanted a way to bring conservation to the classNameroom, to educate the next
          generation in a fun, interactive way. Partnering with the National Save the Sea Turtle
          Foundation, Rebecca created the “Traveling Trunks” program, a hands-on initiative to get
          sea turtles into the classNameroom and get students involved.
        </Typography>
      </div>
      <div>
        <div className="lg:float-left mb-5"><img className="rounded-2xl lg:mr-5" src={EduInnov} alt="" title="" /></div>
        <Typography paragraph>
          Rebecca has worked tirelessly with educators to create a fun, interactive program. The
          trunk offers four lessons, complete with plans for teachers that highlight the myriad of
          state standards that are targeted for each lesson. Some of the lessons mirror the research
          conducted at IRG, so students get excited and feel engaged, increasing retention and the
          likelihood that the conservation messages will stick with them for years to come. The
          programs cover topics such as nesting, beachfront lighting, population dynamics, data
          collection, and so much more.
        </Typography>
        <Typography paragraph>
          The result? While students turned marine biologists bustle about their classNamerooms to catch
          their classNamemates turned turtles, they’re learning. They’re learning common ailments that
          plague sea turtles such as entanglements and boat strikes. They’re learning how to create
          policies that may help protect turtles, while also keeping other stakeholders, such as
          fishermen and boaters in mind. They’re learning as they examine a model turtle they’ve
          “caught.” The turtle may have a hook in its mouth. It may have encrusting epibiota, such
          as barnacles and algae. It may look like a perfectly healthy turtle, but when it’s
          weighed, the scale tells a different story. These students record their observations on a
          datasheet that looks strikingly similar to those that actual turtle biologists use.
          Students learn without even realizing it. The lessons are games to them, and at the end of
          the program, students understand the different types of sea turtles, their diets, where
          they live, and some of the major threats they face.
        </Typography>
        <Typography paragraph>
          With testing at the forefront of most educator’s minds, the “Traveling Trunk” program
          strives to make the lessons not only student-friendly, but teacher-friendly as well. At
          the beginning of each lesson, the exact state standards that each lesson targets are
          listed. Teachers take comfort knowing they’re hitting their required standards while
          they’re teaching from the trunk.
        </Typography>

        <Typography paragraph>
          The trunks are set up so that they’re a “lesson plan in a box.” Teachers with no
          background in marine education are able to borrow these trunks and still provide a
          high-level education to their students. The trunk contains everything they need, from
          supplies and materials to lesson how-tos for teachers. The best part? Thanks to
          partnerships and generous support, these Traveling Trunks are offered for free. Teachers
          are able to borrow the trunks at no charge, effectively removing all financial barriers.
          This ensures that all students and schools, even the most underserved communities, have
          access to the same high-quality education programs.
        </Typography>
      </div>

      <div className="lg:flex lg:gap-5">
        <div className="lg:w-1/2">
          <Typography clas paragraph>
            This incredible program has not gone unnoticed. This year, our Director of Education,
            Rebecca Mott was nominated for the “Blue Friend of the Year” Award at the annual Go Blue
            Awards hosted by Loggerhead Marinelife Center. This prestigious award is given to those
            who exemplify significant contributions in marine conservation through their
            work-related efforts. Rebecca was nominated for creating the amazing Traveling Trunk
            program, and was up against some stiff competition. The other nominees are amazing folks
            who exemplify what it means to bring ocean conservation to the public, including
            Jennifer Reilly who brings marine conservation to the deaf community via American Sign
            Language and Dr. Mariana Fuentes a lead turtle researcher with Florida State University.
            Rebecca and the Traveling Trunks won the “Blue Friend of the Year” Award! We’re beyond
            thrilled for her, and are immeasurably proud of the program she’s created, and the
            students it’s served.
          </Typography>
          <Typography paragraph>
            During the 2018-2019 school year alone, over seven thousand students in the Martin and
            St. Lucie County areas experienced the magic of learning from Inwater’s Traveling Trunk
            Program. With raving reviews from students such as “it makes me want to be a marine
            biologist when I grow up!” and happy teachers who are thrilled to bring “hands-on,
            engaging, and thoughtful lesson plans” into their classNameroom, we’re excited to see the
            program grow.
          </Typography>
          <Typography paragraph>
            IRG has partnered with other organizations across the state, including preserves, parks,
            and nature centers in order to bring the traveling trunk program to students all across
            Florida. These partnerships have allowed IRG’s programs to expand to 35 trunks offered
            in 17 school districts through 17 partner organizations. IRG’s marine education programs
            can now be found in coastal Florida counties that represent 94% of total sea turtle
            nesting in Florida.
          </Typography>
        </div>
        <div className="lg:w-1/2">
          <div>
            <img
              className="rounded-2xl"
              src={BigCheck1}
              alt=""
              title="The National Save The Sea Turtle Foundation presents Ms. Rebecca Mott (4th
              from left) with a ‘big check’ in support of her award-winning educational programming
              that reaches thousands of students throughout Florida."
            />
          </div>
          <div className="pt-2">
            <em>
              The National Save The Sea Turtle Foundation presents Ms. Rebecca Mott (4th from left)
              with a ‘big check’ in support of her award-winning educational programming that
              reaches thousands of students throughout Florida.
            </em>
          </div>
        </div>
      </div>
      <div>
        <Typography paragraph>
          As we head into the next decade, IRG is looking forward to creating new partnerships and
          expanding the trunk’s reach to every county in the state, including inland communities,
          not just those with sea turtle nesting beaches. We believe that you protect what you love,
          and bringing the iconic sea turtle to the classNameroom fosters a sense of responsibility in
          Florida’s children. By bringing this hands-on program to all students in Florida, we hope
          to create a sense of stewardship in the next generation, to inspire them to become
          advocates for the ocean and to help protect the turtles they’ve learned so much about.
        </Typography>
      </div>
      <div className="py-4">
        <p className="text-base">
          Our outstanding trunk program would not be possible without the generous support of The
          National Save The Sea Turtle Foundation. If you're interested in bringing this novel
          program to your classNameroom, please visit <a href="http://inwater.org" className="underline">
            inwater.org
          </a> for more information.
        </p>
      </div>
    </div >
  );
}
