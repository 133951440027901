import React from "react";
import { Typography, Divider } from "@mui/material";
import FIUFloridaInter from "../Images/FIU-Florida-International-University.jpg";
import UncoverSecret1 from "../Images/Uncovering-the-secret-lives-of-sea-turtles.jpg";
import HawksbillTresearch from "../Images/Hawksbill-sea-turtle-research-2.jpg";
import HawksbillTresearch3 from "../Images/Hawksbill-sea-turtle-research-3.jpg";
import GreenResearch1 from "../Images/Green-sea-turtle-research.jpg";

export default function GlobalSeaTurtle() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex">
        <div className="lg:w-2/3">
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              Florida International University Leads in Global Sea Turtle Conservation
            </h1>
          </div>
          <Typography paragraph>
            Angela Nicoletti,<br />
            College of Arts, Sciences, and Education<br />
            Florida International University
          </Typography>
        </div>
        <div className="lg:w-1/3">
          <div className="lg:float-right mx-auto p-2">
            <img
              className="rounded-2xl"
              src={FIUFloridaInter}
              alt=""
              title="The National Save The Sea Turtle Foundation
Team is proud to support Florida Gulf Coast
University."
            />
          </div>
        </div>

      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="my-5">
        <Typography paragraph>
          Sea turtle populations are in decline. Pollution, sea level rise, development, habitat
          encroachment, overfishing and illegal harvesting have decimated the number of turtles
          around the globe. FIU is committed to protecting sea turtles and restoring their habitats.
          Dr. Mike Heithaus — dean of FIU’s College of Arts, Sciences & Education — oversees the
          Heithaus Lab, which has been involved in sea turtle research since 1997 when initial
          studies began as part of the Shark Bay Ecosystem Research Project in Western Australia.
        </Typography>
        <Typography paragraph>
          Using a combination of observational and experimental studies, FIU researchers are
          studying the foraging ecology, diving behavior and social interactions of sea turtles; the
          role sharks play in shaping turtle behavior; as well as the role of sea turtles in
          seagrass and coral reef ecosystems. They are working in Australia, the Caribbean Sea
          (French West Indies and Abaco, Bahamas), French Polynesia and Madagascar.
        </Typography>
      </div>
      <div className="lg:flex">
        <div className="lg:w-2/3">
          <Typography className="underline">
            <h2 className="text-2xl my-2">Uncovering the secret lives of sea turtles</h2>
          </Typography>
          <Typography paragraph>
            Sea turtles are difficult to observe in their natural habitat because they spend so much
            of their time underwater. Understanding their diet, movements and behavior is critical
            when it comes to managing and conserving populations. In the past, researchers would
            have to examine the stomach contents of dead or stranded turtles to understand more
            about these animals. Now, FIU researchers are deploying new technology that takes
            scientists along for a ride on the back of a sea turtle. Using GoPro video cameras
            attached to the turtles’ shells, FIU scientists are gathering detailed data on turtle
            diving, foraging, and social behavior. “The nice thing about using video cameras in the
            study was to be able to directly see what animals were doing and not having to make
            guesses based on other information like how long they spent underwater between each time
            they came up for air,” Heithaus said.
          </Typography>
          <Typography paragraph>
            In Shark Bay, Australia a team of researchers deployed more than 120 video cameras
            yielding nearly 400 hours of footage. They found strong patterns in sea turtle behavior
            associated with seasonal water temperatures, which have long been suspected but have
            previously proven difficult to observe. Specifically, they found turtles are more active
            during the warm season, spending most of their time swimming, surfacing, exploring vast
            seagrass meadows, feeding and interacting with other turtles. During the cold season,
            the turtles’ lives slow down as they spend most of their time resting on the seabeds and
            rarely surfacing.
          </Typography>
        </div>
        <div className="lg:w-1/3 m-5">
          <div>
            <img
              className="lg:float-right rounded-2xl"
              src={UncoverSecret1}
              alt=""
              title="The National Save The Sea Turtle Foundation
Team is proud to support Florida Gulf Coast
University."
            />
          </div>
        </div>
      </div>
      <div className="lg:flex">
        <div className="">
          <div className="underline pb-5">
            <h2 className="text-2xl my-2">Hawksbill sea turtle research</h2>
          </div>
          <div>
            <img
              className="lg:float-left lg:mr-5 mb-5 mx-auto rounded-2xl"
              src={HawksbillTresearch}
              alt=""
              title="The National Save The Sea Turtle Foundation
Team is proud to support Florida Gulf Coast
University."
            />
          </div>
          <div>
            <img
              className="lg:float-right mx-auto rounded-2xl lg:w-1/3 ml-5"
              src={HawksbillTresearch3}
              alt=""
              title="The National Save The Sea Turtle Foundation
Team is proud to support Florida Gulf Coast
University."
            />
          </div>
          <div className="pt-5">
            <div>
              <Typography paragraph>
                Hawksbill sea turtles are understudied relative to other species. Known to be
                predominantly sponge eaters, they spend their adult life on coral reefs. Previous
                research indicates that hawksbills have the potential to play a vital role in shaping
                marine ecosystems despite their small population numbers. However, the effects of turtle
                foraging on community dynamics between prey and habitat remain largely unclear. FIU
                researchers are conducting research on hawksbill foraging in Nosy Be, Madagascar using
                cameras attached to the turtles’ shell. The footage revealed the turtle’s comings and
                goings – where it went in search of food, what it ate, and whether it interacted with
                other turtles or marine species.
              </Typography>
            </div>
            <div>
              <Typography paragraph>
                In addition, researchers are analyzing data collected from Global Finprint, an
                international project focused on capturing sharks and rays on camera in their natural
                habitats. This project has received core funding from Paul G. Allen, co-founder of
                Microsoft, and is part of FIU’s Tropical Conservation Institute. Global FinPrint’s data
                is providing desperately needed information to protect the sharks and rays, but also
                giving researchers a glimpse at the health of other marine populations around the globe,
                including sea turtle populations.
              </Typography>
            </div>
          </div>



        </div>
      </div>
      <div className="my-5">
        <Typography className="underline">
          <h2 className="text-2xl my-2">Green sea turtle research</h2>
        </Typography>
        <Typography paragraph>
          Green turtles primarily graze on seagrass. This means they have the potential to affect
          seagrass communities. In Abaco, Bahamas, the Heithaus Lab has had a rare opportunity to
          study the non-human factors affecting a recently protected green turtle population so that
          management agencies can apply ecologically meaningful conservation tactics. Researchers
          have used unmanned aerial video, baited remote underwater video and seagrass surveys to
          study the foraging behavior of green turtles, including the impact of grazing on seagrass
          communities. The lab is also studying the effects of green turtle grazing on seagrass
          communities using a multi-year grazer exclusion study within four tidal creek sites along
          Abaco’s eastern coastline.
        </Typography>
      </div>
      <div className="">
        <img
          className="lg:float-left rounded-2xl mr-5 mb-5"
          src={GreenResearch1}
          alt=""
          title="The National Save The Sea Turtle Foundation
Team is proud to support Florida Gulf Coast
University."
        />
        <Typography paragraph>
          In 2014, Heithaus also co-authored a study that looked at the impacts of green sea turtles
          on seagrass communities in Bermuda, Australia, Indonesia and India, all locations with
          large green sea turtle populations. In each of the sites, data suggests seagrass meadows
          are being disrupted by heavy grazing where turtle populations are increasing and shark
          populations are down. “We’ve seen similar issues on land – the loss of wolves leads to
          population explosions of deer that then damage ecosystems,” Heithaus said. “We need
          efforts to protect and rebuild turtle populations in most of the world, but we also need
          to be working to ensure that populations of their predators are intact so the turtles
          don’t eat themselves out of house and home.”
        </Typography>
        <Typography paragraph>
          Recently, the National Save The Sea Turtle Foundation donated $45,000 to FIU to establish
          a Scholarship Fund to help marine science students advance their sea turtle research.
          Based on academic merit and a strong commitment to studying marine science, three
          senior-level student applicants will be chosen receive full-year scholarships to complete
          their undergraduate degrees. The remainder will support research labs in FIU’s College of
          Arts, Sciences & Education to further the efforts of Dr. Heithaus and his students.
        </Typography>
      </div>
    </div>
  );
}
