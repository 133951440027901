import React from "react";
import { Typography, Divider } from "@mui/material";
import Stcroix from "../Images/ST-CROIX-SEA-TURTLE-PROJECT.jpg";
import Nathalie1 from "../Images/Nathalie-was-a-dawn-turtle.png";
import CroixYop from "../Images/YouthOp.png";
import Leather1 from "../Images/LEATHERBACK-Nest-Count.jpg";
import Green1 from "../Images/GREEN-Nest-Count.jpg";
import Hawksb2 from "../Images/HAWKSBILL-Nest-Count.jpg";
import A959 from "../Images/359.jpg";
import B97102 from "../Images/97-102-58.jpg";
import C98 from "../Images/98-1058-220.jpg";
import Twatch from "../Images/TURTLE-WATCH.png";
import StcP from "../Images/StcroixPhoto.jpg";
import Outreach1 from "../Images/OUTREACH-EDUCATION.jpg";
import WhatRturtles from "../Images/What-are-sea-turtle.jpg";
import AdoptTurtle1 from "../Images/AdoptTurtle.jpg";
import Sportlight1 from "../Images/VOLUNTEER-SPOTLIGHT.png";
import MightyVol from "../Images/OUR-MIGHTY-VOLUNTEERS.png";
import StudenS from "../Images/StudentSpot.jpg";
import MightyVo2 from "../Images/OUR-MIGHTY-VOLUNTEERS-2.png";
import MightyVo3 from "../Images/OUR-MIGHTY-VOLUNTEERS-3.jpg";
import HactchNum from "../Images/HATCHLINGS-BY-THE-NUMBERS.jpg";
import ProjectSt from "../Images/ST-CROIX-SEA-TURTLE-PROJECT-2.jpg";

export default function StcroixProject() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div>
        <div className="pb-5">
          <h1 className="text-2xl font-bold">St. Croix Turtle Project</h1>
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="lg:flex justify-center items-center my-5">
        <img className="rounded-2xl" src={Stcroix} alt="" title="" />
      </div>
      <div className="lg:flex justify-center items-center my-5">
        <img className="rounded-2xl" src={Nathalie1} alt="" title="" />
      </div>
      <div className="my-5">
        <Divider className='dark:bg-[#032546] bg-white/70' />
      </div>
      <div className="lg:flex lg:gap-5">
        <div className="lg:w-2/3 mr-5">
          <Typography className="pb-5">
            <h1 className="text-2xl font-bold">2021 PROJECT SUMMARY</h1>
          </Typography>
          <Typography className="pb-5">
            <strong>
              B Y &nbsp;K E L L Y S T E W A R T ,&nbsp; P R O J E C T&nbsp; D I R E C T O R
            </strong>
          </Typography>
          <Typography paragraph>
            Although green and hawksbill sea turtles nest all year round at Sandy Point, our
            intensive research starts in March, with the arrival of the leatherbacks. These 900 lb
            mommas have traveled long distances to return home for nesting. This year we encountered
            27 individual nesters, with the oldest one being Rosie, who was first tagged back in
            1999! She still carried the flipper tag she was given - PPQ244. She has nested over 50
            times at Sandy Point! She was adopted by Nancy Byrnes.
          </Typography>
          <div className="lg:flex my-5 lg:gap-5">
            <div className="text-center">
              <Typography>LEATHERBACK</Typography>
              <div className="my-2"><img className="rounded-2xl mx-auto" src={Leather1} alt="" title="" /></div>
              <Typography>98</Typography>
            </div>
            <div className="text-center">
              <Typography>GREEN</Typography>
              <div className="my-2"><img className="rounded-2xl mx-auto" src={Green1} alt="" title="" /></div>
              <Typography>1,075</Typography>
            </div>
            <div className="text-center">
              <Typography>HAWKSBILL</Typography>
              <div className="my-2"><img className="rounded-2xl mx-auto" src={Hawksb2} alt="" title="" /></div>
              <Typography>230</Typography>
            </div>
          </div>

          <Typography paragraph>
            We saw many familiar turtle faces, including many of the turtles that our supporters
            have adopted. We also tagged 52 green turtles, and 9 hawksbills this year. The oldest
            green turtle was Happy (adopted by Sally Hewitt), first tagged in 2003 and the oldest
            hawksbill was Yesca from 2010 (adopted by the Grahams). In October and November, it's
            all about the hatchlings as our green and hawksbill nests are hatching!
          </Typography>
          <div className="lg:flex my-5 lg:gap-5">
            <div className="mt-5">
              <img className="rounded-2xl mx-auto" src={A959} alt="" title="" />
            </div>
            <div className="mt-5">
              <img className="rounded-2xl mx-auto" src={B97102} alt="" title="" />
            </div>
            <div className="mt-5">
              <img className="rounded-2xl mx-auto" src={C98} alt="" title="" />
            </div>
          </div>
          <Typography paragraph>
            A popular addition to the refuge entrance was our sea turtle nest count sign. We updated
            it daily all year for our three sea turtle species!
          </Typography>
        </div>
        <div className="lg:w-1/3">
          <img className="rounded-2xl mx-auto" src={CroixYop} alt="" title="" />
        </div>
      </div>
      <div className="lg:flex justify-center items-center my-5">
        <img className="rounded-2xl mx-auto" src={Twatch} alt="" title="" />
      </div>
      <div className="my-5">
        <Divider className='dark:bg-[#032546] bg-white/70' />
      </div>
      <div className="lg:flex">
        <div className="lg:W-1/3">
          <img className="rounded-2xl mx-auto" src={StcP} alt="" title="" />
        </div>

        <div className="lg:w-2/3 mx-auto my-5">
          <Typography className="pb-5">
            <h1 className="text-2xl font-bold">OUTREACH & EDUCATION</h1>
          </Typography>
          <Typography className="pb-5">
            <strong>
              B Y &nbsp;H A L E Y &nbsp; J A C K S O N , O U T R E A C H &nbsp;C O O R D I N A T O R
            </strong>
          </Typography>
          <Typography paragraph>
            Supporting the staff at Sandy Point National Wildlife Refuge, we hosted a variety of
            outreach events and educational opportunities for the community of St. Croix. A focus on
            youth and children is a priority!
          </Typography>
          <Typography paragraph>
            We ran two beach cleanups during the summer, participating in the International Coastal
            Cleanup (Coastweeks). These events brought our volunteers and community members together
            to clean nearly 70 lbs of trash from St. Croix beaches! In 2022 please look out for more
            of these cleanup events at beaches around the island and at Sandy Point.
          </Typography>
          <img className="rounded-2xl mx-auto mb-5" src={Outreach1} alt="" title="" />
          <div className="lg:flex lg:mr-5">
            <img className="rounded-2xl mr-5 mx-auto" src={WhatRturtles} alt="" title="" />
            <Typography paragraph>
              Participants in the Sandy Point Sea Turtle Education Program received these fantastic
              educational worksheets. They were made by Haley Jackson (as her Duke University
              Master's thesis) to be specific to St. Croix. They are available free of charge to St.
              Croix schools and teachers courtesy of Sandy Point! To request copies for your
              classrooms, please email us! sandypoint.turtlewatch@gmail.com
            </Typography>
          </div>
        </div>
      </div>
      <Typography className="pb-5">
        <h1 className="text-2xl font-bold">TRACKING LEATHERBACKS</h1>
        <strong>
          A M A Z I N G &nbsp;T R A V E L S &nbsp; A R O U N D &nbsp;T H E &nbsp; C A R I B B E A N
        </strong>
      </Typography>

      <Typography paragraph>
        In 2020, we began to satellite track leatherbacks, in collaboration with the New England
        Aquarium and Amigos de las Tortugas Marinas (ATMAR) in Maunabo, Puerto Rico. Our goal is to
        understand more about leatherback nesting site choices, as populations in the Caribbean have
        been declining. This work complements our genetics research looking at family relationships
        (moms and daughters) and survival and recruitment of the hatchlings. We can also figure out
        the dads from the genetic profiles and build extensive family trees for our leatherbacks!
      </Typography>
      <Typography paragraph>
        We have now tracked 11 leatherbacks on their journeys around the Caribbean and north to
        their foraging areas!
      </Typography>
      <Typography paragraph>
        One of the amazing tracks from 2021 was Spot. She nested three times in St. Croix, before
        heading off to Antigua, where she nested twice more. After that, she traveled to the Gulf of
        Mexico (off New Orleans), where she remained all summer into fall. Most of our leatherbacks
        head to Canadian waters, but not Spot! She's the first to venture to the Gulf!
      </Typography>
      <Typography paragraph>
        Three Sandy Point leatherbacks are still going.. Carol, Mona & Sunshine!
      </Typography>
      <Typography paragraph>
        You can see all the tracks of our satellite tagged leatherbacks online at STXTurtles.com,
        then go to Tracking. Maps are published live daily and are free to access. Sponsorships are
        welcome of course!
      </Typography>
      <div className="my-5">
        <Divider className='dark:bg-[#032546] bg-white/70' />
      </div>
      <div className="lg:flex my-5">
        <div className="lg:w-1/2">
          <Typography paragraph>
            <h1 className="text-2xl font-bold">VOLUNTEER SPOTLIGHT</h1>
            Two of our amazing volunteers are Donna Boles (left) and Dante Trivett (right). They
            help with absolutely everything at Sandy Point! Experts at keeping an eye on the
            hawksbill population, they carefully record every crawl they come across.
            <img className="rounded-2xl float-right ml-5" src={Sportlight1} alt="" title="" />
            Donna's artistic contributions to the Frederiksted community and Dante's willingness to
            learn every aspect of resource management, make them incredibly valuable team members at
            the refuge!
          </Typography>
        </div>
        <div className="lg:w-1/2 mx-auto">
          <img className="rounded-2xl mx-auto" src={AdoptTurtle1} alt="" title="" />
        </div>
      </div>
      <div className="lg:flex justify-center items-center my-5 lg:ml-5">
        <img className="rounded-2xl mx-auto" src={MightyVol} alt="" title="" />
      </div>
      <div className="lg:flex my-8">
        <div className="lg:w-2/3">
          <Typography className="pb-5">
            <h1 className="text-2xl font-bold">OUR MIGHTY VOLUNTEERS!</h1>
          </Typography>
          <Typography paragraph>
            Volunteers are an integral part of the sea turtle conservation work at Sandy Point! They
            spend many hours walking beaches identifying sea turtle crawls, assisting with
            educational programs, helping with maintenance projects around the refuge, and lending a
            hand whenever needed. In all, during 2021, volunteers spent over 1,500 hours patrolling
            Sandy Point and other St.
            <img className="rounded-2xl m-5 float-right mx-auto mb-5" src={MightyVo2} alt="" title="" /> Croix
            beaches, recording over 7,000 sea turtle activities! The work we do at Sandy Point would
            not be possible without their support.If you'd like to sign up to be notified of any
            volunteer opportunities that come up from time to time, please visit STXTurtles.com and
            click on the volunteer link!
          </Typography>
          <Typography paragraph>
            Thanks to our volunteers for their hard work and dedication!
          </Typography>
          <img className="rounded-2xl m-5" src={MightyVo3} alt="" title="" />
        </div>
        <div className="lg:w-1/3">
          <img className="rounded-2xl mx-auto" src={StudenS} alt="" title="" />
        </div>
      </div>
      <div className="lg:flex">
        <div className="lg:w-1/3">
          <Typography className="pb-5">
            <h1 className="text-2xl font-bold">HATCHLINGS BY THE NUMBERS! </h1>
          </Typography>
          <img className="rounded-2xl mx-auto" src={HactchNum} alt="" title="" />
        </div>
        <div className="lg:w-2/3">
          <Typography paragraph>
            Sandy Point is an incredible incubator! A whopping 152,105 sea turtle eggs were
            deposited this year. Total hatchlings: 108,471. By species below...
          </Typography>
          <div className="lg:flex my-5">
            <div className="mx-auto">
              <Typography paragraph>LEATHERBACK 4,665</Typography>
            </div>
            <div className="mx-auto">
              <Typography paragraph>GREEN 81,668</Typography>
            </div>
            <div className="mx-auto">
              <Typography paragraph>HAWKSBILL 22,138</Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="my-5">
        <Divider className='dark:bg-[#032546] bg-white/70' />
      </div>
      <div className="lg:flex justify-center items-center lg:ml-5 mt-5">
        <img className="rounded-2xl mx-auto" src={ProjectSt} alt="" title="" />
      </div>
    </div>
  );
}
