import React from "react";
import { Typography, Divider } from "@mui/material";
import LogoFinal from '../Images/National-Save-The-Sea-Turtle-Foundation-logo-Final.png'
import IRG3 from '../Images/Inwater-Research-group-3.jpg'
import TravelingTurtles from '../Images/Traveling-Turtles.jpg'
import AmigosdelasTortugas1 from '../Images/Amigos-de-las-Tortugas-1.jpg'
import AmigosdelasTortugas2 from '../Images/Amigos-de-las-Tortugas-2.jpg'
import TeacherFieldProfessionalDevelopment from '../Images/Teacher-Field-Professional-Development.jpg'
import ArtandConservationShown from '../Images/Art-and-Conservation-Shown.jpg'

export default function FinalReport2020() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:float-left lg:w-36 mb-5"><img className="mx-auto" src={LogoFinal} alt="" title="" /></div>
      <div className="lg:float-right lg:w-36  mb-5"><img className="border-4 border-white mx-auto" src={IRG3} alt="" title="" /></div>
      <div>
        <div className="text-center"><div className="pb-5"><h1 className='text-2xl font-bold'>International Education Initiative 2019-20 Final Report</h1></div></div>
        <div className="text-center"><Typography paragraph>April 2020</Typography></div>
        <div className="text-center"><Typography>Prepared by Rebecca Mott and the Inwater Research Group, Inc., as part of their</Typography></div>
        <div className="text-center"><Typography>International Education Initiative</Typography></div>
        <div className="text-center"><Typography>Sponsored by the</Typography></div>
        <div className="text-center pb-5"><Typography>National Save The Sea Turtle Foundation</Typography></div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <div className="pt-5"><Typography paragraph>Education</Typography></div>
      <div><Typography paragraph>Over the past 6 years, I have been fortunate enough to be funded by an organization who saw no limits to what we were capable of. When I came on board in 2014, my job duties were to write curriculum and manage our Facebook page while ensuring we had outreach supplies. With creativity, drive, hard work, and a wonderful support system, I have been able to put IRG and NSTSTF on the proverbial map. We have built a reputation within both the science and education realms by becoming an authority on how to combine the two. Scientists, educators, and organizations from all over seek us out to help them better their education practices.</Typography></div>
      <div><Typography paragraph>This is in large part due to our successful programs that reach over 30,000 students in our state each year. Hosting workshops, chairing special sessions, and presenting at meetings also brands us as experts in our field. Collaborative partnerships and finding areas of growth both from education and research sectors set us apart from most organizations.</Typography></div>
      <div><Typography paragraph>We currently run three trunk programs: “Traveling Turtles”, “Darker Skies, Darker Beaches”, and “Turtles to Go". Locally, we offer 4 TT trunks and 2 DSDB trunks. Statewide, we now have 19 partner organizations in 21 school districts with over 40 trunks being implemented every year with new students. Organizations such as Anna Maria Island Turtle Watch, Indian River County, GTM Research Reserve, Sea Turtle Preservation Society, FIU, Nature Conservancy, are some of our current and prospective partners. These numbers continue to grow as more partners commission us to build trunks.</Typography></div>
      <div><Typography paragraph>To date, we have had 19 trunks commissioned.</Typography></div>
      <div><img className="mx-auto" src={TravelingTurtles} alt="" title="" /></div>
      <div>
        <div className="pt-5"><Typography paragraph>K-12 Annual Accomplishments</Typography></div>
        <div><Typography paragraph>2014-15</Typography></div>
        <ul className="list-disc list-inside pb-5">
          <li>Began developing K-12 curricula, which was shipped to over 33 states and 4 countries, reaching over 110,000 students in its first year</li>
          <li>Developed relationship with Martin and St. Lucie County School Districts</li>
        </ul>
        <div><Typography paragraph>2015-16</Typography></div>
        <ul className="list-disc list-inside pb-5">

          <li>Began Traveling Turtles (TT) trunk program</li>
          <li>Began attending professional development days for Martin and St. Lucie County School Districts</li>
          <li>Began attending international, out-of-state, and local conferences promoting IRG’s programs</li>
          <li>Began hosting workshops for state and regional meetings</li>
          <li>Reached over 4,000 local students in trunk program’s first year</li>

        </ul>
        <div><Typography paragraph>2016-17</Typography></div>
        <ul className="list-disc list-inside pb-5">
          <li>Continued to promote our trunk program locally</li>
          <li>Continued to host workshops, present at, and host roundtables for international, out-ofstate, and local conferences</li>
          <li>Built two additional Traveling Turtles trunks</li>
          <li>Developed and beta-tested framework for statewide partnerships using our trunks across FL</li>
          <li>Secured funding to underwrite trunks for 5 statewide partners</li>
          <li>Reached over 12,000 students in statewide program’s first year</li>
        </ul>
        <div><Typography paragraph>2017-18</Typography></div>
        <ul className="list-disc list-inside pb-5">
          <li>Began Darker Skies, Darker Beaches (DSDB) trunk program locally</li>
          <li>Using previous framework, turned DSDB program into statewide program</li>
          <li>Secured grant to underwrite 5 additional statewide trunks</li>
          <li>Reached over 24,000 students with both statewide programs</li>
          <li>Continued to host workshops, present at, and be on planning committee for international, out-of-state, and local conferences</li>
          <li>Invited to present at school district-only conferences</li>
        </ul>
        <div><Typography paragraph>2018-19</Typography></div>
        <ul className="list-disc list-inside pb-5">

          <li>Continued running two statewide trunk programs</li>
          <li>Began developing framework for organizations requesting trunks outside of grant process</li>
          <li>Started partnerships with additional organizations across the state</li>
          <li>Continued to host workshops, present at, and be on planning committee for international, out-of-state, and local conferences</li>
          <li>Invited to present at school district-only conferences</li>
          <li>Commissioned to build additional trunks for previous partners to keep up with demand</li>
          <li>Reached over 28,000 students from both statewide programs</li>

        </ul>
        <div><Typography paragraph>2019-20</Typography></div>
        <ul className="list-disc list-inside pb-5">
          <li>Continued running two statewide trunk programs</li>
          <li>Forged numerous new partnerships for statewide programs</li>
          <li>Began Turtles to Go traveling trunk program for scientists and other non-educators</li>
          <li>Continued to host workshops, present at, be on planning committee for, submit videos for, and chair special sessions at international, out-of-state, and local conferences</li>
          <li>Invited to present at school district-only conferences</li>
          <li>Estimated to reach 35,000 students by the end of the school year</li>
        </ul>
      </div>

      <div>
        <div><Typography paragraph>Other Education Endeavors</Typography></div>
        <div><Typography paragraph>Turtle Ambassadors Class with the YMCA (2018)</Typography></div>
        <div><Typography paragraph>Through hands-on stations, interactive activities, and the collaboration with YMCA staff, we were able to teach kids how to protect sea turtles through the roles of both sea turtle and biologist.</Typography></div>
        <div><Typography paragraph>Amigos de las Tortugas (2019-Present)</Typography></div>
        <div><Typography paragraph>This program developed after presenting at the 2019 Permit Holder’s Meeting. A researcher at Clearwater Aquarium approached me because she wanted to bring turtle education to Hispanic communities along the west coast but didn’t know how. In exchange for translating our Turtles to Go program entirely into Spanish, we donated them one of these trunks. Clearwater Aquarium now offers Amigos de las Tortugas for Hispanic communities in their area using nothing but IRG programming, which we hope to expand in the near future.</Typography></div>
      </div>

      <div className="lg:flex lg:gap-4">
        <div className="lg:w-1/2 mb-5"><img className="rounded-2xl mx-auto" src={AmigosdelasTortugas1} alt="" title="" /></div>
        <div className="lg:w-1/2 mb-5"><img className="rounded-2xl mx-auto" src={AmigosdelasTortugas2} alt="" title="" /></div>
      </div>

      <div><Typography paragraph>Teacher Field Professional Development (2019-Present)</Typography></div>
      <div><Typography paragraph>We’ve hosted two field professional development days so far in Crystal River and have one booked for this school year in Martin and St. Lucie Counties. These days allow us to show teachers first-hand how our programs really do mirror the work we do every day at IRG. The days are split between a workshop in the classroom running through our trunk programs and a day in the field watching IRG biologists catch and work up turtles.</Typography></div>
      <div><img className="rounded-2xl mx-auto mb-5" src={TeacherFieldProfessionalDevelopment} alt="" title="" /></div>
      <div><Typography paragraph>Bridging the Gap Publication (2019-Present)</Typography></div>
      <div><Typography paragraph>It has been a goal of mine the past 6 years and will continue to be a large focus of mine, to bridge the gap that exists between sea turtle scientists and educators. Through workshops, roundtables, meetings, programs, and now a publication, I hope what I leave for this community achieves just that. In collaboration with a social scientist (FWC/FWRI), environmental educator (Earth Day Network), and data expert (Volusia County), I am working on locating areas of educational weakness in our sea turtle community. I plan on determining barriers, limitations, and perceptions toward education by scientists in our field so I can build a support system that will allow them to feel more comfortable taking on role of educator or at the very least, knowing how to reach out to those who do.</Typography></div>
      <div><Typography paragraph>This will eventually turn into a handbook detailing how each type of organization can build valuable and effective education within their group based on their resources. I plan on finding funding to turn this into statewide and eventually international workshops where scientists get hands-on experience implementing education initiatives.</Typography></div>
      <div><Typography paragraph>National Trunk Program (2020-Present)</Typography></div>
      <div><Typography paragraph>We are currently in talks with Sea Turtle Inc. about re-working our TT traveling trunk to reflect Texas-based turtle information. This would allow us to not only branch into the second largest state in the contiguous U.S., it would set the framework for upscaling our trunk to other states as well.</Typography></div>
      <div><Typography paragraph>We are also in talks with the Department of Environmental Protection and the Everglades Foundation to develop a consortium of trunk organizations across the state. This would allow us to walk organizations who specialize in various fields of conservation through the trunk creation process. We would then find funding to recreate our trunks and donate to participating consortium partners. This would allow each organization to offer well-rounded education programs that cater to fields outside their expertise.</Typography></div>
      <div><Typography paragraph>Turtle Trackers App (2020-Present)</Typography></div>
      <div><Typography paragraph>We are currently in talks with Gregor Shear who developed our nesting app to develop a new app for us. This app, Turtle Trackers, would employ citizen science to help locate sea turtle hotspots in various locations. This would allow us to focus our research efforts on areas of high density, locate areas of high human interaction, and provide citizens with education to help protect these animals. This app has research, rehab, and education implications and will even be able to alert boaters when they are reaching hotspots so they can change their boating behaviors accordingly.</Typography></div>
      <div><Typography paragraph>Youth Driven Cinema (2020-Present)</Typography></div>
      <div><Typography paragraph>Working with Martin County School District, we hold a film contest open to K-12 students. Videos will be judged by esteemed community members and the winners shown at the Lyric Theatre in Stuart. Canceled for 2020 due to Coronavirus.</Typography></div>
      <div><Typography paragraph>Go Green! Art and Conservation Shown (2020)</Typography></div>
      <div><Typography paragraph>Our first year hosting this education/art show fundraiser was a HUGE success. With roughly 80 Visionary School of Arts students participating, we were able to put on a collaborative, conservation-filled night for our community. In addition to this, we raised $1,585.91 for IRG’s research.</Typography></div>
      <div><img className="rounded-2xl mx-auto mb-5" src={ArtandConservationShown} alt="" title="" /></div>
      <div><Typography>Presentations/Meetings/Professional Development</Typography></div>
      <div className="pt-5"><Typography><u>Youth Leadership, Martin County Chapter- Stuart, FL (2016-2018)</u></Typography></div>
      <ul className="list-disc list-inside pb-5">
        <li>Professional Development (Presenter)“Who is Inwater Research Group?”</li>
      </ul>
      <div><Typography><u>Leadership, Martin County Chapter- Stuart, FL (2016-2018)</u></Typography></div>
      <ul className="list-disc list-inside pb-5"><li>Professional Development (Presenter)“Who is Inwater Research Group?”</li></ul>
      <div><Typography><u>Southeast Regional Sea Turtle Meeting- Jekyll Island, GA (2016)</u></Typography></div>
      <ul className="list-disc list-inside pb-5">
        <li>Workshop (Host):“Education, Outreach, and Social Media”</li>
        <li>Workshop (Presenter):“The Importance of Free Education”</li>
      </ul>
      <div><Typography><u>Florida Marine Turtle Permit Holder’s Meeting- Orlando, FL (2017)</u></Typography></div>
      <ul className="list-disc list-inside pb-5">
        <li>Meeting (Presenter):“Bridging the Gap Between Research and Education”</li>
        <li>Roundtable Discussion (Host):“How to Expand your Education Programs”</li>
      </ul>
      <div><Typography><u>Broward County Dark Sky Sustainability Workshop- Fort Lauderdale, FL (2017)</u></Typography></div>
      <ul className="list-disc list-inside pb-5"><li>Meeting (Presenter): “Darker Skies Darker Beaches:Using IRG Education Programs to Teach Turtle-friendly Lighting”</li></ul>
      <div><Typography><u>International Sea Turtle Symposium Presentation- Kobe, Japan (2018)</u></Typography></div>
      <ul className="list-disc list-inside pb-5"><li>Meeting (Presenter):“Finding Innovative Ways to Bring Free Quality Education Programs Statewide”</li></ul>
      <div><Typography><u>Southeast Regional Sea Turtle Meeting Workshop- Myrtle Beach, SC (2018)</u></Typography></div>
      <ul className="list-disc list-inside pb-5"><li>Workshop (Host):“Turtle Ambassadors, How Citizens can aid in Sea Turtle Conservation”</li></ul>
      <div><Typography><u>Florida Marine Science Educator’s Association Annual Conference (2018)</u></Typography></div>
      <ul className="list-disc list-inside pb-5">
        <li>Concurrent Session (Presenter):“Bringing Innovative STEM Education to Classrooms Statewide”</li>
      </ul>
      <div><Typography><u>Florida Association of Science Supervisors- Miami, FL (2018)</u></Typography></div>
      <ul className="list-disc list-inside pb-5">
        <li>Meeting (Presenter):“Working Together to Bring Quality Education to Classrooms Statewide”</li>
      </ul>
      <div><Typography><u>Florida Association of Science Teachers- Miami, FL (2018)</u></Typography></div>
      <ul className="list-disc list-inside pb-5">
        <li>Concurrent Session (Presenter):“Bringing Innovative STEM Education to Classrooms Statewide”</li>
      </ul>
      <div><Typography><u>Florida Marine Turtle Permit Holder’s Meeting- Orlando, FL (2019)</u></Typography></div>
      <ul className="list-disc list-inside pb-5">
        <li>Meeting (Presenter)“Turtles to Go: Empowering Scientists to Become Educators”</li>
      </ul>
      <div><Typography><u>International Sea Turtle Symposium- Charleston, SC (2019)</u></Typography></div>
      <ul className="list-disc list-inside pb-5">
        <li>Workshop (Host):“Beyond the Brochure: Creating a Meaningful Education Component to Secure your Research Grants”</li>
        <li>Workshop (Presenter):“Finding Innovative Ways to Bring Quality Education to Classrooms Statewide”</li>
        <li>Video Night (Presenter):“What Next: Left After the Data”</li>
        <li>Symposium (Presenter):“Creating Tomorrow’s Scientists Using Today’s Research”</li>
        <li>Live Auction (Chair/Emcee)</li>
      </ul>
      <div><Typography><u>Florida Marine Turtle Permit Holder’s Meeting- Orlando, FL (2019)</u></Typography></div>
      <ul className="list-disc list-inside pb-5">
        <li>Meeting (Presenter):“Turtles to Go: Empowering Scientists to Become Educators”</li>
      </ul>
      <div><Typography><u>Florida Marine Science Educator’s Association Annual Conference- Crystal River, FL (2019)</u></Typography></div>
      <ul className="list-disc list-inside pb-5">
        <li>Meeting (Presenter):“Working with School Districts to Bring Hands-on Science to Classrooms”</li>
        <li>Meeting (Field Experience):IRG offered a one-day field experience for interested
          educators who accompanied us alongside our boat as we worked. They got to
          experience sea turtle research first-hand as we captured, worked up, and released
          turtles in this important habitat.</li>
      </ul>
      <div><Typography><u>Florida Association of Science Supervisors- Orlando, FL (2019)</u></Typography></div>
      <ul className="list-disc list-inside pb-5">
        <li>Meeting (Presenter):“Darker Skies Darker Beaches: Using STEM to Promote Conservation”</li>
      </ul>
      <div><Typography><u>Florida Association of Science Supervisors- St. Augustine, FL (2019)</u></Typography></div>
      <ul className="list-disc list-inside pb-5">
        <li>Meeting (Presenter):“Creating Connections: The True Impact of FASS”</li>
      </ul>
      <div><Typography><u>Florida Association of Science Supervisors- St. Augustine, FL (May 2019)</u></Typography></div>
      <ul className="list-disc list-inside pb-5">
        <li>Meeting (Presenter):“Harnessing the Power of FASS”</li>
      </ul>
      <div><Typography><u>Southeast Regional Sea Turtle Meeting- Corpus Christie, TX (February 2020)</u></Typography></div>
      <ul className="list-disc list-inside pb-5">
        <li>Workshop (Host):“Learning to Adapt: Tailoring our Message to Appeal to Multiple Granting Organizations”</li>
        <li>Workshop (Co-host):“We Can do it!: Navigating the Fields of STEM as a Woman”</li>
      </ul>
      <div><Typography><u>Teacher Professional Development</u></Typography></div>
      <ul className="list-disc list-inside pb-5">
        <li>Martin County (2014- present)</li>
        <li>St. Lucie County (2014- present)</li>
        <li>Broward County (2016)</li>
        <li>Palm Beach County (2019)</li>
        <li>Collier County (2019)</li>
        <li>Citrus County (2019)</li>
        <li>Brevard County (2019)</li>

      </ul>
      <div><Typography paragraph>In addition to these meetings, we also give outreach presentations for career day, garden clubs, STEAM nights, etc.</Typography></div>
      <div><Typography paragraph>Awards</Typography></div>
      <ul>
        <li>Keep Martin Beautiful: Environmental Stewardship Award (2014)</li>
        <li>Ecology Project International: John Denham Award Finalist (2018)</li>
        <li>Loggerhead Marinelife Center: Blue Friend of the Year Award (2019)</li>
      </ul>
    </div>
  );
}
