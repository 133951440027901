import React from "react";
import { Divider, Typography } from "@mui/material";
import MEC1 from "../Images/Marine-Environmental-Education-Center.jpg";
import MEC2 from "../Images/Marine-Environmental-Education-Center-2.jpg";
import MEC3 from "../Images/Marine-Environmental-Education-Center-3.jpg";
import MEC4 from "../Images/Marine-Environmental-Education-Center-4.jpg";
import MEC5 from "../Images/Marine-Environmental-Education-Center-5.jpg";
import MEC6 from "../Images/Marine-Environmental-Education-Center-6.jpg";

export default function SupportsEducation() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex">
        <div>
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              The National Save The Sea Turtle Foundation Supports<br /> Education Programs at
              the Marine Environmental Education<br /> Center at the Carpenter House (MEEC) in
              Hollywood, Fl
            </h1>
          </div>
          <div className="pb-5">
            <h2 className="text-2xl font-bold">Dr. Derek Burkholder and the The MEEC Team</h2>
          </div>
        </div>
        <div className=" p-2">
          <img
            className="lg:float-right rounded-2xl mx-auto"
            src={MEC1}
            alt=""
            title="Marine Environmental Education Center"
          />
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="my-5">
        <img
          className="lg:float-right rounded-2xl m-5"
          src={MEC2}
          alt=""
          title="Marine Environmental Education Center"
        />
        <Typography paragraph>
          The Marine Environmental Education Center (MEEC) at the Carpenter House, located in
          Hollywood, Florida, opened in 2017 to educate the public about current marine science
          research taking place at Nova Southeastern University. When visiting the MEEC, guests get
          the chance to meet a green sea turtle, Captain, who has permanent injuries after a boat
          strike, while also learning about the plight of other endangered marine animals and
          conservation issues.
        </Typography>
        <Typography paragraph>
          Since opening the MEEC, over 33,000 guests have visited Captain and learned about the
          struggles that sea turtles are facing worldwide. The MEEC has been developing education
          programs which integrate marine science into the grade level curriculum.
        </Typography>
        <Typography paragraph>
          The goal is to build connections with classroom academic content and inspire students to
          take action in marine conservation on a local level. The MEEC has partnered with several
          local non-profit groups like Free Our Seas and Beyond and Surfrider Broward to educate the
          public on marine debris issues and local conservation efforts.
        </Typography>
        <Typography paragraph>
          To expand the MEEC’s reach in the community, they have recently welcomed two new staff
          members, thanks to the support from The National Save the Sea Turtle Foundation.
        </Typography>
      </div>
      <div className="my-5">
        <div>
          <img
            className="lg:float-left rounded-2xl m-5"
            src={MEC3}
            alt=""
            title="Marine Environmental Education Center"
          />
          <Typography paragraph>
            Laura Palomino joins the MEEC team as the Education Specialist and Volunteer
            Coordinator. She grew up in the fast-paced life of Miami, FL, but has spent most of her
            time underwater with over 500 dives (...and counting!). As an AAUS scientific diver,
            Laura has had the opportunity to work on various projects including fish surveys,
            Elkhorn coral health assessments and outplanting in the keys, and lionfish and marine
            debris removals in Biscayne National Park. She graduated from the University of Florida,
            with a B.S in Interdisciplinary Marine Science and then moved to the Florida Keys to
            intern with Reef
            <img
              className="lg:float-right rounded-2xl m-5"
              src={MEC4}
              alt=""
              title="Marine Environmental Education Center"
            />
            Environmental Education Foundation (REEF) and pursue a career in marine conservation.
            Laura discovered her passion for citizen science, environmental education, and lionfish
            awareness. This lead her to work for the Florida Fish and Wildlife Conservation
            Commission and Biscayne National Park conducting lionfish management and education
            throughout South Florida. While working at Biscayne National Park, Laura was lucky to
            experience a sea turtle hatching event and help with stranded and injured turtles. Her
            interest in marine conservation and education brought her to the MEEC, where she is
            excited to work alongside Captain and the MEEC crew to promote ocean conservation and
            awareness.
          </Typography>
        </div>
      </div>

      <div>
        <img
          className="rounded-2xl m-5"
          src={MEC5}
          alt=""
          title="Marine Environmental Education Center"
        />
      </div>
      <div className="my-5">
        <img
          className="lg:float-right rounded-2xl m-5"
          src={MEC6}
          alt=""
          title="Marine Environmental Education Center"
        />
        <Typography paragraph>
          Taylor Apter also joins the MEEC team. Originally from New Jersey, she learned at an early
          age to foster a love of the ocean at the Jersey shore. After graduating from the Marine
          Academy of Science and Technology, she attended Stonybrook University where she received a
          Bachelor of Science Degree in Marine Vertebrate Biology. Growing sick of the cold, she
          immediately moved to Florida to pursue her love of not only the ocean, but of SCUBA
          diving. Taylor worked as a SCUBA instructor for two years in Vero Beach, Florida, before
          being accepted to Nova Southeastern University’s marine science program. She is currently
          pursuing her Master of Science degree under Amy C. Hirons in the Charismatic Megafauna and
          Oceanography Laboratory. Her thesis is on the study of trophic dynamics and heavy metal
          contaminant levels in a threatened population of Northern Sea Otters. Taylor is very
          excited to combine her passion for marine creatures and education at the MEEC.
        </Typography>
        <Typography paragraph>
          If you are visiting the Hollywood area and would like to stop by to meet Captain and the
          MEEC crew, public hours are 10am-5pm, Tuesday through Saturday! Follow us on Fackbook
          <a
            href="https://www.facebook.com/SeektheMEEC/"
            class="text-blue-500 underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            @seekthemeec
          </a>
          and Instagram
          <a
            href="https://www.instagram.com/seekthemeec/"
            class="text-blue-500 underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            @the_meec
          </a>
          for updates and upcoming
        </Typography>
      </div>
    </div>
  );
}
