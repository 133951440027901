import React from "react";
import { Typography, Divider } from "@mui/material";
import InwaterResearchGroup from "../Images/Inwater-Research-group-3.jpg";
import TravelingT1 from "../Images/Traveling-Turtles-program-1.jpg";
import TravelingT2 from "../Images/Traveling-Turtles-program-2.jpg";

export default function ClassroomEducation() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>

      <div className="lg:flex lg:gap-5">
        <div className="lg:w-3/4">
          <div><h1 className="text-2xl font-bold">Taking Classroom Education to a Whole New Level</h1></div>
          <div className="pt-5">
            <Typography paragraph>
              Kara Muzia <br /> Inwater Research Group, Inc.
            </Typography>
          </div>
        </div>
        <div className="lg:w-1/4">
          <div className="lg:float-right mb-5">
            <img className="rounded-2xl mx-auto" src={InwaterResearchGroup} alt="" title="" />
          </div>
        </div>
      </div>

      <div>
        <Divider className='dark:bg-[#032546] bg-white/70' />
        <div className="pt-5">
          <Typography paragraph>
            There are STEM (Science, Technology, Engineering, and Math) programs and then there’s
            Limestone Creek Elementary. This elementary school, located in the heart of Jupiter,
            Florida, gives hands on interactive learning a new meaning.
          </Typography>
        </div>
        <div>
          <Typography paragraph>
            At Inwater Research Group, we first heard about Limestone Creek through our flagship
            education program: the Traveling Trunks. Cheryl Kenney, a fifth grade teacher at
            Limestone Creek, borrowed both of our educational trunks, Darker Skies Darker Beaches
            and Traveling Turtles, to educate her classroom. The results were outstanding.
          </Typography>
        </div>


        <div className="lg:float-left lg:mr-5">
          <img className="rounded-2xl mx-auto" src={TravelingT1} alt="" title="" />
        </div>
        <div className="pt-5">
          <Typography paragraph>
            The Darker Skies Darker Beaches education program let the students put their critical
            thinking skills to the test as they came up with beach friendly lighting! After learning
            about the effects of light pollution on nesting turtles, students are greatly motivated
            to create their own prototypes. Using batteries, white and red lights, and different
            coverings the students design the perfect, turtle friendly lights. Material costs,
            conductivity of materials also play a role in their designs, and at the end they make a
            commercial advertising their newly designed lights!
          </Typography>
        </div>
        <div>
          <Typography paragraph>
            The Traveling Turtles program allows the students to put their marine biology skills to
            the tests. In one of the lessons, students are given model turtles to weigh and measure,
            and take the overall assessment of health. Depending if the turtles have injuries or if
            they’re underweight, the students decide whether to “Rehab or Release” the animal.
          </Typography>
        </div>
        <div>
          <Typography paragraph>
            These trunks, and their fantastic lessons, are available for teachers to borrow for
            free. The power of hands-on learning is one worth spreading, and Inwater Research Group
            along with their partners at National Save the Sea Turtle Foundation are proud to be
            able to offer this trunk program.
          </Typography>
        </div>
      </div>
      <div>
        <div className="lg:float-right lg:ml-5">
          <img className="rounded-2xl mx-auto" src={TravelingT2} alt="" title="" />
        </div>
        <div className="pt-5">
          <Typography paragraph>
            Mrs. Kenney invited the IRG crew down to Limestone Creek to see our trunks, and her
            students, in action. Watching the students playing the trunk’s games, you’d never
            realize that they were learning so much. What struck us biologists was how knowledgeable
            these young people are about sea turtles. More than just fun facts, these students knew
            the seven species of turtles, which ones nested in South Florida, their habitats, and
            even their diet. They fundamentally understood some of the core issues surrounding these
            marine creatures. When asked what plagues turtles, “plastic pollution” and “light
            pollution” rank among the highest answer responses.
          </Typography>
        </div>
        <div>
          <Typography paragraph>
            While her budding scientists were sent out for recess, Mrs. Kenney gave us a tour of the
            rest of Limestone Creek Elementary. Outside, she shows us the garden. Each classroom is
            given a raised garden bed to tend, and the results are lush beautiful vegetables such as
            tomatoes and collard greens that the students take pride in growing.
          </Typography>
        </div>
        <div>
          <Typography paragraph>
            There’s also a marine science lab where students have access to aquariums and aquatic
            fish. Limestone Creek is in a formal partnership with the Reef Institute, who sends
            staff out three times a week to work on the aquariums and with the kids. There’s touch
            tanks, Pacific and Atlantic tanks where fish and coral are delineated by which oceans
            they’re grown in. Come May, the students will get to try their hand in coral fragging,
            saving the world one sea turtle and piece of coral at a time.
          </Typography>
        </div>
        <div>
          <Typography paragraph>
            Schools like Limestone Creek and classes like Mrs. Kenney’s are a wonderful example of
            how future generations can learn and have fun at the same time. STEM doesn’t have to be
            a four letter word - instead it can spell out a great way to teach youngsters about
            their environment in an interactive way, making students, parents, and educators happy.
          </Typography>
        </div>
      </div>
    </div>
  );
}
