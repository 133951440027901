import React from "react";
import { Typography, Divider } from "@mui/material";
import SupGulf from "../Images/Florida-Gulf-Coast-University.jpg";
import DrPhillAll from "../Images/Dr-Phil-Allmans.jpg";
import DrPhillAllGhana from "../Images/Dr-Allmans-team-works-with-the-people-of-Ghana.jpg";
import FGCUunivSupport from "../Images/support-Florida-Gulf-Coast-University.jpg";

export default function SupportFLgulf() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex">
        <div>
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              The National Save The Sea Turtle Foundation Supports Florida Gulf Coast University
            </h1>
          </div>
          <Typography paragraph>
            Dr. Phil Allman<br />
            Associate Professor of Vertebrate Zoology<br />
            Florida Gulf Coast University
          </Typography>
        </div>
        <div className="lg:float-right lg:ml-5 mb-5">
          <img
            className=" rounded-2xl mx-auto border-4 border-white"
            src={SupGulf}
            alt=""
            title="The National Save The Sea Turtle Foundation
Team is proud to support Florida Gulf Coast
University."
          />
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70 m-2' />
      <div className="mt-5">
        <Typography paragraph>
          The National Save The Sea Turtle Foundation recently established a scholarship program for
          students at Florida Gulf Coast University, located in Fort Myers, Florida. The
          scholarships will support senior-year undergraduate students by covering expenses related
          to tuition, books and fees, and provide additional support for Dr. Phil Allman’s sea
          turtle research and conservation projects.
        </Typography>
      </div>
      <div>
        <Typography paragraph>
          Florida Gulf Coast University and Dr. Allman’s FGCU Herpetology Research Lab provide
          opportunities for students interested in sea turtle research and conservation. Dr. Allman
          has been working on international and domestic turtle conservation issues for over 25
          years and is a member of the IUCN Tortoise and Freshwater Turtle Specialist Group as well
          as the IUCN Marine Turtle Specialist Group. As a US Fulbright Scholar, he initiated the
          first long-term sea turtle tagging and research program in Ghana (West Africa). Thirteen
          years later he continues to direct a network of sea turtle monitoring programs as part of
          the Ghana Turtle Conservation Project. The project enables undergraduate and graduate
          students from FGCU and the University of Ghana to obtain valuable field experience while
          conducting conservation-based sea turtle research.
        </Typography>
      </div>
      <div className="lg:flex">
        <figure className="lg:w-1/3">
          <img
            className="rounded-2xl mx-auto"
            src={DrPhillAll}
            alt=""
            title="Dr. Phil Allman’s sea turtle programs are focused
both in SW Florida and Ghana, West Africa."
          />
          <figcaption className="mt-2">
            <em>
              Dr. Phil Allman’s sea turtle programs are focused both in SW Florida and Ghana, West
              Africa.
            </em>
          </figcaption>
        </figure>
        <div className="lg:w-2/3 ">
          <Typography paragraph>
            In 2010, a group of 12 undergraduate students from FGCU participated in a Study Abroad
            program where they interviewed fishermen along Ghana’s coast. This research allowed us
            to discover many coastal communities consider it taboo to harm or even touch a sea
            turtle. The origin of this traditional protection is based on an oral story about sea
            turtles saving fishermen lost at sea during a storm. One of the undergraduate students
            became fascinated with this information and helped write a peer reviewed paper on the
            study – she was the primary author. Another student from a 2012 Study Abroad program
            helped publish a paper documenting loggerhead nesting activity in Ghana – a species that
            had not been seen in the region since the 1950s. FGCU students helped initiate a fishery
            observer program in Ghana to determine how many sea turtles are accidently killed as
            by-catch in the artisanal gill-net fishery. Over 12,000 gill-net fishing vessels operate
            in Ghana’s water and research indicates 4 sea turtles are killed per vessel each year.
            Olive ridley sea turtles are the most commonly captured, but these nets also kill a
            significant number of leatherback and green sea turtles.
          </Typography>
          <Typography paragraph>
            In 2016, the lab initiated an experiment to test the effectiveness of using green LED
            lights to reduce sea turtle by-catch. Three years of data indicate green lights reduce
            sea turtle captures by 80% in Ghana’s gillnet fishery. Because of the taboo against
            harming sea turtles fishermen are excited to use the green lights. Dr. Allman is leading
            another Study Abroad program in 2020 and hopes to install lights on more fishing boats
            with a goal of having lights on all boats in two fishing communities (approximately 350
            boats). Over 40 students at FGCU have participated in sea turtle research activities in
            Ghana, and many have continued conservation work through graduate research activities,
            Peace Corp, and even the US Fulbright program. Graduate students have also participated
            in sea turtle research activities in Ghana by exploring nesting patterns and population
            genetics among the species. We recently discovered that leatherback turtles are not only
            genetically similar to populations in Gabon and South Africa, but also to those of the
            Caribbean and US. An important study just completed in the lab provides genetic support
            for Archie Carr’s hypothesis that olive ridley sea turtles colonized the Atlantic Ocean
            from the Indian Ocean. Theses genetic studies helped the IUCN Marine Turtle Specialist
            Group delineate sea turtle conservation management units as a tool of prioritizing
            conservation efforts where the most work is needed.
          </Typography>
        </div>
      </div>
      <Divider className='dark:bg-[#032546] bg-white/70' />
      <br />
      <div className="lg:float-right m-5">
        <img
          className="rounded-2xl"
          src={DrPhillAllGhana}
          alt=""
          title="Dr Allman’s team works with the people of Ghana’s coast to establish
sustainable practices for the long-term protection of sea turtles."
        />
        <div className="pt-2">
          <em>
            Dr Allman’s team works with the people of Ghana’s coast <br />to establish sustainable
            practices for the long-term<br />protection of sea turtles.
          </em>
        </div>
      </div>
      <Typography paragraph>
        The lab will continue implementing conservation strategies in Ghana while simultaneously
        researching the animals to learn how best to implement those efforts. For example, the lab
        hopes to continue working with Ghana’s fishermen to further explore the most efficient use
        of LED lights on gillnets, but also to develop gear modifications on other fishery types
        (purse seine, trawlers) in Ghana that may also catch large numbers of sea turtles. It is
        also important to investigate migration patterns of Ghana’s sea turtles through satellite
        telemetry studies. This information would allow the lab to initiate multi-nation agreements
        to protect sea turtles that utilize Ghana’s beaches.
      </Typography>
      <div className="lg:float-left mr-5">
        <img
          className="rounded-2xl"
          src={FGCUunivSupport}
          alt=""
          title="The National Save The Sea Turtle Foundation
Team is proud to support Florida Gulf Coast
University."
        />
        <div className="pt-2">
          <em>
            The National Save The Sea Turtle Foundation Team<br /> is proud to support Florida Gulf
            Coast University.
          </em>
        </div>
      </div>
      <Typography paragraph>
        The FGCU Herpetology Research Lab has recently been asked to provide oversight for sea
        turtle monitoring activities at Cayo Costa State Park (CCSP) through the Florida Park
        Service. CCSP is located in southwest Florida, on Cayo Costa Island in the Gulf of Mexico
        about 12 miles west of Cape Coral. It is known to host nesting populations of loggerhead and
        green sea turtles, and both Kemp’s Ridley and hawksbill turtles are found in nearby waters.
        Dr. Allman’s team will greatly augment the Florida Park Service’s abilities to study and
        preserve the Island’s sea turtle poulations. Four undergraduate students conduct daily
        morning nesting surveys and collect data using FWC and FPS protocols. Students have the
        opportunity to conduct their own independent research while working on Cayo Costa. A student
        is currently exploring the impact of coyote predation on sea turtle recruitment with hopes
        of improving predator control measures in the park. The Herpetology lab has only been
        involved at Cayo Costa for three years but the project will continue offering research
        opportunities for FGCU’s students. The lab hopes to investigate sand temperature and changes
        in nesting patterns to explore how climate change is impacting the reproductive ecology of
        loggerhead sea turtles in southwest Florida.
      </Typography>
    </div>
  );
}
