import React from "react";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import HawksbillImg from "../Images/Hawksbill-B.jpg";
import Link from "@mui/material/Link";
import {createTheme, ThemeProvider} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#932B16", // custom secondary color
    },
  },
});

export default function Hawksbill() {
  return (
    <div className="dark:text-[#032546] dark:bg-white/70 p-5 w-full h-full bg-[#032546]/90 rounded-3xl text-[#ffffff]">
      <div>
        <Typography paragraph>
          <h2 className="text-2xl font-bold">Hawksbill Sea Turtles</h2>
        </Typography>
      </div>
      <div className="lg:float-left lg:w-1/3 mb-5 lg:mr-5">
        <img className="rounded-3xl shadow-lg" src={HawksbillImg} alt="" title="" />
      </div>
      <div>
        <Typography>
          The endangered hawksbill, a relatively small turtle, has been hunted to the brink of
          extinction for its beautiful shell. Once fairly common in Florida, these turtles now nest
          here only rarely. Hawksbills feed on sponges and other invertebrates and tend to nest on
          small isolated beaches.
        </Typography>
      </div>
      <div className="my-5 text-right mt-20">
        <ThemeProvider theme={theme}>
          <Link href="/SeaTurtles/Hawksbill" title="Hawksbill Sea Turtles">
            <Button color="secondary" variant="contained">
              More about Hawksbill Sea Turtles
            </Button>
          </Link>
        </ThemeProvider>
      </div>
    </div>
  );
}
