import React from "react";
import { Divider, Typography } from "@mui/material";
import Inwater2 from "../Images/Inwater-Research-Group-Marine-Conservation.jpg";
import Encomp from "../Images/Encompassing-Biscayne-Bay.jpg";
import Logger1 from "../Images/Loggerhead-Turtle.jpg";
import VariousRoutes from "../Images/various-routes-the-Team-explored-in-search-of-the-sea-turtles.jpg";

export default function StudySeaTurtlesinBiscayneNationalPark() {
  return (
    <div className='dark:text-[#032546] dark:bg-white/70 p-10 bg-[#032546]/90 text-[#ffffff] shadow-2xl'>
      <div className="lg:flex">
        <div>
          <div className="pb-5">
            <h1 className="text-2xl font-bold">
              Florida Hawksbill Project Partners with Inwater Research Group, Inc. to Study
              Sea Turtles in Biscayne National Park
            </h1>
          </div>
          <Typography paragraph>Cody Mott, Inwater Research Group, Inc.</Typography>
        </div>
        <div className="lg:ml-5">
          <img className="lg:float-right mx-auto rounded-2xl" src={Inwater2} alt="" title="" />
        </div>
      </div>
      <div className="my-5">
        <Divider className='dark:bg-[#032546] bg-white/70' />
      </div>
      <Typography paragraph>
        Florida’s nearshore waters are home to thousands of marine species that, collectively, play
        an important role in the health and well-being of Florida’s ecological and economic future.
        Among the diverse assemblages of marine organisms that thrive there, several species of
        marine turtles also reside among the shallow waters of Florida’s bays and estuaries.
      </Typography>
      <div className="lg:flex gap-5">
        <div className="lg:w-1/2">
          <img className="rounded-2xl mx-auto mb-5" src={Encomp} alt="" title="" />
          <Typography paragraph>
            The first trip to BNP in July was a success. Thanks to the National Park Service, our
            team members were housed at Elliot Key, giving us immediate access to the Bay each
            morning. Each day the team surveyed among the barrier mangrove islands, the nearshore
            reefs, and within the Bay itself. These baselines surveys will be used to locate sea
            turtle “hot spots” and selected for more detailed investigation on future trips. Though
            more turtles were observed in some areas than others, enough data were collected to
            begin estimating the relative abundance of sea turtle species and age groups in the
            park. Depending on our location, green turtles were most likely to be encountered
            followed by loggerheads. No hawksbills were positively identified, but that’s
            understandable given the habitat types were surveyed.
          </Typography>
          <Typography paragraph>
            The group plans to return to BNP in the summer of 2017 to continue the research. Next
            year the team will continue to survey within Biscayne Bay to search for more sea turtle
            “hot spots.” The surveys will also be expanded to include deeper offshore barrier reefs,
            preferred habitat for hawksbill turtles. These surveys will give BNP the necessary
            scientific information to better protect the threatened and endangered sea turtles
            living within the park.
          </Typography>
        </div>
        <div className="lg:w-1/2">
          <Typography paragraph>
            Encompassing Biscayne Bay and its offshore barrier reefs, Biscayne National Park (BNP)
            is located just south of Miami, Florida, USA. It is unusual because 95% of the park’s
            175,000 acres are under water and most of the land within the Park is only accessible by
            boat. These waters provide refuge to 16 endangered or threatened marine species
            including potentially significant numbers of green, loggerhead, and hawksbill sea
            turtles. Despite its proximity to the busy Miami metropolitan area and its known
            suitability as sea turtle foraging habitat, there has been surprisingly little effort to
            study the sea turtle populations found in the park. Managers depend on solid scientific
            research to make important decisions regarding the waters, habitats, and organisms
            living within the park. The Florida Hawksbill Project at The National Save The Sea
            Turtle Foundation are partnering with the Inwater Research Group, Inc. to undertake the
            first detailed study of threatened and endangered sea turtles within BNP waters. The
            resulting data will provide a long-awaited baseline of sea turtle abundance and
            distribution in this important and biologically productive part of Florida’s coastline.
            This data will then be reported to the BNP and benefit the management strategies for sea
            turtles within the park’s boundaries.
          </Typography>
          <div className="">
            <img className="rounded-2xl mx-auto" src={Logger1} alt="" title="" />
            <div className="pt-2">
              <em>
                This Loggerhead Turtle (Caretta caretta) was one of many sea turtles the <br /> team
                encountered in Miami’s Biscayne National Park. A few were <br /> selected for capture
                and taggiug.
              </em>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <img className="rounded-2xl mx-auto" src={VariousRoutes} alt="" title="" />
        <div className="pt-2">
          <em>
            The various routes the Team explored in search of the sea turtles of Biscayne National
            Park (yellow lines), and where they were spotted along the way (turtles). These
            preliminary data inform the Team of the relative abundance and distribution of the
            turtles in the Bay, and help target future efforts.
          </em>
        </div>
      </div>
    </div>
  );
}
